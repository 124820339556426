import React, { useEffect, useMemo, useState } from 'react';
import { ButtonProps, Form, FormRow, OptionsProps } from '@alpha-recycling/component-library';
import { FormikContextType } from 'formik';
import { isUndefined } from 'lodash';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldMultiSelect } from 'components/shared/Fields/FieldSelect/FieldMultiSelect';
import {
  FieldSelect,
  FieldSelectOnChangeSingle,
} from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { CompanyTypes, METAL_ABBR, PERMISSIONS, PRICE_SOURCES, UserTypes } from 'shared/constants';
import { HedgeOrderBy } from 'shared/constants/hedgeOrderBy';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import {
  Company,
  CompanyListItem,
  HedgeListItem,
  Metals,
  Status,
  UserFormData,
  UserTypeListItem,
} from 'shared/types';
import { fetchHedges } from 'store/hedgesSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { ProfitMarginSection } from './ProfitMarginsSection';
import { useTypedIntl } from '../locale/messages';

interface HedgeOptionsProps extends OptionsProps {
  hedge: HedgeListItem;
  labelHtml: {
    line1: string;
    line2?: string;
  };
}

type Props = {
  context: FormikContextType<UserFormData>;
  userTypes?: UserTypeListItem[];
  companies?: (Company | CompanyListItem)[];
  controlButtons?: React.ReactNode;
};

export const UserPricingForm = ({
  context,
  companies,
  userTypes,
  controlButtons,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const authorize = useAuthorization();
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();
  const { list: hedges } = useAppSelector(state => state.hedges);
  const [isSubscribingCompanyType, setSubscribingCompanyType] = useState(false);
  const allowedToSeeHedgePrices = authorize(PERMISSIONS.HEDGES.SHOW_HEDGE_PRICES);
  const buyerUserTypeId = userTypes?.find(r => r.name === UserTypes.BUYER)?.id;
  const isBuyer =
    !isUndefined(buyerUserTypeId) &&
    !!context.values.userTypes?.userTypes?.includes(buyerUserTypeId);

  const canModifyTermsAdjustments =
    isBuyer && authorize(PERMISSIONS.USERS.UPDATE_TERMS_ADJUSTMENTS);

  const { values, handleChange, getFieldMeta, handleBlur, setFieldValue, setFieldTouched } =
    context;

  useEffect(() => {
    dispatch(
      fetchHedges(0, 0, {
        status: Status.ACTIVE,
        orderBy: HedgeOrderBy.NAME,
        company: currentUser.company.id,
      }),
    );
  }, []);

  const hedgesOptions = useMemo(
    () =>
      hedges.length
        ? [...hedges]
            .map(hedge => {
              const prices = allowedToSeeHedgePrices
                ? [
                    `Pt: ${formatCurrency(hedge.ptPriceCust)}`,
                    `Pd: ${formatCurrency(hedge.pdPriceCust)}`,
                    `Rh: ${formatCurrency(hedge.rhPriceCust)}`,
                  ].join(', ')
                : '';

              return {
                option: {
                  hedge,
                  label: `${hedge.name}, ${formatDateWithTime(hedge.placedAt)}${
                    prices && `, ${prices}`
                  }`,
                  labelHtml: {
                    line1: `${hedge.name}, ${formatDateWithTime(hedge.placedAt)}`,
                    ...(prices && { line2: prices }),
                  },
                  value: hedge.id,
                },
                applicableTo: hedge.applicableTo,
              };
            })
            .sort()
        : [],
    [hedges],
  );
  const assignedHedgesOptions = useMemo(
    () => hedgesOptions.map(val => val.option, [hedgesOptions]),
    [hedgesOptions],
  );
  const getUsedHedgesOptions = metal =>
    hedgesOptions
      .filter(
        hedge =>
          values.prices?.assignedHedges?.includes(hedge.option.value) &&
          ((hedge.applicableTo.includes(Metals.PLATINUM) && metal === METAL_ABBR.PT) ||
            (hedge.applicableTo.includes(Metals.PALLADIUM) && metal === METAL_ABBR.PD) ||
            (hedge.applicableTo.includes(Metals.RHODIUM) && metal === METAL_ABBR.RH)),
      )
      .map(val => val.option);

  const getPriceSourceOptions = metal => {
    const availableOptions = [
      {
        value: PRICE_SOURCES.MARKET,
        label: intl.formatMessage({ id: `Global.PriceSource.${PRICE_SOURCES.MARKET}` }),
      },
    ];
    const usedHedgesOptions = getUsedHedgesOptions(metal);
    if (usedHedgesOptions && usedHedgesOptions.length > 0) {
      availableOptions.push({
        value: PRICE_SOURCES.HEDGE,
        label: intl.formatMessage({ id: `Global.PriceSource.${PRICE_SOURCES.HEDGE}` }),
      });
    }
    return availableOptions;
  };

  useEffect(() => {
    if (currentUser.userTypes.some(item => item.name === UserTypes.MANAGER)) {
      setSubscribingCompanyType(true);
    } else {
      const isSubscribingType = !!companies?.some(
        ({ id, type }) => id === values.info?.company && type === CompanyTypes.SUBSCRIBING,
      );
      setSubscribingCompanyType(isSubscribingType);
    }
  }, [values.info?.company, companies, currentUser?.id]);

  const getArrayErrors = name => {
    const indexesNotToValidate =
      getFieldMeta<{ value: string }[]>(name)
        .value?.map((val, i) => val.value === '' && i)
        .filter(Boolean) ?? [];

    const yupResult = ([] as ({ value: string } | undefined)[])
      .concat(getFieldMeta(name)?.error as unknown as { value: string })
      .filter((_, i) => !indexesNotToValidate.includes(i))
      .filter(Boolean)
      .shift()?.value;

    if (yupResult) return yupResult;

    if (
      values?.prices?.userProfitMargin
        ?.map(pM => ((pM?.value || pM?.value === 0) && pM?.value !== '-' ? +pM.value : null))
        ?.filter((e, i, a) => a.indexOf(e) !== i).length
    ) {
      return intl.formatMessage({ id: 'UserForm.Errors.UserProfitMargin.Unique' });
    }
  };

  const handleAssignedHedgeChange = val => {
    if (val.length) {
      const ids = val.map(({ value }) => value);

      if (!ids.includes(values.prices?.prices?.ptHedgeUsed))
        setFieldValue('prices.ptHedgeUsed', null);
      if (!ids.includes(values.prices?.prices?.pdHedgeUsed))
        setFieldValue('prices.pdHedgeUsed', null);
      if (!ids.includes(values.prices?.prices?.rhHedgeUsed))
        setFieldValue('prices.rhHedgeUsed', null);

      setFieldValue(
        'prices.assignedHedges',
        val.map(({ value }) => value),
      );
    } else {
      setFieldValue('prices.ptHedgeUsed', null);
      setFieldValue('prices.pdHedgeUsed', null);
      setFieldValue('prices.rhHedgeUsed', null);
    }
  };

  const handlePriceSourceChange: (metal: METAL_ABBR) => FieldSelectOnChangeSingle =
    metal => opt => {
      if (opt?.value === PRICE_SOURCES.MARKET) {
        setFieldValue(`prices.${metal}HedgeUsed`, null, false);
      }
    };

  const formatUsedHedgeLabel = (metalAbbr: METAL_ABBR, options: HedgeOptionsProps[]) =>
    options.map(({ labelHtml, hedge, value }) => {
      if (!allowedToSeeHedgePrices) return { value, label: labelHtml?.line1 };

      const price = formatCurrency(hedge?.[`${metalAbbr}PriceCust`]);
      return { value, label: `${labelHtml?.line1}, ${price}` };
    });

  const getErrors = name => {
    const { touched: fieldTouched, error } = getFieldMeta(`prices.${name}`);
    return fieldTouched && error;
  };

  return (
    <FormInWizard
      context={context}
      header={intl.formatMessage({ id: 'UserForm.UpdateHeader' })}
      controlButtons={controlButtons}
    >
      <ProfitMarginSection
        formValues={values}
        handleChange={handleChange}
        getArrayErrors={getArrayErrors}
        setFieldTouched={setFieldTouched}
        isSubscribingCompanyType={isSubscribingCompanyType}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        label={intl.formatMessage({ id: 'UserForm.Section.ProfitMargin' })}
      />
      <Form
        headerText={intl.formatMessage({ id: 'UserForm.AssignedHedges' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldMultiSelect
            label={intl.formatMessage({ id: 'UserForm.AssignedHedges' })}
            name="prices.assignedHedges"
            onChange={handleAssignedHedgeChange}
            value={values.prices?.assignedHedges}
            error={getErrors('assignedHedges')}
            options={assignedHedgesOptions}
            clearable={false}
          />
        </FormRow>
      </Form>
      <Form
        headerText={intl.formatMessage({ id: 'UserForm.Prices' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldSelect
            label={intl.formatMessage({ id: 'UserForm.PtPriceSource' })}
            name="prices.prices.ptPriceSource"
            onChange={handlePriceSourceChange(METAL_ABBR.PT)}
            value={values.prices?.prices?.ptPriceSource}
            error={getErrors('prices.ptPriceSource')}
            options={getPriceSourceOptions(METAL_ABBR.PT)}
            clearable={false}
            required
          />
          <FieldSelect
            label={intl.formatMessage({ id: 'UserForm.PtHedgeUsed' })}
            name="prices.prices.ptHedgeUsed"
            value={
              values.prices?.prices?.ptPriceSource === PRICE_SOURCES.HEDGE
                ? values.prices?.prices?.ptHedgeUsed
                : ''
            }
            options={formatUsedHedgeLabel(METAL_ABBR.PT, getUsedHedgesOptions(METAL_ABBR.PT))}
            error={getErrors('prices.ptHedgeUsed')}
            required={values.prices?.prices?.ptPriceSource === PRICE_SOURCES.HEDGE}
            disabled={values.prices?.prices?.ptPriceSource !== PRICE_SOURCES.HEDGE}
            data-cy="prices-pt-hedge-used"
          />
          <FieldSelect
            label={intl.formatMessage({ id: 'UserForm.PdPriceSource' })}
            name="prices.prices.pdPriceSource"
            onChange={handlePriceSourceChange(METAL_ABBR.PD)}
            value={values.prices?.prices?.pdPriceSource}
            error={getErrors('prices.pdPriceSource')}
            options={getPriceSourceOptions(METAL_ABBR.PD)}
            required
          />
          <FieldSelect
            label={intl.formatMessage({ id: 'UserForm.PdHedgeUsed' })}
            name="prices.prices.pdHedgeUsed"
            value={
              values.prices?.prices?.pdPriceSource === PRICE_SOURCES.HEDGE
                ? values.prices?.prices?.pdHedgeUsed
                : ''
            }
            options={formatUsedHedgeLabel(METAL_ABBR.PD, getUsedHedgesOptions(METAL_ABBR.PD))}
            error={getErrors('prices.pdHedgeUsed')}
            required={values.prices?.prices?.pdPriceSource === PRICE_SOURCES.HEDGE}
            disabled={values.prices?.prices?.pdPriceSource !== PRICE_SOURCES.HEDGE}
            data-cy="prices-pd-hedge-used"
          />
          <FieldSelect
            label={intl.formatMessage({ id: 'UserForm.RhPriceSource' })}
            name="prices.prices.rhPriceSource"
            onChange={handlePriceSourceChange(METAL_ABBR.RH)}
            value={values.prices?.prices?.rhPriceSource}
            error={getErrors('prices.rhPriceSource')}
            options={getPriceSourceOptions(METAL_ABBR.RH)}
            required
          />
          <FieldSelect
            label={intl.formatMessage({ id: 'UserForm.RhHedgeUsed' })}
            name="prices.prices.rhHedgeUsed"
            value={
              values.prices?.prices?.rhPriceSource === PRICE_SOURCES.HEDGE
                ? values.prices?.prices?.rhHedgeUsed
                : ''
            }
            options={formatUsedHedgeLabel(METAL_ABBR.RH, getUsedHedgesOptions(METAL_ABBR.RH))}
            error={getErrors('prices.rhHedgeUsed')}
            required={values.prices?.prices?.rhPriceSource === PRICE_SOURCES.HEDGE}
            disabled={values.prices?.prices?.rhPriceSource !== PRICE_SOURCES.HEDGE}
            data-cy="prices-rh-hedge-used"
          />
        </FormRow>
      </Form>
      {canModifyTermsAdjustments ? (
        <Form
          headerText={intl.formatMessage({ id: 'UserForm.TermsAdjustments' })}
          headerButton={false as unknown as ButtonProps}
        >
          <FormRow>
            <FieldInput
              label={intl.formatMessage({ id: 'UserForm.PtTermsAdjustment' })}
              name="prices.ptTermsAdjustment"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.prices?.ptTermsAdjustment as string}
              error={getErrors('ptTermsAdjustment')}
              suffix="%"
              data-cy="pt-terms-adjustment"
            />
            <FieldInput
              label={intl.formatMessage({ id: 'UserForm.PdTermsAdjustment' })}
              name="prices.pdTermsAdjustment"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.prices?.pdTermsAdjustment as string}
              error={getErrors('pdTermsAdjustment')}
              suffix="%"
              data-cy="pd-terms-adjustment"
            />
            <FieldInput
              label={intl.formatMessage({ id: 'UserForm.RdTermsAdjustment' })}
              name="prices.rhTermsAdjustment"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.prices?.rhTermsAdjustment as string}
              error={getErrors('rhTermsAdjustment')}
              suffix="%"
              data-cy="rh-terms-adjustment"
            />
          </FormRow>
        </Form>
      ) : null}
    </FormInWizard>
  );
};
