import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { ProfitMargin } from 'shared/types/profitMargin';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';
import { AlphamartHttpError, CompanyTheme } from '../shared/types';

export interface ConfigState extends GenericStoreSlice {
  auth0?: {
    domain: string;
    clientId: string;
    audience: string;
  };
  upload?: {
    converterPhoto: {
      fileSizeLimit: number;
      imageExtensions: string[];
    };
    vehiclePhoto: {
      fileSizeLimit: number;
      imageExtensions: string[];
    };
    watermark: {
      fileSizeLimit: number;
      imageExtensions: string[];
    };
    imageRecognition: {
      fileSizeLimit: number;
      imageExtensions: string[];
    };
  };
  maps?: {
    key: string;
  };
  theme?: CompanyTheme;
  profitMarginLimit?: {
    single: number;
    slider: number;
  };
  questionnaire?: {
    checkHour: number;
    checkIntervalInMinutes: number;
    skipTimeInMinutes: number;
  };
  shoppingCart?: {
    validityInDays: number;
    maxCartsPerUser: number;
  };
  termsTypeDefaultMargin?: {
    grading: ProfitMargin;
    assay: ProfitMargin;
  };
  marketTool?: {
    url: string;
  };
}

const configSlice = createSlice<ConfigState, GenericStoreReducer<ConfigState>>({
  name: 'config',
  initialState: {},
  reducers: {
    ...getGenericReducers(payload => payload ?? null),
  },
});

export const { success, failure } = configSlice.actions;

export default configSlice.reducer;

export const fetchConfig = (): GenericThunk => async (dispatch, getState, httpClient) => {
  try {
    const { data } = await httpClient.get(`${apiHostname}/api/config`);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure((err as AlphamartHttpError).message));
  }
};
