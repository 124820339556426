import React, { ReactElement, useCallback } from 'react';
import {
  Button,
  Table,
  TABLE_CELL_SIZES_TYPE,
  TableCell,
  TableHeader,
  TableRow,
} from '@alpha-recycling/component-library';
import {
  Cell,
  CellContext,
  flexRender,
  Row,
  RowData,
  Table as ReactTable,
} from '@tanstack/react-table';
import { has } from 'lodash';

import { useTypedIntl } from 'locale/messages';
import { SortDirection, SortSelectOptions, SortTypes } from 'shared/constants/sortableModules';
import { useDeepCompareEffectAfterMount } from 'shared/hooks';
import { DataListContainer, NoResultsRow, RowProps } from './DataList.styles';
import { GridCell, GridCellContent } from './DataListGrid.styles';
import { TableContainer, TableRowControlBtn } from './DataListTable.styles';
import { ListPagination } from './ListPagination';
import { LoadableContent } from '../Loader';

// eslint-disable-next-line @typescript-eslint/ban-types
type ComponentWithChildren<P = {}> = React.ComponentType<{ children?: React.ReactNode } & P>;

interface DataListProps<T extends RowData> {
  table: ReactTable<T>;
  isLoading?: boolean;
  onRowClicked?: (item: T) => void;
  gridRowComponent?: ComponentWithChildren<RowProps<T>>;
  gridBodyComponent?: ComponentWithChildren;
  outerContainerComponent?: ComponentWithChildren;
  emptyDataComponent?: ComponentWithChildren;
  sortOptions?: SortSelectOptions[];
  noDataMessage?: React.ReactNode | string;
  restIndex?: number;
  size?: TABLE_CELL_SIZES_TYPE;
  subTables?: React.ComponentType<{
    row: Row<T>;
    table?: ReactTable<T>;
    onRowClicked?: (item: T) => void;
  }>[];
  selectable?: boolean;
}

interface DataListRowProps<T extends RowData> {
  row: Row<T>;
  parentRow?: Row<T>;
  table: ReactTable<T>;
  onRowClicked?: (item: T) => void;
  gridRow: ComponentWithChildren<RowProps<T>>;
}

export function getCellByColumnId<T>(row: Row<T>, columnId: string): Cell<T, unknown> | undefined {
  return row.getAllCells().find(cell => cell.column.id === columnId);
}

export function useGetRowId() {
  return useCallback(
    <T extends { id?: number | string }>(
      originalRow: T,
      index: number,
      parent?: Row<T>,
    ): string => {
      if (parent) {
        return [parent.id, originalRow.id ?? index].join('.');
      }
      return `${originalRow.id ?? index}`;
    },
    [],
  );
}

function BodyRow<T>({
  onRowClicked,
  row,
  table,
  parentRow,
  gridRow: GridRow,
}: DataListRowProps<T>): React.ReactElement {
  const handleRowClick = () => {
    const contentSelected = !!window.getSelection()?.toString();
    if (contentSelected) return;

    if (row.getCanSelect()) row.toggleSelected();
    else if (onRowClicked) onRowClicked(row.original);
    else if (row.getCanExpand()) row.toggleExpanded();
  };

  const getRowColor = (item: T, key: string): string => (has(item, key) ? item[key] : '');

  return (
    <GridRow
      rowColor={getRowColor(row.original, 'color')}
      row={row}
      table={table}
      parentRow={parentRow}
      onClick={handleRowClick}
      isClickable={!!onRowClicked}
      data-cy="table-row"
    >
      {row.getVisibleCells().map(cell => (
        <GridCell data-cy="table-cell" cell={cell} column={cell.column} key={cell.id}>
          <GridCellContent cell={cell} />
        </GridCell>
      ))}
      {row.getCanExpand() && (
        <div className={TableRowControlBtn(row.getCanSelect())}>
          <Button
            data-cy="plus-button"
            content="icon"
            size="medium"
            iconName={row?.getIsExpanded() ? 'Minus' : 'Plus'}
            variant="plain"
            onClick={e => {
              e.stopPropagation();
              row.toggleExpanded();
            }}
          />
        </div>
      )}
    </GridRow>
  );
}

export function DataList<T extends RowData>({
  table,
  isLoading,
  onRowClicked,
  gridRowComponent: GridRow,
  gridBodyComponent: GridBody,
  outerContainerComponent: Container = TableContainer,
  emptyDataComponent: EmptyDataComponent = NoResultsRow,
  sortOptions,
  noDataMessage,
  restIndex = 0,
  size = 'auto',
  subTables,
  selectable,
}: DataListProps<T>): React.ReactElement {
  const intl = useTypedIntl();
  const paginationState = table.getState().pagination;
  const sortingState = table.getState().sorting;
  const { rows, rowsById } = table.getRowModel();

  const rowsIds = Object.keys(rowsById);
  useDeepCompareEffectAfterMount(() => {
    table.resetExpanded(true);
  }, [table, rowsIds]);

  const getParentRow = (row: Row<T>): Row<T> => {
    const rowsAscendancy = row.id.split('.');
    return rowsById[rowsAscendancy.slice(0, rowsAscendancy.length - 1).join('.')];
  };

  return (
    <DataListContainer>
      <LoadableContent loading={isLoading} mode={LoadableContent.MODE.OVERLAY}>
        <Container data-cy="table-container">
          {rows.length > 0 &&
            (GridRow && GridBody ? (
              <GridBody>
                {rows.map(row => (
                  <BodyRow
                    key={row.id}
                    onRowClicked={onRowClicked}
                    row={row}
                    parentRow={getParentRow(row)}
                    table={table}
                    gridRow={GridRow}
                  />
                ))}
              </GridBody>
            ) : (
              <Table
                header={
                  <TableHeader>
                    {table.getHeaderGroups()[0].headers.map(({ id, column, getContext }) => (
                      <TableCell key={id}>
                        {`${flexRender(column.columnDef.header, getContext()) ?? ''}`}
                      </TableCell>
                    ))}
                  </TableHeader>
                }
                restIndex={restIndex}
                size={size}
                subTableControlRow={table.getCanSomeRowsExpand()}
                selectableRows={selectable}
              >
                {rows.map(row => (
                  <TableRow
                    key={row.id}
                    {...(row.getCanSelect() && {
                      selectState: {
                        onSelectStatusChange: () => row.toggleSelected(),
                        hidden: !row.getCanSelect(),
                        innerProps: { state: row.getIsSelected() },
                      },
                    })}
                    {...(subTables &&
                      table.getCanSomeRowsExpand() && {
                        subTables: subTables.map(SubTable => (
                          <SubTable row={row} table={table} onRowClicked={onRowClicked} />
                        )),
                        subTableControlState: {
                          tableVisible: false,
                          hidden: !row.getCanExpand(),
                          innerProps: { name: 'plus-button' },
                        },
                      })}
                  >
                    {row.getVisibleCells().map(cell => (
                      <TableCell
                        key={cell.id}
                        onClick={() => !row.getCanSelect() && onRowClicked?.(row.original)}
                      >
                        {
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext() ?? ({} as CellContext<T, unknown>),
                          ) as ReactElement
                        }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </Table>
            ))}
        </Container>

        {!rows?.length && (
          <EmptyDataComponent data-cy="no-results">
            {noDataMessage ?? intl.formatMessage({ id: 'Global.NoResults' })}
          </EmptyDataComponent>
        )}
        {table.options.manualPagination && (
          <ListPagination
            onPageChange={page =>
              table.setPagination({ pageIndex: page - 1, pageSize: paginationState.pageSize })
            }
            pages={table.getPageCount()}
            currentPage={paginationState.pageIndex + 1}
            currentSortValue={
              sortingState.length
                ? {
                    sortDirection: sortingState[0].desc ? SortDirection.DESC : SortDirection.ASC,
                    sortType: sortingState[0].id as SortTypes,
                  }
                : undefined
            }
            handleSortingChange={sorting =>
              table.setSorting(
                sorting
                  ? [{ id: sorting.sortType, desc: sorting.sortDirection === SortDirection.DESC }]
                  : [],
              )
            }
            sortOptions={sortOptions}
          />
        )}
      </LoadableContent>
    </DataListContainer>
  );
}
