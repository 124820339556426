import { CaseReducer, createSlice } from '@reduxjs/toolkit';
import { endOfDay, startOfDay, subMonths } from 'date-fns';

import { HedgePlacedOn } from 'components/views/Hedges/HedgesList/HedgesFilters';
import { apiHostname, SortType } from 'shared/constants';
import { HedgeOrderBy } from 'shared/constants/hedgeOrderBy';
import { Status } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';
import { AlphamartHttpError, HedgeListItem } from '../shared/types';

type HedgeFiltersType = {
  status?: Status | null;
  orderBy?: HedgeOrderBy;
  sortBy?: SortType;
  placedOn?: HedgePlacedOn;
  company?: number | null;
  users?: number[] | null;
  activeFirst?: boolean;
};

interface FetchHedgesState extends GenericStoreSlice {
  count: number;
  list: HedgeListItem[];
}

type FetchHedgesReducer = GenericStoreReducer<FetchHedgesState> & {
  clearHedgesAction: CaseReducer<FetchHedgesState, { type: string }>;
};

const fetchHedgesSlice = createSlice<FetchHedgesState, FetchHedgesReducer>({
  name: 'hedges',
  initialState: {
    list: [],
    count: 0,
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      list: payload.data,
      count: payload.count,
    })),
    clearHedgesAction: () => ({
      list: [],
      count: 0,
      isPending: false,
      error: undefined,
    }),
  },
});

export const {
  pending: fetchHedgesAction,
  success: fetchHedgesSuccessAction,
  failure: fetchHedgesFailureAction,
  clearHedgesAction,
} = fetchHedgesSlice.actions;

export const fetchHedges =
  (
    page,
    pageSize,
    { status, orderBy, placedOn, sortBy, users, company }: HedgeFiltersType = {},
  ): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(fetchHedgesAction());
      const placedAtFrom = placedOn && startOfDay(subMonths(new Date(), placedOn)).toISOString();
      const placedAtTo = placedOn && endOfDay(new Date()).toISOString();
      const { data } = await httpClient.get(`${apiHostname}/api/hedges`, {
        params: {
          page,
          pageSize,
          status,
          orderBy,
          placedAtFrom,
          placedAtTo,
          users,
          company,
          sortBy,
        },
      });

      dispatch(fetchHedgesSuccessAction(data));
    } catch (error) {
      dispatch(fetchHedgesFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };

export default fetchHedgesSlice.reducer;
