import { css } from '@emotion/css';

import { MEDIA_QUERY, Theme } from 'theme';

export const shoppingCartMenuStyles = (theme: Theme): string => css`
  display: flex;
  align-items: center;
  background: ${theme.colors.mineShaftLighter};
  padding: 12px 40px;
  gap: 24px;
  position: relative;
  border-left: 1px solid ${theme.colors.mineShaft};
  height: 54px;
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.white};
  margin: 0 0 0 auto;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  min-width: 74px;

  @media ${MEDIA_QUERY.MD} {
    max-width: 288px;
    min-width: 288px;
    height: 96px;
    padding: 0 32px;
    justify-content: flex-start;
  }
`;
