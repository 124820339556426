import React, { useMemo } from 'react';
import { OptionsProps, Select } from '@alpha-recycling/component-library';

import { useDropdownPlacement } from 'shared/hooks';
import { ShoppingCart as ShoppingCartType } from 'shared/types';
import { HIDDEN_ON_MEDIA_QUERY } from 'theme';

interface Props {
  carts: ShoppingCartType[];
  activeCartId: number | null;
  setActiveCartId: (id: number) => void;
}

const CartSelect = ({ carts, activeCartId, setActiveCartId }: Props): React.ReactElement => {
  const { dropdownRef, refreshDropdownPlacement } = useDropdownPlacement('bottom');

  const selectOptions: OptionsProps[] = useMemo(
    () => carts.map(cart => ({ label: `${cart.name} (${cart.itemsCount})`, value: cart.id })),
    [carts],
  );
  const selectedOption: OptionsProps = useMemo(
    () => selectOptions.find(cart => cart.value === activeCartId)!,
    [selectOptions, activeCartId],
  );

  return (
    <div ref={dropdownRef}>
      <Select
        name="shoppingCart"
        value={selectedOption}
        options={selectOptions}
        onChange={option => option && option.value && setActiveCartId(+option.value)}
        onMenuOpen={refreshDropdownPlacement}
        className={HIDDEN_ON_MEDIA_QUERY.LG}
        required
      />
    </div>
  );
};

export default CartSelect;
