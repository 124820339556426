import React from 'react';
import { InputCounter, InputCounterProps } from '@alpha-recycling/component-library';

import { withFieldLabel } from '../FieldWrapper/FieldLabel';

interface Props extends Omit<InputCounterProps, 'error'> {
  label?: string;
  error?: string | boolean;
}

export const FieldNumberLayout = ({
  label,
  required,
  name,
  error,
  ...props
}: Props): React.ReactElement => (
  <InputCounter
    error={!!error}
    {...(!!error && { errorMessage: String(error) })}
    required={required}
    name={name}
    {...props}
  />
);

const FieldNumber = withFieldLabel(FieldNumberLayout);

export { FieldNumber, FieldNumberLayout as FieldNumberRaw };
