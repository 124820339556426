import { Store } from '@reduxjs/toolkit';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { merge } from 'lodash';

import { HttpStatus } from 'shared/constants/httpStatus';
import {
  PriceTimestampHeaders,
  validatePricesTimestamp,
  validateVersion,
  VersionHeaders,
} from 'shared/helpers';
import { AlphamartHttpError, isAlphamartHttpError } from 'shared/types';
import { RootState } from 'store';
import { setError } from 'store/errorsSlice';
import { clear } from 'store/shared/clearStore';
import { ErrorCode } from '../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AlphamartReduxStore = { defaultStore: Store<RootState, any> };

class HttpClient {
  instance: AxiosInstance;

  stores?: AlphamartReduxStore;

  obsoleteVersion: boolean;

  private tokenGenerator?: () => Promise<string>;

  constructor(stores?: AlphamartReduxStore) {
    this.obsoleteVersion = false;
    this.instance = axios.create({
      withCredentials: true,
    });

    this.stores = stores;

    this.instance.interceptors.request.use(async config => {
      const token = await this.tokenGenerator?.();
      if (token) {
        return merge(config, { headers: { authorization: `Bearer ${token}` } });
      }
      return config;
    });

    this.instance.interceptors.response.use(
      response => {
        if (!this.obsoleteVersion && this.stores) {
          this.obsoleteVersion = validateVersion(
            response.headers as unknown as VersionHeaders,
            this.stores,
          );
        }

        validatePricesTimestamp(response.headers as unknown as PriceTimestampHeaders);

        return response;
      },
      async err => {
        const error: AlphamartHttpError = err;
        if (
          error.response?.status === HttpStatus.FORBIDDEN_ACCESS &&
          error.response.data?.errorCode === ErrorCode.CONVERTER_TOOL_ACCESS_FORBIDDEN
        ) {
          window.location.replace(
            `${this.stores?.defaultStore.getState().config.marketTool?.url}/convertertool`,
          );
          return;
        }

        if (axios.isCancel(error)) return Promise.reject(error);

        if (
          isAlphamartHttpError(error) &&
          error.response?.status === HttpStatus.UNAUTHORIZED &&
          error.response?.data?.errorCode === ErrorCode.UNAUTH_USER &&
          !this.stores?.defaultStore.getState().auth.isPending
        ) {
          this.dispatch(clear());
        }

        if (
          isAlphamartHttpError(error) &&
          error.response?.status === HttpStatus.UNAUTHORIZED &&
          error.response?.data?.errorCode === ErrorCode.UNRECOGNIZED_DEVICE &&
          !this.stores?.defaultStore.getState().auth.isPending
        ) {
          this.dispatch(
            setError({
              httpStatus: HttpStatus.UNAUTHORIZED,
              errorCode: error?.response?.data?.errorCode,
            }),
          );
        }

        if (error?.response?.status === HttpStatus.NOT_FOUND) {
          this.dispatch(
            setError({
              httpStatus: HttpStatus.NOT_FOUND,
              errorCode: error?.response?.data?.errorCode,
            }),
          );
        }

        if (error?.response?.status === HttpStatus.FORBIDDEN_ACCESS) {
          this.dispatch(
            setError({
              httpStatus: HttpStatus.FORBIDDEN_ACCESS,
              errorCode: error?.response?.data?.errorCode,
            }),
          );
        }

        return Promise.reject(error);
      },
    );
  }

  setTokenGenerator(tokenGenerator: () => Promise<string>): void {
    this.tokenGenerator = tokenGenerator;
  }

  private dispatch(action: unknown): void {
    this.stores?.defaultStore.dispatch(action);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.get(url, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<T = any>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.instance.post(url, data, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put<T = any>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.instance.put(url, data, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch<T = any>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.instance.patch(url, data, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.delete(url, config);
  }

  getCancelToken(): CancelTokenSource {
    const { CancelToken } = axios;
    return CancelToken.source();
  }

  isCancel(error: unknown): boolean {
    return axios.isCancel(error);
  }
}

let httpClient: HttpClient;

export function setHttpClient(client: HttpClient): void {
  httpClient = client;
}

export function getHttpClient(): HttpClient {
  if (!httpClient) {
    throw new Error('HttpClient is not initialized. Make sure redux store is initialized first.');
  }
  return httpClient;
}

export default HttpClient;
