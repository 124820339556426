import React, { useMemo } from 'react';
import { useFormik } from 'formik';

import { FieldDatePickerRangeRaw } from 'components/shared/Fields/FieldDatePickerRange/FieldDatePickerRange';
import { FieldMultiSelectRaw } from 'components/shared/Fields/FieldSelect/FieldMultiSelect';
import { FieldSelectRaw } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FiltersForm } from 'components/shared/forms/FiltersForm/FiltersForm';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { DEFAULT_FILTERS, FilterableModules } from 'shared/constants';
import { FeedbackReviewStatus } from 'shared/constants/feedbackReviewStatus';
import { FEEDBACK_TYPES } from 'shared/constants/feedbackTypes';
import { useEffectAfterMount, useMediaQuery } from 'shared/hooks';
import { useGetFeedbackCompanies } from 'shared/queries';
import { useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';

export interface FeedbacksFiltersShape {
  status: FeedbackReviewStatus | null;
  type: FEEDBACK_TYPES | null;
  rating: number[] | null;
  dates: { from: Date | null; to: Date | null };
  company: number | null;
  query?: string;
}

interface Props {
  onFiltersChanged: (filters: FeedbacksFiltersShape) => void;
  onFiltersApplied: () => void;
}

export const FeedbacksFilters = ({
  onFiltersChanged,
  onFiltersApplied,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.FEEDBACKS]);

  const formikContext = useFormik<FeedbacksFiltersShape>({
    initialValues: savedFilters.data,
    onSubmit: () => {},
  });
  const { setFieldValue, setValues, values } = formikContext;
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  useEffectAfterMount(() => {
    !isMobile && onFiltersChanged(values);
  }, [values]);

  const onFiltersClear = () => {
    setValues(DEFAULT_FILTERS.feedbacks);
    onFiltersChanged(DEFAULT_FILTERS.feedbacks);
  };

  const onFiltersApply = () => {
    onFiltersChanged(values);
    onFiltersApplied();
  };

  const datesClearable = !!(values?.dates?.from || values?.dates?.to);

  const onDatesClear = () => {
    setFieldValue('dates', DEFAULT_FILTERS.feedbacks.dates);
    onFiltersChanged({ ...values, dates: DEFAULT_FILTERS.feedbacks.dates });
  };

  const companiesForFilters = useGetFeedbackCompanies();

  const companyOptions = useMemo(
    () =>
      companiesForFilters.data?.data.map(company => ({ value: company.id, label: company.name })) ??
      [],
    [companiesForFilters.data?.data],
  );

  const ratingOptions = [1, 2, 3, 4, 5].map(rating => ({
    value: rating,
    label: rating.toString(),
  }));

  const typeOptions = [FEEDBACK_TYPES.WITH_COMMENT, FEEDBACK_TYPES.WITHOUT_COMMENT].map(type => ({
    value: type,
    label: intl.formatMessage({ id: `Feedbacks.Filters.Types.${type}` }),
  }));

  const statusValues = Object.values(FeedbackReviewStatus);
  const statusOptions = statusValues.map(value => ({
    value,
    label: intl.formatMessage({ id: `Feedbacks.Filters.Status.${value}` }),
  }));

  return (
    <FiltersForm
      context={formikContext}
      onFiltersClear={onFiltersClear}
      onFiltersApply={onFiltersApply}
      savedFilters={savedFilters}
    >
      <FiltersSection
        sectionName="status"
        label={intl.formatMessage({ id: 'Feedbacks.Filters.Status' })}
      >
        <FieldSelectRaw name="status" options={statusOptions} value={values.status} />
      </FiltersSection>
      <FiltersSection
        sectionName="company"
        label={intl.formatMessage({ id: 'Feedbacks.Filters.Company' })}
      >
        <FieldSelectRaw name="company" options={companyOptions} value={values.company} clearable />
      </FiltersSection>
      <FiltersSection
        sectionName="type"
        label={intl.formatMessage({ id: 'Feedbacks.Filters.Type' })}
      >
        <FieldSelectRaw name="type" options={typeOptions} value={values.type} />
      </FiltersSection>
      <FiltersSection
        sectionName="rating"
        label={intl.formatMessage({ id: 'Feedbacks.Filters.Rating' })}
      >
        <FieldMultiSelectRaw name="rating" options={ratingOptions} value={values.rating} />
      </FiltersSection>

      <FiltersSection
        sectionName="dates"
        label={intl.formatMessage({ id: 'Feedbacks.Filters.Dates' })}
        clearable={datesClearable}
        onSectionClear={onDatesClear}
      >
        <FieldDatePickerRangeRaw name="dates" value={values.dates} />
      </FiltersSection>
    </FiltersForm>
  );
};
