import React from 'react';
import { ButtonProps, Form, FormRow } from '@alpha-recycling/component-library';
import { FormikContextType } from 'formik';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { CompanyFormData } from 'shared/types';
import { messages, useTypedIntl } from '../locale/messages';

interface Props {
  formikContext: FormikContextType<CompanyFormData>;
  label: string;
  grading?: boolean;
}

export const OtherChargesSectionComponent = ({
  formikContext,
  label,
  grading,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const {
    values: { terms },
    handleChange,
    handleBlur,
    errors,
  } = formikContext;
  const metalsReturnTermInDaysRequired = !!(
    terms.rhodiumReturnFinanceRate ||
    terms.palladiumReturnFinanceRate ||
    terms.platinumReturnFinanceRate
  );

  return (
    <Form
      data-cy="other-charges-section"
      headerText={label}
      headerButton={false as unknown as ButtonProps}
    >
      <FormRow>
        <FieldInput
          label={intl.formatMessage({ id: 'TermsForm.TreatmentChargePerPound' })}
          name="terms.treatmentChargePerPound"
          onChange={handleChange}
          onBlur={handleBlur}
          value={terms.treatmentChargePerPound ? String(terms.treatmentChargePerPound) : ''}
          error={errors.terms?.treatmentChargePerPound}
          disabled={grading}
          autoComplete="off"
          prefix="$"
          data-cy="terms-treatmentChargePerPound"
        />
        <FieldInput
          label={intl.formatMessage({ id: 'TermsForm.MetalsReturnTermInDays' })}
          name="terms.metalsReturnTermInDays"
          onChange={handleChange}
          onBlur={handleBlur}
          value={terms.metalsReturnTermInDays ? String(terms.metalsReturnTermInDays) : ''}
          error={errors.terms?.metalsReturnTermInDays}
          disabled={grading}
          autoComplete="off"
          data-cy="terms-metalsReturnTermInDays"
          maxLength={3}
          required={metalsReturnTermInDaysRequired}
        />
      </FormRow>
    </Form>
  );
};

export const OtherChargesSection = withAlphamartIntlProvider(
  OtherChargesSectionComponent,
  messages,
);
