import styled from '@emotion/styled';

import { MEDIA_QUERY, Theme } from 'theme';

export const MessageInfo = styled.div<{ theme?: Theme }>`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.mineShaftLighter};
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const RateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -40px;
`;

export const RateInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const QuestionnaireContentWrapper = styled.div`
  padding: 24px 12px;

  @media ${MEDIA_QUERY.M} {
    padding: 24px;
  }

  @media ${MEDIA_QUERY.LG} {
    padding: 24px 40px;
  }
`;

export const QuestionnaireTextAreaWrapper = styled.div`
  margin-top: 20px;
`;

export const RateInfo = styled.div<{ theme?: Theme }>`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.mineShaftLighter};
  font-size: 12px;
  text-align: center;
  @media ${MEDIA_QUERY.MAX_SM} {
    margin-top: 10px;
    text-align: start;
    margin-left: 0px;
  }
`;
