import React, { Children, useMemo } from 'react';
import { Button } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';
import { FormikContextType, FormikProvider } from 'formik';

import { useTypedIntl } from 'locale/messages';
import { useMediaQuery } from 'shared/hooks';
import { FilterData } from 'store/shared/filtersSlice';
import { MEDIA_QUERY, Theme } from 'theme';
import { FiltersFooter } from '../FiltersFooter/FiltersFooter';

export const SidebarFormContent = styled.div<{ theme?: Theme }>`
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 32px;

  fieldset:last-child {
    border-bottom: none;
  }
`;

const StyledForm = styled.form`
  height: calc(100% - 180px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .form-content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

interface Props<Values> {
  children: React.ReactNode;
  onFiltersClear: React.MouseEventHandler;
  onFiltersApply: React.MouseEventHandler;
  savedFilters: FilterData;
  context: FormikContextType<Values>;
}

export const FiltersForm = <Values,>({
  children: sections,
  context,
  onFiltersClear,
  onFiltersApply,
  savedFilters,
}: Props<Values>): React.ReactElement => {
  const intl = useTypedIntl();
  const parsedSections = useMemo(
    () => Children.toArray(sections).filter(Boolean).flat(),
    [sections],
  ) as unknown as React.ReactElement<{ sectionName: string }>[];
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  return (
    <FormikProvider value={context}>
      <StyledForm noValidate>
        <div className="form-content">
          <SidebarFormContent>
            {parsedSections.map((section, index) => (
              <React.Fragment key={section?.props?.sectionName ?? index}>{section}</React.Fragment>
            ))}
          </SidebarFormContent>
        </div>
      </StyledForm>
      <FiltersFooter>
        <Button
          onClick={onFiltersClear}
          variant="plain"
          content="text"
          disabled={savedFilters.diff.count === 0}
          label={intl.formatMessage({ id: 'Global.Filters.Clear' })}
        />
        {isMobile && (
          <Button
            onClick={onFiltersApply}
            content="text"
            disabled={context.errors && Object.keys(context.errors).length !== 0}
            label={intl.formatMessage({ id: 'Global.Filters.Apply' })}
          />
        )}
      </FiltersFooter>
    </FormikProvider>
  );
};
