import styled from '@emotion/styled';

export const TermsContainer = styled.div`
  height: 100%;
  overflow-x: hidden;
`;

export const InfoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: var(--color-function-info);
  color: var(--color-neutral-text);
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 40px;
`;
