import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { Button } from '@alpha-recycling/component-library';

import {
  AlphamartIntlProvider,
  withAlphamartIntlProvider,
} from 'components/shared/AlphamartIntlProvider';
import { CustomDragLayer } from 'components/shared/CustomDragLayer/CustomDragLayer';
import { DraggableProvider } from 'components/shared/DraggableProvider/DraggableProvider';
import { LoadableContent } from 'components/shared/Loader';
import {
  PartialConvertersContainer,
  PartialConvertersGroupHeader,
  PartialConvertersPanel,
  PartialConvertersPartialHeader,
} from 'components/views/Converters/PartialConverters/PartialConverters.styles';
import { ConvertersGroup } from 'shared/types';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { GroupsSection } from './GroupsSection/GroupsSection';
import ManageConvertersGroup from './ManageConvertersGroup/ManageConvertersGroup';
import { PartialConvertersSection } from './PartialConvertersSection/PartialConvertersSection';
import { messages, TypedFormattedMessage, useTypedIntl } from '../locale/messages';

function PartialConvertersPage(): React.ReactElement {
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const [isModalOpen, setModalOpen] = useState(false);
  const [groupToUpdate, setGroupToUpdate] = useState<ConvertersGroup | null>(null);
  const { convertersGroups, isPending: areConvertersGroupsPending } = useAppSelector(
    state => state.convertersGroups,
  );
  const { isPending: arePartialConvertersPending } = useAppSelector(
    state => state.partialConverters,
  );

  const disableCreatingGroup = useMemo(
    () => convertersGroups.some(group => !group.converters.length && !group.files.length),
    [convertersGroups],
  );

  const createGroup = useCallback(() => setModalOpen(!isModalOpen), [isModalOpen]);
  const showSnackbar = useCallback(
    () => dispatch(snackBarPushFailure(<TypedFormattedMessage id="Global.CannotCreateGroup" />)),
    [dispatch],
  );

  return (
    <AlphamartIntlProvider messages={messages}>
      <DraggableProvider>
        <PartialConvertersContainer>
          <PartialConvertersPanel>
            <PartialConvertersPartialHeader>
              <TypedFormattedMessage id="Converters.PartialConverters.PartialConverters" />
              <LoadableContent
                mode={LoadableContent.MODE.INLINE}
                loading={arePartialConvertersPending}
              />
            </PartialConvertersPartialHeader>
            <PartialConvertersGroupHeader>
              <TypedFormattedMessage id="Converters.PartialConverters.Groups" />
              <LoadableContent
                mode={LoadableContent.MODE.INLINE}
                loading={areConvertersGroupsPending}
              />
              <Button
                variant="plain"
                onClick={disableCreatingGroup ? showSnackbar : createGroup}
                content="text"
                label={intl.formatMessage({
                  id: 'Converters.PartialConverters.AddGroup',
                })}
              />
            </PartialConvertersGroupHeader>
            <PartialConvertersSection />
            <GroupsSection setModalOpen={setModalOpen} setGroupToUpdate={setGroupToUpdate} />
          </PartialConvertersPanel>
        </PartialConvertersContainer>
        <CustomDragLayer />
      </DraggableProvider>
      {isModalOpen && (
        <Suspense
          fallback={<LoadableContent mode={LoadableContent.MODE.OVERLAY} loading drawContent />}
        >
          <ManageConvertersGroup
            isVisible={isModalOpen}
            setIsVisible={setModalOpen}
            setGroupToUpdate={setGroupToUpdate}
            groupToUpdate={groupToUpdate}
          />
        </Suspense>
      )}
    </AlphamartIntlProvider>
  );
}

export const PartialConverters = withAlphamartIntlProvider(PartialConvertersPage, messages);
