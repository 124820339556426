import React from 'react';
import { ButtonProps, Form, FormRow } from '@alpha-recycling/component-library';
import { FormikContextType } from 'formik';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { CompanyFormData } from 'shared/types';
import { messages, useTypedIntl } from '../locale/messages';

interface Props {
  formikContext: FormikContextType<CompanyFormData>;
  label: string;
  grading?: boolean;
}

export const RatesAndChargesSectionComponent = ({
  formikContext,
  label,
  grading,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const {
    values: { terms },
    handleChange,
    handleBlur,
    errors,
  } = formikContext;
  const metalsReturnTermInDaysDefined = !!terms.metalsReturnTermInDays;

  return (
    <Form
      data-cy="rates-and-charges-section"
      headerText={label}
      headerButton={false as unknown as ButtonProps}
    >
      <FormRow additionalHeader={intl.formatMessage({ id: 'Terms.Section.MetalReturnRate' })}>
        <FieldInput
          label={intl.formatMessage({ id: 'TermsForm.PlatinumReturnRate' })}
          name="terms.platinumReturnRate"
          onChange={handleChange}
          onBlur={handleBlur}
          value={terms.platinumReturnRate ? String(terms.platinumReturnRate) : ''}
          error={errors.terms?.platinumReturnRate}
          required={!grading}
          disabled={grading}
          autoComplete="off"
          suffix="%"
          data-cy="terms-platinumReturnRate"
          maxLength={5}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'TermsForm.PalladiumReturnRate' })}
          name="terms.palladiumReturnRate"
          onChange={handleChange}
          onBlur={handleBlur}
          value={terms.palladiumReturnRate ? String(terms.palladiumReturnRate) : ''}
          error={errors.terms?.palladiumReturnRate}
          required={!grading}
          disabled={grading}
          autoComplete="off"
          suffix="%"
          data-cy="terms-palladiumReturnRate"
          maxLength={5}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'TermsForm.RhodiumReturnRate' })}
          name="terms.rhodiumReturnRate"
          onChange={handleChange}
          onBlur={handleBlur}
          value={terms.rhodiumReturnRate ? String(terms.rhodiumReturnRate) : ''}
          error={errors.terms?.rhodiumReturnRate}
          required={!grading}
          disabled={grading}
          autoComplete="off"
          suffix="%"
          data-cy="terms-rhodiumReturnRate"
          maxLength={5}
        />
      </FormRow>
      <FormRow additionalHeader={intl.formatMessage({ id: 'Terms.Section.FinanceRate' })}>
        <FieldInput
          label={intl.formatMessage({ id: 'TermsForm.PlatinumReturnFinanceRate' })}
          name="terms.platinumReturnFinanceRate"
          onChange={handleChange}
          onBlur={handleBlur}
          value={terms.platinumReturnFinanceRate ? String(terms.platinumReturnFinanceRate) : ''}
          error={errors.terms?.platinumReturnFinanceRate}
          autoComplete="off"
          suffix="%"
          data-cy="terms-platinumReturnFinanceRate"
          maxLength={5}
          disabled={grading}
          required={metalsReturnTermInDaysDefined}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'TermsForm.PalladiumReturnFinanceRate' })}
          name="terms.palladiumReturnFinanceRate"
          onChange={handleChange}
          onBlur={handleBlur}
          value={terms.palladiumReturnFinanceRate ? String(terms.palladiumReturnFinanceRate) : ''}
          error={errors.terms?.palladiumReturnFinanceRate}
          autoComplete="off"
          suffix="%"
          data-cy="terms-palladiumReturnFinanceRate"
          maxLength={5}
          disabled={grading}
          required={metalsReturnTermInDaysDefined}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'TermsForm.RhodiumReturnFinanceRate' })}
          name="terms.rhodiumReturnFinanceRate"
          onChange={handleChange}
          onBlur={handleBlur}
          value={terms.rhodiumReturnFinanceRate ? String(terms.rhodiumReturnFinanceRate) : ''}
          error={errors.terms?.rhodiumReturnFinanceRate}
          autoComplete="off"
          suffix="%"
          data-cy="terms-rhodiumReturnFinanceRate"
          maxLength={5}
          disabled={grading}
          required={metalsReturnTermInDaysDefined}
        />
      </FormRow>
      <FormRow additionalHeader={intl.formatMessage({ id: 'Terms.Section.RefiningCharge' })}>
        <FieldInput
          label={intl.formatMessage({ id: 'TermsForm.PlatinumRefiningCharge' })}
          name="terms.platinumRefiningCharge"
          onChange={handleChange}
          onBlur={handleBlur}
          value={terms.platinumRefiningCharge ? String(terms.platinumRefiningCharge) : ''}
          error={errors.terms?.platinumRefiningCharge}
          autoComplete="off"
          prefix="$"
          data-cy="terms-platinumRefiningCharge"
          maxLength={3}
          disabled={grading}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'TermsForm.PalladiumRefiningCharge' })}
          name="terms.palladiumRefiningCharge"
          onChange={handleChange}
          onBlur={handleBlur}
          value={terms.palladiumRefiningCharge ? String(terms.palladiumRefiningCharge) : ''}
          error={errors.terms?.palladiumRefiningCharge}
          autoComplete="off"
          prefix="$"
          data-cy="terms-palladiumRefiningCharge"
          maxLength={3}
          disabled={grading}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'TermsForm.RhodiumRefiningCharge' })}
          name="terms.rhodiumRefiningCharge"
          onChange={handleChange}
          onBlur={handleBlur}
          value={terms.rhodiumRefiningCharge ? String(terms.rhodiumRefiningCharge) : ''}
          error={errors.terms?.rhodiumRefiningCharge}
          autoComplete="off"
          prefix="$"
          data-cy="terms-rhodiumRefiningCharge"
          maxLength={3}
          disabled={grading}
        />
      </FormRow>
    </Form>
  );
};

export const RatesAndChargesSection = withAlphamartIntlProvider(
  RatesAndChargesSectionComponent,
  messages,
);
