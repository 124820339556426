import React, { useMemo } from 'react';
import { InputText, InputTextProps } from '@alpha-recycling/component-library';

import { withFieldLabel } from '../FieldWrapper/FieldLabel';

export interface FieldInputProps extends Omit<InputTextProps, 'error'> {
  capitalize?: boolean;
  error?: string | boolean | React.ReactNode;
  protection?: {
    hide?: boolean;
    copy?: boolean;
    paste?: boolean;
  };
}

export const FieldInputLayout = React.forwardRef<HTMLInputElement, FieldInputProps>(
  (
    {
      maxLength = 524288,
      error,
      protection = { hide: false, copy: false, paste: false },
      value,
      capitalize,
      onChange,
      name = '',
      required,
      ...props
    },
    ref,
  ) => {
    const maskedValue = useMemo(
      () => (capitalize ? String(value).toUpperCase() : String(value)),
      [value],
    );

    const onPaste = e => {
      if (protection && protection.paste) {
        e.preventDefault();
      }
    };

    const onCopy = e => {
      if (protection && protection.paste) {
        e.preventDefault();
      }
    };

    const handleChange = e => {
      const inputValue = e.target.value;
      const parsedValue = inputValue.replace(/\s+/g, ' ').trimStart();
      e.target.value = parsedValue;
      onChange && onChange(e);
    };

    return (
      <InputText
        type={protection && protection.hide ? 'password' : 'text'}
        maxLength={maxLength}
        error={!!error}
        {...(!!error && { errorMessage: String(error) })}
        value={maskedValue}
        onPaste={onPaste}
        onCopy={onCopy}
        onChange={handleChange}
        name={name}
        required={required}
        ref={ref}
        {...props}
      />
    );
  },
);

const FieldInput = withFieldLabel(FieldInputLayout);

export { FieldInput, FieldInputLayout as FieldInputRaw };
