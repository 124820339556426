import { AccessFlag, ALLOW_ALL, UserTypes } from 'shared/constants';
import { useCurrentUser } from '../hooks';

export type AllowedFor = (AccessFlag | UserTypes)[] | typeof ALLOW_ALL;

type AuthMethod = (allowedFor: AllowedFor, options?: Options) => boolean;

type Options = {
  gradingRequirement?: 'assay' | 'grading' | null;
};

const useAuthorization = (): AuthMethod => {
  const user = useCurrentUser();

  return (allowedFor, options) => {
    if (allowedFor === ALLOW_ALL) return true;

    const hasUserType = !!user?.userTypes?.some(userType => allowedFor.includes(userType.name));
    const hasAccess = !!user?.accessFlags?.some(access => allowedFor.includes(access));
    const grading =
      !options?.gradingRequirement ||
      (user?.company?.grading && options?.gradingRequirement === 'grading') ||
      (user?.company?.grading === null && options?.gradingRequirement === null) ||
      (!user?.company?.grading && options?.gradingRequirement === 'assay');
    return (hasUserType || hasAccess) && grading;
  };
};

export { useAuthorization };
