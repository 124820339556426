import React, { useEffect, useMemo } from 'react';
import { InputGroup, OptionsProps } from '@alpha-recycling/component-library';
import { useField } from 'formik';

import { useTypedIntl } from 'locale/messages';
import { FieldSelectRaw } from '../FieldSelect/FieldSelect';
import { withFieldLabel } from '../FieldWrapper/FieldLabel';

type Value = {
  from?: number | string | null;
  to?: number | string | null;
};
interface Props {
  disabled?: boolean;
  name: string;
  onBlur?: () => void;
  onChange?: (value?: Value) => void;
  onFocus?: (event?: React.FocusEvent) => void;
  options: OptionsProps[];
  searchable?: boolean;
  value?: Value;
  error?: boolean;
}

const FieldRangeLayout = ({
  disabled,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  searchable = true,
  error,
  value,
}: Props) => {
  const intl = useTypedIntl();
  const [, , { setValue, setTouched }] = useField(name);
  const touchDevice = 'ontouchstart' in window;
  const handleChangeFrom = val => {
    onChange?.({ ...value, from: val });
    setValue({ ...value, from: val });
  };

  const handleChangeTo = val => {
    onChange?.({ ...value, to: val });
    setValue({ ...value, to: val });
  };
  const handleFocus = e => {
    onFocus?.(e);
  };
  const handleBlur = () => {
    setTouched(true);
    onBlur?.();
  };
  useEffect(() => {
    if (value) setTouched(true);
  }, [value]);

  const selectedFrom = useMemo(
    () => options?.find(option => value?.from === option.value)?.value ?? null,
    [options, value],
  );
  const selectedTo = useMemo(
    () => options?.find(option => value?.to === option.value)?.value ?? null,
    [options, value],
  );
  const optionsFrom = useMemo(
    () => options.filter(option => option.value && option.value <= (value?.to ?? Number.MAX_VALUE)),
    [value, options],
  );
  const optionsTo = useMemo(
    () =>
      options.filter(option => option.value && option.value >= (value?.from ?? Number.MIN_VALUE)),
    [value, options],
  );

  return (
    <InputGroup data-cy={name} error={error} disabled={disabled} size="fifty-fifty">
      <FieldSelectRaw
        placeholder={intl.formatMessage({ id: 'Global.Fields.Select.From' })}
        disabled={disabled}
        searchable={!touchDevice && searchable}
        name={`${name}From`}
        onChange={handleChangeFrom}
        onBlur={handleBlur}
        onFocus={handleFocus}
        options={optionsFrom}
        value={selectedFrom}
        clearable={false}
      />
      <FieldSelectRaw
        placeholder={intl.formatMessage({ id: 'Global.Fields.Select.To' })}
        disabled={disabled}
        searchable={!touchDevice && searchable}
        name={`${name}To`}
        onChange={handleChangeTo}
        onBlur={handleBlur}
        onFocus={handleFocus}
        options={optionsTo}
        value={selectedTo}
        clearable={false}
      />
    </InputGroup>
  );
};

const FieldRangeFilters = withFieldLabel(FieldRangeLayout);

export { FieldRangeFilters, FieldRangeLayout as FieldRangeLayoutRaw };
