import React from 'react';
import { Icon } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { theme } from 'theme';

interface Props {
  onClick?: () => void;
}

const BasketWrapper = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
`;

export function Basket({ onClick }: Props): React.ReactElement {
  return (
    <BasketWrapper onClick={onClick} data-cy="basket">
      <Icon name="BasketSecondary" size="medium" fillColor={theme.colors.white} />
    </BasketWrapper>
  );
}
