import React from 'react';
import { CellContext, flexRender } from '@tanstack/react-table';

import { IconTooltip } from 'components/shared/IconTooltip/IconTooltip';
import {
  getCellByColumnId,
  GridActionsContainer,
  GridCellContent,
  GridImageContainer,
  GridImagePlaceholder,
  GridItem,
  GridItemTooltipWrapper,
  GridItemValues,
  RowProps,
} from 'components/shared/List';
import { lambdaHostname } from 'shared/constants';
import { useExtendedTheme } from 'shared/hooks';
import { AssayListItem } from 'shared/types/assayListItem';
import { AssaysSearchGrid } from './AssaysSearchGrid';
import { TypedFormattedMessage as FormattedMessage } from '../locale/messages';

export const AssaysListGridItem = ({
  row,
  parentRow,
  onClick,
  table,
}: React.PropsWithChildren<RowProps<AssayListItem>>) => {
  const assay = row!.original;
  const theme = useExtendedTheme();
  const sampleNameCell = getCellByColumnId(row!, 'sampleName');
  const relatedConverterCell = getCellByColumnId(row!, 'relatedConverter');
  const actionsCell = getCellByColumnId(row!, 'actions');

  return (
    <GridItem row={row} parentRow={parentRow} onClick={onClick}>
      {assay?.converterPhoto ? (
        <GridImageContainer>
          <img
            alt={assay?.converterIdentifier}
            src={`${lambdaHostname}${assay?.converterPhoto?.presignedUrls?.LARGE}`}
          />
        </GridImageContainer>
      ) : (
        <GridImagePlaceholder>
          <FormattedMessage id="Global.NoPhoto" />
        </GridImagePlaceholder>
      )}
      <GridItemValues>
        <div>
          <GridCellContent cell={sampleNameCell} />
          <GridCellContent cell={relatedConverterCell} />
        </div>
        {assay.converterCounterfeit && (
          <GridItemTooltipWrapper>
            <IconTooltip
              key="warning"
              icon="Danger"
              size="big"
              color={theme.colors.fuzzyWuzzyBrown}
              tooltip={assay.converterNotes}
            />
          </GridItemTooltipWrapper>
        )}
      </GridItemValues>
      <GridActionsContainer>
        <span>
          <FormattedMessage id="Global.Actions" />:
        </span>
        {flexRender(
          actionsCell?.column?.columnDef.cell,
          actionsCell?.getContext?.() as CellContext<AssayListItem, unknown>,
        )}
      </GridActionsContainer>
      <AssaysSearchGrid row={row} table={table} />
    </GridItem>
  );
};
