import styled from '@emotion/styled';

export const StyledPrice = styled.span`
  word-break: keep-all;
`;

export const ListSections = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
`;

export const ListSection = styled.div`
  h3 {
    font-size: 14px;
  }
`;
