import React, { useState } from 'react';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { PanelLayout } from 'components/shared/PanelLayout/PanelLayout';
import { CompanyTypes, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser, useMarketToolUrls, useSubscriptionSeats } from 'shared/hooks';
import { RegularUserMessage } from './BasicUserView/BasicUserView';
import { messages as localMessages } from './locale/messages';
import { GradingSelectedStep } from './Steps/GradingSelectedStep/GradingSelectedStep';
import { GradingSelectStep } from './Steps/GradingSelectStep/GradingSelectStep';
import { TermsSelectStep } from './Steps/TermsSelectStep/TermsSelectStep';
import { messages as companyMessages } from '../Companies/locale/messages';

enum STEPS {
  GRADING_SELECT = 'GRADING_SELECT',
  GRADING_SELECTED = 'GRADING_SELECTED',
  GRADING_NOT_SELECTED = 'GRADING_NOT_SELECTED',
}

const Questionnaire = () => {
  const [step, setStep] = useState<STEPS>(STEPS.GRADING_SELECT);
  const hasManySeats = useSubscriptionSeats(seats => seats > 1);
  const authorize = useAuthorization();
  const user = useCurrentUser();
  const canGoThroughAllSteps =
    !hasManySeats ||
    (user.company.type === CompanyTypes.INTERNAL &&
      authorize(PERMISSIONS.SET_YOUR_APP.INTERNAL_SETUP));
  const marketNewsUrls = useMarketToolUrls();

  if (!canGoThroughAllSteps) {
    return (
      <TermsSelectStep onCancel={() => window.location.replace(marketNewsUrls.home)} assayOnly />
    );
  }

  switch (step) {
    case STEPS.GRADING_SELECT:
      return (
        <GradingSelectStep
          onSetAppClick={gradingSelected =>
            setStep(gradingSelected ? STEPS.GRADING_SELECTED : STEPS.GRADING_NOT_SELECTED)
          }
        />
      );
    case STEPS.GRADING_SELECTED:
      return <GradingSelectedStep />;
    case STEPS.GRADING_NOT_SELECTED:
      return <TermsSelectStep onCancel={() => setStep(STEPS.GRADING_SELECT)} />;
  }
};

const SetYourAppComponent = () => {
  const user = useCurrentUser();
  const authorize = useAuthorization();
  const canSeeQuestionnaire =
    authorize(PERMISSIONS.SET_YOUR_APP.QUESTIONNAIRE) ||
    (user.company.type === CompanyTypes.INTERNAL &&
      authorize(PERMISSIONS.SET_YOUR_APP.INTERNAL_SETUP));

  return (
    <PanelLayout hideInternalNavigation>
      {canSeeQuestionnaire ? <Questionnaire /> : <RegularUserMessage />}
    </PanelLayout>
  );
};

export const SetYourApp = withAlphamartIntlProvider(SetYourAppComponent, {
  EN: { ...localMessages.EN, ...companyMessages.EN },
  ES: { ...localMessages.ES, ...companyMessages.ES },
  FR: { ...localMessages.FR, ...companyMessages.FR },
  PL: { ...localMessages.PL, ...companyMessages.PL },
});
