import React from 'react';
import { DataOutput, OutputCell } from '@alpha-recycling/component-library';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { SHARED } from 'shared/constants';
import { Terms } from 'shared/types';
import { messages, useTypedIntl } from '../../locale/messages';

type Props = {
  terms: Terms;
};

const MiscellaneousFeesComponent = ({ terms }: Props): React.ReactElement => {
  const intl = useTypedIntl();

  return (
    <DataOutput
      headerText={intl.formatMessage({
        id: 'Terms.MiscellaneousFees.MiscellaneousFees',
      })}
    >
      {[
        {
          name: 'miscellaneousFees',
          value: terms.miscellaneousFees ?? SHARED.LONG_DASH,
          label: intl.formatMessage({
            id: 'Terms.MiscellaneousFees.MiscellaneousFees',
          }),
        },
      ].map(field => (
        <OutputCell key={field.name} data-cy={field.name} mergeCells={4} labelValue={field.label}>
          {field.value}
        </OutputCell>
      ))}
    </DataOutput>
  );
};

export const MiscellaneousFees = withAlphamartIntlProvider(MiscellaneousFeesComponent, messages);
