import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { LoadableContent } from 'components/shared/Loader';
import { AccessFlag, MarginVisibility, PERMISSIONS, UserTypes } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useGetCompanies } from 'shared/queries';
import { BooleanString, UserFormData } from 'shared/types';
import { fetchUser as fetchCurrentUser } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateUser } from 'store/updateUserSlice';
import { fetchUser } from 'store/userDetailsSlice';
import { useProcessMarginWarnings } from '../hooks/useProcessMarginWarnings';
import { messages, useTypedIntl } from '../locale/messages';
import { UserForm } from '../UserForm/UserForm';

function UpdateUserComponent(): React.ReactElement {
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const authorize = useAuthorization();
  const processWarnings = useProcessMarginWarnings();
  const userId = Number(useParams<{ id: string }>().id);
  const { user, isPending } = useAppSelector(({ userDetails }) => userDetails);
  const { isPending: isUserUpdatePending } = useAppSelector(state => state.updateUser);
  const currentUser = useCurrentUser();
  const allowedToFetchCompanies = authorize(PERMISSIONS.COMPANIES.LIST);
  const { data: fetchedCompanies } = useGetCompanies({}, { enabled: allowedToFetchCompanies });
  const companies = allowedToFetchCompanies ? fetchedCompanies?.data : [currentUser.company];

  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch]);

  const allowedToChangeTermsAdjustment = authorize(PERMISSIONS.USERS.UPDATE_TERMS_ADJUSTMENTS);
  const allowedToChangeDisplayHedgePrices = authorize(
    PERMISSIONS.USERS.SHOW_DISPLAY_HEDGE_PRICES_FIELD,
  );
  const initialValues: UserFormData | undefined = user && {
    info: {
      company: user.company.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      mobilePhone: user.mobilePhone,
      mobilePhonePrefix: user.mobilePhonePrefix,
      officePhone: user.officePhone || '',
      officePhonePrefix: user.officePhonePrefix || '',
    },
    userTypes: {
      userTypes: user.userTypes?.map(r => r.id) || [],
      chosenUserTypes: [],
      userAccessFlags: {
        ...Object.fromEntries(Object.values(AccessFlag).map(flag => [flag, false])),
        ...Object.fromEntries(user?.accessFlags?.map(key => [key, true])),
      },
      privileges: {
        additionalDevice: (user.privileges?.additionalDevice ?? false).toString() as BooleanString,
      },
    },
    prices: {
      marginVisibility: user.marginVisibility,
      userProfitMargin: user.profitMargins || [{ value: user.selectedProfitMargin }],
      assignedHedges: user.assignedHedges?.map(({ id }) => id) ?? [],
      prices: {
        ...user.prices,
        ptHedgeUsed: user.prices?.ptHedgeUsed?.id,
        pdHedgeUsed: user.prices?.pdHedgeUsed?.id,
        rhHedgeUsed: user.prices?.rhHedgeUsed?.id,
      },
      ...(user.userTypes.some(({ name }) => name === UserTypes.BUYER)
        ? {
            ptTermsAdjustment: user.ptTermsAdjustment,
            pdTermsAdjustment: user.pdTermsAdjustment,
            rhTermsAdjustment: user.rhTermsAdjustment,
          }
        : {}),
      currentProfitMarginPt: user.currentProfitMarginPt,
      currentProfitMarginPd: user.currentProfitMarginPd,
      currentProfitMarginRh: user.currentProfitMarginRh,
    },
  };

  const handleSuccess = () => {
    dispatch(
      snackBarPushSuccess(
        intl.formatMessage({
          id: 'UserForm.Message.SuccessUpdate',
        }),
      ),
    );

    navigate(`/users/${userId}`);
  };

  const onSubmit = async (values: UserFormData) => {
    if (!companies) return;
    const selectedCompany = [...companies].find(c => c.id === values?.info.company);
    const canUseMarginSliderPerMetal = selectedCompany?.canUseProfitMarginPerMetal;
    const parseUserParams = {
      allowedToChangeTermsAdjustment,
      allowedToChangeDisplayHedgePrices,
      canUseMarginSlider:
        values.prices.marginVisibility === MarginVisibility.SLIDER_AND_TOTAL_MARGIN ||
        values.prices.marginVisibility === MarginVisibility.SLIDER_MARGIN_ONLY,
      canUseMarginSliderPerMetal,
    };

    try {
      await dispatch(updateUser(values, userId, parseUserParams));
      handleSuccess();
    } catch (e) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }

    if (currentUser.id === +userId) dispatch(fetchCurrentUser());
  };

  return (
    <LoadableContent loading={isPending || isUserUpdatePending}>
      {user && <UserForm onSubmit={processWarnings(onSubmit)} initialValues={initialValues} />}
    </LoadableContent>
  );
}

export const UpdateUser = withAlphamartIntlProvider(UpdateUserComponent, messages);
