import React, { useMemo } from 'react';
import { Button } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { theme } from 'theme';

const SectionCell = styled.div<{ noPadding?: boolean }>`
  margin: 12px 0;
  padding: ${({ noPadding }) => (noPadding ? 0 : '0 32px')};
  display: block;
`;

const FieldSet = styled.fieldset`
  padding: 0;
  border: none;
  margin-left: 0;
  margin-right: 0;
  padding: 0 0 16px;
  border-bottom: 1px solid ${theme.colors.mineShaftLightest};
`;

const SectionHeader = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ noPadding }) => (noPadding ? '25px 0 0' : '25px 32px 0')};
  align-items: center;
  height: 50px;

  legend {
    font-size: 12px;
    font-weight: 700;
    color: ${theme.colors.dataInput.text};
  }

  span:last-of-type {
    height: 15px;
    width: 15px;
    cursor: pointer;
  }
`;

const SectionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1px;
`;

interface Props {
  children?: React.ReactNode;
  label?: string | React.ReactNode;
  onSectionClear?: React.MouseEventHandler;
  clearable?: boolean;
  noPadding?: boolean;
  // sectionName is used for mapping in <FiltersForm />
  // eslint-disable-next-line react/no-unused-prop-types
  sectionName?: string;
}

export const FiltersSection = ({
  children,
  label,
  onSectionClear,
  clearable,
  noPadding,
}: Props): React.ReactElement => {
  const filteredChildren = useMemo(
    () => React.Children.toArray(children).filter(child => !!child),
    [children],
  );

  return (
    <FieldSet>
      {(label || clearable) && (
        <SectionHeader noPadding={noPadding}>
          <legend>{label}</legend>
          {clearable && (
            <Button
              onClick={onSectionClear}
              data-cy="filters-section-clear-button"
              iconName="X"
              size="medium"
              content="icon"
              variant="transparent"
            />
          )}
        </SectionHeader>
      )}
      <SectionContent>
        {filteredChildren.map((child, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SectionCell key={index} noPadding={noPadding}>
            {child}
          </SectionCell>
        ))}
      </SectionContent>
    </FieldSet>
  );
};
