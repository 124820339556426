import React from 'react';
import { Checkbox, CheckboxProps } from '@alpha-recycling/component-library';

import { withFieldLabel } from '../FieldWrapper/FieldLabel';
import { withFilterWrapper } from '../FieldWrapper/FilterWrapper';

type FieldCheckboxRawProps = Omit<CheckboxProps, 'state'>;

const FieldCheckboxRaw = (props: FieldCheckboxRawProps) => (
  <Checkbox {...props} id={props.name} state={!!props.checked} />
);

const FieldCheckbox = withFieldLabel(FieldCheckboxRaw);
const FilterCheckbox = withFilterWrapper(FieldCheckboxRaw);

export { FieldCheckbox, FieldCheckboxRaw, FilterCheckbox };
