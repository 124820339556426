import React from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { CellContext, flexRender } from '@tanstack/react-table';

import checkIcon from 'assets/images/icons/confirmBold.svg';
import { MEDIA_QUERY, Theme, theme as mainTheme } from 'theme';
import { calcGridTemplate } from './DataList.helpers';
import { CellProps, RowProps } from './DataList.styles';

export const TableContainer = styled.div<{ theme?: Theme }>`
  font-size: 12px;
  font-weight: 700;
  grid-column: -1/1;
  list-style: none;
  margin-top: 8px;
  padding: 0;
`;

export const NestedTableContainer = styled.div<{ theme?: Theme }>`
  position: relative;
  border: ${mainTheme.colors.mineShaft};
  margin: 5px 0 10px;
  background: ${mainTheme.colors.mineShaftLightest};
  box-shadow: 0 0 0 5px ${mainTheme.colors.mineShaftLightest};
  border-radius: 0;

  :last-of-type {
    border-radius: 0px 0px 8px 8px;
  }
`;

export const AdditionalNestedTableContainer = styled.div<{ theme?: Theme }>`
  position: relative;
  border: ${mainTheme.colors.mineShaft};
  margin: 5px 0 10px;
  background: ${mainTheme.colors.mineShaftLightest};
  box-shadow: 0 0 0 5px ${mainTheme.colors.mineShaftLightest};
  border-radius: 0px 0px 8px 8px;
`;

export const VehicleDetailsNestedTableContainer = styled.div<{ theme?: Theme }>`
  position: relative;
  border: ${mainTheme.colors.mineShaft};
  margin: 5px 0 10px;
  background: ${mainTheme.colors.mineShaftLightest};
  box-shadow: 0 0 0 5px ${mainTheme.colors.mineShaftLightest};
  border-radius: 4px;
`;

const isLastChild = ({ row, parentRow }: RowProps) =>
  parentRow?.originalSubRows && row && parentRow.originalSubRows.length - 1 === row.index;

export const TableRowHeader = styled.div<RowProps>`
  color: ${({ theme }) => theme.colors.dataInput.text};
  display: grid;
  grid-template-columns: ${({ table }) =>
    table?.getHeaderGroups()[0].headers.map(calcGridTemplate).join(' ')}}
`;

export const TableRowBody = styled.div<RowProps>`
  align-items: flex-start;
  border-radius: 4px;
  display: grid;
  grid-template-columns: ${({ row }) => row?.getVisibleCells().map(calcGridTemplate).join(' ')};

  margin-top: 8px;
  min-height: 54px;
  position: relative;
  word-break: break-word;

  cursor: ${({ row, isClickable }) =>
    row?.getCanSelect() || row?.getCanExpand() || isClickable ? 'pointer' : 'default'};

  ${({ rowColor }) => (rowColor !== '' ? `border-left: 3px solid ${rowColor};` : '')}

  ${({ row }) => (row?.getIsExpanded() ? `margin-top: 12px;` : '')}

  ${({ row }) =>
    (row && row.depth > 0) || row?.getIsExpanded()
      ? `
      box-shadow: 0 0 0 5px ${mainTheme.colors.mineShaftLightest}, 0 15px 0 5px ${mainTheme.colors.mineShaftLightest};
      `
      : ''}

  ${props =>
    isLastChild(props)
      ? `
      box-shadow: 0 0 0 5px ${mainTheme.colors.mineShaftLightest};
      margin-bottom: 12px;
      `
      : ''}

  ${({ row, theme }) =>
    row?.getCanSelect()
      ? `
    position: relative;

    ::before,::after {
      content: '';
      position: absolute;
      left: -12px;
      top: calc(50% - 13px);
      height: 20px;
      width: 20px;
    }

    ::before {
      background-color: ${theme.colors.mineShaft};
      border-radius: 3px;
      border: 2px solid ${theme.colors.mineShaftLighter};
    }

    ::after {
      content: ${row.getIsSelected() ? '""' : 'none'};
      background-color: ${theme.fontColor};
      mask-image: url(${checkIcon});
      mask-size: 21px;
      mask-position: 1px 1px;
    }
  `
      : ''}
`;

export const TableRowControlBtn = (selectable?: boolean): string => css`
  background-color: ${mainTheme.colors.mineShaftLighter} !important;
  border-radius: 50%;
  position: absolute;
  left: -12px;
  top: ${selectable ? '-12px' : '50%'};
  transform: translateY(${selectable ? '0' : '-50%'});

  &,
  & > span {
    cursor: pointer;
  }
`;

export const TableCell = styled.div<CellProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 10px 10px;
  background-color: ${mainTheme.colors.mineShaft};

  @media ${MEDIA_QUERY.MAX_MD} {
    padding: 6px 4px;
  }

  &:first-of-type {
    border-radius: 4px 0 0 4px;
    padding-left: 32px;

    @media ${MEDIA_QUERY.MAX_MD} {
      padding-left: 16px;
    }
  }

  &:last-of-type {
    border-radius: 0 8px 8px 0;
    padding-right: 32px;

    @media ${MEDIA_QUERY.MAX_MD} {
      padding-right: 16px;
    }
  }

  ${({ column }) => {
    const align = column?.columnDef?.meta?.align;
    return align
      ? `
      justify-content: ${align};
      align-items: ${align};
      `
      : '';
  }}
`;

export const HeaderCell = styled.div<CellProps>`
  align-items: center;
  display: flex;
  height: 100%;
  padding: 10px 10px;

  @media ${MEDIA_QUERY.MAX_MD} {
    padding: 6px 4px;
  }

  &:first-of-type {
    padding-left: 32px;

    @media ${MEDIA_QUERY.MAX_MD} {
      padding-left: 16px;
    }
  }

  &:last-of-type {
    padding-right: 32px;

    @media ${MEDIA_QUERY.MAX_MD} {
      padding-right: 16px;
    }
  }

  ${({ column }) => {
    const align = column?.columnDef?.meta?.align;
    return align
      ? `
      justify-content: ${align};
      align-items: ${align};
      `
      : '';
  }}
`;

export function TableCellContent<T>({ cell }: CellProps<T>) {
  return (
    <>
      {flexRender(
        cell?.column.columnDef.cell,
        cell?.getContext() ?? ({} as CellContext<T, unknown>),
      )}
    </>
  );
}
