import React, { Children, useMemo } from 'react';
import styled from '@emotion/styled';
import { FormikContextType, FormikProvider } from 'formik';

import { LoadableContent } from 'components/shared/Loader';
import { useTypedIntl } from 'locale/messages';
import { StyledH1 } from './StyledForm';

export const StyledFormWrapper = styled.form`
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;
  width: 100%;
  max-width: 720px;
`;

interface Props {
  children: React.ReactNode;
  header?: string;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: FormikContextType<any>;
  loaderMode?: 'FULL' | 'OVERLAY' | 'MODAL';
  controlButtons?: React.ReactNode;
  controlSteps?: React.ReactNode;
  dataCy?: string;
}
export const FormContainer = ({
  children: sections,
  header,
  onSubmit,
  context,
  loaderMode = 'FULL',
  controlSteps,
  controlButtons,
  dataCy,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();

  const parsedSections = useMemo(
    () =>
      Children.toArray(sections)
        .filter(child => !!child)
        .flat(),
    [sections],
  ) as unknown as React.ReactElement<{
    sectionName: string;
    label: string;
  }>[];

  return (
    <FormikProvider value={context}>
      <LoadableContent
        loading={context?.isSubmitting}
        mode={LoadableContent.MODE[loaderMode]}
        label={intl.formatMessage({ id: 'Global.Submitting' })}
      />
      <StyledFormWrapper onSubmit={onSubmit || context.handleSubmit} noValidate data-cy={dataCy}>
        <header> {header && <StyledH1>{header}</StyledH1>}</header>
        {controlSteps}
        {parsedSections.map(section => section)}
        {controlButtons}
      </StyledFormWrapper>
    </FormikProvider>
  );
};
