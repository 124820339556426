import React from 'react';
import {
  DataOutput,
  OutputCell,
  Status,
  STATUS_TYPE_VALUES,
} from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { Details } from 'components/shared/Details';
import { LoadableContent } from 'components/shared/Loader';
import { formatDate } from 'helpers/dateTime/dateTime';
import { PERMISSIONS, SHARED } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { ItemAction, NotificationStatus } from 'shared/types';
import { clearNotificationDetails } from 'store/notificationDetails';
import { notificationResolve } from 'store/notificationResolve';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { theme } from 'theme';
import { useTypedIntl } from './locale/messages';

const NotificationMessageContainer = styled.div`
  a {
    color: ${theme.mainColor} !important;
  }
`;

export const NotificationDetails = (): React.ReactElement | null => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const authorization = useAuthorization();
  const notification = useAppSelector(state => state.notificationDetails.notification!);
  const resolveIsPending = useAppSelector(state => state.notificationResolve.isPending);

  const handleMarkAsResolved = async () => {
    try {
      await dispatch(notificationResolve(notification.id));
      await dispatch(
        snackBarPushSuccess(intl.formatMessage({ id: 'NotificationDetails.Resolve.Success' })),
      );
      dispatch(clearNotificationDetails());
    } catch {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  if (!notification) return null;

  const actions: ItemAction[] = [
    {
      id: 1,
      label: intl.formatMessage({ id: 'NotificationDetails.Resolve' }),
      onClick: () => handleMarkAsResolved(),
      visible: notification.status === NotificationStatus.OPEN,
      dataCy: () => `btn-mark-as-resolve`,
    },
  ];

  return (
    <LoadableContent loading={resolveIsPending} mode={LoadableContent.MODE.FULL} drawContent>
      <Details
        title={intl.formatMessage({ id: 'NotificationDetails.Title' })}
        onClose={() => {
          dispatch(clearNotificationDetails());
          return true;
        }}
        open={!!notification}
        actions={actions}
      >
        <DataOutput
          headerText={intl.formatMessage({
            id: 'NotificationDetails.Details',
          })}
        >
          {[
            {
              name: 'subject',
              label: intl.formatMessage({ id: 'NotificationDetails.Subject' }),
              value: notification.subject,
            },
            ...(authorization(PERMISSIONS.NOTIFICATIONS.ALL_DETAILS)
              ? [
                  {
                    name: 'status',
                    label: intl.formatMessage({ id: 'NotificationDetails.Status' }),
                    value: (
                      <Status
                        type={
                          notification.status === NotificationStatus.RESOLVED
                            ? STATUS_TYPE_VALUES.active
                            : STATUS_TYPE_VALUES.pending
                        }
                        value={intl.formatMessage({
                          id: `Notifications.ListItem.Status.${notification.status}`,
                        })}
                        size="small"
                        discrete
                      />
                    ),
                  },
                ]
              : []),
            {
              name: 'text',
              label: intl.formatMessage({ id: 'NotificationDetails.Text' }),
              // eslint-disable-next-line react/no-danger
              value: (
                <NotificationMessageContainer
                  dangerouslySetInnerHTML={{ __html: notification.text }}
                />
              ),
            },
          ].map((field, index, fields) => (
            <OutputCell
              key={field.name}
              data-cy={field.name}
              mergeCells={fields.length % 2! !== 0 && fields.length - 1 === index ? 4 : 2}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>

        {authorization(PERMISSIONS.NOTIFICATIONS.ALL_DETAILS) && notification.recipients && (
          <DataOutput
            headerText={intl.formatMessage({
              id: 'NotificationDetails.Recipients',
            })}
          >
            {notification.recipients
              .map((recipient, index) => ({
                name: `recipient${index + 1}`,
                label: recipient.company.name,
                value: `${recipient.firstName} ${recipient.lastName}`,
              }))
              .map((field, index, fields) => (
                <OutputCell
                  key={field.name}
                  data-cy={field.name}
                  mergeCells={fields.length % 2! !== 0 && fields.length - 1 === index ? 4 : 2}
                  labelValue={field.label}
                >
                  {field.value}
                </OutputCell>
              ))}
          </DataOutput>
        )}

        <DataOutput
          headerText={intl.formatMessage({
            id: 'Global.MoreDetails',
          })}
        >
          {[
            {
              name: 'createdAt',
              label: intl.formatMessage({ id: 'Global.CreatedOn' }),
              value: formatDate(notification.createdAt),
            },
            {
              name: 'readAt',
              label: intl.formatMessage({ id: 'NotificationDetails.ReadAt' }),
              value: formatDate(notification.readAt),
            },
            ...(authorization(PERMISSIONS.NOTIFICATIONS.ALL_DETAILS)
              ? [
                  {
                    name: 'resolvedAt',
                    label: intl.formatMessage({ id: 'NotificationDetails.ResolvedAt' }),
                    value: notification.resolvedAt
                      ? formatDate(notification.resolvedAt)
                      : SHARED.LONG_DASH,
                  },
                ]
              : []),
            ...(authorization(PERMISSIONS.NOTIFICATIONS.ALL_DETAILS)
              ? [
                  {
                    name: 'resolvedBy',
                    label: intl.formatMessage({ id: 'NotificationDetails.ResolvedBy' }),
                    value: notification.resolvedBy
                      ? `${notification.resolvedBy.firstName} ${notification.resolvedBy.lastName}`
                      : SHARED.LONG_DASH,
                  },
                ]
              : []),
          ].map((field, index, fields) => (
            <OutputCell
              key={field.name}
              data-cy={field.name}
              mergeCells={fields.length - 1 === index ? -(fields.length - 5) : 1}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>
      </Details>
    </LoadableContent>
  );
};
