import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { DataOutput, OutputCell } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { Details } from 'components/shared/Details';
import { DetailsField } from 'components/shared/Details/Details';
import { LoadableContent } from 'components/shared/Loader';
import { TagGroup } from 'components/shared/TagGroup';
import { formatDate } from 'helpers/dateTime/dateTime';
import { formatDeviceData } from 'helpers/devices/formatDeviceData';
import { getUserTypeLang } from 'helpers/userTypes/userTypes';
import { SHARED } from 'shared/constants';
import { useCurrentUser, useExtendedTheme } from 'shared/hooks';
import { useGetDevices } from 'shared/queries';
import { Status as StatusTypes } from 'shared/types';
import { fetchUser } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { closeProfile } from 'store/shared/userProfileSlice';
import { layers, MEDIA_QUERY, Theme } from 'theme';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from './locale/messages';
import { ChangeLanguageActions } from './ChangeLanguageActions';
import { UserProfileChangeCurrency } from './UserProfileChangeCurrency';
import { UserProfileNotifications } from './UserProfileNotifications';
import { UserProfilePriceSources } from './UserProfilePriceSources';
import { UserProfileProfitMargin } from './UserProfileProfitMargin';
import { UserProfileTotalMargins } from './UserProfileTotalMargins';

const StyledLink = styled.a<{ theme: Theme }>`
  color: ${({ theme }) => theme.mainColor};
`;

const UserProfileModalStyle = {
  overlay: {
    backgroundColor: 'transparent',
    zIndex: layers.userProfile,
    [MEDIA_QUERY.MAX_MD]: {
      top: 64,
    },
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};

function UserProfileComponent(): React.ReactElement | null {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const theme = useExtendedTheme();
  const { isVisible, scrollToId } = useAppSelector(state => state.userProfile);
  const currentUser = useCurrentUser();
  const { data: devices } = useGetDevices(
    { userId: currentUser?.id, status: [StatusTypes.ACTIVE] },
    { initialData: [] },
  );
  const { isPending: notificationsSettingsPending } = useAppSelector(
    state => state.changeUserNotifications,
  );

  const [requestPending, setRequestPending] = useState(false);

  const userTypesList = (
    <TagGroup
      items={currentUser?.userTypes?.map(userType => getUserTypeLang(userType.name, intl) ?? '')}
      size="small"
      countLabelFormatter={count => intl.formatMessage({ id: 'Global.Roles' }, { count })}
    />
  );

  useEffect(() => {
    if (isVisible) {
      dispatch(fetchUser(true));
    }
  }, [isVisible]);

  useEffect(() => {
    if (currentUser && scrollToId) {
      const element = document.getElementById(scrollToId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [currentUser, scrollToId]);

  const close = useCallback(() => {
    dispatch(closeProfile());
    return true;
  }, [dispatch]);

  if (!currentUser) return null;

  return (
    <ReactModal
      isOpen={isVisible}
      style={UserProfileModalStyle}
      parentSelector={() => document.querySelector('#root')!}
    >
      <LoadableContent
        mode={LoadableContent.MODE.FULL}
        loading={requestPending || notificationsSettingsPending}
        drawContent
      >
        <Details title={<FormattedMessage id="UserProfile.Title" />} open onClose={close}>
          <DataOutput
            headerText={intl.formatMessage({
              id: 'UserProfile.Details',
            })}
          >
            {[
              {
                name: 'userName',
                label: intl.formatMessage({
                  id: 'UserProfile.UserName',
                }),
                value: `${currentUser.firstName} ${currentUser.lastName}`,
              },
              {
                name: 'email',
                label: intl.formatMessage({
                  id: 'UserProfile.Email',
                }),
                value: (
                  <StyledLink href={`mailto:${currentUser.email}`} theme={theme}>
                    {currentUser.email}
                  </StyledLink>
                ),
              },
              {
                name: 'mobilePhone',
                label: intl.formatMessage({
                  id: 'UserProfile.MobilePhone',
                }),
                value: currentUser.mobilePhone,
              },
              {
                name: 'company',
                label: intl.formatMessage({
                  id: 'UserProfile.CompanyName',
                }),
                value: currentUser.company?.name,
              },
              {
                name: 'userType',
                label: intl.formatMessage({
                  id: 'UserProfile.UserType',
                }),
                value: userTypesList,
              },
              {
                name: 'officePhone',
                label: intl.formatMessage({
                  id: 'UserProfile.OfficePhone',
                }),
                value: currentUser.officePhone || SHARED.LONG_DASH,
              },
            ].map(field => (
              <OutputCell
                key={field.name}
                data-cy={field.name}
                mergeCells={2}
                labelValue={field.label}
              >
                {field.value}
              </OutputCell>
            ))}
          </DataOutput>

          <UserProfilePriceSources />

          {devices && (
            <DataOutput
              headerText={intl.formatMessage({
                id: 'UserProfile.AuthorizedDevices',
              })}
            >
              {devices
                .reduce<DetailsField[]>(
                  (prev, { name, os, browserFamily, createdAt }, index) => [
                    ...prev,
                    ...[
                      {
                        name: `device${index + 1}Name`,
                        label: intl.formatMessage({ id: 'UserProfile.DeviceName' }),
                        value: formatDeviceData(name, os, browserFamily),
                      },
                      {
                        name: `device${index + 1}Date`,
                        label: intl.formatMessage({ id: 'UserProfile.DeviceAddDate' }),
                        value: formatDate(createdAt),
                      },
                    ],
                  ],
                  [],
                )
                .map(field => (
                  <OutputCell
                    key={field.name}
                    data-cy={field.name}
                    mergeCells={2}
                    labelValue={field.label}
                  >
                    {field.value}
                  </OutputCell>
                ))}
            </DataOutput>
          )}

          <UserProfileTotalMargins user={currentUser} />
          <UserProfileProfitMargin user={currentUser} setRequestPending={setRequestPending} />
          <UserProfileNotifications user={currentUser} />
          <ChangeLanguageActions />
          <UserProfileChangeCurrency />
        </Details>
      </LoadableContent>
    </ReactModal>
  );
}

export const UserProfile = withAlphamartIntlProvider(UserProfileComponent, messages);
