import React, { useEffect } from 'react';
import { InfoBar } from '@alpha-recycling/component-library';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FeedTypes } from 'shared/constants';
import { Adjustment } from 'shared/types';
import { fetchAdjustments } from 'store/adjustmentsSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { messages, TypedFormattedMessage as FormattedMessage } from '../locale/messages';

const ManualEntryPriceNotificationComponent = (): React.ReactElement | null => {
  const dispatch = useAppDispatch();
  const adjustmentState = useAppSelector(state => state.adjustments);
  const list = adjustmentState.adjustmentRates.list as Omit<Adjustment, 'id'>[];
  const auth = useAppSelector(state => state.auth);
  useEffect(() => {
    dispatch(fetchAdjustments());
  }, [dispatch]);

  const usingManualEntryPrice = list.some(
    ({ sourceAdjustmentType }) => FeedTypes.MANUAL_ENTRY === sourceAdjustmentType,
  );

  const company = auth?.user?.data?.company;
  if (!usingManualEntryPrice || !company) return null;

  return (
    <InfoBar
      text={
        <FormattedMessage
          id="Dashboard.ManualEntryPricesInUse"
          values={{
            companyName: company.name,
          }}
        />
      }
      type="info"
      icon
      variant="basic"
    />
  );
};

export const ManualEntryPriceNotification = withAlphamartIntlProvider(
  ManualEntryPriceNotificationComponent,
  messages,
);
