import React from 'react';
import { Button } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { TypedFormattedMessage, useTypedIntl } from 'locale/messages';
import { Theme } from 'theme';

interface Props {
  resetZoom: () => void;
  isZoomedIn?: boolean;
  isMobileZoomStarted?: boolean;
}

const LegendContent = styled.div<{ theme?: Theme; isVisible?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  text-align: center;
  font-size: 13px;
  margin-left: 32px;
  height: 48px;

  button {
    position: relative;
    left: calc(var(--left-offset) * -1);
  }
`;
const MobileHint = styled.span<{ theme?: Theme }>`
  color: ${({ theme }) => theme.mainColor};
`;
export const ScatterChartLegend = ({
  resetZoom,
  isZoomedIn,
  isMobileZoomStarted,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  return (
    <LegendContent isVisible={isMobileZoomStarted || isZoomedIn}>
      {isMobileZoomStarted && !isZoomedIn ? (
        <MobileHint>
          <TypedFormattedMessage id="Global.Charts.Scatter.MobileZoomActivated" />
        </MobileHint>
      ) : (
        <Button
          onClick={resetZoom}
          content="text"
          variant="filled"
          label={intl.formatMessage({ id: 'Global.Charts.Scatter.ResetZoom' })}
        />
      )}
    </LegendContent>
  );
};
