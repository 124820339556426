import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { MEDIA_QUERY, theme } from 'theme';

export const StyledDetails = styled.div`
  header {
    display: flex;
    align-items: center;
  }

  & > div > div {
    grid-template-columns: repeat(4, 1fr);
    display: grid;

    :first-child > div {
      margin-top: 1px;
      border-bottom: none !important;

      @media ${MEDIA_QUERY.MAX_MD} {
        border: none;
        margin: 0;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;

      &:nth-child(4n) {
        border-bottom: 2px solid rgba(255, 255, 255, 0.25);
      }

      @media ${MEDIA_QUERY.MAX_MD} {
        &:last-child {
          border: none;
          margin: 0;
        }
      }
    }
  }
`;

export const StyledMessageWrapper = styled.span`
  white-space: pre;
`;

export const incompletePriceTip = css`
  display: inline;
  color: ${theme.colors.mineShaftLightest};
  font-size: 18px;
  cursor: default;

  & > div {
    display: inline;
  }
`;

export const HeaderWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
