import React from 'react';
import {
  Icon,
  ICON_NAMES_TYPE,
  ICON_SIZES_TYPE,
  Tooltip,
} from '@alpha-recycling/component-library';

import { layers, theme } from 'theme';

interface BaseProps {
  tooltip: string;
  size?: ICON_SIZES_TYPE;
}

interface Props extends BaseProps {
  color?: string;
  icon: ICON_NAMES_TYPE;
}

const IconTooltip = ({ icon, color, tooltip, size }: Props): React.ReactElement => (
  <Tooltip position="top" label={tooltip}>
    <Icon name={icon} fillColor={color} size={size} />
  </Tooltip>
);

const IconTooltipWarning = ({ tooltip, size = 'big' }: BaseProps): React.ReactElement => (
  <Tooltip position="top" label={tooltip} zIndex={layers.tooltip}>
    <Icon
      name="Danger"
      fillColor={theme.colors.fuzzyWuzzyBrown}
      size={size}
      data-cy="icon-tooltip-warning"
    />
  </Tooltip>
);

const IconTooltipInfo = ({ tooltip, size = 'big' }: BaseProps): React.ReactElement => (
  <Tooltip label={tooltip} zIndex={layers.tooltip}>
    <Icon
      name="Info"
      fillColor={theme.colors.mineShaftLightest}
      data-cy="icon-tooltip-info"
      size={size}
    />
  </Tooltip>
);

export { IconTooltip, IconTooltipInfo, IconTooltipWarning };
