import React, { useState } from 'react';
import { DataOutput, OutputCell } from '@alpha-recycling/component-library';
import { isNull } from 'lodash';

import { Details } from 'components/shared/Details';
import { DetailsField } from 'components/shared/Details/Details';
import { Gallery } from 'components/shared/Gallery';
import { HedgePriceInUseBadge } from 'components/shared/HedgePriceInUseBadge';
import { IconTooltipWarning } from 'components/shared/IconTooltip/IconTooltip';
import { MarketHedgePrice } from 'components/shared/Price';
import { ProtectedArea } from 'components/shared/ProtectedArea/ProtectedArea';
import { TagGroup } from 'components/shared/TagGroup';
import { formatDate } from 'helpers/dateTime/dateTime';
import { removeChecker } from 'helpers/objects/removeChecker';
import { AccessFlag, ModalFormType, PERMISSIONS, SHARED } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { Converter, ItemAction, User } from 'shared/types';
import { showModalForm } from 'store/modalFormSlice';
import { useAppDispatch } from 'store/shared/hooks';
import {
  DisclaimerContent,
  HeaderWrapper,
  LinkToAssay,
  StyledNotes,
} from './ConverterDetails.styles';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from '../locale/messages';

interface Props {
  converter: Converter | null;
  currentUser: User;
}

export const ConverterDetails = ({ converter, currentUser }: Props): React.ReactElement | null => {
  const intl = useTypedIntl();
  const [areDetailsScrollable, setAreDetailsScrollable] = useState(false);
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();
  const canSeeDisclaimer = authorize(PERMISSIONS.CONVERTERS.DISCLAIMER);

  if (!converter) return null;

  const otherNumbersList = (
    <TagGroup
      size="small"
      items={converter?.otherNumbers?.map(p => p.original ?? '')}
      countLabelFormatter={count =>
        intl.formatMessage({ id: 'ConverterDetails.OtherNumbersGroup' }, { count })
      }
    />
  );

  const assayFields: DetailsField[] = [
    {
      name: 'carbonPercentage',
      label: intl.formatMessage({
        id: 'ConverterDetails.CarbonPercentage',
      }),
      value: converter.assay.carbonPercentage
        ? `${converter.assay.carbonPercentage.toFixed(3)} %`
        : SHARED.LONG_DASH,
    },
  ];

  if (authorize(PERMISSIONS.CONVERTERS.DETAILS.ASSAY)) {
    assayFields.unshift({
      name: 'sampleName',
      label: intl.formatMessage({
        id: 'ConverterDetails.Assay.SampleName',
      }),
      value: (
        <LinkToAssay to={`/assays/${converter.assay.id}`}>{converter.assay.sampleName}</LinkToAssay>
      ),
    });
  }

  const actions: ItemAction[] = [
    {
      id: 1,
      label: intl.formatMessage({
        id: 'ShoppingCart.Modal.Add.Title',
      }),
      onClick: () =>
        dispatch(
          showModalForm({
            modalType: ModalFormType.AddShoppingCartItems,
            params: { converters: [converter] },
          }),
        ),
      visible: authorize([AccessFlag.SHOPPING_CART_VIEWER]) && !isNull(converter.price.marketPrice),
    },
  ];

  return (
    <Details
      title={
        <HeaderWrapper>
          {converter.isPartial ? (
            <FormattedMessage
              id="ConverterDetails.PartialIdentifier"
              values={{ identifier: converter.identifier }}
            />
          ) : (
            converter.identifier
          )}
          <HedgePriceInUseBadge />
        </HeaderWrapper>
      }
      backUrl="/converters"
      scrollable={!areDetailsScrollable}
      actions={actions}
    >
      {canSeeDisclaimer && (
        <DisclaimerContent>
          <FormattedMessage id="ConvertersList.Header.Disclaimer" />
        </DisclaimerContent>
      )}

      {converter.files?.[0] && (
        <Gallery
          images={converter.files}
          onLightboxToggle={isOpened => {
            setAreDetailsScrollable(isOpened);
          }}
          largeImage
        />
      )}

      <DataOutput
        data-cy="rates-and-charges"
        headerText={intl.formatMessage({
          id: 'ConverterDetails.Header',
        })}
      >
        {[
          {
            name: 'calculatedPrice',
            label: intl.formatMessage({
              id: 'ConverterDetails.CalculatedPrice',
            }),
            value: (
              <MarketHedgePrice
                price={converter.price}
                currentUser={currentUser}
                prefix={{
                  market: intl.formatMessage({
                    id: 'ConverterDetails.MarketPricePrefix',
                  }),
                  hedge: intl.formatMessage({
                    id: 'ConverterDetails.HedgePricePrefix',
                  }),
                }}
              />
            ),
          },
          {
            name: 'partName',
            label: intl.formatMessage({
              id: 'ConverterDetails.PartName',
            }),
            value: converter.partName ?? SHARED.LONG_DASH,
          },
          {
            name: 'nicknames',
            label: intl.formatMessage({
              id: 'ConverterDetails.Nicknames',
            }),
            value: converter.nicknames ?? SHARED.LONG_DASH,
          },
          {
            name: 'otherNumbers',
            label: intl.formatMessage({
              id: 'ConverterDetails.OtherNumbers',
            }),
            value: otherNumbersList ?? SHARED.LONG_DASH,
          },
          {
            name: 'type',
            label: intl.formatMessage({
              id: 'ConverterDetails.Type',
            }),
            value: converter.type?.name ?? SHARED.LONG_DASH,
          },
          {
            name: 'counterfeit',
            label: intl.formatMessage({
              id: 'ConverterDetails.Counterfeit',
            }),
            value: converter.counterfeit
              ? intl.formatMessage({
                  id: 'Global.Yes',
                })
              : intl.formatMessage({
                  id: 'Global.No',
                }),
          },
        ].map(field => (
          <OutputCell key={field.name} data-cy={field.name} mergeCells={2} labelValue={field.label}>
            {field.value}
          </OutputCell>
        ))}
      </DataOutput>

      <DataOutput
        data-cy="rates-and-charges"
        headerText={intl.formatMessage({
          id: 'ConverterDetails.CarHeader',
        })}
      >
        {[
          {
            name: 'make',
            label: intl.formatMessage({
              id: 'ConverterDetails.Make',
            }),
            value: converter.make,
          },
          {
            name: 'model',
            label: intl.formatMessage({
              id: 'ConverterDetails.Model',
            }),
            value: converter.model,
          },
          {
            name: 'year',
            label: intl.formatMessage({
              id: 'ConverterDetails.Year',
            }),
            value: converter.year ?? SHARED.LONG_DASH,
          },
        ].map((field, index, fields) => (
          <OutputCell
            key={field.name}
            data-cy={field.name}
            mergeCells={fields.length - 1 === index ? 4 : 2}
            labelValue={field.label}
          >
            {field.value}
          </OutputCell>
        ))}
      </DataOutput>

      <DataOutput
        headerText={intl.formatMessage({
          id: 'ConverterDetails.Notes',
        })}
      >
        {[
          {
            name: 'notes',
            label: 'Notes',
            value: converter.counterfeit ? (
              <StyledNotes>
                <IconTooltipWarning
                  tooltip={intl.formatMessage({
                    id: 'ConverterDetails.Counterfeit',
                  })}
                />
                {converter.notes ?? SHARED.LONG_DASH}
              </StyledNotes>
            ) : (
              converter.notes ?? SHARED.LONG_DASH
            ),
          },
        ].map(field => (
          <OutputCell key={field.name} data-cy={field.name} mergeCells={4} labelValue={field.label}>
            {field.value}
          </OutputCell>
        ))}
      </DataOutput>

      <DataOutput
        headerText={intl.formatMessage({
          id: 'ConverterDetails.Assay.Header',
        })}
      >
        {assayFields.map(field => (
          <OutputCell key={field.name} data-cy={field.name} mergeCells={2} labelValue={field.label}>
            {field.value}
          </OutputCell>
        ))}
      </DataOutput>

      <ProtectedArea permission={PERMISSIONS.CONVERTERS.DETAILS.CREATED_BY}>
        <DataOutput
          headerText={intl.formatMessage({
            id: 'Global.MoreDetails',
          })}
        >
          {[
            {
              name: 'createdBy',
              label: intl.formatMessage({
                id: 'Global.CreatedBy',
              }),
              value:
                converter.createdBy &&
                `${converter.createdBy.firstName} ${converter.createdBy.lastName}`,
            },
            {
              name: 'createdAt',
              label: intl.formatMessage({
                id: 'Global.CreatedOn',
              }),
              value: formatDate(converter.createdAt),
            },
            {
              name: 'updatedBy',
              label: intl.formatMessage({
                id: 'Global.UpdatedBy',
              }),
              value: converter.updatedBy
                ? `${converter.updatedBy.firstName} ${converter.updatedBy.lastName}`
                : SHARED.LONG_DASH,
            },
            {
              name: 'updatedAt',
              label: intl.formatMessage({
                id: 'Global.UpdatedOn',
              }),
              value: converter.updatedAt ? formatDate(converter.updatedAt) : SHARED.LONG_DASH,
            },
            ...(removeChecker(converter)
              ? [
                  {
                    name: 'deletedBy',
                    label: intl.formatMessage({
                      id: 'Global.DeletedBy',
                    }),
                    value:
                      converter.deletedBy &&
                      `${converter.deletedBy.firstName} ${converter.deletedBy.lastName}`,
                  },
                  {
                    name: 'deletedAt',
                    label: intl.formatMessage({
                      id: 'Global.DeletedOn',
                    }),
                    value: converter.deletedAt && formatDate(converter.deletedAt),
                  },
                ]
              : []),
          ].map((field, index, fields) => (
            <OutputCell
              key={field.name}
              data-cy={field.name}
              mergeCells={fields.length % 2! !== 0 && fields.length - 1 === index ? 4 : 1}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>
      </ProtectedArea>
    </Details>
  );
};
