import { useEffect, useState } from 'react';

import { useAuth } from 'shared/hooks';
import { getHttpClient } from 'shared/utils/httpClient';

export function useSetTokenGenerator(): boolean {
  const [isLoaded, setIsLoaded] = useState(false);
  const { isAuthenticated, getAccessTokenSilently } = useAuth();
  useEffect(() => {
    if (!isAuthenticated || isLoaded) return;
    getHttpClient().setTokenGenerator(getAccessTokenSilently);
    setIsLoaded(true);
  }, [isAuthenticated, getAccessTokenSilently, isLoaded, setIsLoaded]);

  return isLoaded;
}
