import React from 'react';
import { Button, BUTTON_CONTENTS } from '@alpha-recycling/component-library';
import { FormikHelpers, useFormik } from 'formik';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldInputRaw } from 'components/shared/Fields/FieldInput/FieldInput';
import { ErrorMessage } from 'components/shared/Fields/FieldWrapper/ErrorMessage/ErrorMessage';
import { ErrorCode } from 'shared/constants';
import { useResendDeviceChallenge, VerifyChallengeOptions } from 'shared/mutations';
import { isAlphamartHttpError } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { getChallengeSchema } from './challengeSchema';
import { Container, Content, Footer, Header, wrapperStyles } from '../Auth.styles';
import { messages, TypedFormattedMessage, useTypedIntl } from '../locale/messages';

interface Props {
  onSubmitChallenge: (challenge: string) => Promise<void>;
}

function DeviceVerificationPage({ onSubmitChallenge }: Props) {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();

  const resendChallenge = useResendDeviceChallenge();

  const handleChallengeSubmit = async (
    values: VerifyChallengeOptions,
    helpers: FormikHelpers<VerifyChallengeOptions>,
  ) => {
    try {
      await onSubmitChallenge(values.challenge);
    } catch {
      helpers.setFieldError(
        'challenge',
        intl.formatMessage({ id: 'Login.Error.Challenge.Invalid' }),
      );
    }
  };

  const { values, isSubmitting, isValid, handleSubmit, errors, touched, handleBlur, handleChange } =
    useFormik<VerifyChallengeOptions>({
      validateOnMount: true,
      initialValues: { challenge: '' },
      validationSchema: getChallengeSchema(intl),
      onSubmit: handleChallengeSubmit,
    });

  const handleChallengeResend = async () => {
    try {
      await resendChallenge.mutateAsync();
      dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'ResendChallenge.Success' })));
    } catch (e) {
      if (!isAlphamartHttpError(e)) throw e;
      const limitReached = e.response?.data.errorCode === ErrorCode.MAXIMAL_REQUESTS_NUMBER_REACHED;
      dispatch(
        snackBarPushFailure(
          intl.formatMessage({
            id: limitReached
              ? 'ResendChallenge.Error.LimitReached'
              : 'Global.Error.SomethingWentWrong',
          }),
        ),
      );
    }
  };

  const isPending = isSubmitting || resendChallenge.isLoading;

  return (
    <Container>
      <form className={wrapperStyles} onSubmit={handleSubmit}>
        <Header>
          <TypedFormattedMessage id="Login.Header.LogIn" />
        </Header>
        <Content>
          {!!errors.challenge && touched.challenge && (
            <ErrorMessage standalone show>
              {errors.challenge}
            </ErrorMessage>
          )}
          <FieldInputRaw
            name="challenge"
            type="text"
            placeholder={intl.formatMessage({ id: 'Login.Label.Challenge' })}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.challenge && errors.challenge}
            value={values.challenge}
            maxLength={4}
            data-cy="input-challenge"
          />
        </Content>
        <Footer>
          <Button
            content={BUTTON_CONTENTS.text}
            disabled={resendChallenge.isLoading || isSubmitting}
            type="button"
            data-cy="button-resend-challenge"
            onClick={handleChallengeResend}
            label={intl.formatMessage({ id: 'ResendChallenge.Button' })}
            variant="tonal"
          />
          <Button
            content={BUTTON_CONTENTS.text}
            disabled={isPending || !isValid}
            type="submit"
            data-cy="button-login"
            label={intl.formatMessage({ id: 'Login.Button.Login' })}
          />
        </Footer>
      </form>
    </Container>
  );
}

export const DeviceVerification = withAlphamartIntlProvider(DeviceVerificationPage, messages);
