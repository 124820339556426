import React from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';

import { SHARED } from 'shared/constants';
import { useCurrentUser, useMediaQuery } from 'shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';
import { messages, TypedFormattedMessage as FormattedMessage } from './locale/messages';
import { AlphamartIntlProvider } from '../AlphamartIntlProvider';
import { ReactModalStyle } from '../Modals.styles';

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  width: 50vw;
  height: 50vh;
  background-color: ${theme.colors.mineShaft};
  padding: 24px 32px;
  border-radius: 12px;

  @media ${MEDIA_QUERY.MAX_XL} {
    width: 75vw;
    height: 75vh;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    padding: 16px;
  }

  & > * {
    margin-top: 16px;
  }
`;

function UserBlockedModal(): React.ReactElement {
  const currentUser = useCurrentUser();
  const isUserBlocked = !!currentUser?.isBlocked;

  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  // TODO replace empty string with hardcoded values
  const messageValues = {
    mobilePhonePrefix: SHARED.LONG_DASH,
    mobilePhone: SHARED.LONG_DASH,
    email: SHARED.LONG_DASH,
  };

  return (
    <AlphamartIntlProvider messages={messages}>
      <ReactModal
        isOpen={isUserBlocked}
        style={ReactModalStyle(isPhone)}
        parentSelector={() => document.querySelector('#root')!}
      >
        <ModalContent data-guide-blocker>
          <FormattedMessage id="UserBlockedModal.Message" values={messageValues} />
        </ModalContent>
      </ReactModal>
    </AlphamartIntlProvider>
  );
}

export { UserBlockedModal };
