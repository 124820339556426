import React from 'react';
import { Button } from '@alpha-recycling/component-library';
import { FormikContextType } from 'formik';

import { useTypedIntl } from 'locale/messages';
import { useMediaQuery } from 'shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { FormContainer } from './FormContainer';
import { FormActions } from './StyledForm';

interface Props {
  children: React.ReactNode;
  header?: string;
  onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: FormikContextType<any>;
  loaderMode?: 'FULL' | 'OVERLAY' | 'MODAL';
  submitLabel?: string;
  showSubmitIcon?: boolean;
  submitDisabled?: boolean;
  dataCy?: string;
}
export const FormWrapper = ({
  children: sections,
  header,
  onCancel,
  onSubmit,
  submitDisabled,
  context,
  submitLabel,
  showSubmitIcon = false,
  loaderMode = 'FULL',
  dataCy,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const isMaxMd = useMediaQuery(MEDIA_QUERY.MAX_MD);

  const baseSubmitLabel = submitLabel || header;
  const submitLabelText =
    isMaxMd && typeof baseSubmitLabel === 'string'
      ? baseSubmitLabel.split(' ')[0]
      : baseSubmitLabel;

  return (
    <FormContainer
      context={context}
      onSubmit={onSubmit}
      loaderMode={loaderMode}
      dataCy={dataCy}
      header={header}
      controlButtons={
        <FormActions>
          <Button
            onClick={onCancel}
            variant="transparent"
            content="text"
            data-cy="cancel"
            label={intl.formatMessage({ id: 'Global.Cancel' })}
          />
          <Button
            iconName="Plus"
            data-cy="submit"
            type="submit"
            content={showSubmitIcon ? 'icon_text' : 'text'}
            disabled={submitDisabled}
            label={submitLabelText}
          />
        </FormActions>
      }
    >
      {sections}
    </FormContainer>
  );
};
