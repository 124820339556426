import React, { useState } from 'react';
import { OptionsProps } from '@alpha-recycling/component-library';
import { debounce } from 'lodash';

import { FieldCreatableSelect } from 'components/shared/Fields/FieldSelect/FieldCreatableSelect';
import { useTypedIntl } from 'locale/messages';
import { useGetConverterIdentifiers } from 'shared/queries';

interface ConverterIdentifierSelectProps {
  name: string;
  label: string;
  value?: string;
  'data-cy': string;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (newValue: OptionsProps | undefined) => void;
  error: string | false | undefined;
  onCreateOption: (inputValue: string) => void;
  maskInput?: boolean;
}

export const ConverterIdentifierSelect = ({
  name,
  label,
  value,
  'data-cy': dataCy,
  onFocus,
  onBlur,
  error,
  onChange,
  onCreateOption,
  maskInput,
}: ConverterIdentifierSelectProps) => {
  const [selectInputValue, setSelectInputValue] = useState('');
  const { data: identifiers } = useGetConverterIdentifiers({
    query: selectInputValue || value || '',
    limit: 20,
  });
  const intl = useTypedIntl();

  const onSelectChange = (newValue: OptionsProps | undefined) => {
    setSelectInputValue('');
    return onChange(newValue);
  };

  const onCreate = (newValue: string) => {
    setSelectInputValue('');
    onCreateOption(newValue);
  };

  const onSelectInputChange = debounce((options, query) => {
    setSelectInputValue(query);
    return options;
  }, 700);

  const foundOptions = (identifiers ?? []).map(id => ({ value: id, label: id }));
  const maskedValue =
    value
      ?.split('')
      ?.map(() => '•')
      ?.join('') ?? '';
  const options = value
    ? [{ value, label: maskInput ? maskedValue : value }, ...foundOptions]
    : foundOptions;

  return (
    <FieldCreatableSelect
      label={label}
      name={name}
      options={options}
      value={value}
      data-cy={dataCy}
      onFocus={onFocus}
      error={error}
      onBlur={onBlur}
      required
      maxLength={100}
      onChange={onSelectChange}
      onCreateOption={onCreate}
      noOptionsMessage={intl.formatMessage({ id: 'Global.Fields.Select.StartTyping' })}
      customSearchFunction={onSelectInputChange}
    />
  );
};
