import styled from '@emotion/styled';

import { Details } from 'components/shared/Details';
import { layers, MEDIA_QUERY, Theme } from 'theme';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledDetails = styled(Details)`
  & > div > div {
    height: calc(100vh - 100px);
    display: grid;
    align-items: end;
    grid-template-columns: 100%;
    > div:last-of-type {
      padding-bottom: 56px;
    }
  }

  header {
    position: sticky;
    top: 0;
    z-index: ${layers.detailsHeader};
    background-color: inherit;
    align-items: flex-start;
    padding-bottom: 0;

    @media ${MEDIA_QUERY.MAX_MD} {
      min-height: 0;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    & > button {
      margin-top: 6px;
    }

    & > div,
    & > div > div {
      width: 100%;

      [class*='tab-list'] {
        margin-top: 24px;

        &:after {
          background-color: transparent;
        }
      }
    }

    [class^='_tab-list_'] {
      @media ${MEDIA_QUERY.MAX_MD} {
        display: none;
      }
    }
  }

  & > div > header,
  & > div > div {
    padding-inline: 80px;

    @media ${MEDIA_QUERY.MAX_LG} {
      padding-inline: 32px;
    }
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    & > div > header {
      padding-inline: 12px;
    }
    & > div > div {
      & > div > div > div:first-child {
        padding-inline: 12px;
      }
      padding-inline: 0;
    }
  }

  @media ${MEDIA_QUERY.MAX_LG} {
    & > div,
    & > div > div {
      width: 100%;
    }
  }
`;

export const TableContainer = styled.div`
  align-self: start;
  & > div {
    padding-top: 16px;
  }

  th,
  td {
    &:first-of-type {
      padding-left: 16px;
    }
    &:last-of-type {
      padding-right: 16px;
    }
  }
`;

export const SummaryContainer = styled.div<{ theme?: Theme }>`
  position: sticky;
  z-index: ${layers.shoppingCartSummaryContainer};
  bottom: 0;
  padding: 0 32px 16px !important;
  margin-top: 32px;
  background-color: ${({ theme }) => theme.colors.codGrayDarker};
  color: ${({ theme }) => theme.fontColor};
  font-size: 12px;

  @media ${MEDIA_QUERY.MAX_MD} {
    bottom: 64px;
    margin-top: 96px;
  }

  strong {
    color: ${({ theme }) => theme.colors.white};
  }

  & > div {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px 32px;
    text-align: right;
    font-weight: 700;
    @media ${MEDIA_QUERY.LG} {
      padding-top: 24px;
      flex-direction: row;
      text-align: left;
      border-top: 1px solid ${({ theme }) => theme.colors.mineShaftLighter};
    }

    & > div:first-of-type {
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.mineShaftLighter};
      color: ${({ theme }) => theme.colors.white};
      @media ${MEDIA_QUERY.LG} {
        margin-right: auto;
        padding-bottom: 0;
        border: none;
      }
    }
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px 8px;
  h3 {
    font-weight: 500;
    margin-right: auto;
  }
`;

export const InfoContainer = styled.div<{ theme?: Theme }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 24px 32px;
  padding: 12px 24px !important;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.mineShaftDarker};
  span {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const PricesSource = styled(InfoContainer)`
  margin: 0;
  @media ${MEDIA_QUERY.MAX_LG} {
    width: 100%;
  }
`;

export const CartNameContainer = styled.div`
  margin-right: auto;
  display: flex;
  position: relative;
  gap: 16px;
  overflow: hidden;

  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
  }

  @media ${MEDIA_QUERY.MAX_LG} {
    display: none;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: center;

  @media ${MEDIA_QUERY.MAX_MD} {
    padding: 0;
  }

  > div:nth-of-type(1) {
    order: 1;
  }
  > div:nth-of-type(2) {
    order: 2;
  }
  > div:nth-of-type(3) {
    order: 3;
  }
  > div:nth-of-type(4) {
    order: 4;
  }

  @media ${MEDIA_QUERY.MAX_LG} {
    flex-wrap: wrap;

    > div:nth-of-type(1) {
      order: -1;
    }
    > div:nth-of-type(2) {
      order: 1;
      flex: 2;
    }
    > div:nth-of-type(3) {
      order: 3;
    }
    > div:nth-of-type(4) {
      order: 2;
    }
  }
`;

export const EditButton = styled.div`
  position: relative;
  top: 18px;
  height: 25px;
`;

export const Divider = styled.div<{ theme?: Theme }>`
  margin: 12px 0;
  border-bottom: 1px dotted ${({ theme }) => theme.colors.mineShaftLightest};
`;

export const PriceWrapper = styled.div`
  text-align: right;
`;

export const ShoppingCartsListSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > :first-child {
    width: 70%;
  }
  & > :last-child {
    width: 50%;
  }
`;
