import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';
import { isNull } from 'lodash';

import { IconTooltipWarning } from 'components/shared/IconTooltip/IconTooltip';
import {
  getCellByColumnId,
  GridCellContent,
  GridImageContainer,
  GridImagePlaceholder,
  GridItem,
  GridItemValues,
  RowProps,
} from 'components/shared/List';
import { lambdaHostname, ModalFormType, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { ApiFile, Converter, ConverterListItem } from 'shared/types';
import { showModalForm } from 'store/modalFormSlice';
import { theme } from 'theme';
import { ConvertersSearchGrid } from './ConvertersSearchGrid';
import { TypedFormattedMessage as FormattedMessage } from '../locale/messages';

const ItemBadge = styled.div`
  position: absolute;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%);

  background-color: ${theme.colors.mineShaftLightest};
  font-size: 12px;
  font-weight: 700;
  padding: 4px 12px;
  border-radius: 4px;
`;

const GroupBadgeStyles = styled.div`
  background-color: ${theme.colors.mineShaftLightest};
  font-size: 12px;
  font-weight: 700;
  padding: 4px 12px;
  border-radius: 4px;
  margin-right: 6px;
`;

const GroupControlContainer = styled.div`
  position: absolute;
  display: flex;

  transform: translateY(-10px);
  right: -10px;
`;

export const WarningWrapper = styled.div`
  padding: 20px 15px 20px 0px;
  & > div {
    padding-left: 5px;
  }
`;
export const AddToCartButtonWrapper = styled.div<{ counterfeit?: boolean }>`
  padding: ${({ counterfeit }) => (counterfeit ? '5px 0px 0px 0px' : '20px 15px 20px 0px')};
`;

export const ConvertersListGridItem = ({
  row,
  parentRow,
  onClick,
  table,
}: React.PropsWithChildren<RowProps<ConverterListItem | Converter>>) => {
  const converter = row!.original;
  const converterPhoto = (converter.converterGroup?.files?.[0] ||
    (converter as Converter)?.files?.[0] ||
    converter.converterPhoto) as ApiFile;
  const converterIdentifier =
    converter.converterGroup?.converters?.map(c => c.identifier)?.join(', ') ||
    converter.converterIdentifier ||
    converter.identifier;

  const authorize = useAuthorization();
  const dispatch = useDispatch();
  const accessToCart = authorize(PERMISSIONS.SHOPPING_CART.MAIN);
  const handleAddToCart = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dispatch(
      showModalForm({
        modalType: ModalFormType.AddShoppingCartItems,
        params: { converters: [row!.original] as Converter[] },
      }),
    );
  };

  return (
    <GridItem row={row} parentRow={parentRow} onClick={onClick}>
      {converterPhoto ? (
        <GridImageContainer>
          <img
            alt={converterIdentifier}
            src={`${lambdaHostname}${converterPhoto.presignedUrls?.LARGE}`}
          />
        </GridImageContainer>
      ) : (
        <GridImagePlaceholder>
          <FormattedMessage id="Global.NoPhoto" />
        </GridImagePlaceholder>
      )}
      {converter.isPartial && !converter.converterGroup ? (
        <ItemBadge>
          <FormattedMessage id="ConvertersList.Grid.Partial" />
        </ItemBadge>
      ) : null}
      {converter?.converterGroup ? (
        <GroupControlContainer>
          <GroupBadgeStyles>
            <FormattedMessage id="ConvertersList.Grid.Group" />
          </GroupBadgeStyles>
          <Button
            content="icon"
            size="small"
            iconName={row?.getIsExpanded() ? 'Minus' : 'Plus'}
            variant="plain"
            onClick={e => {
              row?.toggleExpanded();
              e.stopPropagation();
            }}
          />
        </GroupControlContainer>
      ) : null}
      <GridItemValues>
        <div>
          <GridCellContent cell={getCellByColumnId(row!, 'identifier')} />
          <GridCellContent cell={getCellByColumnId(row!, 'price')} />
        </div>
        <div>
          {converter.counterfeit && (
            <WarningWrapper>
              <IconTooltipWarning tooltip={converter.notes || ''} />
            </WarningWrapper>
          )}
          {accessToCart && !row!.getCanExpand() && !isNull(row!.original.price.marketPrice) && (
            <AddToCartButtonWrapper counterfeit={converter.counterfeit}>
              <Button
                iconName="BasketSecondary"
                variant="plain"
                size="medium"
                content="icon"
                onClick={event => handleAddToCart(event)}
                data-cy={`add-to-cart-${row!.original.id}`}
              />
            </AddToCartButtonWrapper>
          )}
        </div>
      </GridItemValues>
      <ConvertersSearchGrid row={row} table={table} />
    </GridItem>
  );
};
