import React, { ReactElement, useMemo } from 'react';
import { ButtonProps, Form, FormRow } from '@alpha-recycling/component-library';
import { FieldArray } from 'formik';
import { isNil } from 'lodash';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { UNITS } from 'shared/constants';
import { useGetNonstandardConverters } from 'shared/queries';
import { MaterialUnit, Vehicle } from 'shared/types';
import { MAX_CONVERTERS_COUNT } from './vehicleFormSchema';
import { useTypedIntl } from '../locale/messages';

interface NonstandardConverterFieldsProps {
  values: Vehicle;
  handleChange: (e?: React.ChangeEvent) => void;
  getErrors: (fieldName: string) => string | false | undefined;
  handleBlur: (e?: React.ChangeEvent) => void;
  setActiveInput: (input: string) => void;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
  label?: string;
}

export const NonstandardConverterSection = ({
  values,
  handleChange,
  setActiveInput,
  getErrors,
  handleBlur,
  setFieldValue,
  label = '',
}: NonstandardConverterFieldsProps): React.ReactElement => {
  const intl = useTypedIntl();

  const nonstandardConverters = useGetNonstandardConverters();

  const nonstandardConvertersOptions = useMemo(
    () =>
      nonstandardConverters.data?.data.map(converter => ({
        value: converter.id,
        label: converter.material,
      })) ?? [],
    [nonstandardConverters.data?.data],
  );

  const onBlur = e => {
    setActiveInput('');
    handleBlur(e);
  };

  const onFocus = e => {
    setActiveInput(e.target.name);
  };

  const materialRequiresWeight = (nonstandardConverterId?: number): boolean => {
    if (isNil(nonstandardConverterId)) return false;

    const selectedConverter = nonstandardConverters.data?.data.find(
      converter => converter.id === nonstandardConverterId,
    );
    return selectedConverter?.materialUnit === MaterialUnit.LB;
  };

  const onNonstandardConverterChange = (index: number): void => {
    setFieldValue(`nonstandardConverters.${index}.materialWeight`, undefined);
  };

  const nonstandardConvertersToRender = values.nonstandardConverters?.slice(
    0,
    Math.min(MAX_CONVERTERS_COUNT, values.numberOfNonstandardConverters ?? 0),
  );

  return (
    <FieldArray
      name="nonstandardConverters"
      render={() => (
        <Form headerText={label} headerButton={false as unknown as ButtonProps}>
          <FormRow>
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.NumberOfNonstandardConverters' })}
              name="numberOfNonstandardConverters"
              onChange={handleChange}
              onBlur={onBlur}
              value={
                values.numberOfNonstandardConverters
                  ? String(values.numberOfNonstandardConverters)
                  : ''
              }
              error={getErrors('numberOfNonstandardConverters')}
              data-cy="number-of-nonstandard-converters"
              onFocus={onFocus}
              required
              maxLength={9}
            />
          </FormRow>

          {
            nonstandardConvertersToRender?.map((field, index) => (
              <FormRow key={field.key}>
                <FieldSelect
                  label={intl.formatMessage({ id: 'VehicleForm.NonstandardConverter' })}
                  name={`nonstandardConverters.${index}.nonstandardConverterId`}
                  onChange={() => onNonstandardConverterChange(index)}
                  options={nonstandardConvertersOptions}
                  value={field.nonstandardConverterId}
                  data-cy={`nonstandard-onverters-${index}-nonstandard-converter-id`}
                  onFocus={e => setActiveInput(e.target.name)}
                  error={getErrors(`nonstandardConverters.${index}.nonstandardConverterId`)}
                  onBlur={onBlur}
                  disabled={nonstandardConverters.isFetching}
                  required
                />
                {materialRequiresWeight(field.nonstandardConverterId) ? (
                  <FieldInput
                    label={intl.formatMessage({ id: 'VehicleForm.MaterialWeight' })}
                    name={`nonstandardConverters.${index}.materialWeight`}
                    onChange={handleChange}
                    onBlur={onBlur}
                    value={field.materialWeight ? String(field.materialWeight) : ''}
                    error={getErrors(`nonstandardConverters.${index}.materialWeight`)}
                    data-cy={field.materialWeight}
                    suffix={UNITS.LBS}
                    onFocus={onFocus}
                    autoComplete="off"
                  />
                ) : (
                  (null as unknown as ReactElement)
                )}
              </FormRow>
            )) as unknown as ReactElement
          }
        </Form>
      )}
    />
  );
};
