import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from '@alpha-recycling/component-library';

import { useTypedIntl } from 'locale/messages';
import { useExtendedTheme } from 'shared/hooks';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MessageShape, snackBarRemoveMessage } from 'store/shared/snackBarSlice';
import {
  SNACKBAR_ANIMATION_TIME,
  snackBarDefaultMessageStyles,
  snackBarMessageButtonStyles,
  snackBarMessageHideAnimationStyles,
  snackBarMessageShowAnimationStyles,
  snackBarStyles,
} from './SnackBar.styles';

type MessageProps = {
  id: number;
  type?: 'success' | 'failure';
  children: React.ReactNode;
  time?: number;
};

const SnackBarMessage = ({
  type = 'success',
  id,
  children,
  time = 5000,
}: MessageProps): React.ReactElement => {
  const dispatch = useAppDispatch();
  const messageRef = useRef<HTMLDivElement>(null);
  const theme = useExtendedTheme();

  const [className, setClassName] = useState(snackBarDefaultMessageStyles(type, theme));

  let timeout: NodeJS.Timeout | null = null;

  const removeMessage = useCallback(
    messageId => {
      timeout && clearTimeout(timeout);
      messageRef.current &&
        setClassName(
          snackBarMessageHideAnimationStyles(type, messageRef.current.offsetHeight, theme),
        );
      setTimeout(() => {
        dispatch(snackBarRemoveMessage(messageId));
      }, SNACKBAR_ANIMATION_TIME);
    },
    [type, messageRef.current],
  );

  useEffect(() => {
    messageRef.current &&
      setClassName(
        snackBarMessageShowAnimationStyles(type, messageRef.current.offsetHeight, theme),
      );
    timeout = setTimeout(() => {
      removeMessage(id);
    }, time - SNACKBAR_ANIMATION_TIME);

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [removeMessage, dispatch, messageRef.current]);

  return (
    <div className={className} ref={messageRef}>
      {children}
      <button
        className={snackBarMessageButtonStyles}
        type="button"
        onClick={() => removeMessage(id)}
        onKeyPress={() => removeMessage(id)}
      >
        <Icon name="X" size="big" />
      </button>
    </div>
  );
};

const SnackBar = (): React.ReactElement => {
  const intl = useTypedIntl();
  const snackBarMessages = useAppSelector(state => state.snackBar.messages);
  const getMessageTimeout = (item: MessageShape): number => {
    if (item.timeout) {
      return item.timeout;
    }

    if (typeof item.message === 'string') {
      return item.message.length * 150;
    }

    const { message } = item as { message: React.ReactElement };
    return message.props?.id && intl.formatMessage({ id: message.props.id }).length * 150;
  };

  return (
    <div className={snackBarStyles} data-cy="snackbar-container">
      {snackBarMessages.map(item => (
        <SnackBarMessage key={item.id} id={item.id} type={item.type} time={getMessageTimeout(item)}>
          {item.message}
        </SnackBarMessage>
      ))}
    </div>
  );
};

export { SnackBar };
