import React from 'react';
import { DataOutput, OutputCell } from '@alpha-recycling/component-library';

import { DetailsField } from 'components/shared/Details/Details';
import { formatDate } from 'helpers/dateTime/dateTime';
import { formatDeviceData } from 'helpers/devices/formatDeviceData';
import { UserDevice } from 'shared/types';
import { useTypedIntl } from '../locale/messages';

export function UserDetailsDevices({
  userDevices,
}: {
  userDevices: UserDevice[];
}): React.ReactElement | null {
  const intl = useTypedIntl();

  return userDevices.length > 0 ? (
    <DataOutput
      headerText={intl.formatMessage({
        id: 'UserDetails.Devices.Label',
      })}
    >
      {userDevices
        .reduce<DetailsField[]>(
          (prev, { name, os, browserFamily, createdAt, status }, index) => [
            ...prev,
            ...[
              {
                name: `device${index + 1}Name`,
                label: intl.formatMessage({ id: 'UserDetails.Devices.OS' }),
                value: formatDeviceData(name, os, browserFamily),
              },
              {
                name: `device${index + 1}Date`,
                label: intl.formatMessage({ id: 'UserDetails.Devices.Date' }),
                value: formatDate(createdAt),
              },
              {
                name: `device${index + 1}Date`,
                label: intl.formatMessage({ id: 'UserDetails.Devices.Status' }),
                value: intl.formatMessage({ id: `Global.Status.${status}` }),
              },
            ],
          ],
          [],
        )
        .map((field, index) => (
          <OutputCell
            key={field.name}
            data-cy={field.name}
            mergeCells={index % 3 !== 0 ? 2 : 4}
            labelValue={field.label}
          >
            {field.value}
          </OutputCell>
        ))}
    </DataOutput>
  ) : null;
}
