import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname, CompanyTypes } from 'shared/constants';
import { Company, CompanyListItem, PaginatedData, Status } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum CompaniesKeys {
  GetCompanies = 'GetCompanies',
  GetAssignedCompanies = 'GetAssignedCompanies',
  GetCompanyDetails = 'GetCompanyDetails',
}

type GetCompaniesParams = {
  page?: number;
  pageSize?: number;
  country?: number | null;
  state?: string | null;
  query?: string;
  status?: Status | null;
  type?: CompanyTypes | null;
};
export type PaginatedCompaniesData = PaginatedData<CompanyListItem>;

export const useGetCompanies = (
  params: GetCompaniesParams,
  options: UseQueryOptions<PaginatedCompaniesData> = {},
): UseQueryResult<PaginatedCompaniesData> =>
  useQuery(
    [CompaniesKeys.GetCompanies, params] as QueryKey,
    async () => {
      const response = await getHttpClient().get<PaginatedCompaniesData>(
        `${apiHostname}/api/companies`,
        { params },
      );

      return response.data;
    },
    {
      initialData: {
        count: 0,
        data: [],
      },
      cacheTime: 6000,
      ...options,
    },
  );

export const useGetCompanyDetails = (
  id: string | number,
  options: UseQueryOptions<Company> = {},
): UseQueryResult<Company> =>
  useQuery(
    [CompaniesKeys.GetCompanyDetails, id] as QueryKey,
    async () => {
      const response = await getHttpClient().get<Company>(`${apiHostname}/api/companies/${id}`);

      return response.data;
    },
    options,
  );
