import React, { useEffect } from 'react';
import { Button, ModalPortal } from '@alpha-recycling/component-library';
import { FormikProvider, useFormik } from 'formik';
import { isNil } from 'lodash';
import * as Yup from 'yup';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldMultiSelect } from 'components/shared/Fields/FieldSelect/FieldMultiSelect';
import { ModalFormContent } from 'components/shared/forms/Form/ModalForm.styles';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { ModalFormType, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useAssignHedgeToUsers } from 'shared/mutations';
import { useGetAssignableUsers } from 'shared/queries';
import { HedgeAssignParams, hideModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { Subheader } from './AssignHedgeModal.styles';
import { messages, TypedIntlShape, useTypedIntl } from '../locale/messages';

const validationSchema = (intl: TypedIntlShape) =>
  Yup.object().shape({
    users: Yup.array()
      .of(Yup.number())
      .required()
      .min(1, intl.formatMessage({ id: 'HedgeAssign.Modal.Form.Users.MinError' }))
      .max(20, intl.formatMessage({ id: 'HedgeAssign.Modal.Form.Users.MaxError' }))
      .ensure(),
  });

function AssignHedgeModalComponent(): React.ReactElement {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();
  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);
  const hedgeAssignParams = params as HedgeAssignParams;
  const { hedge } = hedgeAssignParams ?? {};
  const isModalOpen = modalType === ModalFormType.AssignHedge && isOpen && !isNil(hedge);
  const assignHedgeToUser = useAssignHedgeToUsers();
  const getAssignableUsers = useGetAssignableUsers(
    { hedgeId: hedge?.id },
    { enabled: !isNil(hedge?.id) },
  );
  const formikContext = useFormik<{ users: number[] | null }>({
    initialValues: {
      users: null,
    },
    enableReinitialize: true,
    onSubmit: async x => x,
    validationSchema: validationSchema(intl),
  });
  const hideModal = () => {
    dispatch(hideModalForm());
  };
  const handleConfirmClick = async () => {
    try {
      await assignHedgeToUser.mutateAsync({
        hedgeId: hedge.id,
        usersIds: formikContext.values.users,
      });
      dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'HedgeAssign.Modal.Success' })));
      if (typeof hedgeAssignParams.onSuccess === 'function') {
        hedgeAssignParams.onSuccess();
      }
      hideModal();
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      formikContext.resetForm();
    }
  }, [isModalOpen, hedge]);

  const usersOptions = getAssignableUsers.data!.map(user => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));
  const submitDisabled =
    !formikContext.dirty ||
    !formikContext.isValid ||
    getAssignableUsers.isFetching ||
    assignHedgeToUser.isLoading;
  const displayHedgePrices = authorize(PERMISSIONS.HEDGES.SHOW_HEDGE_PRICES);

  return isModalOpen ? (
    <ModalPortal
      data-cy="hedge-modal"
      isOpen={isModalOpen}
      innerPadding={false}
      header={intl.formatMessage({ id: 'HedgeAssign.Modal.Header' })}
      rightButtons={[
        <Button
          onClick={hideModal}
          variant="transparent"
          content="text"
          data-cy="assign-hedge-cancel"
          label={intl.formatMessage({ id: 'Global.Cancel' })}
        />,
        <Button
          onClick={handleConfirmClick}
          disabled={submitDisabled}
          content="text"
          data-cy="assign-hedge-confirm"
          label={intl.formatMessage({ id: 'Global.Confirm' })}
        />,
      ]}
    >
      <ModalFormContent>
        <FormikProvider value={formikContext}>
          <Subheader data-cy="assign-hedge-subheader">
            {hedge.name} {formatDateWithTime(hedge.placedAt)}
            <br />
            {displayHedgePrices &&
              [
                `Pt: ${formatCurrency(hedge.ptPriceCust)}`,
                `Pd: ${formatCurrency(hedge.pdPriceCust)}`,
                `Rh: ${formatCurrency(hedge.rhPriceCust)}`,
              ].join(', ')}
          </Subheader>
          <FieldMultiSelect
            label={intl.formatMessage({ id: 'HedgeAssign.Modal.Users' })}
            name="users"
            value={formikContext.values.users}
            options={usersOptions}
            required
            placeholder={intl.formatMessage({ id: 'HedgeAssign.Modal.Select' })}
            error={formikContext.touched.users && formikContext.errors.users}
          />
        </FormikProvider>
      </ModalFormContent>
    </ModalPortal>
  ) : (
    <div />
  );
}

export const AssignHedgeModal = withAlphamartIntlProvider(AssignHedgeModalComponent, messages);
