import styled from '@emotion/styled';

import { Theme } from 'theme';

const StyledH1 = styled.h1`
  display: flex;
  font-size: 18px;
  color: var(--color-natural-text);
  font-weight: 700;
  align-items: center;
  margin: 0;
  justify-content: flex-start;
  width: 100%;
  height: 52px;
`;

const StyledUl = styled.ul`
  display: flex;
  flex-flow: wrap;
  padding: 0;
  margin: 0;
`;

const StyledLi = styled.li<{ theme?: Theme; active?: boolean }>`
  display: inline-block;
  padding: 4px 16px;
  border-radius: 4px;
  margin: 5px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.mineShaftLightest : theme.colors.mineShaftLighter};
`;

const FormActions = styled.div`
  grid-area: actions;
  display: flex;
  justify-content: space-between;
  max-height: 40px;

  button:not(:last-child) {
    margin-right: 32px;
  }
`;

export { FormActions, StyledH1, StyledLi, StyledUl };
