import styled from '@emotion/styled';

import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { MEDIA_QUERY, Theme } from 'theme';

const PartialConvertersPanel = styled.div<{ theme?: Theme }>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.mineShaftLightest};
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-template-rows: 97px auto;
  grid-template-areas: 'header groups' 'partialConverters groupsSection';
  gap: 1px;

  & > div {
    background-color: ${({ theme }) => theme.background};
    font-size: 18px;
    display: flex;
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    min-height: 100vh;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    grid-template-columns: 100%;
    grid-template-rows: 64px auto 64px auto;
    grid-template-areas: 'header' 'partialConverters' 'groups' 'groupsSection';
  }
`;

const PartialConvertersPartialHeader = styled.div`
  grid-area: header;
  padding-left: 38px;
  align-items: center;
  margin-left: 77px;

  @media ${MEDIA_QUERY.MAX_XL} {
    margin: 0;
  }
`;

const PartialConvertersGroupHeader = styled.div`
  grid-area: groups;
  align-items: center;
  justify-content: space-between;
  padding: 0 38px;

  & > button {
    min-width: 144px;
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    padding: 0 16px;
  }
`;

const PartialConvertersContainer = styled(FormContainer)`
  grid-area: partialConverters;
`;

export {
  PartialConvertersContainer,
  PartialConvertersGroupHeader,
  PartialConvertersPanel,
  PartialConvertersPartialHeader,
};
