import React, { useEffect } from 'react';
import { Button, ModalPortal } from '@alpha-recycling/component-library';
import { useQueryClient } from '@tanstack/react-query';
import { FormikProvider, useFormik } from 'formik';
import { isNil } from 'lodash';
import * as Yup from 'yup';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { ModalFormContent } from 'components/shared/forms/Form/ModalForm.styles';
import { ModalFormType } from 'shared/constants';
import { useRenameShoppingCart } from 'shared/mutations';
import { ShoppingCartsKeys } from 'shared/queries';
import { hideModalForm, ShoppingCartParams } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { messages, useTypedIntl } from '../../locale/messages';

function RenameModalComponent(): React.ReactElement {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);

  const cartParams = params as ShoppingCartParams;
  const { cart } = cartParams ?? {};
  const isModalOpen = modalType === ModalFormType.RenameShoppingCart && isOpen && !isNil(cart);
  const renameShoppingCart = useRenameShoppingCart();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(intl.formatMessage({ id: 'ShoppingCart.Modal.ShoppingCartName.RequiredError' }))
      .ensure(),
  });

  const formikContext = useFormik<{ name: string }>({
    initialValues: {
      name: cart?.name ?? '',
    },
    enableReinitialize: true,
    onSubmit: async x => x,
    validateOnChange: true,
    validationSchema,
  });

  const { values, handleChange, errors, handleBlur, isValid, dirty } = formikContext;
  const queryClient = useQueryClient();

  const hideModal = () => {
    dispatch(hideModalForm());
  };

  const handleConfirmClick = async () => {
    try {
      await renameShoppingCart.mutateAsync({ id: cart.id, name: values.name });
      await queryClient.invalidateQueries({ queryKey: [ShoppingCartsKeys.GetShoppingCarts] });
      dispatch(
        snackBarPushSuccess(intl.formatMessage({ id: 'ShoppingCart.Modal.Rename.Success' })),
      );
      hideModal();
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      formikContext.resetForm();
    }
  }, [isModalOpen, cart]);

  const submitDisabled = !dirty || !isValid || renameShoppingCart.isLoading;

  return (
    <ModalPortal
      isOpen={isModalOpen}
      innerPadding={false}
      header={intl.formatMessage({ id: 'ShoppingCart.Modal.Rename.Header' })}
      rightButtons={[
        <Button
          onClick={hideModal}
          variant="transparent"
          content="text"
          data-cy="rename-shopping-cart-cancel"
          label={intl.formatMessage({ id: 'Global.Cancel' })}
        />,
        <Button
          onClick={handleConfirmClick}
          disabled={submitDisabled}
          content="text"
          data-cy="rename-shopping-cart-confirm"
          label={intl.formatMessage({ id: 'Global.Confirm' })}
        />,
      ]}
    >
      <ModalFormContent>
        <FormikProvider value={formikContext}>
          <FieldInput
            label={intl.formatMessage({ id: 'ShoppingCart.Modal.Name' })}
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.name}
            value={values.name}
            data-cy="rename-shopping-cart-name"
            autoComplete="off"
            maxLength={20}
          />
        </FormikProvider>
      </ModalFormContent>
    </ModalPortal>
  );
}

export const RenameCartModal = withAlphamartIntlProvider(RenameModalComponent, messages);
