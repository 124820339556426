import React, { useEffect, useMemo } from 'react';
import { Button } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { ContextMenu } from 'components/shared/ContextMenu';
import { DataList } from 'components/shared/List/DataList';
import { Price } from 'components/shared/Price';
import { formatDate } from 'helpers/dateTime/dateTime';
import { SHARED, SUPPORTED_PORTAL_CURRENCIES } from 'shared/constants';
import { useCurrentUser, useLanguage, useMediaQuery } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { fetchExchangeRates } from 'store/exchangeRatesSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from '../locale/messages';
import { ListSection } from '../Settings.styles';

const ExchangeRatesInfo = styled.p`
  color: ${theme.fontColor};
  font-size: 12px;
`;

const ActionColumn = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;

export const ExchangeRates = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const language = useLanguage();
  const currentUser = useCurrentUser();
  const { exchangeRates, isPending } = useAppSelector(state => state.exchangeRates);
  const intl = useTypedIntl();
  useEffect(() => {
    dispatch(fetchExchangeRates());
  }, [dispatch, language]);

  const actions = [
    {
      id: 0,
      label: <FormattedMessage id="Settings.ExchangeRates.EditAdjustment" />,
      onClick: item => navigate(`/company/settings/currency/${item.currency}/edit`),
      dataCy: item => `${item.name}-create-adjustment`,
    },
  ];

  const getCurrencyName = currencyPair => {
    const currencyArr = currencyPair.split('/');
    return currencyArr[0] === SUPPORTED_PORTAL_CURRENCIES.USD
      ? SUPPORTED_PORTAL_CURRENCIES.PLN
      : currencyArr[0];
  };

  const columns: ColumnDef<{
    name: string;
    rate: string;
    adjustment: string;
    adjusted: string;
    currency: string;
  }>[] = [
    {
      id: 'currencyPair',
      header: intl.formatMessage({ id: 'Settings.ExchangeRates.CurrencyPair' }),
      cell: ({ row }) => row.original.name,
    },
    {
      id: 'rate',
      header: intl.formatMessage({ id: 'Settings.ExchangeRates.Rate' }),
      cell: ({ row }) => (
        <Price
          value={+row.original.rate}
          language={currentUser.language}
          currency={currentUser.currency}
          minimumFractionDigits={4}
        />
      ),
    },
    {
      id: 'adjustment',
      header: intl.formatMessage({ id: 'Settings.ExchangeRates.Adjustment' }),
      cell: ({ row }) =>
        +row.original.adjustment ? `${row.original.adjustment}%` : SHARED.LONG_DASH,
    },
    {
      id: 'adjusted',
      header: intl.formatMessage({ id: 'Settings.ExchangeRates.Adjusted' }),
      cell: ({ row }) => (
        <Price
          value={+row.original.adjusted}
          language={currentUser.language}
          currency={currentUser.currency}
          minimumFractionDigits={4}
        />
      ),
    },
    {
      id: 'actions',
      header: '',
      cell: ({ row }) => (
        <ActionColumn>
          <ContextMenu
            offsetX={-60}
            trigger={
              <Button
                dataTestId="actionButton"
                iconName="ThreeDots"
                variant="plain"
                content="icon"
              />
            }
          >
            {actions.map(action => (
              <button
                type="button"
                onClick={() => action.onClick(row.original)}
                key={action.id}
                data-cy={action.dataCy?.(row.original)}
              >
                {action.label}
              </button>
            ))}
          </ContextMenu>
        </ActionColumn>
      ),
    },
  ];

  const exchangeRatesData = useMemo(
    () =>
      Object.entries(exchangeRates.rates ?? {}).map(([name, value]) => ({
        name,
        ...value,
        currency: getCurrencyName(name),
      })),
    [exchangeRates.rates],
  );

  const table = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    data: exchangeRatesData,
    getRowId: row => row.name,
    enableRowSelection: false,
    state: {
      columnVisibility: {
        rate: useMediaQuery(MEDIA_QUERY.SM),
        adjustment: useMediaQuery(MEDIA_QUERY.MD),
      },
    },
  });

  return (
    <ListSection>
      <h3>
        <FormattedMessage id="Settings.ExchangeRates.Header.Title" />
      </h3>
      <ExchangeRatesInfo>
        <FormattedMessage id="Settings.SourceInfo.Source" />
        <strong>{exchangeRates.source}</strong>
        <FormattedMessage id="Settings.SourceInfo.Updated" />
        <strong>{exchangeRates.createdAt && formatDate(exchangeRates.createdAt)}</strong>
      </ExchangeRatesInfo>
      <DataList
        table={table}
        isLoading={isPending}
        size={useMediaQuery(MEDIA_QUERY.XXL) ? 'l' : 's'}
      />
    </ListSection>
  );
};
