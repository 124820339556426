import React, { useState } from 'react';
import { InfoBar } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';
import { useFormik } from 'formik';

import { FormWrapper } from 'components/shared/forms/Form/FormWrapper';
import { companyFormSchema } from 'components/views/Companies/CompanyForm/companyFormSchema';
import { useTypedIntl as useTypedIntlCompany } from 'components/views/Companies/locale/messages';
import { MiscellaneousFeesSection } from 'components/views/Terms/Forms/MiscellaneousFeesSection';
import { OtherChargesSection } from 'components/views/Terms/Forms/OtherChargesSection';
import { RatesAndChargesSection } from 'components/views/Terms/Forms/RatesAndChargesSection';
import { CompanyTypes, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser, useSubscriptionSeats } from 'shared/hooks';
import { CompanyFormData } from 'shared/types';
import { TypeSection } from './TypeSection';
import { useTypedIntl } from '../locale/messages';

const TermsFormWrapper = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
`;

type Props = {
  onSubmit: (
    values: CompanyFormData,
    termsType: boolean,
    termsTypeChanged: boolean,
  ) => Promise<void>;
  onCancel: () => void;
};

const TermsForm = ({ onSubmit, onCancel }: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const intlCompany = useTypedIntlCompany();
  const currentUser = useCurrentUser();
  const authorize = useAuthorization();

  const [termsType, setTermsType] = useState<string>(currentUser.company.grading ? '1' : '0');
  const hasManySeats = useSubscriptionSeats(seats => seats > 1);
  const canChangeTermsType =
    !hasManySeats ||
    (currentUser.company.type === CompanyTypes.INTERNAL &&
      authorize(PERMISSIONS.SET_YOUR_APP.INTERNAL_SETUP));
  const validationSchema = companyFormSchema(intlCompany);
  const { terms } = currentUser.company;
  const initialValues: CompanyFormData = {
    terms: {
      treatmentChargePerPound: terms.treatmentChargePerPound,
      metalsReturnTermInDays: terms.metalsReturnTermInDays,
      platinumReturnRate: terms.platinumReturnRate,
      palladiumReturnRate: terms.palladiumReturnRate,
      rhodiumReturnRate: terms.rhodiumReturnRate,
      platinumReturnFinanceRate: terms.platinumReturnFinanceRate,
      palladiumReturnFinanceRate: terms.palladiumReturnFinanceRate,
      rhodiumReturnFinanceRate: terms.rhodiumReturnFinanceRate,
      platinumRefiningCharge: terms.platinumRefiningCharge,
      palladiumRefiningCharge: terms.palladiumRefiningCharge,
      rhodiumRefiningCharge: terms.rhodiumRefiningCharge,
      miscellaneousFees: terms.miscellaneousFees,
    },
  };

  const formikContext = useFormik<CompanyFormData>({
    initialValues,
    validationSchema: termsType === '0' ? validationSchema : null,
    onSubmit: rawValues =>
      onSubmit?.(
        validationSchema.cast(rawValues, { stripUnknown: true }),
        termsType === '1',
        termsTypeChanged,
      ).catch(() => formikContext.setSubmitting(false)),
  });

  const { handleSubmit, isValidating, isSubmitting, isValid } = formikContext;

  const termsTypeChanged = termsType !== (currentUser.company.grading ? '1' : '0');

  const isFormInvalid = !isValid || isValidating || isSubmitting;

  return (
    <FormWrapper
      dataCy="terms-form"
      header={intl.formatMessage({
        id: 'Terms.Header',
      })}
      submitLabel={intl.formatMessage({
        id: 'Global.Save',
      })}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitDisabled={termsType === '0' ? isFormInvalid : false}
      context={formikContext}
    >
      {canChangeTermsType && (
        <TermsFormWrapper>
          <TypeSection
            type={termsType}
            setType={setTermsType}
            label={intl.formatMessage({ id: 'Terms.TermsType' })}
          />
          {termsType === '1' && !currentUser.company.grading && (
            <InfoBar
              dataTestId="terms-hedge-remove-warning"
              text={intl.formatMessage({ id: 'TermsForm.HedgeRemoveWarning' })}
              type="alert"
              icon
              variant="independent"
            />
          )}
        </TermsFormWrapper>
      )}
      {termsType === '0' && [
        <RatesAndChargesSection
          key="ratesAndCharges"
          formikContext={formikContext}
          label={intl.formatMessage({ id: 'TermsForm.Section.RatesAndCharges' })}
        />,
        <OtherChargesSection
          key="otherCharges"
          formikContext={formikContext}
          label={intl.formatMessage({ id: 'TermsForm.Section.OtherCharges' })}
        />,
        <MiscellaneousFeesSection
          key="miscellaneousFeesSection"
          formikContext={formikContext}
          label={intl.formatMessage({
            id: 'TermsForm.Section.MiscellaneousFees',
          })}
        />,
      ]}
    </FormWrapper>
  );
};

export { TermsForm };
