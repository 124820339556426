import React, { ReactNode, useEffect } from 'react';
import { FormikContextType } from 'formik';
import { v4 as uuid4 } from 'uuid';

import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { ConverterSection } from './ConverterSection';
import { NonstandardConverterSection } from './NonstandardConverterSection';
import { MAX_CONVERTERS_COUNT } from './vehicleFormSchema';
import { VehicleFormShape } from './VehicleFormStepOne';
import { useTypedIntl } from '../locale/messages';

interface Props {
  activeInput?: string | null;
  values: VehicleFormShape;
  context: FormikContextType<VehicleFormShape>;
  getErrors: (fieldName: string) => string | false | undefined;
  handleChange: (e?: React.ChangeEvent) => void;
  handleBlur: (e?: React.ChangeEvent) => void;
  setActiveInput: (input: string) => void;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
  editMode?: boolean;
  controlButtons?: ReactNode;
}

export const VehicleFormStepTwo = ({
  handleChange,
  handleBlur,
  values,
  getErrors,
  context,
  activeInput,
  setActiveInput,
  setFieldValue,
  editMode = false,
  controlButtons,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();

  useEffect(() => {
    setFieldValue(
      'converters',
      [
        ...values.converters,
        ...[...new Array(MAX_CONVERTERS_COUNT)].map(() => ({
          key: uuid4(),
          identifier: '',
          identifierConfirm: '',
        })),
      ].slice(0, MAX_CONVERTERS_COUNT),
    );

    setFieldValue(
      'nonstandardConverters',
      [
        ...values.nonstandardConverters,
        ...[...new Array(MAX_CONVERTERS_COUNT)].map(() => ({
          key: uuid4(),
          nonstandardConverterId: '',
          materialWeight: '',
        })),
      ].slice(0, MAX_CONVERTERS_COUNT),
    );
  }, [values.numberOfConverters, values.numberOfNonstandardConverters]);

  return (
    <FormInWizard
      context={context}
      header={intl.formatMessage(
        editMode ? { id: 'VehicleForm.UpdateVehicle' } : { id: 'VehicleForm.CreateVehicle' },
      )}
      controlButtons={controlButtons}
    >
      <ConverterSection
        handleChange={handleChange}
        activeInput={activeInput}
        values={values}
        setFieldValue={setFieldValue}
        setActiveInput={setActiveInput}
        getErrors={getErrors}
        handleBlur={handleBlur}
        label={intl.formatMessage({ id: 'VehicleForm.ConverterDetails' })}
      />
      <NonstandardConverterSection
        handleChange={handleChange}
        values={values}
        setFieldValue={setFieldValue}
        setActiveInput={setActiveInput}
        getErrors={getErrors}
        handleBlur={handleBlur}
        label={intl.formatMessage({ id: 'VehicleForm.NonstandardConverterDetails' })}
      />
    </FormInWizard>
  );
};
