import * as React from 'react';
import styled from '@emotion/styled';

import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { theme } from 'theme';

type Props = {
  className?: string;
};

const PhotoPlaceholderLayout = ({ className }: Props): React.ReactElement => (
  <div className={className}>
    <FormattedMessage id="Global.NoPhoto" />
  </div>
);

const PhotoPlaceholder = styled(PhotoPlaceholderLayout)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  background-color: ${theme.colors.mineShaftLightest};
  border-radius: 4px;
  min-width: 72px;
  width: 72px;
  height: 48px;
  text-align: center;
`;

export { PhotoPlaceholder };
