import React from 'react';
import { Button, InfoBar, ModalPortal } from '@alpha-recycling/component-library';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { ModalFormType } from 'shared/constants';
import { hideModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { openProfile } from 'store/shared/userProfileSlice';
import { setTermsTypeChanged } from 'store/termsSlice';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

export const GoToPreferenceModal = () => {
  const intl = useTypedIntl();
  const { isOpen, modalType } = useAppSelector(state => state.modalForm);
  const isModalOpen = modalType === ModalFormType.GoToPreference && isOpen;
  const dispatch = useAppDispatch();

  const hideModal = () => {
    dispatch(hideModalForm());
  };

  return (
    <AlphamartIntlProvider messages={messages}>
      <ModalPortal
        isOpen={isModalOpen}
        innerPadding={false}
        data-cy="go-to-preference-modal"
        header={intl.formatMessage({ id: 'Terms.GoToPreferenceModal.Header' })}
        rightButtons={[
          <Button
            onClick={() => {
              dispatch(setTermsTypeChanged(false));
              hideModal();
            }}
            variant="transparent"
            content="text"
            label={intl.formatMessage({ id: 'Global.Close' })}
            data-cy="cancel-button"
          />,
          <Button
            onClick={() => {
              hideModal();
              dispatch(setTermsTypeChanged(false));
              dispatch(openProfile({ scrollToId: 'profileProfitMarginSection' }));
            }}
            content="text"
            label={intl.formatMessage({ id: 'Terms.GoToPreferenceModal.Button' })}
          />,
        ]}
      >
        <div>
          <InfoBar
            text={<FormattedMessage id="Terms.GoToPreferenceModal.Content" />}
            type="info"
            icon
            variant="basic"
          />
        </div>
      </ModalPortal>
    </AlphamartIntlProvider>
  );
};
