import React, { ReactElement, useEffect } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import { fetchConfig } from 'store/config';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { AuthGuard } from '../AuthGuard/AuthGuard';

interface Props {
  children: ReactElement;
}

export function AuthProvider({ children }: Props): ReactElement {
  const dispatch = useAppDispatch();
  const auth0Config = useAppSelector(state => state.config?.auth0);

  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  useEffect(() => {
    window.sessionStorage.setItem('converterToolAppState', 'loaded');
  }, []);

  if (!auth0Config) return children;
  const { audience, ...rest } = auth0Config;

  return (
    <Auth0Provider authorizationParams={{ redirect_uri: origin, audience }} {...rest}>
      <AuthGuard>{children}</AuthGuard>
    </Auth0Provider>
  );
}
