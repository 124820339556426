import React from 'react';
import { OptionsProps, Select } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { useTypedIntl } from 'locale/messages';
import {
  SortDirection,
  SortInterface,
  SortSelectOptions,
  SortTypes,
} from 'shared/constants/sortableModules';

const PageSortSelectContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

interface PageSortInterface {
  customSortTypes?: SortSelectOptions[];
  handleChange?: (changed: SortInterface | null) => void;
  currentSortValue?: SortInterface;
}

const PageSortSelect = ({
  customSortTypes,
  handleChange,
  currentSortValue,
}: PageSortInterface): React.ReactElement => {
  const intl = useTypedIntl();

  const defaultSortTypes = [
    {
      label: intl.formatMessage({ id: `Global.Filters.Default` }),
      value: null,
    },
  ];

  const sortOptions = Array.isArray(customSortTypes)
    ? [...defaultSortTypes, ...customSortTypes]
    : defaultSortTypes;

  const selectOptions = sortOptions.map(({ label, value }) => ({
    label,
    value: value ? `${value.sortType}.${value.sortDirection}` : '',
  }));

  const optionValue =
    selectOptions.find(
      option => option.value === `${currentSortValue?.sortType}.${currentSortValue?.sortDirection}`,
    ) ?? selectOptions[0];
  const handleSortChange = (selected: OptionsProps | null) => {
    const [sortType, sortDirection] = `${selected?.value}`?.split('.') as [
      SortTypes,
      SortDirection,
    ];
    handleChange?.(selected?.value ? { sortType, sortDirection } : null);
  };

  return (
    <PageSortSelectContainer>
      <Select
        id="sort-list-select"
        name="sort-list"
        onChange={handleSortChange}
        options={selectOptions}
        value={optionValue}
        searchable={false}
        required
        data-cy="sort-list-select"
      />
    </PageSortSelectContainer>
  );
};

export { PageSortSelect };
