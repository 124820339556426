import React from 'react';
import { Icon } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';

type Size = 'small' | 'medium' | 'big';
interface Props {
  size?: Size;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const BellContainer = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  position: relative;
`;

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  transition: background-color 0.3s;
`;

const CounterIcon = styled.span<{ count: number }>`
  position: absolute;
  display: flex;
  top: 15px;
  right: 15px;
  background: ${theme.mainColor};
  color: ${theme.fontColor};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: ${({ count }) => (count > 9 ? '12px' : '15px')};
  font-weight: 700;
  align-items: center;
  justify-content: center;

  @media ${MEDIA_QUERY.MD} {
    top: -5px;
    right: -5px;
  }
`;

export function NotificationsBell({ size = 'big', onClick }: Props): React.ReactElement {
  const count = useAppSelector(state => state.notificationCount?.count);

  return (
    <BellContainer onClick={onClick} data-cy="bell-container">
      <StyledIcon>
        <Icon
          name="Bell"
          size={size}
          fillColor={count ? theme.fontColor : theme.colors.mineShaftLighter}
        />
      </StyledIcon>
      {count ? <CounterIcon count={count}>{count > 9 ? '9+' : count}</CounterIcon> : null}
    </BellContainer>
  );
}
