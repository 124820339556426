import React from 'react';
import { css } from '@emotion/css';
import { SerializedStyles } from '@emotion/react';

import { MEDIA_QUERY } from 'theme';

const panelContentStyles = (className: string | SerializedStyles = '') => css`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  overflow: hidden;
  position: relative;

  @media ${MEDIA_QUERY.MAX_MD} {
    overflow: visible;
  }

  ${className}
`;

const PanelContent = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): React.ReactElement => <div className={panelContentStyles(className)}>{children}</div>;

export { PanelContent };
