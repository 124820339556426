import React from 'react';

import { DetailsSection } from 'components/shared/Details';
import { isTruthy } from 'helpers/isTruthy/isTruthy';
import { MarginVisibility } from 'shared/constants/marginVisibility';
import { User } from 'shared/types';
import { TypedFormattedMessage as FormattedMessage } from './locale/messages';

type UserProfileTotalMarginParams = {
  user: User;
};

export const UserProfileTotalMargins = ({
  user,
}: UserProfileTotalMarginParams): React.ReactElement | null => {
  const canSeeProfitMarginPerMetal = user.company.canUseProfitMarginPerMetal === true;

  const profileSelectedMargin = user.selectedProfitMargin ? user.selectedProfitMargin : 0;
  const profileProfitMarginPd = user.currentProfitMarginPd!;
  const profileProfitMarginRh = user.currentProfitMarginRh!;
  const profileProfitMarginPt = user.currentProfitMarginPt!;
  const profileSelectedMarginChecker = profileSelectedMargin || profileProfitMarginPt;

  const canSeeProfitMarginSection = !!user.marginVisibility;
  const canSeeMergedValues =
    user.company.canUseProfitMarginPerMetal === true &&
    user.marginVisibility === MarginVisibility.SLIDER_MARGIN_ONLY;
  const canSeeUserProfitMargin =
    (user.marginVisibility === MarginVisibility.SLIDER_AND_TOTAL_MARGIN ||
      user.marginVisibility === MarginVisibility.SLIDER_MARGIN_ONLY ||
      user.selectedProfitMargin) &&
    !canSeeMergedValues;
  const canSeeTotalProfitMargin =
    user.marginVisibility === MarginVisibility.SLIDER_AND_TOTAL_MARGIN ||
    (user.marginVisibility === MarginVisibility.SLIDER_MARGIN_ONLY &&
      user.company.canUseProfitMarginPerMetal === true);

  const marginFields = [
    canSeeUserProfitMargin
      ? {
          name: 'margin1',
          label: <FormattedMessage id="UserProfile.ProfitMarginSlider" />,
          value: `${profileSelectedMarginChecker} %`,
        }
      : undefined,
    canSeeTotalProfitMargin
      ? {
          name: 'margin2',
          label: !canSeeUserProfitMargin ? (
            <FormattedMessage id="UserProfile.ProfitMarginSlider" />
          ) : (
            <FormattedMessage id="UserProfile.ProfitTotalMargin" />
          ),
          value: canSeeProfitMarginPerMetal ? (
            <FormattedMessage
              id="UserProfile.TotalProfitMarginPdPtRh"
              values={{
                pt: profileProfitMarginPt + profileSelectedMargin,
                pd: profileProfitMarginPd + profileSelectedMargin,
                rh: profileProfitMarginRh + profileSelectedMargin,
              }}
            />
          ) : (
            `${profileSelectedMargin + profileProfitMarginPt} %`
          ),
        }
      : undefined,
  ].filter(isTruthy);

  if (canSeeProfitMarginSection) {
    return (
      <DetailsSection
        id="profileProfitMarginSection"
        title={<FormattedMessage id="UserProfile.ProfitMarginSection" />}
        fields={marginFields}
      />
    );
  }
  return null;
};
