import React from 'react';
import { DataOutput, OutputCell, Status } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { TextButton } from 'components/shared/Buttons';
import { DetailsField } from 'components/shared/Details/Details';
import { HedgePricesGroup } from 'components/shared/HedgePricesGroup/HedgePricesGroup';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { resolveHedgeStatus } from 'helpers/hedges/resolveHedgeStatus';
import { PERMISSIONS, PRICE_SOURCES, SHARED } from 'shared/constants';
import { resolveStatusColor, useAuthorization } from 'shared/helpers';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { Hedge, HedgeListItem, User } from 'shared/types';
import { useTypedIntl } from '../locale/messages';

const GroupedValue = styled.div`
  line-height: 1.5;
`;

function HedgeButton({ hedge }: { hedge?: Hedge | HedgeListItem }): React.ReactElement {
  const navigate = useAlphamartNavigate();

  if (!hedge) return <span>{SHARED.LONG_DASH}</span>;

  return (
    <TextButton onClick={() => navigate(`/hedges/${hedge.id}`)}>
      {hedge.name}
      <br />
      {formatDateWithTime(hedge.placedAt)}
    </TextButton>
  );
}

type UserDetailsHedgesProps = { user: Pick<User, 'prices' | 'assignedHedges'> };

export function UserDetailsHedges({ user }: UserDetailsHedgesProps): React.ReactElement {
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const auth = useAuthorization();
  const getMetalPrice = metalAbbr => {
    const price = user.prices?.[`${metalAbbr}HedgeUsed`]?.[`${metalAbbr}PriceCust`];
    const source = user.prices?.[`${metalAbbr}PriceSource`];
    return price && source === PRICE_SOURCES.HEDGE ? formatCurrency(price) : SHARED.LONG_DASH;
  };

  const canSeeHedgePrices = auth(PERMISSIONS.HEDGES.SHOW_HEDGE_PRICES);

  const associatedHedgesFields: DetailsField[] =
    user.assignedHedges?.reduce<DetailsField[]>(
      (prev, hedge) => [
        ...prev,
        ...[
          {
            name: `hedge${hedge.id}Status`,
            label: intl.formatMessage({ id: 'UserDetails.Hedges.Status' }),
            value: (
              <Status
                type={resolveStatusColor(resolveHedgeStatus(hedge))}
                value={intl.formatMessage({ id: `Global.Status.${resolveHedgeStatus(hedge)}` })}
                size="small"
                discrete
              />
            ),
          },
          {
            name: `hedge${hedge.id}Name`,
            label: intl.formatMessage({ id: 'UserDetails.Hedges.Name' }),
            value: (
              <GroupedValue>
                <TextButton onClick={() => navigate(`/hedges/${hedge.id}`)}>
                  {hedge.name}
                  <br />
                  {hedge.relatedLot ?? SHARED.LONG_DASH}
                </TextButton>
              </GroupedValue>
            ),
          },
          {
            name: `hedge${hedge.id}Dates`,
            label: intl.formatMessage({ id: 'UserDetails.Hedges.Dates' }),
            value: (
              <GroupedValue>
                {formatDateWithTime(hedge.placedAt)} <br />
                {formatDateWithTime(hedge.expiresAt)}
              </GroupedValue>
            ),
          },
          ...(canSeeHedgePrices
            ? [
                {
                  name: `hedge${hedge.id}Prices`,
                  label: intl.formatMessage({ id: 'UserDetails.Hedges.Prices' }),
                  value: (
                    <GroupedValue>
                      <HedgePricesGroup hedge={hedge} />
                    </GroupedValue>
                  ),
                },
              ]
            : []),
        ],
      ],
      [],
    ) ?? [];

  const pricesFields: DetailsField[] = [
    {
      name: 'ptSource',
      label: intl.formatMessage({ id: 'UserDetails.PlatinumSource' }),
      value: intl.formatMessage({
        id: `Global.PriceSource.${user.prices?.ptPriceSource ?? 'MARKET'}`,
      }),
    },
    {
      name: 'pdSource',
      label: intl.formatMessage({ id: 'UserDetails.PalladiumSource' }),
      value: intl.formatMessage({
        id: `Global.PriceSource.${user.prices?.pdPriceSource ?? 'MARKET'}`,
      }),
    },
    {
      name: 'rhSource',
      label: intl.formatMessage({ id: 'UserDetails.RhodiumSource' }),
      value: intl.formatMessage({
        id: `Global.PriceSource.${user.prices?.rhPriceSource ?? 'MARKET'}`,
      }),
    },
    {
      name: 'ptHedgeUsed',
      label: intl.formatMessage({ id: 'UserDetails.PtHedgeUsed' }),
      value: <HedgeButton hedge={user.prices?.ptHedgeUsed} />,
    },
    {
      name: 'pdHedgeUsed',
      label: intl.formatMessage({ id: 'UserDetails.PdHedgeUsed' }),
      value: <HedgeButton hedge={user.prices?.pdHedgeUsed} />,
    },
    {
      name: 'rhHedgeUsed',
      label: intl.formatMessage({ id: 'UserDetails.RhHedgeUsed' }),
      value: <HedgeButton hedge={user.prices?.rhHedgeUsed} />,
    },
    ...(canSeeHedgePrices
      ? [
          {
            name: 'ptPrice',
            label: intl.formatMessage({ id: 'UserDetails.PlatinumPrice' }),
            value: getMetalPrice('pt'),
          },
          {
            name: 'pdPrice',
            label: intl.formatMessage({ id: 'UserDetails.PalladiumPrice' }),
            value: getMetalPrice('pd'),
          },
          {
            name: 'rhPrice',
            label: intl.formatMessage({ id: 'UserDetails.RhodiumPrice' }),
            value: getMetalPrice('rh'),
          },
        ]
      : []),
  ];

  return (
    <>
      <DataOutput
        headerText={intl.formatMessage({
          id: 'UserDetails.UserPrices',
        })}
      >
        {pricesFields.map((field, index, fields) => (
          <OutputCell
            key={field.name}
            data-cy={field.name}
            mergeCells={fields.length % 2! !== 0 && fields.length - 1 === index ? 4 : 2}
            labelValue={field.label}
          >
            {field.value}
          </OutputCell>
        ))}
      </DataOutput>
      {user?.assignedHedges && user.assignedHedges.length > 0 && (
        <DataOutput
          headerText={intl.formatMessage({
            id: 'UserDetails.AssignedHedges',
          })}
        >
          {associatedHedgesFields.map((field, index, fields) => (
            <OutputCell
              key={field.name}
              data-cy={field.name}
              mergeCells={fields.length % 2! !== 0 && fields.length - 1 === index ? 4 : 2}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>
      )}
    </>
  );
}
