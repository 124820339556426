import React, { ReactElement } from 'react';
import { ButtonProps, Form, FormRow, OptionsProps } from '@alpha-recycling/component-library';
import { FormikContextType } from 'formik';
import { isNil } from 'lodash';

import { FieldMultiSelect } from 'components/shared/Fields/FieldSelect/FieldMultiSelect';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useGetAssignableUsersForUpdate, useGetCompanies, useGetUsers } from 'shared/queries';
import { Status } from 'shared/types';
import { HedgeFormSchema } from 'shared/types/hedgeFormSchema ';
import { useTypedIntl } from '../locale/messages';

interface Props {
  editMode?: boolean;
  hedgeId?: number;
  formikContext: FormikContextType<HedgeFormSchema>;
  label: string;
}

export const HedgeFormAssign = ({
  hedgeId,
  formikContext,
  editMode = false,
  label,
}: Props): React.ReactElement => {
  const authorize = useAuthorization();
  const intl = useTypedIntl();
  const canChangeCompany = authorize(PERMISSIONS.HEDGES.CHANGE_COMPANY);
  const assignableUsers = useGetUsers(
    {
      status: [Status.ACTIVE],
      company: formikContext.values.company,
    },
    {
      enabled: !editMode,
      initialData: [],
    },
  );
  const companies = useGetCompanies({ status: Status.ACTIVE }, { enabled: canChangeCompany });
  const assignableUsersForEdit = useGetAssignableUsersForUpdate(
    {
      hedgeId: hedgeId!,
      companyId: formikContext.values.company!,
    },
    { enabled: editMode && !isNil(hedgeId) && !isNil(formikContext.values.company) },
  );
  const usersOptions: Array<OptionsProps> = (
    editMode ? assignableUsersForEdit.data! : assignableUsers.data!
  ).map(user => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));
  const companiesOptions = companies.data!.data.map(company => ({
    label: company.name,
    value: company.id,
  }));

  return (
    <Form headerText={label} headerButton={false as unknown as ButtonProps}>
      <FormRow>
        {canChangeCompany ? (
          <FieldSelect
            label={intl.formatMessage({ id: 'HedgeForm.Assign.Company' })}
            name="company"
            value={formikContext.values.company}
            options={companiesOptions}
            disabled={companies.isFetching}
            required
          />
        ) : (
          (null as unknown as ReactElement)
        )}
        <FieldMultiSelect
          label={intl.formatMessage({ id: 'HedgeForm.Assign.Users' })}
          name="assignedUsers"
          value={formikContext.values.assignedUsers}
          options={usersOptions}
          disabled={editMode ? assignableUsersForEdit.isFetching : assignableUsers.isFetching}
          error={formikContext.touched.assignedUsers && formikContext.errors.assignedUsers}
        />
      </FormRow>
    </Form>
  );
};
