import { css, keyframes } from '@emotion/css';

import { layers } from 'theme';
import { LoadableContentModes, LoaderSizes } from './LoadableContent';

export const loaderContainerStyles = (mode: LoadableContentModes): string => css`
  width: 100%;
  // @TODO remove when https://github.com/alpharecycling/markettool-component-library/issues/260 is ready
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background: rgba(0, 0, 0, 1);
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;

  ${mode === 'FULL' &&
  `
    position: fixed; width: 100vw;
    z-index: ${layers.loaderOverlayFull};
  `}
  ${mode === 'OVERLAY' &&
  `
    position: absolute;
    z-index: ${layers.loaderOverlay};
  `}
  ${mode === 'MODAL' &&
  `
    position: absolute;
    z-index: ${layers.loaderOverlayModal};
  `}
  ${mode === 'INLINE' &&
  `
    position: block;
    z-index: ${layers.loaderOverlay};
    background-color: transparent;
  `}
`;

export const loaderStyles = (size: LoaderSizes = 'M'): string => css`
  padding-bottom: 10px;
  transform: scale(${size === 'S' ? '0.5' : '0.8'});
`;

export const dashAnimation = (pathLength: number, speed = 1, bias = 0, dilate = 2.5): string => css`
  stroke-dasharray: ${pathLength};
  stroke-dashoffset: ${pathLength};
  animation: ${keyframes`
  ${25 + bias * dilate}%
  {
    stroke-dashoffset: 0;
  }
  ${50 + bias * dilate}%
  {
    stroke-dashoffset: -${pathLength};
  }
  ${75 + bias * dilate}%
  {
    stroke-dashoffset: -${2 * pathLength};
  }
  100%
  {
    stroke-dashoffset: -${3 * pathLength};
  }
  `} ${speed}s linear;
  animation-iteration-count: infinite;
`;
