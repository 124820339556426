import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, Model } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface ModelsState extends GenericStoreSlice {
  models: Model[];
}

const fetchModelsSlice = createSlice<ModelsState, GenericStoreReducer<ModelsState>>({
  name: 'models',
  initialState: {
    models: [],
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      models: payload,
    })),
  },
});

export const {
  pending: fetchModelsAction,
  success: fetchModelsSuccessAction,
  failure: fetchModelsFailureAction,
} = fetchModelsSlice.actions;

export const fetchModels = (): GenericThunk => async (dispatch, getState, httpClient) => {
  try {
    dispatch(fetchModelsAction());
    const models = await httpClient.get(`${apiHostname}/api/models`);
    dispatch(fetchModelsSuccessAction(models.data));
  } catch (error) {
    dispatch(fetchModelsFailureAction((error as AlphamartHttpError)?.response?.data.message));
    return Promise.reject(error);
  }
};

export default fetchModelsSlice.reducer;
