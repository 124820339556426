import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Forbidden } from 'components/views/Forbidden/Forbidden';
import { AllowedFor, useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';

interface Props {
  allowedFor?: AllowedFor;
  gradingRequirement?: 'assay' | 'grading' | null;
  children?: React.ReactElement;
  skipSetupCheck?: boolean;
}

const ProtectedRoute = ({
  allowedFor,
  skipSetupCheck,
  children,
  gradingRequirement,
}: Props): React.ReactElement | null => {
  const user = useCurrentUser();
  const authorize = useAuthorization();
  const authorized = !allowedFor || authorize(allowedFor, { gradingRequirement });

  if (user?.company.grading !== null && gradingRequirement === null) {
    return <Navigate to="/" />;
  }
  if (!skipSetupCheck && user?.company.grading === null) {
    return <Navigate to="/setup/app-questionnaire" />;
  }
  if (user && authorized) {
    return children ?? <Outlet />;
  }
  if (user && !authorized) {
    return <Forbidden />;
  }
  return null;
};

export { ProtectedRoute };
