import React, { useEffect, useRef, useState } from 'react';
import Slider, { LazyLoadTypes } from 'react-slick';
import { Icon } from '@alpha-recycling/component-library';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

import icon from 'assets/images/icons/arrowBackWhite.svg';
import { layers, MEDIA_QUERY, Theme } from 'theme';

const ImageOverlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: ${layers.lightbox};

  @media ${MEDIA_QUERY.MAX_MD} {
    top: 64px;
  }
`;

const CloseButton = styled.button`
  margin: 0 0 0 auto;
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 14px;
  right: 16px;
  padding: 16px;
  z-index: ${layers.galleryClose};
  background: radial-gradient(rgba(0, 0, 0, 0.08) 0%, transparent 65%);
`;

const StyledCarousel = styled.div<{ theme?: Theme }>`
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .slick-slider,
  .slick-list {
    height: 100%;
  }

  .slick-arrow {
    position: absolute;
    z-index: ${layers.carousel};
    top: 50%;
    height: 88px;
    width: 88px;
    border-radius: 88px;
    border: none;
    background: ${({ theme }) => theme.colors.mineShaftLightest};
    margin: -44px 0 0 0;
    outline: none;
    font-size: 0;
    background-image: url(${icon});
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: right 0px top 50%;
    cursor: pointer;
  }

  .slick-arrow.slick-prev {
    left: -62px;
    z-index: ${layers.carousel + 1};
  }

  .slick-arrow.slick-next {
    right: -62px;
    transform: rotate(180deg);
  }

  .slick-initialized .slick-slide div picture {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .slick-track {
    display: flex;
    align-items: center;
    height: 100%;
  }

  div {
    height: 100%;

    img {
      display: block;
      border-radius: 4px;
      border: 1px solid transparent;
      max-height: 90vh;
      max-width: calc(100% - 88px);
      transform: translate(44px, 5vh);
      object-fit: contain;
    }
  }

  @media ${MEDIA_QUERY.MOBILE_LANDSCAPE} {
    padding: 0 90px;

    .slick-arrow.slick-prev {
      left: calc(-158px + 4px);
    }

    .slick-arrow.slick-next {
      right: calc(-158px + 4px);
      transform: rotate(180deg);
    }
  }
`;

interface Props {
  onClick: () => void;
  children: React.ReactNode[];
  startFrom: number;
}
const ImageLightbox = ({ onClick, children, startFrom = 0 }: Props): React.ReactElement => {
  const [isMounted, setMounted] = useState(false);
  const sliderRef = useRef<Slider>(null);
  const lightboxRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    const handler = setTimeout(() => {
      setMounted(true);
    }, 300);

    return () => {
      document.body.style.overflow = 'auto';

      clearTimeout(handler);
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    const changeSlide = e => {
      switch (e.key) {
        case 'ArrowRight':
          return sliderRef.current?.slickNext();
        case 'ArrowLeft':
          return sliderRef.current?.slickPrev();
      }
    };

    if (children.length > 1) {
      window.addEventListener('keydown', changeSlide);

      return () => {
        window.removeEventListener('keydown', changeSlide);
      };
    }
  }, [sliderRef, children]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: startFrom,
    arrows: children.length > 1,
    draggable: children.length > 1,
    swipe: children.length > 1,
    lazyLoad: 'ondemand' as LazyLoadTypes,
  };

  return (
    <ImageOverlay ref={lightboxRef} id="imageLightbox">
      <CloseButton type="button" onClick={onClick}>
        <Icon name="X" size="big" />
      </CloseButton>
      <StyledCarousel>
        <div
          className={css`
            position: relative;
            height: 100%;
            ${!isMounted && 'opacity: 0'}
          `}
        >
          <Slider ref={sliderRef} {...settings}>
            {children.length > 1 ? children : [children, children]}
          </Slider>
        </div>
      </StyledCarousel>
    </ImageOverlay>
  );
};

export default ImageLightbox;
