import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { InfoBar } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { formatDate } from '../../../helpers/dateTime/dateTime';
import { useCurrentUser, useMarketToolUrls } from '../../../shared/hooks';
import { theme } from '../../../theme';

export const StyledLink = styled(Link)`
  color: ${theme.fontColor};
  text-decoration: underline;
`;

export const PaymentRequiredInfoBar = () => {
  const user = useCurrentUser();
  const paymentRequiredUntil = user?.company?.paymentRequiredUntil;

  if (!paymentRequiredUntil) {
    return null;
  }

  const { manage } = useMarketToolUrls();

  return (
    <InfoBar
      text={
        <FormattedMessage
          id="Global.PaymentRequired"
          values={{
            url: (
              <StyledLink to={`${manage}/payments`}>
                <FormattedMessage id="Global.Payments" />
              </StyledLink>
            ),
            date: formatDate(paymentRequiredUntil),
          }}
        />
      }
      data-cy="paymentRequiredInfoBar"
      type="danger"
      icon
      variant="basic"
    />
  );
};
