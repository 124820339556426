import { getHiddenOnMediaQuery, getMediaQueries } from './shared/utils/getMediaQueries';

export const BREAKPOINTS = {
  SM: 480,
  M: 599,
  MD: 768,
  LG: 1024,
  XL: 1280,
  XXL: 1536,
} as const;

const MEDIA_QUERY = {
  ...getMediaQueries(BREAKPOINTS),
  MOBILE_LANDSCAPE: '(pointer: coarse) and (orientation: landscape)',
};

const HIDDEN_ON_MEDIA_QUERY = getHiddenOnMediaQuery(MEDIA_QUERY);

export const NAVBAR_HEIGHT_MOBILE = '56px';
const theme = {
  background: 'var(--color-neutral-surface)',
  fontColor: 'var(--color-neutral-text)',
  mainColor: 'var(--color-accent-content)',
  pageColor: 'var(--color-page-surface)',
  colors: {
    black: 'var(--color-overlay)',
    codGrayDarker: 'var(--color-neutral-stroke-alternative)',
    codGray: 'var(--color-neutral-surface-alternative-2)',
    alto: 'var(--color-neutral-content)',
    stroke: 'var(--color-button-stroke-alternative)',
    mineShaftDarker: 'var(--color-neutral-surface-alternative)',
    mineShaft: 'var(--color-neutral-surface-alternative-2)',
    mineShaftLighter: 'var(--color-neutral-surface-alternative-3)',
    mineShaftLightest: 'var(--color-neutral-surface-alternative)',
    shingleFawn: 'var(--color-function-graph-copper)',
    fuzzyWuzzyBrown: 'var(--color-function-danger)',
    alphaOrange: 'var(--color-function-graph-copper)',
    white: 'var(--color-function-graph-neutral)',
    gold: 'var(--color-function-graph-gold)',
    pictonBlue: 'var(--color-navigation-surface-selected-alternative)',
    functionInfo: 'var(--color-function-info)',
    statuses: {
      active: 'var(--color-function-status-active)',
      inactive: 'var(--color-function-status-inactive)',
      blocked: 'var(--color-function-status-blocked)',
      pending: 'var(--color-function-status-pending)',
      unread: 'var(--color-function-status-unread)',
    },
    dataInput: {
      text: 'var(--color-datainput-text)',
      surface: 'var(--color-datainput-surface)',
      border: 'var(--color-datainput-stroke)',
    },
    neutral: {
      surface: 'var(--color-neutral-surface)',
      stroke: 'var(--color-neutral-stroke)',
    },
  },
  fonts: {
    satoshi: 'Satoshi',
  },
  modal: {
    background: 'var(--color-neutral-surface-alternative-3)',
  },
  nav: {
    wide: '228px',
    thin: '97px',
    zero: '0px',
  },
};
export type Theme = typeof theme;

const layers = {
  carousel: 0,
  datePicker: 1,
  fieldInput: 1,
  formContainer: 1,
  galleryClose: 1,
  detailsHeader: 1,
  colorPickerPalette: 2,
  fieldInputRemove: 2,
  fieldInputAction: 2,
  disclaimer: 2,
  shoppingCartSummaryContainer: 2,
  fieldInputError: 3,
  listCheckboxes: 3,
  datePickerPopper: 4,
  averageBox: 4,
  loaderOverlay: 4,
  selectMenu: 4,
  sidebar: 5,
  navListLeaf: 6,
  lightbox: 7,
  navbar: 8,
  details: 13,
  notificationModal: 15, // has to be more than details for mobile views
  loaderOverlayFull: 13,
  loaderOverlayModal: 13,
  userProfile: 14,
  modal: 16,
  floatingButton: 50,
  snackbar: 99,
  menu: 100,
  guide: 101,
  tooltip: 101,
};

const MAIN_COLOR_PALETTE = [
  {
    value: theme.mainColor,
    label: 'Global.Color.Orange',
  },
  {
    value: '#909BE2',
    label: 'Global.Color.Purple',
  },
  {
    value: '#87F88C',
    label: 'Global.Color.Aquamarine',
  },
  {
    value: '#C5C5C5',
    label: 'Global.Color.Gray',
  },
  {
    value: '#F88787',
    label: 'Global.Color.Pink',
  },
  {
    value: '#00FFF7',
    label: 'Global.Color.Turquoise',
  },
  {
    value: '#FF9A00',
    label: 'Global.Color.Gold',
  },
  {
    value: '#289CEA',
    label: 'Global.Color.Blue',
  },
];

const scrollbarStyles = {
  scrollbarColor: `${theme.colors.mineShaftLightest}`,

  '::-webkit-scrollbar-track': {
    borderRadius: '4px',
    backgroundColor: 'transparent',
  },

  '::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: 'transparent',
  },

  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    boxShadow: 'inset 0 0 16px rgba(0,0,0,.4)',
    backgroundColor: theme.colors.mineShaftLightest,

    ':hover': {
      backgroundColor: `${theme.colors.mineShaftLightest}99`,
    },
  },

  '::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent',
  },
};

const globalStyles = (currentTheme: Theme) => ({
  '*': {
    boxSizing: 'border-box',
    fontFamily: theme.fonts.satoshi,
    ...scrollbarStyles,
  },
  html: {
    overscrollBehaviorX: 'none',
    WebkitOverflowScrolling: 'touch !important',
  },
  body: {
    margin: '0',
    background: currentTheme.background,
    color: currentTheme.fontColor,
    fontFamily: currentTheme.fonts.satoshi,
    overscrollBehaviorX: 'none',
    WebkitOverflowScrolling: 'touch !important',
    WebkitTapHighlightColor: 'transparent',
  },
  a: {
    color: currentTheme.mainColor,
    textDecoration: 'none',
  },
  // @TODO remove when https://github.com/alpharecycling/markettool-component-library/issues/259 is ready
  "[class*='modal-portal']": {
    zIndex: 100,
  },
  // @TODO remove when https://alpharec.atlassian.net/browse/NEWA-3114 is ready
  "[class*='status']": {
    width: 'fit-content',
  },
});

export { globalStyles, HIDDEN_ON_MEDIA_QUERY, layers, MAIN_COLOR_PALETTE, MEDIA_QUERY, theme };
