import React, { useEffect, useMemo } from 'react';
import { Button, ModalPortal } from '@alpha-recycling/component-library';
import { add, addDays, differenceInDays } from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { FieldDatePickerRange } from 'components/shared/Fields/FieldDatePickerRange/FieldDatePickerRange';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { LoadableContent } from 'components/shared/Loader';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { useTypedIntl } from 'locale/messages';
import { ModalFormType } from 'shared/constants';
import { Status } from 'shared/types';
import { getDateString } from 'shared/utils/getDateString';
import { downloadFile } from 'store/downloadFileSlice';
import {
  DownloadCompanyLogsExcelForm,
  DownloadUserLogsForm,
  hideModalForm,
} from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { fetchUsers } from 'store/usersListSlice';
import { FieldMultiSelect } from '../Fields/FieldSelect/FieldMultiSelect';
import { ModalFormContent } from '../forms/Form/ModalForm.styles';

export const DownloadLogsModal = (): React.ReactElement => {
  const intl = useTypedIntl();
  const users = useAppSelector(state => state.users.list);
  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);
  const isFileDownloading = useAppSelector(state => state.downloadFile.isPending);
  const paramsTyped = params as DownloadCompanyLogsExcelForm | DownloadUserLogsForm;
  const dispatch = useAppDispatch();

  const hedgeLogsModal =
    modalType === ModalFormType.HedgeLogs || modalType === ModalFormType.UserHedgeLogs;
  const converterLogsModal =
    modalType === ModalFormType.ConverterLogs || modalType === ModalFormType.UserConverterLogs;
  const userLogsModal =
    modalType === ModalFormType.UserHedgeLogs || modalType === ModalFormType.UserConverterLogs;
  const shouldRenderModal = hedgeLogsModal || converterLogsModal;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        addedAt: Yup.object()
          .shape({
            from: Yup.string().required(),
            to: Yup.string().required(),
          })
          .required(),
        ...(!userLogsModal ? { users: Yup.array().required().min(1).ensure() } : {}),
      }),
    [userLogsModal],
  );

  const formikContext = useFormik<{
    addedAt: { from: Date | null; to: Date | null };
    users: number[] | null;
  }>({
    initialValues: {
      addedAt: { from: null, to: null },
      users: null,
    },
    validationSchema,
    onSubmit: async x => x,
  });

  const submitDisabled = !formikContext.isValid || Object.keys(formikContext.touched).length === 0;
  const handleCancelClick = () => {
    formikContext.resetForm();
    dispatch(hideModalForm());
  };

  const handleConfirmClick = async () => {
    try {
      const { from, to } = formikContext.values.addedAt;
      if (!from || !to) return;
      const usersIds = userLogsModal
        ? [(paramsTyped as DownloadUserLogsForm).userId]
        : formikContext.values?.users;
      const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
      const path = hedgeLogsModal ? '/api/hedge-use-logs/export' : '/api/converters/logs';
      const prefix = hedgeLogsModal ? 'hedge' : 'converters';
      await dispatch(
        downloadFile(path, `${prefix}_logs_${formatDateWithTime(new Date())}.xlsx`, {
          addedFrom: getDateString(from),
          addedTo: getDateString(addDays(to, 1)),
          usersIds,
          timezone,
        }),
      );
      dispatch(hideModalForm());
      formikContext.resetForm();
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  useEffect(() => {
    paramsTyped &&
      'companyId' in paramsTyped &&
      shouldRenderModal &&
      dispatch(
        fetchUsers({
          company: paramsTyped.companyId,
          ...(hedgeLogsModal && { status: Status.ACTIVE }),
        }),
      );
  }, [(paramsTyped as DownloadCompanyLogsExcelForm)?.companyId]);

  const mappedUsers = users.map(user => ({
    label: `${user.lastName} ${user.firstName}`,
    value: user.id,
  }));

  const maxDaysDiff =
    formikContext.values?.users?.length && formikContext.values.users.length > 1 ? 6 : 30;
  const maxDate =
    formikContext.values?.addedAt?.from &&
    add(new Date(formikContext.values.addedAt.from), { days: maxDaysDiff });

  useEffect(() => {
    const { from, to } = formikContext.values.addedAt;
    if (from && to && differenceInDays(new Date(to), new Date(from)) > maxDaysDiff) {
      formikContext.setFieldValue('addedAt', { from, to: null });
    }
  }, [maxDaysDiff]);

  return (
    <ModalPortal
      isOpen={isOpen && shouldRenderModal}
      rightButtons={[
        <Button
          onClick={handleCancelClick}
          variant="transparent"
          content="text"
          label={intl.formatMessage({ id: 'Global.Cancel' })}
          data-cy="cancel-button"
        />,
        <Button
          onClick={handleConfirmClick}
          content="text"
          disabled={submitDisabled}
          label={intl.formatMessage({ id: 'Global.DownloadLogs.Confirm' })}
          data-cy="confirm-button"
        />,
      ]}
    >
      <ModalFormContent>
        <FormikProvider value={formikContext}>
          {!userLogsModal && (
            <FiltersSection
              noPadding
              label={intl.formatMessage({ id: 'Global.DownloadLogs.Users' })}
            >
              <FieldMultiSelect
                options={mappedUsers}
                value={formikContext.values.users}
                selectAll
                name="users"
              />
            </FiltersSection>
          )}

          <FiltersSection
            noPadding
            label={intl.formatMessage({ id: 'Global.DownloadLogs.SelectDateRange' })}
          >
            <FieldDatePickerRange
              name="addedAt"
              value={formikContext.values.addedAt}
              max={maxDate}
              isClearable
              dataTestId="addedAt"
            />
          </FiltersSection>
        </FormikProvider>
      </ModalFormContent>
      <LoadableContent mode={LoadableContent.MODE.FULL} loading={isFileDownloading} />
    </ModalPortal>
  );
};
