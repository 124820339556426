import React from 'react';

import { Loader } from './Loader';
import { loaderContainerStyles } from './Loader.styles';

export enum LoadableContentModes {
  OVERLAY = 'OVERLAY',
  FULL = 'FULL',
  MODAL = 'MODAL',
  INLINE = 'INLINE',
}
export type LoaderSizes = 'S' | 'M';
interface Props {
  label?: string;
  children?: React.ReactNode;
  loading?: boolean;
  drawContent?: boolean;
  mode?: LoadableContentModes;
  size?: LoaderSizes;
  hideLabel?: boolean;
}

export const LoadableContent = ({
  children,
  loading,
  label,
  mode = LoadableContentModes.FULL,
  drawContent = false,
  size,
  hideLabel = false,
}: Props): React.ReactElement => {
  const loaderSize = mode === 'INLINE' ? 'S' : size || 'M';
  return (
    <>
      {loading && (
        <div className={loaderContainerStyles(mode)}>
          <Loader mode={mode} label={label} hideLabel={hideLabel} size={loaderSize} />
        </div>
      )}
      {(!loading || drawContent) && children}
    </>
  );
};

LoadableContent.MODE = LoadableContentModes;
