import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { theme } from 'theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  padding: 20px;
`;

export const wrapperStyles = css`
  background-color: ${theme.colors.mineShaft};
  border-radius: 4px;
  width: 100%;
  max-width: 480px;
`;

export const Header = styled.div`
  padding: 16px 48px;
  background-color: ${theme.colors.mineShaftLighter};
  font-size: 16px;
  width: 100%;
  border-radius: 4px 4px 0 0;
`;

export const Content = styled.div`
  padding: 12px 48px;
  font-size: 14px;
`;

export const Footer = styled.div`
  padding: 16px 48px;
  background-color: ${theme.colors.mineShaftLighter};
  width: 100%;
  border-radius: 0 0 4px 4px;
  display: flex;
  gap: 8px;

  & > button {
    width: 100%;
  }
`;
