import React from 'react';
import { Label } from '@alpha-recycling/component-library';

import { ErrorMessage } from './ErrorMessage/ErrorMessage';
import { Container } from './Container';

interface Props {
  id: string;
  error?: React.ReactNode;
  label?: React.ReactNode;
  labelSuffix?: string | null;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  standalone?: boolean;
}
const FieldWrapper = React.memo(
  ({
    className,
    label,
    labelSuffix = '',
    id,
    disabled,
    children,
    error,
    required,
    standalone,
  }: Props): React.ReactElement => (
    <Container className={className}>
      <ErrorMessage standalone={standalone} label={label} show={!!error && !disabled}>
        {error}
      </ErrorMessage>
      <Label value={`${label}${labelSuffix}`} htmlFor={id} required={required && !disabled}>
        {children}
      </Label>
    </Container>
  ),
);

function withFieldWrapper<T>(
  C: React.FunctionComponent<T>,
  defaultProps?: Partial<Props>,
): (props: T & Props) => React.ReactElement {
  return (props: T & Props) => (
    <FieldWrapper {...props} {...defaultProps}>
      <C {...props} />
    </FieldWrapper>
  );
}

export { FieldWrapper, withFieldWrapper };
