import React, { useEffect, useMemo } from 'react';
import { ButtonProps, Form, FormRow } from '@alpha-recycling/component-library';
import { FormikContextType } from 'formik';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldPhoneNumber } from 'components/shared/Fields/FieldPhoneNumber/FieldPhoneNumber';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { getUserTypeLang } from 'helpers/userTypes/userTypes';
import { UserTypes } from 'shared/constants';
import { HedgeOrderBy } from 'shared/constants/hedgeOrderBy';
import { useCurrentUser } from 'shared/hooks';
import { Company, CompanyListItem, Status, UserFormData, UserTypeListItem } from 'shared/types';
import { fetchHedges } from 'store/hedgesSlice';
import { useAppDispatch } from 'store/shared/hooks';
import { useTypedIntl } from '../locale/messages';

type Props = {
  context: FormikContextType<UserFormData>;
  companies: (CompanyListItem | Company)[];
  userTypes: UserTypeListItem[];
  controlButtons?: React.ReactNode;
};

export const UserInfoForm = ({
  context,
  companies,
  userTypes,
  controlButtons,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();

  const { values, setFieldValue } = context;

  useEffect(() => {
    const canFilter = currentUser.userTypes.some(r =>
      [UserTypes.SUPER_ADMIN, UserTypes.INTERNAL_COMPANY_MANAGER].includes(r.name),
    );
    const relatedCompanies = [values.info.company];

    dispatch(
      fetchHedges(0, 0, {
        status: Status.ACTIVE,
        ...(canFilter && { relatedCompanies }),
        orderBy: HedgeOrderBy.NAME,
      }),
    );
  }, [values.info.company]);

  const userTypeOptions = useMemo(
    () =>
      userTypes.map(userType => ({
        label: getUserTypeLang(userType.name, intl),
        value: userType.id,
      })),
    [userTypes],
  );

  useEffect(() => {
    if (Array.isArray(context.initialValues.userTypes)) {
      setFieldValue('userTypes.userTypes', context.initialValues.userTypes);
    }
  }, [userTypeOptions]);

  useEffect(() => {
    if (!values.info.officePhonePrefix) setFieldValue('info.officePhone', '');
  }, [values.info.officePhonePrefix]);

  useEffect(() => {
    if (!values.info.mobilePhonePrefix) setFieldValue('info.mobilePhone', '');
  }, [values.info.mobilePhonePrefix]);

  const companiesOptions = useMemo(
    () => companies.map(company => ({ label: company.name, value: company.id })),
    [companies],
  );

  useDeepCompareEffect(() => {
    const selectedCompany = companies.find(c => c.id === values?.info?.company);
    const canUseMarginSliderPerMetal = selectedCompany?.canUseProfitMarginPerMetal ?? false;
    setFieldValue('prices.canUseMarginSliderPerMetal', !!canUseMarginSliderPerMetal);
  }, [companies, values?.info?.company]);

  return (
    <FormInWizard
      context={context}
      header={intl.formatMessage({ id: 'UserForm.UpdateHeader' })}
      controlButtons={controlButtons}
    >
      <Form
        headerText={intl.formatMessage({ id: 'UserForm.Section.UserInfo' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldInput
            label={intl.formatMessage({ id: 'UserForm.FirstName' })}
            name="info.firstName"
            value={values.info.firstName}
            data-cy="first-name"
            disabled
          />
          <FieldInput
            label={intl.formatMessage({ id: 'UserForm.LastName' })}
            name="info.lastName"
            value={values.info.lastName}
            data-cy="last-name"
            disabled
          />
          <FieldPhoneNumber
            label={intl.formatMessage({ id: 'UserForm.MobilePhone' })}
            name="info.mobilePhone"
            prefixName="info.mobilePhonePrefix"
            value={values.info.mobilePhone}
            prefixValue={values.info.mobilePhonePrefix}
            data-cy="mobile-phone"
            disabled
            placeholder=""
          />
          <FieldPhoneNumber
            label={intl.formatMessage({ id: 'UserForm.OfficePhone' })}
            name="info.officePhone"
            prefixName="info.officePhonePrefix"
            value={values.info.officePhone!}
            prefixValue={values.info.officePhonePrefix}
            data-cy="office-phone"
            disabled
            placeholder=""
          />
          <FieldInput
            label={intl.formatMessage({ id: 'UserForm.Email' })}
            name="info.email"
            value={values.info.email}
            disabled
            data-cy="email"
          />
        </FormRow>
      </Form>

      <Form
        headerText={intl.formatMessage({ id: 'UserForm.Section.CompanyInfo' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldSelect
            label={intl.formatMessage({ id: 'UserForm.UserCompany' })}
            name="info.company"
            value={values.info.company}
            options={companiesOptions}
            disabled
          />
        </FormRow>
      </Form>
    </FormInWizard>
  );
};
