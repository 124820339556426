import React, { useState } from 'react';

import { useAlphamartNavigate } from 'shared/hooks';
import { useSetGradingAndTerms } from 'shared/mutations';
import { CompanyFormData } from 'shared/types';
import { fetchUser } from 'store/auth';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { useTypedIntl } from '../../locale/messages';
import { TermsForm } from '../../TermsForm/TermsForm';

interface Props {
  onCancel: () => void;
  assayOnly?: boolean;
}

export const TermsSelectStep = ({ onCancel, assayOnly }: Props) => {
  const intl = useTypedIntl();
  const [type, setType] = useState<string>('0');
  const dispatch = useAppDispatch();
  const setGradingAndTerms = useSetGradingAndTerms();
  const navigate = useAlphamartNavigate();

  const handleSubmit = async (values: CompanyFormData) => {
    try {
      await setGradingAndTerms.mutateAsync({
        grading: type === '1',
        terms: type === '1' ? undefined : values.terms,
      });
      await dispatch(fetchUser(true));
      dispatch(
        snackBarPushSuccess(
          intl.formatMessage({ id: 'SetYourApp.TermsSelectedStep.Message.ConfigureSuccess' }),
        ),
      );
      navigate('/converters');
    } catch (error) {
      dispatch(
        snackBarPushFailure(
          intl.formatMessage({ id: 'SetYourApp.TermsSelectedStep.Error.ConfigureFail' }),
        ),
      );
    }
  };

  return (
    <TermsForm
      onSubmit={handleSubmit}
      onCancel={onCancel}
      type={type}
      setType={setType}
      assayOnly={assayOnly}
    />
  );
};
