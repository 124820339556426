import React, { ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';

import { SHARED } from 'shared/constants';
import { MEDIA_QUERY, Theme } from 'theme';

interface DetailsFieldProps {
  background?: string;
  label?: ReactNode;
  value?: ReactNode;
  name?: string;
  dataCy?: string;
  padding?: { v?: number; h?: number };
}
const DetailsFieldLayout = styled.div<{
  theme?: Theme;
  name?: string;
  padding?: { v?: number; h?: number };
  background?: string;
}>`
  ${({ name }) => (name ? `grid-area: ${name};` : 'grid-template-rows: 30px 1fr;')}
  display: grid;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  padding: ${({ padding }) => `${padding?.v ?? 20}px ${padding?.h ?? 34}px`};
  background: ${({ theme, background }) => background || theme.colors.mineShaft};
  width: 100%;
  height: 100%;
  min-width: 150px;
  word-break: break-word;

  @media ${MEDIA_QUERY.MAX_XL} {
    margin-bottom: 1px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

const DetailsFieldLabel = styled.div<{ theme?: Theme }>`
  font-weight: 700;
  color: ${({ theme }) => theme.fontColor};
  margin-bottom: 8px;
`;

const DetailsFieldValue = styled.div<{ theme?: Theme }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: auto 0;
  color: ${({ theme }) => theme.fontColor};
  word-wrap: break-word;
  font-weight: 300;
`;

export const DetailsField = ({
  background,
  label,
  value,
  name,
  dataCy,
  padding,
}: DetailsFieldProps): ReactElement => (
  <DetailsFieldLayout background={background} padding={padding} name={name}>
    {label && <DetailsFieldLabel data-cy="data-field-label">{label}</DetailsFieldLabel>}
    <DetailsFieldValue data-cy={dataCy || 'data-field-value'}>
      {value || SHARED.LONG_DASH}
    </DetailsFieldValue>
  </DetailsFieldLayout>
);
