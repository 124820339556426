import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { UserTypeListItem } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum UserTypesKeys {
  GetUserTypes = 'GetUserTypes',
}

type GetUserTypesParams = {
  companyId: number;
};

export const useGetUserTypes = (
  params: GetUserTypesParams,
  options: UseQueryOptions<UserTypeListItem[]> = {},
): UseQueryResult<UserTypeListItem[]> =>
  useQuery<UserTypeListItem[]>(
    [UserTypesKeys.GetUserTypes, params.companyId],
    async () => {
      if (!params.companyId) return [];

      const response = await getHttpClient().get<UserTypeListItem[]>(
        `${apiHostname}/api/me/managed-user-types?companyId=${params.companyId}`,
      );

      return response.data ?? [];
    },
    { initialData: [], cacheTime: 60000, ...options },
  );
