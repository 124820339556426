import React from 'react';
import { Pagination } from '@alpha-recycling/component-library';

import { SortInterface, SortSelectOptions } from 'shared/constants/sortableModules';
import { listPaginationStyles, ListPaginationWrapper } from './ListPagination.styles';
import { PageSizeSelect } from './PageSizeSelect';
import { PageSortSelect } from './PageSortSelect';
import { GoToPageButton } from '../Buttons';

interface Props {
  pages: number;
  onPageChange: (page: number) => void;
  currentPage: number;
  sortOptions?: SortSelectOptions[];
  handleSortingChange?: (changed: SortInterface | null) => void;
  currentSortValue?: SortInterface;
}

export const ListPagination = ({
  pages,
  onPageChange,
  currentPage,
  handleSortingChange,
  sortOptions,
  currentSortValue,
}: Props): React.ReactElement => (
  <div className={listPaginationStyles}>
    <PageSizeSelect />
    {sortOptions && (
      <PageSortSelect
        customSortTypes={sortOptions}
        handleChange={handleSortingChange}
        currentSortValue={currentSortValue}
      />
    )}
    {pages > 1 && (
      <>
        <ListPaginationWrapper>
          <Pagination
            totalCount={pages}
            currentPage={currentPage}
            onPageChange={onPageChange}
            dataTestId="pagination"
          />
        </ListPaginationWrapper>
        <GoToPageButton onClick={onPageChange} max={pages} currentPage={currentPage} />
      </>
    )}
  </div>
);
