import React, { ReactElement } from 'react';
import { IconProps, Switch, Switcher, SwitcherProps } from '@alpha-recycling/component-library';
import { useField } from 'formik';

interface RawProps extends Omit<SwitcherProps, 'children' | 'onChange'> {
  label?: string;
  dataCy?: string;
  name: string;
  options: {
    label: string | ReactElement<IconProps>;
    value: string | number | readonly string[];
    description?: string;
  }[];
  onChange?: (value: string | null) => void;
}

const getKey = (
  name: string,
  label: string | ReactElement<IconProps>,
  value: string | number | readonly string[],
) => `${name}_${typeof label === 'string' ? label : value}`;

interface Props extends Omit<RawProps, 'onChange'> {
  onChange: (value: string | null) => void;
}

const FieldSwitcher = ({ name, onChange, ...props }: RawProps): React.ReactElement => {
  const [, , { setValue }] = useField(name);
  const handleChange = (val: string | null) => {
    onChange?.(val);
    setValue(val);
  };
  return <FieldSwitcherRaw onChange={handleChange} name={name} {...props} />;
};

const FieldSwitcherRaw = ({
  value: currentValue,
  options,
  name,
  dataCy = 'field-radio-button',
  ...props
}: Props): React.ReactElement => (
  <Switcher data-cy={dataCy} value={currentValue || ''} {...props}>
    {options.map(({ label, value }) => (
      <Switch name={name} key={getKey(name, label, value)} id={String(value)}>
        {label}
      </Switch>
    ))}
  </Switcher>
);

export { FieldSwitcher, FieldSwitcherRaw };
