import { AccessFlag, UserTypes } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';

interface Props {
  permission: (UserTypes | AccessFlag)[];
  children: React.ReactElement;
}
export const ProtectedArea = ({ permission, children }: Props): React.ReactElement | null => {
  const authorized = useAuthorization();

  return authorized(permission) ? children : null;
};
