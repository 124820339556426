import React, { ReactNode } from 'react';
import { Navigate, Outlet, useRouteError } from 'react-router-dom';
import { Global } from '@emotion/react';

import {
  AssaysList,
  ConvertersList,
  CreateAssay,
  CreateVehicle,
  Dashboard,
  DisplaySettings,
  Settings,
  UpdateAssay,
  UpdateVehicle,
  UsersList,
  Vehicles,
  VehiclesList,
} from 'components/views';
import { useTypedIntl } from 'locale/messages';
import { PERMISSIONS } from 'shared/constants';
import { useDefaultRedirect, useModuleChange } from 'shared/hooks';
import { resetListOnModuleChange } from 'store/listComponentSlice';
import { resetFiltersOnModuleChange } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { globalStyles } from 'theme';
import { DisabledGeolocationModal } from './shared/DisabledGeolocationModal';
import { LoadableContent, LoadableContentModes } from './shared/Loader';
import { ProtectedRoute } from './shared/ProtectedRoute/ProtectedRoute';
import { SnackBar } from './shared/SnackBar/SnackBar';
import { UserBlockedModal } from './shared/UserBlockedModal';
import { CompaniesList } from './views/Companies/CompaniesList/CompaniesList';
import { UpdateCompany } from './views/Companies/UpdateCompany/UpdateCompany';
import { PartialConverters } from './views/Converters/PartialConverters/PartialConverters';
import { FeedbacksList } from './views/Feedbacks/FeedbacksList/FeedbacksList';
import { FeedbacksSummary } from './views/Feedbacks/FeedbacksSummary/FeedbacksSummary';
import { QuestionnaireModal } from './views/Feedbacks/Questionnaire/QuestionnaireModal';
import { UserLocationsView } from './views/Geolocation';
import { Guides } from './views/Guides/Guides';
import { CreateHedge, HedgesList, UpdateHedge } from './views/Hedges';
import { HedgeDetails } from './views/Hedges/HedgeDetails/HedgeDetails';
import { CreateNonstandardConverter } from './views/NonstandardConverters/CreateNonstandardConverter/CreateNonstandardConverter';
import { NonstandardConvertersList } from './views/NonstandardConverters/NonstandardConvertersList/NonstandardConvertersList';
import { UpdateNonstandardConverter } from './views/NonstandardConverters/UpdateNonstandardConverter/UpdateNonstandardConverter';
import { NotFound } from './views/NotFound/NotFound';
import { Notifications } from './views/Notifications/Notifications';
import { UpdateExchangeRatesAdjustment } from './views/Settings/ExchangeRates/UpdateExchangeRatesAdjustment';
import { UpdateMetalPrices } from './views/Settings/MetalPrices/UpdateMetalPrices';
import { SetYourApp } from './views/SetYourApp/SetYourApp';
import { StatisticsPage } from './views/Statistics/ConvertersStatistics/StatisticsPage';
import { TermsPage } from './views/Terms/TermsPage';
import { UpdateTerms } from './views/Terms/UpdateTerms/UpdateTerms';
import { UserProfile } from './views/UserProfile/UserProfile';
import { UpdateUser } from './views/Users/UpdateUser/UpdateUser';

export function BaseRoute({ children }: { children?: ReactNode }) {
  const config = useAppSelector(state => state.config);
  const auth = useAppSelector(state => state.auth);
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();

  useModuleChange(() => {
    dispatch(resetFiltersOnModuleChange());
    dispatch(resetListOnModuleChange());
  });

  if (config.error) {
    return <NotFound />;
  }

  const loading = config?.isPending || auth?.isPending;

  return (
    <>
      <Global styles={globalStyles as never} />
      <LoadableContent
        mode={LoadableContentModes.OVERLAY}
        loading={loading}
        label={intl.formatMessage({
          id: auth?.isPending ? 'Global.Authorization' : 'Global.Loading',
        })}
      />
      {children}
      {!loading && <Outlet />}
      <SnackBar />
    </>
  );
}

export function RootRoute() {
  return (
    <BaseRoute>
      <UserProfile />
      <Notifications />
      <DisabledGeolocationModal />
      <UserBlockedModal />
      <QuestionnaireModal />
      <Guides />
    </BaseRoute>
  );
}

function ErrorBoundary(): null {
  const error = useRouteError();
  if (error) {
    throw error;
  }
  return null;
}

export const routes = [
  {
    path: '/',
    element: <RootRoute />,
    ErrorBoundary,
    children: [
      {
        path: '',
        Component() {
          const redirectTo = useDefaultRedirect();
          return <Navigate to={redirectTo} />;
        },
      },
      {
        path: 'dashboard',
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: 'converters',
        element: <ProtectedRoute allowedFor={PERMISSIONS.CONVERTERS.LIST} />,
        children: [
          {
            path: 'partial',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.CONVERTERS.PARTIAL_CONVERTERS}>
                <PartialConverters />
              </ProtectedRoute>
            ),
          },
          { path: ':id?', element: <ConvertersList /> },
          { path: 'group/:id?', element: <ConvertersList /> },
        ],
      },
      {
        path: 'users',
        children: [
          {
            path: ':id?',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.USERS.LIST} gradingRequirement="assay">
                <UsersList />
              </ProtectedRoute>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.USERS.UPDATE} gradingRequirement="assay">
                <UpdateUser />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'assays',
        children: [
          {
            path: ':id?',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.ASSAYS.LIST}>
                <AssaysList />
              </ProtectedRoute>
            ),
          },
          {
            path: 'create',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.ASSAYS.CREATE}>
                <CreateAssay />
              </ProtectedRoute>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.ASSAYS.UPDATE}>
                <UpdateAssay />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'companies',
        children: [
          {
            path: ':id?',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.COMPANIES.LIST}>
                <CompaniesList />
              </ProtectedRoute>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.COMPANIES.UPDATE}>
                <UpdateCompany />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'company/settings',
        element: <ProtectedRoute allowedFor={PERMISSIONS.COMPANY_SETTINGS.MAIN} />,
        children: [
          { path: '', element: <Settings /> },
          { path: 'currency/:currency/edit', element: <UpdateExchangeRatesAdjustment /> },
          {
            path: 'metals/edit',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.COMPANY_SETTINGS.UPDATE_PRICES}>
                <UpdateMetalPrices />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'company/settings/display',
        element: (
          <ProtectedRoute allowedFor={PERMISSIONS.COMPANY_DISPLAY_SETTINGS}>
            <DisplaySettings />
          </ProtectedRoute>
        ),
      },
      {
        path: 'geolocation',
        element: (
          <ProtectedRoute allowedFor={PERMISSIONS.GEOLOCATION.DISPLAY}>
            <UserLocationsView />
          </ProtectedRoute>
        ),
      },
      {
        path: 'statistics',
        element: (
          <ProtectedRoute allowedFor={PERMISSIONS.STATISTICS.LIST}>
            <StatisticsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'hedges',
        children: [
          {
            path: 'create',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.HEDGES.CREATE} gradingRequirement="assay">
                <CreateHedge />
              </ProtectedRoute>
            ),
          },
          {
            path: '',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.HEDGES.LIST} gradingRequirement="assay">
                <HedgesList />
              </ProtectedRoute>
            ),
            children: [
              {
                path: ':id',
                element: (
                  <ProtectedRoute
                    allowedFor={PERMISSIONS.HEDGES.DETAILS.SHOW_PAGE}
                    gradingRequirement="assay"
                  >
                    <HedgeDetails />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: ':id/edit',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.HEDGES.UPDATE} gradingRequirement="assay">
                <UpdateHedge />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'vehicles',
        children: [
          {
            path: '',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.VEHICLES.MAIN}>
                <Vehicles />
              </ProtectedRoute>
            ),
          },
          {
            path: 'create',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.VEHICLES.CREATE}>
                <CreateVehicle />
              </ProtectedRoute>
            ),
          },
          {
            path: 'list/update/:id',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.VEHICLES.EDIT}>
                <UpdateVehicle />
              </ProtectedRoute>
            ),
          },
          {
            path: 'list/:id?',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.VEHICLES.LIST}>
                <VehiclesList />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'nonstandard-converters',
        children: [
          {
            path: 'create',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.NONSTANDARD_CONVERTERS.CREATE}>
                <CreateNonstandardConverter />
              </ProtectedRoute>
            ),
          },
          {
            path: 'sort?',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.NONSTANDARD_CONVERTERS.MAIN}>
                <NonstandardConvertersList />
              </ProtectedRoute>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <ProtectedRoute allowedFor={PERMISSIONS.NONSTANDARD_CONVERTERS.UPDATE}>
                <UpdateNonstandardConverter />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'feedback',
        element: <ProtectedRoute allowedFor={PERMISSIONS.FEEDBACKS.MAIN} />,
        children: [
          { path: 'summary', element: <FeedbacksSummary /> },
          { path: ':id?', element: <FeedbacksList /> },
        ],
      },
      {
        path: 'terms',
        element: <ProtectedRoute allowedFor={PERMISSIONS.TERMS.MAIN} />,
        children: [
          {
            path: '',
            element: <TermsPage />,
          },
          {
            path: 'edit',
            element: <UpdateTerms />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: '/setup',
    element: <BaseRoute />,
    ErrorBoundary,
    children: [
      {
        path: '',
        Component() {
          return <Navigate to="/setup/app-questionnaire" />;
        },
      },
      {
        path: 'app-questionnaire',
        element: (
          <ProtectedRoute skipSetupCheck gradingRequirement={null}>
            <SetYourApp />
          </ProtectedRoute>
        ),
      },
    ],
  },
];
