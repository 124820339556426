import styled from '@emotion/styled';

import { MEDIA_QUERY, Theme } from 'theme';

export const IndicatorDot = styled.div<{
  theme?: Theme;
  isActive?: boolean;
  bgColor?: string;
  display?: string;
}>`
  grid-area: dot;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin: 0 auto;
  background-color: ${({ isActive, theme, bgColor }) =>
    isActive ? theme.colors.mineShaftLightest : bgColor ?? theme.colors.mineShaftLighter};
  display: ${({ display }) => display ?? 'none'};

  .metal-shortcut {
    font-size: 10px;
    font-weight: normal;
    opacity: 0.7;
  }

  @media ${MEDIA_QUERY.XL} {
    display: block;

    .metal-shortcut {
      font-size: 24px;
    }
  }
`;
