import React from 'react';

import starEmptyIcon from 'assets/images/icons/star-empty.svg';
import starFullIcon from 'assets/images/icons/star-full.svg';
import { theme } from 'theme';
import { Image } from '../Buttons';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  filled?: boolean;
  size?: string;
}

const Star = ({ filled = true, size = '20px', ...props }: Props): React.ReactElement => (
  <Image
    color={theme.colors.white}
    name={filled ? starFullIcon : starEmptyIcon}
    data-cy={filled ? 'star-full' : 'star-empty'}
    size={size}
    {...props}
  />
);

export { Star };
