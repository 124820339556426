import React from 'react';
import Popup from 'reactjs-popup';
import { PopupProps } from 'reactjs-popup/dist/types';
import { css } from '@emotion/css';

interface ContextMenuProps extends PopupProps {
  trigger: JSX.Element;
  children: React.ReactNode;
}

export const contextMenuStyles = css`
  * {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
  }

  button {
    word-break: break-word;
    width: 100%;
    border: none;
    background: no-repeat;
    color: inherit;
    text-align: left;
    padding: 12px 8px;
  }
`;

export function ContextMenu({ trigger, children, ...restProps }: ContextMenuProps) {
  return (
    <Popup
      keepTooltipInside
      on={['click']}
      closeOnDocumentClick
      arrow={false}
      {...restProps}
      trigger={() => <span>{trigger}</span>}
      contentStyle={{
        zIndex: 30,
        border: `1px solid var(--color-selector-stroke)`,
        background: `var(--color-selector-surface)`,
        width: `166px`,
        borderRadius: `4px`,
        padding: `4px`,
      }}
    >
      <div className={contextMenuStyles}>{children}</div>
    </Popup>
  );
}
