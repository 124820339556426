import { CaseReducer, createSlice } from '@reduxjs/toolkit';

interface State {
  isOpen: boolean;
}

const sidebarSlice = createSlice<
  State,
  { toggleSidebar: CaseReducer<State, { type: string; payload: boolean | undefined }> }
>({
  name: 'sidebar',
  initialState: { isOpen: true },
  reducers: {
    toggleSidebar: (state, { payload }) => ({ isOpen: payload ?? !state.isOpen }),
  },
});

export const { toggleSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
