import React from 'react';
import { Datepicker } from '@alpha-recycling/component-library';
import { DatepickerProps } from '@alpha-recycling/component-library/dist/components/molecules/Datepicker';
import { setHours, setMinutes } from 'date-fns';

import { useTypedIntl } from 'locale/messages';
import { useLanguage } from 'shared/hooks';
import { withFieldLabel } from '../FieldWrapper/FieldLabel';

interface Props
  extends Partial<Omit<DatepickerProps, 'value' | 'onChange' | 'error' | 'errorMessage'>> {
  name: string;
  value?: Date;
  onChange?: (name: string, value: Date) => void;
  onTouch?: (name: string) => void;
  dateFormat?: string;
  max?: Date;
  maxTime?: Date;
  showTimeSelect?: boolean;
  min?: Date;
  minTime?: Date;
  required?: boolean;
  dataTestId?: string;
  error?: string | boolean | React.ReactNode;
}

const FieldDatePickerLayout = ({
  name,
  value,
  onChange,
  onTouch,
  showTimeSelect = false,
  dateFormat,
  min,
  max,
  minTime,
  maxTime,
  required,
  dataTestId,
  error,
  ...props
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const defaultMinTime = setHours(setMinutes(new Date(), 0), 0);
  const defaultMaxTime = setHours(setMinutes(new Date(), 59), 23);
  const currentlang = useLanguage().toLowerCase();

  const localMonths = [
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.January' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.February' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.March' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.April' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.May' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.June' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.July' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.August' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.September' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.October' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.November' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.December' }),
  ];

  return (
    <Datepicker
      {...props}
      id={name}
      value={value === undefined ? defaultMinTime : value}
      dateFormat={dateFormat}
      handleOnChange={val => {
        onTouch?.(name);
        onChange?.(name, val);
      }}
      minDate={min}
      maxDate={max}
      minTime={minTime ?? defaultMinTime}
      maxTime={maxTime ?? defaultMaxTime}
      locale={currentlang}
      openToDate={value ?? defaultMinTime}
      startDate={min ?? defaultMinTime}
      showTime={showTimeSelect}
      required={required}
      dataTestId={dataTestId}
      months={localMonths}
      onFocus={() => onTouch?.(name)}
      error={!!error}
      {...(!!error && { errorMessage: String(error) })}
    />
  );
};

const FieldDatePicker = withFieldLabel(FieldDatePickerLayout);

export { FieldDatePicker };
