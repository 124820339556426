import React, { useState } from 'react';
import { DataOutput, OutputCell } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { Details } from 'components/shared/Details';
import { Gallery } from 'components/shared/Gallery';
import { IconTooltipWarning } from 'components/shared/IconTooltip/IconTooltip';
import { LoadableContent } from 'components/shared/Loader';
import { TagGroup } from 'components/shared/TagGroup';
import { formatDate } from 'helpers/dateTime/dateTime';
import { removeChecker } from 'helpers/objects/removeChecker';
import { SHARED } from 'shared/constants';
import { useAssayEditActions } from 'shared/hooks';
import { useAlphamartLocation, useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { Assay, ItemAction } from 'shared/types';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from '../locale/messages';

const StyledNotes = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;

  span {
    margin-bottom: 5px;
  }
`;

type AssayDetailsParam = {
  assay: Assay;
};

const AssayDetails = ({ assay }: AssayDetailsParam): React.ReactElement | null => {
  const intl = useTypedIntl();
  const [isDetailsScrollable, setIsDetailsScrollable] = useState(false);
  const navigate = useAlphamartNavigate();
  const location = useAlphamartLocation();
  const { actionsPending, handleRemove } = useAssayEditActions();

  if (!assay) return null;

  const otherNumbersList = (
    <TagGroup
      size="small"
      items={assay?.converter?.otherNumbers?.map(otherNumber => otherNumber.original ?? '')}
      countLabelFormatter={count =>
        intl.formatMessage({ id: 'AssaysDetails.OtherNumbersGroup' }, { count })
      }
    />
  );

  const actions: ItemAction[] = [
    {
      id: 1,
      label: <FormattedMessage id="Global.Remove" />,
      onClick: () => handleRemove(assay, 'assays', location.state),
      visible: !removeChecker(assay),
    },
    {
      id: 2,
      label: <FormattedMessage id="Global.Update" />,
      onClick: () => navigate(`/assays/${assay.id}/edit`, { state: location.state }),
      visible: !removeChecker(assay),
    },
  ];

  return (
    <LoadableContent loading={actionsPending} mode={LoadableContent.MODE.OVERLAY} drawContent>
      <Details
        title={assay.sampleName}
        backUrl="/assays"
        backState={location.state}
        scrollable={!isDetailsScrollable}
        actions={actions}
      >
        {assay.converter.files[0] && (
          <Gallery images={assay.converter.files} onLightboxToggle={setIsDetailsScrollable} />
        )}
        <DataOutput
          headerText={intl.formatMessage({
            id: 'AssaysDetails.ConverterDetails',
          })}
        >
          {[
            {
              name: 'identifier',
              label: intl.formatMessage({
                id: 'AssaysDetails.Identifier',
              }),
              value: assay.converter.identifier,
            },
            {
              name: 'partName',
              label: intl.formatMessage({
                id: 'AssaysDetails.PartName',
              }),
              value: assay.converter.partName ?? SHARED.LONG_DASH,
            },
            {
              name: 'nicknames',
              label: intl.formatMessage({
                id: 'AssaysDetails.Nicknames',
              }),
              value: assay.converter.nicknames ?? SHARED.LONG_DASH,
            },
            {
              name: 'partialConverter',
              label: intl.formatMessage({
                id: 'AssaysDetails.PartialConverter',
              }),
              value: assay.converter.isPartial
                ? intl.formatMessage({
                    id: 'Global.Yes',
                  })
                : intl.formatMessage({
                    id: 'Global.No',
                  }),
            },
            {
              name: 'type',
              label: intl.formatMessage({
                id: 'AssaysDetails.Type',
              }),
              value: assay.converter.type?.name,
            },
            {
              name: 'folder',
              label: intl.formatMessage({
                id: 'AssaysDetails.Folder',
              }),
              value: assay.converter.folder?.name,
            },
            {
              name: 'make',
              label: intl.formatMessage({
                id: 'AssaysDetails.Make',
              }),
              value: assay.converter.make,
            },
            {
              name: 'model',
              label: intl.formatMessage({
                id: 'AssaysDetails.Model',
              }),
              value: assay.converter.model,
            },
            {
              name: 'year',
              label: intl.formatMessage({
                id: 'AssaysDetails.Year',
              }),
              value: assay.converter.year ?? SHARED.LONG_DASH,
            },
            {
              name: 'otherNumbers',
              label: intl.formatMessage({
                id: 'AssaysDetails.OtherNumbers',
              }),
              value: otherNumbersList ?? SHARED.LONG_DASH,
            },
            {
              name: 'counterfeit',
              label: intl.formatMessage({
                id: 'AssaysDetails.Counterfeit',
              }),
              value: assay.converter.counterfeit
                ? intl.formatMessage({
                    id: 'Global.Yes',
                  })
                : intl.formatMessage({
                    id: 'Global.No',
                  }),
            },
            {
              name: 'notes',
              label: intl.formatMessage({
                id: 'AssaysDetails.ConverterNotes',
              }),
              value: assay.converter.counterfeit ? (
                <StyledNotes>
                  <IconTooltipWarning
                    size="medium"
                    tooltip={intl.formatMessage({
                      id: 'AssaysDetails.Counterfeit',
                    })}
                  />
                  <span>{assay.converter.notes ?? SHARED.LONG_DASH}</span>
                </StyledNotes>
              ) : (
                assay.converter.notes ?? SHARED.LONG_DASH
              ),
            },
          ].map(field => {
            let mergeCells = 1;
            if (field.name === 'otherNumbers') {
              mergeCells = 2;
            } else if (field.name === 'notes') {
              mergeCells = 4;
            }

            return (
              <OutputCell
                key={field.name}
                data-cy={field.name}
                labelValue={field.label}
                mergeCells={mergeCells}
              >
                {field.value}
              </OutputCell>
            );
          })}
        </DataOutput>

        <DataOutput
          headerText={intl.formatMessage({
            id: 'AssaysDetails.AssayDetails',
          })}
        >
          {[
            {
              name: 'sampleDate',
              label: intl.formatMessage({
                id: 'AssaysDetails.SampleDate',
              }),
              value: assay.sampleDate ? formatDate(assay.sampleDate) : SHARED.LONG_DASH,
            },
            {
              name: 'weightWet',
              label: intl.formatMessage({
                id: 'AssaysDetails.WeightWet',
              }),
              value: assay.weightWetGrams
                ? `${assay.weightWetGrams.toFixed(3)} g`
                : SHARED.LONG_DASH,
            },
            {
              name: 'weightDry',
              label: intl.formatMessage({
                id: 'AssaysDetails.WeightDry',
              }),
              value: assay.weightDryLbs ? `${assay.weightDryLbs.toFixed(3)} lbs` : SHARED.LONG_DASH,
            },
            {
              name: 'moisturePercentage',
              label: intl.formatMessage({
                id: 'AssaysDetails.MoisturePercentage',
              }),
              value: assay.moisturePercentage
                ? `${assay.moisturePercentage.toFixed(3)} %`
                : SHARED.LONG_DASH,
            },
            {
              name: 'carbonPercentage',
              label: intl.formatMessage({
                id: 'AssaysDetails.CarbonPercentage',
              }),
              value: assay.carbonPercentage
                ? `${assay.carbonPercentage.toFixed(3)} %`
                : SHARED.LONG_DASH,
            },
            {
              name: 'notes',
              label: intl.formatMessage({
                id: 'AssaysDetails.Notes',
              }),
              value: assay.notes ?? SHARED.LONG_DASH,
            },
          ].map(field => {
            let mergeCells = 1;
            if (field.name === 'sampleDate' || field.name === 'notes') {
              mergeCells = 4;
            }

            return (
              <OutputCell
                key={field.name}
                data-cy={field.name}
                mergeCells={mergeCells}
                labelValue={field.label}
              >
                {field.value}
              </OutputCell>
            );
          })}
        </DataOutput>

        <DataOutput
          headerText={intl.formatMessage({
            id: 'AssaysDetails.Analysis',
          })}
        >
          {[
            {
              name: 'platinumPpm',
              label: intl.formatMessage({
                id: 'AssaysDetails.PlatinumPpm',
              }),
              value: Number.isFinite(assay.platinumPpm)
                ? `${assay.platinumPpm.toFixed(3)} ppm`
                : SHARED.LONG_DASH,
            },
            {
              name: 'palladiumPpm',
              label: intl.formatMessage({
                id: 'AssaysDetails.PalladiumPpm',
              }),
              value: Number.isFinite(assay.palladiumPpm)
                ? `${assay.palladiumPpm.toFixed(3)} ppm`
                : SHARED.LONG_DASH,
            },
            {
              name: 'rhodiumPpm',
              label: intl.formatMessage({
                id: 'AssaysDetails.RhodiumPpm',
              }),
              value: Number.isFinite(assay.rhodiumPpm)
                ? `${assay.rhodiumPpm.toFixed(3)} ppm`
                : SHARED.LONG_DASH,
            },
          ].map((field, index, fields) => (
            <OutputCell
              key={field.name}
              data-cy={field.name}
              mergeCells={fields.length - 1 === index ? 4 : 2}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>

        <DataOutput
          headerText={intl.formatMessage({
            id: 'Global.MoreDetails',
          })}
        >
          {[
            {
              name: 'createdBy',
              label: intl.formatMessage({
                id: 'Global.CreatedBy',
              }),
              value: assay.createdBy && `${assay.createdBy.firstName} ${assay.createdBy.lastName}`,
            },
            {
              name: 'createdAt',
              label: intl.formatMessage({
                id: 'Global.CreatedOn',
              }),
              value: formatDate(assay.createdAt),
            },
            {
              name: 'updatedBy',
              label: intl.formatMessage({
                id: 'Global.UpdatedBy',
              }),
              value: assay.updatedBy
                ? `${assay.updatedBy.firstName} ${assay.updatedBy.lastName}`
                : SHARED.LONG_DASH,
            },
            {
              name: 'updatedAt',
              label: intl.formatMessage({
                id: 'Global.UpdatedOn',
              }),
              value: assay.updatedAt ? formatDate(assay.updatedAt) : SHARED.LONG_DASH,
            },
            ...(removeChecker(assay)
              ? [
                  {
                    name: 'deletedBy',
                    label: intl.formatMessage({
                      id: 'Global.DeletedBy',
                    }),
                    value:
                      assay.deletedBy && `${assay.deletedBy.firstName} ${assay.deletedBy.lastName}`,
                  },
                  {
                    name: 'deletedAt',
                    label: intl.formatMessage({
                      id: 'Global.DeletedOn',
                    }),
                    value: assay.deletedAt && formatDate(assay.deletedAt),
                  },
                ]
              : []),
          ].map((field, index, fields) => (
            <OutputCell
              key={field.name}
              data-cy={field.name}
              mergeCells={fields.length === 6 && fields.length - 1 === index ? 3 : 1}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>
      </Details>
    </LoadableContent>
  );
};

export { AssayDetails };
