import styled from '@emotion/styled';

import { Theme } from 'theme';

type Props = {
  theme?: Theme;
  name?: string;
  color?: string;
  hoverColor?: string;
  pointer?: boolean;
  size?: string;
  maskSize?: string;
};

const Image = styled.span<Props>`
  display: inline-block;
  background-color: ${({ color, theme }) => color || theme.colors.mineShaftLighter};
  ${({ name }) => name && `mask-image: url(${name});`}
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: ${({ size, maskSize }) => maskSize ?? (size ? 'cover' : 'auto')};
  min-width: ${({ size }) => size || '25px'};
  width: ${({ size }) => size || '25px'};
  min-height: ${({ size }) => size || '25px'};
  height: ${({ size }) => size || '25px'};
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ color, hoverColor, theme }) =>
      hoverColor || color || theme.colors.mineShaftLightest};
  }
  ${({ pointer }) => `cursor: ${pointer ? 'pointer' : 'default'}`};
`;

export { Image };
