import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Status } from '@alpha-recycling/component-library';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import {
  AlphamartIntlProvider,
  withAlphamartIntlProvider,
} from 'components/shared/AlphamartIntlProvider';
import { ActionsButton } from 'components/shared/Buttons/ActionsButton';
import { ContextMenu } from 'components/shared/ContextMenu';
import { DownloadLogsModal } from 'components/shared/DownloadLogsModal/DownloadLogsModal';
import {
  HighlightSearch,
  MobileStatus,
  MobileStatusWrapper,
  useGetRowId,
} from 'components/shared/List';
import { DataList } from 'components/shared/List/DataList';
import { LoadableContent } from 'components/shared/Loader';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelLayout } from 'components/shared/PanelLayout/PanelLayout';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';
import { ThresholdsModal } from 'components/shared/ThresholdsModal/ThresholdsModal';
import { formatDate } from 'helpers/dateTime/dateTime';
import { FilterableModules, PERMISSIONS, UserTypes } from 'shared/constants';
import { resolveStatusColor, useAuthorization } from 'shared/helpers';
import { useCompanyActions, useMediaQuery } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useLanguage } from 'shared/hooks/useLanguage';
import { useGetCompanies, useGetCompanyDetails } from 'shared/queries';
import { Company, CompanyListItem, ItemAction, ThresholdsType } from 'shared/types';
import { downloadFile } from 'store/downloadFileSlice';
import { changePageIndexAction, changePagination } from 'store/listComponentSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { MEDIA_QUERY, theme } from 'theme';
import { CompaniesFilters } from './CompaniesFilters';
import { CompanyDetails } from '../CompanyDetails/CompanyDetails';
import { DownloadUserLocationsModal } from '../DownloadUserLocationsModal/DownloadUserLocationsModal';
import { useCompanyItemActions } from '../hooks/useCompanyItemActions';
import {
  CompanyMessages,
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

const CompaniesListComponent = (): React.ReactElement => {
  const authorize = useAuthorization();
  const { actionsPending } = useCompanyActions();
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const language = useLanguage();
  const listComponent = useAppSelector(state => state.listComponent);
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.COMPANIES]);
  const companyDetails = useGetCompanyDetails(id!, {
    enabled: id !== undefined,
  });
  const companiesData = useGetCompanies(
    {
      ...savedFilters.data,
      page: listComponent.pageIndex + 1,
      pageSize: listComponent.pageSize,
    },
    { cacheTime: 0 },
  );
  const [filtersOpen, setFiltersOpen] = useState(false);
  const isMd = useMediaQuery(MEDIA_QUERY.MD);
  const isLg = useMediaQuery(MEDIA_QUERY.LG);

  const onFiltersToggle = () => setFiltersOpen(open => !open);
  const setPageIndex = currentPage => dispatch(changePageIndexAction(currentPage));
  const onFiltersChanged = newFilters => {
    dispatch(
      saveFilters({
        filterKey: FilterableModules.COMPANIES,
        values: newFilters,
      }),
    );
    setPageIndex(0);
  };

  const goToCompany = (item: CompanyListItem) => {
    navigate(`/companies/${item.id}`);
  };

  const actions = useCompanyItemActions();

  const columns: ColumnDef<CompanyListItem>[] = [
    {
      id: 'status',
      header: intl.formatMessage({ id: 'CompaniesList.TableHeader.Status' }),
      cell: ({ row }) => (
        <Status
          type={resolveStatusColor(row.original.status.toUpperCase())}
          value={intl.formatMessage({
            id: `Global.Status.${row.original.status.toUpperCase()}` as keyof CompanyMessages,
          })}
          size="big"
        />
      ),
    },

    {
      id: 'type',
      header: intl.formatMessage({ id: 'CompaniesList.TableHeader.Type' }),
      cell: ({ row }) => (
        <MobileStatusWrapper>
          {!isLg && <MobileStatus theme={theme} state={row.original.status.toLowerCase()} />}
          <div>{intl.formatMessage({ id: `CompaniesList.Filters.Type.${row.original.type}` })}</div>
        </MobileStatusWrapper>
      ),
    },
    {
      id: 'name',
      header: intl.formatMessage({ id: 'CompaniesList.TableHeader.Name' }),
      cell: ({ row }) => (
        <HighlightSearch
          autoEscape
          searchWords={[savedFilters.data.query!]}
          textToHighlight={row.original.name}
        />
      ),
    },
    {
      id: 'country',
      header: intl.formatMessage({ id: 'CompaniesList.TableHeader.Country' }),
      cell: ({ row }) => row.original.country[language],
    },
    {
      id: 'city',
      header: intl.formatMessage({ id: 'CompaniesList.TableHeader.City' }),
      cell: ({ row }) => (
        <HighlightSearch
          searchWords={[savedFilters.data.query!]}
          textToHighlight={row.original.city}
        />
      ),
    },
    {
      id: 'actions',
      header: '',
      cell: ({ row }) => (
        <ContextMenu
          offsetX={-60}
          trigger={
            <Button data-cy="actionButton" iconName="ThreeDots" variant="plain" content="icon" />
          }
        >
          {actions.map(
            action =>
              (typeof action.visible === 'function'
                ? (action.visible as (item?: CompanyListItem | undefined) => boolean)(row.original)
                : action.visible) && (
                <button
                  type="button"
                  onClick={() => action.onClick(row.original as Company)}
                  key={action.id}
                  data-cy={action.dataCy?.(row.original as Company)}
                >
                  {action.label}
                </button>
              ),
          )}
        </ContextMenu>
      ),
    },
  ];

  const handleGenerateBtnClick = async () => {
    try {
      await dispatch(
        downloadFile(
          '/api/companies/report',
          `${intl.formatMessage({ id: 'CompaniesList.ReportName' })} ${formatDate(
            new Date(),
            language === 'EN' ? 'MM-dd-yyyy' : 'dd-MM-yyyy',
          )}.xlsx`,
        ),
      );
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  const buttonActions: ItemAction[] = [
    {
      id: 1,
      label: <FormattedMessage id="CompaniesList.Display.GenerateReport" />,
      onClick: () => {
        handleGenerateBtnClick();
      },
      dataCy: () => 'companies-list-display-generate-report',
      visible: authorize([UserTypes.SUPER_ADMIN, UserTypes.CUSTOMER_SUPPORT]),
    },
  ];

  const listLoading =
    (companiesData.isFetching && !companyDetails.isFetching && !companyDetails.data) ||
    actionsPending;

  const table = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    data: companiesData.data!.data,
    getRowId: useGetRowId(),
    pageCount: Math.ceil(companiesData.data!.count / listComponent.pageSize),
    onPaginationChange: pagination => dispatch(changePagination(pagination)),
    manualPagination: true,
    enableRowSelection: false,
    state: {
      columnVisibility: {
        status: isLg,
        type: authorize(PERMISSIONS.COMPANIES.LIST_RESTRICTED_FIELDS),
        country: isMd,
        city: isLg,
      },
      pagination: { pageIndex: listComponent.pageIndex, pageSize: listComponent.pageSize },
    },
  });

  const tableRestIndex = isLg ? 2 : 0;

  return (
    <AlphamartIntlProvider messages={messages}>
      <PanelLayout>
        <PanelHeader title={<FormattedMessage id="CompaniesList.Header.Companies" />}>
          <ActionsButton actions={buttonActions} />
        </PanelHeader>
        <PanelContent>
          <PanelContentMain disableScroll={listLoading}>
            <SearchBar
              isSearchPending={companiesData.isFetching}
              onSearchChanged={onFiltersChanged}
              initialQuery={savedFilters.data.query}
              onFiltersToggle={onFiltersToggle}
              savedFilters={savedFilters}
            />
            <DataList
              table={table}
              isLoading={companiesData.isFetching || actionsPending}
              onRowClicked={goToCompany}
              restIndex={tableRestIndex}
            />
          </PanelContentMain>
          <PanelContentSidebar
            header={intl.formatMessage({ id: 'Global.Filters' })}
            headerIcon="Filter"
            open={filtersOpen}
            onSidebarClosed={onFiltersToggle}
          >
            <CompaniesFilters
              onFiltersChanged={onFiltersChanged}
              onFiltersApplied={onFiltersToggle}
            />
          </PanelContentSidebar>
        </PanelContent>
        <LoadableContent loading={companyDetails.isFetching}>
          <CompanyDetails company={companyDetails.data!} />
        </LoadableContent>
      </PanelLayout>
      <DownloadUserLocationsModal />
      <DownloadLogsModal />
      <ThresholdsModal modalEndpoint={ThresholdsType.COMPANY} />
    </AlphamartIntlProvider>
  );
};

export const CompaniesList = withAlphamartIntlProvider(CompaniesListComponent, messages);
