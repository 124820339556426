import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { ButtonProps, Form, FormRow, OptionsProps } from '@alpha-recycling/component-library';
import { useFormik } from 'formik';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FieldTextArea } from 'components/shared/Fields/FieldTextArea/FieldTextArea';
import { FormWrapper } from 'components/shared/forms/Form/FormWrapper';
import { MiscellaneousFeesSection } from 'components/views/Terms/Forms/MiscellaneousFeesSection';
import { OtherChargesSection } from 'components/views/Terms/Forms/OtherChargesSection';
import { RatesAndChargesSection } from 'components/views/Terms/Forms/RatesAndChargesSection';
import { CompanyTypes, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useLanguage } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useGetCountries, useGetProvinces } from 'shared/queries';
import { CompanyFormData, Province } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { companyFormSchema } from './companyFormSchema';
import { useTypedIntl } from '../locale/messages';

type Props = {
  onSubmit?: (unknown) => Promise<void>;
  initialValues?: CompanyFormData;
  grading?: boolean;
};

const CompanyForm = ({ onSubmit, initialValues, grading }: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const allowedToViewType = authorize(PERMISSIONS.COMPANIES.VIEW_COMPANY_TYPE);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const { data: countries } = useGetCountries();
  const language = useLanguage();

  const validationSchema = useMemo(() => companyFormSchema(intl), [intl]);

  const formikContext = useFormik({
    initialValues: initialValues || {
      terms: {},
      ...(!allowedToViewType && { type: CompanyTypes.SUBSCRIBING }),
    },
    validationSchema,
    onSubmit: rawValues =>
      onSubmit?.(validationSchema.cast(rawValues, { stripUnknown: true })).catch(() =>
        formikContext.setSubmitting(false),
      ),
  });

  const { values, handleSubmit, touched, isValidating, isSubmitting, isValid } = formikContext;

  useGetProvinces(
    { country: values.country },
    { onSuccess: data => setProvinces(data as Province[]) },
  );

  const countriesOptions = useMemo(
    () =>
      countries!.reduce<OptionsProps[]>((acc, { id: value, name, alphaCode2 }) => {
        if (alphaCode2 === 'US' || alphaCode2 === 'CA') {
          return [{ label: name[language], value }, ...acc];
        }

        return [...acc, { label: name[language], value }];
      }, []),
    [countries],
  );

  const provincesOptions = useMemo(
    () => provinces.map(({ abbreviation: value, name }) => ({ value, label: name[language] })),
    [provinces],
  );

  const onCancel = useCallback(() => {
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'CompanyForm.Modal.Message' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: () => {
          navigate('/companies');
          dispatch(hideModal());
        },
      }),
    );
  }, [dispatch, intl]);

  const submitDisabled = !!(
    !(isValid && Object.keys(touched).length) ||
    isValidating ||
    isSubmitting
  );

  return (
    <FormWrapper
      header={intl.formatMessage({
        id: 'CompanyForm.Header.Update',
      })}
      submitLabel={intl.formatMessage({
        id: 'CompanyForm.Header.Update',
      })}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitDisabled={submitDisabled}
      context={formikContext}
    >
      <Form
        headerText={intl.formatMessage({ id: 'CompanyForm.Section.CompanyInfo' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldInput
            label={intl.formatMessage({ id: 'CompanyForm.Name' })}
            name="name"
            value={values.name ?? ''}
            disabled
            data-cy="name"
          />
          {allowedToViewType ? (
            <FieldSelect
              label={intl.formatMessage({ id: 'CompanyForm.Type' })}
              name="type"
              options={[
                {
                  value: CompanyTypes.INTERNAL,
                  label: intl.formatMessage({ id: 'CompanyForm.Type.INTERNAL' }),
                },
                {
                  value: CompanyTypes.SUBSCRIBING,
                  label: intl.formatMessage({ id: 'CompanyForm.Type.SUBSCRIBING' }),
                },
              ]}
              value={values.type}
              disabled
              data-cy="type"
            />
          ) : (
            (null as unknown as ReactElement)
          )}
        </FormRow>
      </Form>
      <Form
        headerText={intl.formatMessage({ id: 'CompanyForm.Section.Address' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldInput
            label={intl.formatMessage({ id: 'CompanyForm.City' })}
            name="city"
            value={values.city ?? ''}
            disabled
            data-cy="city"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'CompanyForm.ZipCode' })}
            name="zipCode"
            value={values.zipCode ?? ''}
            disabled
            data-cy="zip-code"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'CompanyForm.Street' })}
            name="street"
            value={values.street ?? ''}
            disabled
            data-cy="street"
          />
          <FieldSelect
            label={intl.formatMessage({ id: 'CompanyForm.Country' })}
            name="country"
            value={values.country}
            options={countriesOptions}
            disabled
            data-cy="country"
          />
          <FieldSelect
            label={intl.formatMessage({ id: 'CompanyForm.State' })}
            name="state"
            value={values.state}
            options={provincesOptions}
            disabled
            data-cy="state"
          />
        </FormRow>
      </Form>
      <RatesAndChargesSection
        formikContext={formikContext}
        label={intl.formatMessage({ id: 'CompanyForm.Section.RatesAndCharges' })}
        grading={grading}
      />
      <OtherChargesSection
        formikContext={formikContext}
        label={intl.formatMessage({ id: 'CompanyForm.Section.OtherCharges' })}
        grading={grading}
      />
      <MiscellaneousFeesSection
        formikContext={formikContext}
        label={intl.formatMessage({ id: 'CompanyForm.Section.MiscellaneousFees' })}
        grading={grading}
      />
      <Form
        headerText={intl.formatMessage({ id: 'CompanyForm.Section.Note' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldTextArea
            id="note"
            label={intl.formatMessage({ id: 'CompanyForm.Note' })}
            name="note"
            value={values.note}
            disabled
            data-cy="note"
          />
        </FormRow>
      </Form>
    </FormWrapper>
  );
};

export { CompanyForm };
