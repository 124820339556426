import React, { ReactElement } from 'react';
import {
  NavigationCategory,
  NavigationItem,
  NavigationPanel,
} from '@alpha-recycling/component-library';

import { useCurrentUser, useMarketToolUrls } from 'shared/hooks';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { toggleSidebar } from 'store/shared/sidebarSlice';
import { AccessFlag } from '../../../../shared/constants';
import { useAuthorization } from '../../../../shared/helpers';
import { withAlphamartIntlProvider } from '../../AlphamartIntlProvider';
import { ExtraNavigation } from '../ExtraNavigation/ExtraNavigation';
import { getExternalLink, getInternalLink } from '../helpers';
import { messages, useTypedIntl } from '../locale/messages';

interface Props {
  hideInternalNavigation?: boolean;
}

export function SidebarComponent({ hideInternalNavigation }: Props) {
  const user = useCurrentUser();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(state => state.sidebar.isOpen);
  const intl = useTypedIntl();
  const marketToolUrls = useMarketToolUrls();
  const authorize = useAuthorization();

  const profileData = {
    name: `${user?.firstName} ${user?.lastName}`,
    companyName: user?.company.name,
  };
  const isMarketToolManager = authorize([AccessFlag.MARKET_TOOL_MANAGER]);

  const toggleOpen = (open: boolean) => dispatch(toggleSidebar(open));

  return (
    <NavigationPanel
      activeItemId="catalyticTool"
      logo="MarketTool"
      dataTestId="side-bar"
      profileData={profileData}
      extraPanel={hideInternalNavigation ? undefined : <ExtraNavigation />}
      open={isOpen}
      onChangeOpen={toggleOpen}
      onClickLogo={() => window.location.replace(marketToolUrls.home)}
      onClickUserProfile={() => window.location.replace(marketToolUrls.profile)}
      bottomCategories={
        [
          isMarketToolManager && (
            <NavigationItem
              id="manage"
              iconName="Manage"
              getLinkItem={getExternalLink(marketToolUrls.manage)}
            >
              {intl.formatMessage({ id: 'Global.Manage' })}
            </NavigationItem>
          ),
          <NavigationItem id="help" iconName="Phone">
            {intl.formatMessage({ id: 'Navigation.HelpCenter' })}
          </NavigationItem>,
        ].filter(Boolean) as ReactElement[]
      }
    >
      <NavigationCategory label={intl.formatMessage({ id: 'Navigation.YourTools' })}>
        <NavigationItem
          id="news"
          iconName="NewsBrand"
          getLinkItem={getExternalLink(marketToolUrls.home)}
        >
          {intl.formatMessage({ id: 'Navigation.News' })}
        </NavigationItem>
        <NavigationItem
          id="catalyticTool"
          iconName="CatalyticToolBrand"
          getLinkItem={getInternalLink('/')}
        >
          {intl.formatMessage({ id: 'Navigation.ConverterTool' })}
        </NavigationItem>
      </NavigationCategory>
    </NavigationPanel>
  );
}

export const Sidebar = withAlphamartIntlProvider(SidebarComponent, messages);
