import * as Yup from 'yup';

import { TypedIntlShape } from '../locale/messages';

export const getChallengeSchema = (intl: TypedIntlShape) =>
  Yup.object().shape({
    challenge: Yup.string()
      .required(intl.formatMessage({ id: 'Login.Error.Challenge.Required' }))
      .matches(/^\d{4}$/, { message: intl.formatMessage({ id: 'Login.Error.Challenge.Format' }) }),
  });
