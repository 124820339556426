import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { IconTooltipWarning } from 'components/shared/IconTooltip/IconTooltip';
import { MEDIA_QUERY, Theme } from 'theme';

export const PriceColumn = styled.div<{ theme?: Theme }>`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 7px;
  justify-content: flex-start;

  @media ${MEDIA_QUERY.MAX_MD} {
    flex-direction: column;
  }
`;
export const NameColumn = styled.div`
  @media ${MEDIA_QUERY.MAX_SM} {
    text-wrap: wrap;
  }
`;
export const StyledIconTooltipWarning = styled(IconTooltipWarning)`
  order: 1;
  display: inline-block;

  @media ${MEDIA_QUERY.MAX_MD} {
    order: 0;
  }
`;
export const panelContentStyles = css`
  @media ${MEDIA_QUERY.MAX_XL} {
    padding: 16px 20px;
  }

  div[class*='DataList'] {
    overflow-x: hidden;
    width: fit-content;
    min-width: 100%;
  }
`;
