export enum SHARED {
  NON_BREAKING_SPACE = '\u00A0',
  NON_BREAKING_DASH = '\u2010',
  LONG_DASH = '\u2014',
  MAX_DB_INTEGER = 2147483647, // 2 ** 31 - 1,
  TWO_WEEKS_IN_MS = 12096e5,
  MIN_PAGE_SIZE = 12,
  VEHICLES_STARTING_YEAR = 1990,
  ASSAY_PREFIX = 'CC-',
  NONSTANDARD_CONVERTER_LIMIT = 20,
  SELECT_INPUT_PAGE_LIMIT = 40,
}
