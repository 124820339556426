import React from 'react';
import { Datepicker, InputGroup } from '@alpha-recycling/component-library';
import { add, Duration, endOfDay } from 'date-fns';
import { useField } from 'formik';
import { isNil } from 'lodash';

import { dateFormat as defaultDateFormat } from 'helpers/dateTime/dateTime';
import { useTypedIntl } from 'locale/messages';
import { useLanguage } from 'shared/hooks';
import { withFieldLabel } from '../FieldWrapper/FieldLabel';

export type DateValue = {
  from?: number | Date | string | null;
  to?: number | Date | string | null;
};

interface Props {
  name: string;
  value: DateValue | null | undefined;
  max?: Date | number | null;
  min?: Date | number | null;
  onChange?: (val?: DateValue) => void;
  dateFormat?: string;
  isClearable?: boolean;
  maxDateRange?: Duration;
  resetOnChange?: boolean;
  fillInBounds?: boolean;
  dataTestId?: string;
  required?: boolean;
}
export const FieldDatePickerRangeLayout = ({
  name,
  value,
  onChange,
  dateFormat,
  min,
  max,
  maxDateRange,
  isClearable = false,
  resetOnChange = true,
  fillInBounds = false,
  dataTestId,
  required,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const [, , { setValue, setTouched }] = useField(name);

  const rawLanguage = useLanguage();
  const currentLang = rawLanguage.toLowerCase();

  const localMonths = [
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.January' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.February' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.March' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.April' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.May' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.June' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.July' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.August' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.September' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.October' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.November' }),
    intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.December' }),
  ];

  const getMaxDate = (fromValue?: string | number | Date | null) => {
    if (!maxDateRange || !fromValue) return max;
    const maxFromTimeSpan = add(new Date(fromValue), maxDateRange);
    return !max || maxFromTimeSpan <= max ? maxFromTimeSpan : max;
  };

  const setFromValue = (fromValue?: string | number | Date | null) => {
    let newValue = { ...value, from: fromValue };

    if (resetOnChange && value?.to !== null && !isNil(fromValue)) {
      newValue = { from: fromValue, to: null };
    }

    if (fillInBounds && maxDateRange && !isNil(fromValue)) {
      newValue = { from: fromValue, to: endOfDay(getMaxDate(fromValue)!) };
    }

    setValue(newValue);
    onChange?.(newValue);
  };

  return (
    <InputGroup size="fifty-fifty" required={required}>
      <Datepicker
        value={value?.from as Date}
        openToDate={value?.from as Date}
        startDate={value?.from as Date}
        id={`${name}From`}
        minDate={min as Date}
        maxDate={max as Date}
        dateFormat={dateFormat ?? defaultDateFormat}
        handleOnChange={val => {
          setTouched(true);
          setFromValue(val);
        }}
        placeholder={intl.formatMessage({ id: 'Global.Fields.DateRange.From' })}
        locale={currentLang}
        isClearable={isClearable}
        showTime={false}
        dataTestId={`${dataTestId}From`}
        months={localMonths}
      />
      <Datepicker
        value={value?.to as Date}
        openToDate={value?.to as Date}
        startDate={value?.to as Date}
        id={`${name}To`}
        minDate={(value?.from || min) as Date}
        maxDate={getMaxDate(value?.from) as Date}
        dateFormat={dateFormat ?? defaultDateFormat}
        handleOnChange={val => {
          setTouched(true);
          setValue({ ...value, to: val });
          if (onChange) onChange({ ...value, to: val });
        }}
        placeholder={intl.formatMessage({ id: 'Global.Fields.DateRange.To' })}
        locale={currentLang}
        isClearable={isClearable}
        showTime={false}
        dataTestId={`${dataTestId}To`}
        months={localMonths}
      />
    </InputGroup>
  );
};

const FieldDatePickerRange = withFieldLabel(FieldDatePickerRangeLayout);

export { FieldDatePickerRange, FieldDatePickerRangeLayout as FieldDatePickerRangeRaw };
