import React, { useLayoutEffect, useState } from 'react';
import styled from '@emotion/styled';

import converterSamplePhoto from 'assets/images/converter-sample.jpg';
import logoExample from 'assets/images/logo-example.png';
import { PhotoShape } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { LoadableContent } from 'components/shared/Loader';
import { useExtendedTheme } from 'shared/hooks';
import { Theme } from 'theme';

interface Props {
  className?: string;
  isGeneratingWatermark: boolean;
  watermark: string | null;
  watermarkOpacity: number;
  watermarkLogo: PhotoShape;
}

const ConverterImage = styled.div<{ watermarkOpacity: number }>`
  position: relative;

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: contain;

    &[alt='watermark'] {
      position: absolute;
      left: 0;
      opacity: ${({ watermarkOpacity }) => watermarkOpacity / 100};
    }
  }
`;

const Placeholder = styled.div<{ width: string; theme: Theme; green?: boolean }>`
  height: 16px;
  width: ${({ width }) => width};
  background-color: ${({ theme, green }) =>
    green ? theme.colors.statuses.active : theme.colors.statuses.inactive};
  border-radius: 4px;
  margin-top: 6px;
`;

const GridItemMockupContainer = styled.div<{ theme?: Theme }>`
  background: ${({ theme }) => theme.colors.mineShaft};
  border-radius: 4px;
  padding: 8px 8px 24px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: none;
`;

export function GridItemMockup({
  className,
  watermark,
  isGeneratingWatermark,
  watermarkOpacity,
  watermarkLogo,
}: Props): React.ReactElement {
  const [watermarkMockup, setWatermarkMockup] = useState<string | null>(null);
  const theme = useExtendedTheme();

  useLayoutEffect(() => {
    (async () => {
      const { prepareWatermark } = await import('helpers/watermarks/prepareWatermark');
      setWatermarkMockup(await prepareWatermark(logoExample));
    })();
  }, []);

  const watermarkSrc = watermarkLogo ? watermark : watermarkMockup;

  return (
    <GridItemMockupContainer theme={theme} className={className}>
      <ConverterImage watermarkOpacity={watermarkLogo ? watermarkOpacity : 100}>
        <LoadableContent mode={LoadableContent.MODE.OVERLAY} loading={isGeneratingWatermark} />
        <img alt="converter" src={converterSamplePhoto} />
        {watermarkSrc && <img alt="watermark" src={watermarkSrc} />}
      </ConverterImage>
      <div>
        <Placeholder theme={theme} width="80%" />
        <Placeholder theme={theme} width="60%" />
        <Placeholder theme={theme} width="20%" green />
      </div>
    </GridItemMockupContainer>
  );
}
