import { PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCompanyActions } from 'shared/hooks';
import { Company, ItemAction } from 'shared/types';
import { useTypedIntl } from '../locale/messages';

export const useCompanyItemActions = (
  company?: Company,
): ItemAction<Company | undefined, Company | undefined>[] => {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const {
    handleUpdate,
    handleLocationsModalOpen,
    handleThresholdsModalOpen,
    handleHedgeLogsModalOpen,
    handleConverterLogsModalOpen,
  } = useCompanyActions();

  return [
    {
      id: 1,
      label: intl.formatMessage({ id: 'Global.Update' }),
      onClick: item => handleUpdate((company ?? item)!),
      dataCy: item => `update-${(company ?? item)!.id}`,
      visible: true,
    },
    {
      id: 2,
      label: intl.formatMessage({ id: 'Global.Export.LocationsLogs' }),
      onClick: item => handleLocationsModalOpen({ companyId: (company ?? item)!.id }),
      visible: () => authorize(PERMISSIONS.COMPANIES.EXPORT_LOCATIONS),
      dataCy: item => `company-export-locations-${(company ?? item)!.id}`,
    },
    {
      id: 3,
      label: intl.formatMessage({ id: 'Global.Export.HedgeUseLogs' }),
      onClick: item =>
        handleHedgeLogsModalOpen({
          companyId: (company ?? item)!.id,
          companyName: (company ?? item)!.name,
        }),
      visible: () => authorize(PERMISSIONS.COMPANIES.EXPORT_HEDGE_USE_LOGS),
      dataCy: item => `company-export-hedge-use-logs-${(company ?? item)!.id}`,
    },
    {
      id: 4,
      label: intl.formatMessage({ id: 'Global.Export.SearchLogs' }),
      onClick: item =>
        handleConverterLogsModalOpen({
          companyId: (company ?? item)!.id,
          companyName: (company ?? item)!.name,
        }),
      visible: () => authorize(PERMISSIONS.COMPANIES.EXPORT_CONVERTER_LOGS),
      dataCy: item => `company-export-view-search-logs-${(company ?? item)!.id}`,
    },
    {
      id: 5,
      label: intl.formatMessage({ id: 'Global.Thresholds' }),
      onClick: item =>
        handleThresholdsModalOpen({
          assignedId: (company ?? item)!.id,
          assignedName: (company ?? item)!.name,
        }),
      visible: () => authorize(PERMISSIONS.COMPANIES.EDIT_USAGES_THRESHOLDS),
      dataCy: item => `company-usage-thresholds-${(company ?? item)!.id}`,
    },
  ];
};
