import React, { useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';

import { TextButton } from 'components/shared/Buttons';
import { DroppableGroup } from 'components/shared/DroppableGroup/DroppableGroup';
import { ThumbImageWrapper } from 'components/shared/Gallery/Gallery.styles';
import { IconTooltip, IconTooltipWarning } from 'components/shared/IconTooltip/IconTooltip';
import { ListImage } from 'components/shared/List/ListImage';
import { PhotoPlaceholder } from 'components/shared/PhotoPlaceholder/PhotoPlaceholder';
import { DndTypes, ErrorCode, lambdaHostname } from 'shared/constants';
import { randomHexColor, validateConverters } from 'shared/helpers';
import { useAlphamartNavigate, useExtendedTheme } from 'shared/hooks';
import { AlphamartErrorData, ConvertersGroup } from 'shared/types';
import { replaceEmpty } from 'shared/utils/replaceEmpty';
import {
  clearConvertersGroups,
  fetchConvertersGroups,
  fetchConvertersGroupsSuccessAction,
} from 'store/convertersGroupsSlice';
import {
  fetchPartialConverters,
  fetchPartialConvertersSuccessAction,
} from 'store/partialConvertersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateConvertersGroup } from 'store/updateConvertersGroupSlice';
import { MEDIA_QUERY } from 'theme';
import { TypedFormattedMessage, useTypedIntl } from '../../locale/messages';

interface Props {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setGroupToUpdate: React.Dispatch<React.SetStateAction<ConvertersGroup | null>>;
}

const ConvertersGroupsList = styled.div`
  grid-area: groupsSection;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 99px);
  overflow-y: auto;

  @media ${MEDIA_QUERY.MAX_XL} {
    max-height: unset;
  }

  padding: 38px;
`;

const GroupsSection = ({ setModalOpen, setGroupToUpdate }: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const { convertersGroups } = useAppSelector(state => state.convertersGroups);
  const { partialConverters } = useAppSelector(state => state.partialConverters);
  const { isPending: isUpdatingGroup } = useAppSelector(state => state.updateConvertersGroup);
  const groupItemsSortKey = 'identifier';
  const theme = useExtendedTheme();

  useEffect(() => {
    dispatch(fetchConvertersGroups());

    return () => {
      dispatch(clearConvertersGroups());
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isUpdatingGroup) {
        dispatch(fetchConvertersGroups());
        dispatch(fetchPartialConverters());
      }
    }, 15000);

    return () => clearInterval(interval);
  }, [isUpdatingGroup]);

  const handleDrop = useCallback(
    async (converter, group) => {
      if (converter.group?.id === group.id) return;

      const converters = [...group.converters, converter];

      if (validateConverters(converters))
        return dispatch(
          snackBarPushFailure(
            <TypedFormattedMessage id="Global.PartialConverters.Error.MixedVehicles" />,
          ),
        );

      const newGroups = convertersGroups.map(cGroup => {
        if (cGroup.id === group.id)
          return {
            ...group,
            converters: group.converters.find(({ id }) => id === converter.id)
              ? group.converters
              : [...group.converters, converter],
          };
        if (converter.group?.id === cGroup.id)
          return { ...cGroup, converters: cGroup.converters.filter(c => c.id !== converter.id) };
        return cGroup;
      });

      dispatch(
        fetchPartialConvertersSuccessAction(partialConverters.filter(c => c.id !== converter.id)),
      );
      dispatch(fetchConvertersGroupsSuccessAction(newGroups));

      try {
        await dispatch(updateConvertersGroup({ converters, files: group.files }, group.id));
        dispatch(
          snackBarPushSuccess(<TypedFormattedMessage id="Global.PartialConverters.GroupUpdated" />),
        );
      } catch (error) {
        const areVehiclesMismatched =
          (error as AlphamartErrorData).errorCode === ErrorCode.VEHICLE_DATA_MIXED;
        dispatch(
          snackBarPushFailure(
            <TypedFormattedMessage
              id={
                areVehiclesMismatched
                  ? 'Global.PartialConverters.Error.MixedVehicles'
                  : 'Global.Error.SomethingWentWrong'
              }
            />,
          ),
        );
      }
    },
    [convertersGroups, partialConverters],
  );

  const generateGroupName = group =>
    group.converters
      ?.map(converter => converter.identifier)
      .sort()
      .join(', ');

  const groupColumns = [
    {
      id: 1,
      name: <TypedFormattedMessage id="ConvertersList.TableHeader.Image" />,
      render: item => (
        <ThumbImageWrapper>
          {item.files.length ? (
            <ListImage
              alt={item.sampleName}
              src={`${lambdaHostname}${item.files[0].presignedUrls.SMALL}`}
            />
          ) : (
            <PhotoPlaceholder />
          )}
          {item.counterfeit && <IconTooltipWarning tooltip={item.notes} />}
        </ThumbImageWrapper>
      ),
      mediaQuery: 0,
    },
    {
      id: 2,
      name: <TypedFormattedMessage id="ConvertersList.TableHeader.Identifier" />,
      render: item => <p>{item.identifier}</p>,
      mediaQuery: 0,
    },
    {
      id: 3,
      name: <TypedFormattedMessage id="ConvertersList.TableHeader.SampleName" />,
      render: item => <p>{item.assay.sampleName}</p>,
      mediaQuery: 769,
    },
    {
      id: 4,
      name: <TypedFormattedMessage id="ConvertersList.TableHeader.Vehicle" />,
      render: item => (
        <p>
          {replaceEmpty(
            `${item.year ? `${item.year} ` : ''} ${item.make ? `${item.make} ` : ''} ${
              item.model ? `${item.model} ` : ''
            }`,
          )}
        </p>
      ),
      mediaQuery: 769,
    },
    {
      id: 5,
      render: item => (
        <p>
          <TextButton onClick={() => navigate(`/assays/${item.assay.id}/edit`)}>
            <TypedFormattedMessage id="Global.Update" />
          </TextButton>
        </p>
      ),
      mediaQuery: 769,
    },
  ];

  const infoFields = [
    {
      render: () => (
        <IconTooltip
          key="warning"
          icon="Danger"
          color={theme.colors.fuzzyWuzzyBrown}
          tooltip={intl.formatMessage({
            id: 'Converters.PartialConverters.MissingVehiclesInfo',
          })}
        />
      ),
      access: group =>
        group.converters?.some(converter => !converter.make || !converter.model || !converter.year),
    },
    {
      render: () => (
        <IconTooltip
          key="error"
          icon="Danger"
          color={theme.colors.fuzzyWuzzyBrown}
          tooltip={intl.formatMessage({
            id: 'Converters.PartialConverters.MixedVehicles',
          })}
        />
      ),
      access: group => validateConverters(group.converters),
    },
  ];

  const generatePhoto = group =>
    group.files.length ? (
      <ListImage alt="Group photo" src={`${lambdaHostname}${group.files[0].presignedUrls.SMALL}`} />
    ) : (
      <PhotoPlaceholder />
    );

  const mainColors = useMemo(
    () =>
      convertersGroups.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: randomHexColor(61),
        }),
        {},
      ),
    [convertersGroups.length],
  );

  const groupActions = [
    {
      label: <TypedFormattedMessage id="Global.Update" />,
      onClick: item => {
        setGroupToUpdate(item);
        return setModalOpen(true);
      },
      dataCy: item => `update-${item.id}`,
    },
  ];

  return (
    <ConvertersGroupsList>
      {[...convertersGroups]
        .sort((a, b) => (a.converters.length && a.createdAt < b.createdAt ? 1 : -1))
        .map(group => (
          <DroppableGroup
            key={group.id}
            group={group}
            onDrop={handleDrop}
            generateName={generateGroupName}
            groupItems="converters"
            groupItemsSortKey={groupItemsSortKey}
            generatePhoto={generatePhoto}
            groupColumns={groupColumns}
            groupAccentColor={mainColors[group.id]}
            itemType={DndTypes.CONVERTERS_GROUPS}
            infoFields={infoFields}
            groupActions={groupActions}
          />
        ))}
    </ConvertersGroupsList>
  );
};

export { GroupsSection };
