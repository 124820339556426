import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function SEO(): React.ReactElement {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Converter Tool</title>
      </Helmet>
    </HelmetProvider>
  );
}
