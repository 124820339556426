import React, { useCallback, useMemo, useState } from 'react';
import { OptionsProps } from '@alpha-recycling/component-library';
import { useFormik } from 'formik';

import { FieldSelect, FieldSelectRaw } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FiltersForm } from 'components/shared/forms/FiltersForm/FiltersForm';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { CompanyTypes, DEFAULT_FILTERS, FilterableModules, UserTypes } from 'shared/constants';
import { useEffectAfterMount, useMediaQuery } from 'shared/hooks';
import { useLanguage } from 'shared/hooks/useLanguage';
import { useGetCountries, useGetProvinces } from 'shared/queries';
import { Status } from 'shared/types';
import { useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';

export interface CompaniesFiltersShape {
  status: Status | null;
  type: CompanyTypes | null;
  country: number | null;
  state: string | null;
  query?: string;
}

interface Props {
  onFiltersChanged: (filters?: CompaniesFiltersShape) => void;
  onFiltersApplied: () => void;
}

export const CompaniesFilters = ({
  onFiltersChanged,
  onFiltersApplied,
}: Props): React.ReactElement => {
  const auth = useAppSelector(state => state.auth);
  const intl = useTypedIntl();
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.COMPANIES]);
  const { data: countries } = useGetCountries();
  const isTypeAllowed = auth.user?.data.userTypes.some(
    r => r.name === UserTypes.SUPER_ADMIN || r.name === UserTypes.CUSTOMER_SUPPORT,
  );
  const [specialCountriesIds, setSpecialCountriesIds] = useState<Record<string, string | unknown>>({
    US: null,
    CA: null,
  });
  const language = useLanguage();
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  const formikContext = useFormik<CompaniesFiltersShape>({
    initialValues: savedFilters.data,
    onSubmit: () => {},
  });
  const { setValues, values } = formikContext;
  const { data: provinces } = useGetProvinces({ country: values.country });

  const countryOptions = useMemo(() => {
    if (!countries) return [];
    const specialCountries = countries.reduce<Record<string, unknown>>(
      (acc, { id, alphaCode2 }) => {
        if (alphaCode2 === 'CA' || alphaCode2 === 'US') {
          return { ...acc, [alphaCode2]: id };
        }

        return acc;
      },
      {},
    );

    setSpecialCountriesIds({
      US: specialCountries.US,
      CA: specialCountries.CA,
    });

    return countries.reduce<OptionsProps[]>((acc, { id: value, name, alphaCode2 }) => {
      if (alphaCode2 === 'US' || alphaCode2 === 'CA') {
        return [{ label: name[language], value }, ...acc];
      }

      return [...acc, { label: name[language], value }];
    }, []);
  }, [countries]);

  const provincesOptions = useMemo(
    () =>
      provinces
        ? provinces.map(({ abbreviation: value, name }) => ({ value, label: name[language] }))
        : [],
    [provinces],
  );

  useEffectAfterMount(() => {
    !isMobile && onFiltersChanged(values);
  }, [values]);

  const isSpecialCountry = Object.values(specialCountriesIds).includes(values.country);

  const handleCountryChange = useCallback(
    val => {
      if (val?.value !== values.country) {
        formikContext.setFieldValue('state', null);
      }
    },
    [values],
  );

  const onFiltersClear = () => {
    setValues(DEFAULT_FILTERS.companies);
    onFiltersChanged(DEFAULT_FILTERS.companies);
  };

  const onFiltersApply = () => {
    onFiltersChanged(values);
    onFiltersApplied();
  };

  return (
    <FiltersForm
      context={formikContext}
      onFiltersClear={onFiltersClear}
      onFiltersApply={onFiltersApply}
      savedFilters={savedFilters}
    >
      <FiltersSection
        sectionName="status"
        label={intl.formatMessage({ id: 'CompaniesList.Filters.Status' })}
      >
        <FieldSelectRaw
          name="status"
          options={[
            {
              value: Status.ACTIVE,
              label: intl.formatMessage({ id: 'CompaniesList.Filters.Status.ACTIVE' }),
            },
            {
              value: Status.INACTIVE,
              label: intl.formatMessage({ id: 'CompaniesList.Filters.Status.INACTIVE' }),
            },
          ]}
          value={values.status}
          data-cy="filter-status"
        />
      </FiltersSection>
      {isTypeAllowed && (
        <FiltersSection
          sectionName="type"
          label={intl.formatMessage({ id: 'CompaniesList.Filters.Type' })}
        >
          <FieldSelectRaw
            name="type"
            options={[
              {
                value: CompanyTypes.SUBSCRIBING,
                label: intl.formatMessage({ id: 'CompaniesList.Filters.Type.SUBSCRIBING' }),
              },
              {
                value: CompanyTypes.INTERNAL,
                label: intl.formatMessage({ id: 'CompaniesList.Filters.Type.INTERNAL' }),
              },
            ]}
            value={values.type}
            data-cy="filter-type"
          />
        </FiltersSection>
      )}
      <FiltersSection
        sectionName="country"
        label={intl.formatMessage({ id: 'CompaniesList.Filters.Country' })}
      >
        <FieldSelectRaw
          name="country"
          value={values.country}
          onChange={handleCountryChange}
          options={countryOptions}
          data-cy="country"
        />
        <FieldSelect
          name="state"
          label={intl.formatMessage({ id: 'CompaniesList.Filters.State' })}
          value={values.state}
          options={provincesOptions}
          disabled={!provincesOptions.length || !isSpecialCountry}
          data-cy="state"
        />
      </FiltersSection>
    </FiltersForm>
  );
};
