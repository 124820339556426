import React from 'react';
import { Icon } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { Theme, theme as staticTheme } from 'theme';
import { Option, OptionValue } from './FieldMultiselectCheckbox';

interface GroupProps {
  option: Option;
  handleCheckboxChange: (val: OptionValue) => void;
  checked?: boolean;
}

export const GroupOption = ({
  option,
  handleCheckboxChange,
  checked,
}: GroupProps): React.ReactElement => (
  <OptionContainer
    key={option.value}
    data-cy={`option-${option.value}`}
    onClick={() => handleCheckboxChange(option.value)}
  >
    <OptionLabel>
      <span>{option.label}</span>
    </OptionLabel>
    <Input type="checkbox" checked={checked} readOnly />
    <CheckMarkContainer>
      <CheckMark checked={checked}>
        <Icon name="Check" fillColor={staticTheme.mainColor} size="small" />
      </CheckMark>
    </CheckMarkContainer>
  </OptionContainer>
);

const OptionLabel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const OptionContainer = styled.div<{ theme?: Theme }>`
  color: ${({ theme }) => theme.fontColor};
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  word-break: break-word;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-left: 20px;

  :hover {
    background-color: ${({ theme }) => theme.colors.mineShaftLighter};
  }
`;
const CheckMarkContainer = styled.div`
  heigh: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
const CheckMark = styled.div<{ theme?: Theme; checked?: boolean }>`
  flex-shrink: 0;
  display: ${({ checked }) => (checked ? 'block' : 'none')};
  width: 13px;
  height: 13px;
  transition: all 0.2s ease;
  margin: 0 5px;
`;
const Input = styled.input<{ theme?: Theme }>`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  cursor: pointer;
`;
