import React from 'react';
import { Button } from '@alpha-recycling/component-library';

import lockIcon from 'assets/images/icons/lock.svg';
import { Image } from 'components/shared/Buttons';
import { InfoPageContainer } from 'components/shared/InfoPageContainer';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from 'locale/messages';
import { useDefaultRedirect, useMediaQuery } from 'shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';

function Forbidden(): React.ReactElement {
  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_MD);
  const redirectTo = useDefaultRedirect();
  const intl = useTypedIntl();

  const goBack = () => window.location.replace(redirectTo);

  return (
    <InfoPageContainer theme={theme} data-cy="forbidden-page">
      <div>
        4
        <Image
          name={lockIcon}
          size={isPhone ? '128px' : '240px'}
          color={theme.colors.mineShaftLightest}
        />
        3
      </div>
      <p>
        <FormattedMessage id="Global.NoAccess" />
      </p>
      <Button
        variant="filled"
        onClick={goBack}
        content="text"
        label={intl.formatMessage({
          id: 'Global.TakeMeHome',
        })}
      />
    </InfoPageContainer>
  );
}

export { Forbidden };
