import { AccessFlag } from 'shared/constants';
import { useAuthorization } from '../helpers';

export function useDefaultRedirect() {
  const authorize = useAuthorization();

  if (authorize([AccessFlag.CONVERTER_VIEWER])) {
    return '/converters';
  }
  return '/dashboard';
}
