import React, { useCallback, useRef } from 'react';
import mergeRefs from 'react-merge-refs';
import { useSwipeable } from 'react-swipeable';
import { CSSTransition } from 'react-transition-group';
import { Button, Icon, ICON_NAMES_TYPE } from '@alpha-recycling/component-library';

import { useExtendedTheme } from 'shared/hooks';
import {
  CloseIconWrapper,
  panelContentSidebarStyles,
  sidebarHeaderStyles,
} from './PanelContentSidebar.styles';

interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
  headerIcon: ICON_NAMES_TYPE;
  open: boolean;
  onSidebarClosed: () => void;
  hideYOverflow?: boolean;
}

export const PanelContentSidebar = ({
  children,
  header,
  headerIcon,
  open,
  onSidebarClosed,
}: Props): React.ReactElement => {
  const swipeHandler = useCallback(() => {
    if (open) onSidebarClosed();
  }, [onSidebarClosed, open]);
  const theme = useExtendedTheme();
  const handlers = useSwipeable({
    trackTouch: true,
    trackMouse: true,
    onSwipedRight: swipeHandler,
  });
  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition in={open} classNames="transition" timeout={100} nodeRef={nodeRef}>
      <div
        {...handlers}
        className={panelContentSidebarStyles(theme)}
        ref={mergeRefs([nodeRef, handlers.ref])}
      >
        <CloseIconWrapper>
          <Button
            onClick={onSidebarClosed}
            data-cy="panel-content-sidebar-close"
            iconName="X"
            size="big"
            content="icon"
            variant="transparent"
          />
        </CloseIconWrapper>

        <h3 className={sidebarHeaderStyles(theme)}>
          <Icon name={headerIcon} size="big" fillColor={theme.colors.white} />
          {header}
        </h3>
        {children}
      </div>
    </CSSTransition>
  );
};
