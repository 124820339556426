import styled from '@emotion/styled';

import { MEDIA_QUERY, Theme } from 'theme';

export const InfoPageContainer = styled.div<{ theme?: Theme }>`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 240px;
  color: ${({ theme: t }) => t.colors.mineShaft};
  background: ${({ theme: t }) => t.background};

  p {
    font-size: 24px;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    font-size: 128px;

    p {
      font-size: 16px;
    }
  }
`;
