import styled from '@emotion/styled';

import { Theme } from 'theme';

export const FiltersFooter = styled.div<{ theme?: Theme }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black};
  border-top: 1px solid ${({ theme }) => theme.colors.mineShaftLightest};
  display: flex;
  gap: 20px;
  height: 90px;
  justify-content: center;
  min-width: 288px;
  padding: 0 20px;
`;
