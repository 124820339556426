import { zonedTimeToUtc } from 'helpers/dateTime/dateTime';
import { HedgeFormSchema } from '../types/hedgeFormSchema ';
import { HedgeUpdateSchema } from '../types/hedgeUpdateSchema';

export const parseHedge = (hedge: HedgeFormSchema): HedgeUpdateSchema => ({
  name: hedge.name.replace(/\s+/g, ' ').trim(),
  relatedLot: hedge.relatedLot,
  company: hedge.company,
  hedgeCompany: hedge.hedgeCompany,
  note: hedge.note,
  ptPriceCust: hedge.ptPriceCust === '' ? null : Number(hedge.ptPriceCust),
  pdPriceCust: hedge.pdPriceCust === '' ? null : Number(hedge.pdPriceCust),
  rhPriceCust: hedge.rhPriceCust === '' ? null : Number(hedge.rhPriceCust),
  placedAt: zonedTimeToUtc(hedge.placedAt!),
  expiresAt: zonedTimeToUtc(hedge.expiresAt!),
  assignedUsersIds: hedge.assignedUsers?.length ? hedge.assignedUsers : null,
});
