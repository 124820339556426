import React from 'react';
import { Global, ThemeProvider } from '@emotion/react';

import { LoadableContent, LoadableContentModes } from 'components/shared/Loader';
import { SnackBar } from 'components/shared/SnackBar/SnackBar';
import { useTypedIntl } from 'locale/messages';
import { useExtendedTheme } from 'shared/hooks';
import { globalStyles } from 'theme';
import SEO from '../../SEO/SEO';
import { AuthError } from '../AuthError/AuthError';
import { DeviceVerification } from '../DeviceVerification/DeviceVerification';
import { useAutoLogin, useDeviceVerification, useSetTokenGenerator } from '../hooks';

export function AuthGuard({ children }) {
  useAutoLogin();
  const intl = useTypedIntl();
  const isGeneratorSet = useSetTokenGenerator();

  const theme = useExtendedTheme();
  const { loading, error, verified, addingDevice, addPendingDevice, submitChallenge } =
    useDeviceVerification(isGeneratorSet);

  if (verified) {
    return children;
  }

  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <Global styles={globalStyles as never} />
      <LoadableContent
        label={intl.formatMessage({ id: 'Global.Authorization' })}
        mode={LoadableContentModes.OVERLAY}
        loading={loading}
      />
      {addingDevice ? (
        <DeviceVerification onSubmitChallenge={submitChallenge} />
      ) : (
        error && <AuthError error={error} onAddDevice={addPendingDevice} />
      )}
      <SnackBar />
    </ThemeProvider>
  );
}
