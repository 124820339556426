import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@alpha-recycling/component-library';
import { isNumber } from 'lodash';

import { useTypedIntl } from 'locale/messages';
import { useMediaQuery } from 'shared/hooks';
import { ApiFile } from 'shared/types';
import { MEDIA_QUERY } from 'theme';
import { GalleryLayout } from './Gallery.styles';
import ImageLightbox from '../ImageLightbox';
import { ThumbnailLink } from '../ThumbnailLink';

interface Props {
  largeImage?: boolean;
  onLightboxToggle: (isOpened: boolean) => void;
  images: ApiFile[];
}

export const Gallery = ({
  images,
  largeImage,
  onLightboxToggle,
}: Props): React.ReactElement | null => {
  const intl = useTypedIntl();
  const [viewed, setViewed] = useState<number | null>(null);
  const [seeAll, setSeeAll] = useState<boolean>(false);
  const isSm = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const isMd = useMediaQuery(MEDIA_QUERY.MAX_MD);
  const goToNext = useCallback(
    () => isNumber(viewed) && setViewed(Math.min(images.length - 1, viewed + 1)),
    [viewed],
  );
  const goToPrevious = useCallback(
    () => isNumber(viewed) && setViewed(Math.max(0, viewed - 1)),
    [viewed],
  );

  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {
      if (viewed === null) return;
      // ESC
      if (e.keyCode === 27) {
        setViewed(null);
        return onLightboxToggle(false);
      }
      if (e.keyCode === 37) goToPrevious(); // LEFT
      if (e.keyCode === 39) goToNext(); // RIGHT
    };

    window.addEventListener('keydown', keyListener);
    return () => window.removeEventListener('keydown', keyListener);
  }, [goToPrevious, goToNext]);

  const filteredImages = images?.filter(i => i.presignedUrls) ?? [];

  if (!filteredImages.length) return null;

  const sliceValue = () => {
    if (isSm) return 1;
    if (isMd) return 2;
    return 6;
  };

  const slicedImages =
    !seeAll && images.length > sliceValue() + 1 ? images.slice(0, sliceValue()) : images;

  return (
    <div id="gallery">
      <GalleryLayout>
        {slicedImages.map((image, index) => (
          <ThumbnailLink
            key={image.id}
            thumbnail={largeImage ? image.presignedUrls!.LARGE! : image.presignedUrls!.MEDIUM!}
            onClick={() => {
              onLightboxToggle(true);
              setViewed(index);
            }}
            title={image.fileName}
            disableZoom
          />
        ))}
        {!seeAll && images.length > sliceValue() + 1 && (
          <Button
            onClick={() => setSeeAll(true)}
            label={intl.formatMessage(
              { id: 'Global.PhotoList.LoadMore' },
              { count: images.length - sliceValue() },
            )}
            content="text_icon"
            iconName="ArrowRight"
            variant="content"
          />
        )}
      </GalleryLayout>

      {viewed !== null ? (
        <ImageLightbox
          onClick={() => {
            setViewed(null);
            onLightboxToggle(false);
          }}
          startFrom={viewed}
        >
          {images.map((image, index) => (
            <ThumbnailLink
              key={image.id}
              thumbnail={image.presignedUrls!.ORIGINAL!}
              onClick={() => setViewed(index)}
              title={image.fileName}
            />
          ))}
        </ImageLightbox>
      ) : null}
    </div>
  );
};
