import React, { useCallback, useState } from 'react';
import { Button, Icon, ICON_NAMES_TYPE } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { LoadableContent } from 'components/shared/Loader';
import {
  calculatePathAndArea,
  getRectanglePath,
} from 'components/shared/MapComponent/CustomMapComponents';
import { DrawingModes, DrawingTools } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';
import { parsePolygonPath } from 'shared/parsers/parsePolygonPath';
import { ShapeParam } from 'store/locationsSlice';
import { MapShape, setLastShape, setMapDrawing, setShouldErase } from 'store/mapDrawingSlice';
import { fetchOffZones } from 'store/offZonesSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateOffZones } from 'store/updateOffZonesSlice';
import { Theme, theme as staticTheme } from 'theme';
import { Controls, drawingPanelStyles } from './DrawingPanel.styles';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from '../../locale/messages';
import { MapFiltersShape } from '../MapFilters';

const StyledLabel = styled.label`
  display: flex;
  gap: 8px;
`;

interface Props {
  className?: string;
  getMarkers: (shape: ShapeParam, filters?: MapFiltersShape) => void;
}
const DrawingPanelLayout = ({ className, getMarkers }: Props): React.ReactElement | null => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const {
    drawingMode: isVisible,
    currentShape,
    lastShape,
    map,
    drawingTool,
    eraseOnClick,
    offZones,
  } = useAppSelector(state => state.mapDrawing);
  const [isSavePending, setIsSavePending] = useState(false);
  const isOffZonesView = isVisible === DrawingModes.OFF_ZONES;
  const handleClose = () =>
    dispatch(setMapDrawing({ drawingTool: null, drawingMode: null, eraseOnClick: false }));

  const handleCancel = useCallback(() => {
    if (currentShape) (currentShape as MapShape).overlay.setMap(null);
    if (!lastShape) {
      dispatch(setMapDrawing({ area: 0, savedPath: null }));
      getMarkers({ path: getRectanglePath(map!.getBounds()!) });
    } else {
      (lastShape as MapShape).overlay.setMap(map);

      const { path, area } = calculatePathAndArea(lastShape);

      getMarkers(path as ShapeParam);
      dispatch(
        setMapDrawing({
          currentShape: lastShape,
          savedPath: path as ShapeParam,
          area: Math.round(area * 10) / 10,
        }),
      );
    }

    if (isOffZonesView) dispatch(fetchOffZones());

    handleClose();
  }, [lastShape, currentShape, map, isOffZonesView]);

  const handleSave = useCallback(async () => {
    if (isOffZonesView) {
      setIsSavePending(true);
      const zones = [
        ...offZones[DrawingTools.CIRCLE]!.map(({ radius, center }) => ({
          radius,
          // @ts-ignore lat() is callable
          centerLatitude: center.lat(),
          // @ts-ignore lng() is callable
          centerLongitude: center.lng(),
          company: currentUser.company.id,
        })),
        ...offZones[DrawingTools.POLYGON]!.map(shape =>
          parsePolygonPath(
            shape.id,
            shape.getPath ? shape.getPath().getArray() : shape.path,
            currentUser.company.id,
          ),
        ),
        ...offZones[DrawingTools.RECTANGLE]!.map(shape =>
          parsePolygonPath(null, getRectanglePath(shape.getBounds()!), currentUser.company.id),
        ),
      ];
      try {
        await dispatch(updateOffZones(zones));
        dispatch(snackBarPushSuccess(<FormattedMessage id="Global.OffZones.Updated" />));
      } catch {
        dispatch(snackBarPushFailure(<FormattedMessage id="Global.Error.SomethingWentWrong" />));
      }
      setIsSavePending(false);
    } else if (currentShape) dispatch(setLastShape(currentShape));

    handleClose();
  }, [currentShape, dispatch, offZones]);

  const handleErase = () =>
    dispatch(
      isOffZonesView
        ? setMapDrawing({ eraseOnClick: !eraseOnClick, drawingTool: null })
        : setShouldErase(true),
    );

  const handleDrawingModeChange = event => {
    const val = event.target.value === DrawingTools.NAVIGATE ? null : event.target.value;

    return dispatch(setMapDrawing({ drawingTool: val, eraseOnClick: false }));
  };

  if (!isVisible) return null;

  const drawingTools = [
    {
      value: DrawingTools.POLYGON,
      label: <FormattedMessage id="Geolocation.Drawing.Polygon" />,
      icon: 'Shape' as ICON_NAMES_TYPE,
    },
    {
      value: DrawingTools.RECTANGLE,
      label: <FormattedMessage id="Geolocation.Drawing.Rectangle" />,
      icon: 'Square' as ICON_NAMES_TYPE,
    },
    {
      value: DrawingTools.CIRCLE,
      label: <FormattedMessage id="Geolocation.Drawing.Circle" />,
      icon: 'Circle' as ICON_NAMES_TYPE,
    },
  ];

  return (
    <div className={className}>
      <LoadableContent loading={isSavePending} mode={LoadableContent.MODE.FULL} />
      <header>
        <FormattedMessage
          id={`Geolocation.Drawing.${isOffZonesView ? 'ManageOffZones' : 'SelectTerrain'}`}
        />
      </header>
      <div className="navigate-map">
        <input
          type="radio"
          id={DrawingTools.NAVIGATE}
          name="drawingMode"
          value={DrawingTools.NAVIGATE}
          onClick={() => dispatch(setMapDrawing({ eraseOnClick: false }))}
          onChange={handleDrawingModeChange}
          checked={drawingTool === null}
          data-cy="drawing-mode-radio-btn"
        />
        <StyledLabel htmlFor={DrawingTools.NAVIGATE}>
          <Icon name="Navigation" size="medium" fillColor={staticTheme.fontColor} />
          <FormattedMessage id="Geolocation.Drawing.Navigate" />
        </StyledLabel>
      </div>
      <FiltersSection
        sectionName="drawTool"
        label={<FormattedMessage id="Geolocation.Drawing.SelectDrawingTool" />}
      >
        {drawingTools.map(({ value, label, icon }) => (
          <div key={value}>
            <input
              type="radio"
              id={value}
              name="drawingMode"
              value={value}
              onChange={handleDrawingModeChange}
              checked={value === drawingTool}
              data-cy="drawing-mode-radio-btn"
            />
            <StyledLabel htmlFor={value}>
              <Icon name={icon} size="medium" fillColor={staticTheme.fontColor} />
              {label}
            </StyledLabel>
          </div>
        ))}
      </FiltersSection>
      <FiltersSection
        sectionName="selectTerrain"
        label={<FormattedMessage id="Geolocation.Drawing.EraseTerrain" />}
      >
        <Button
          variant="transparent"
          onClick={handleErase}
          iconName="X"
          label={intl.formatMessage({
            id: 'Geolocation.Drawing.Erase',
          })}
        />
      </FiltersSection>
      <Controls>
        <Button
          onClick={handleCancel}
          variant="transparent"
          content="text"
          label={intl.formatMessage({ id: 'Global.Cancel' })}
          data-cy="cancel-button"
        />
        <Button
          onClick={handleSave}
          iconName="Check"
          label={intl.formatMessage({ id: 'Global.Save' })}
        />
      </Controls>
    </div>
  );
};

const DrawingPanel = styled(DrawingPanelLayout)<{ theme?: Theme }>`
  ${({ theme }) => drawingPanelStyles(theme)};
`;

export { DrawingPanel };
