import React, { useMemo } from 'react';
import { Icon } from '@alpha-recycling/component-library';
import { useFormik } from 'formik';

import { FormWrapper } from 'components/shared/forms/Form/FormWrapper';
import { companyFormSchema } from 'components/views/Companies/CompanyForm/companyFormSchema';
import { useTypedIntl as useTypedIntlCompany } from 'components/views/Companies/locale/messages';
import { MiscellaneousFeesSection } from 'components/views/Terms/Forms/MiscellaneousFeesSection';
import { OtherChargesSection } from 'components/views/Terms/Forms/OtherChargesSection';
import { RatesAndChargesSection } from 'components/views/Terms/Forms/RatesAndChargesSection';
import { CompanyFormData } from 'shared/types';
import { TypeSection } from '../../Terms/Forms/TypeSection';
import { useTypedIntl as useTypedIntlLocal } from '../locale/messages';
import { InfoBox, TermsContainer } from '../SetYourApp.styles';

type Props = {
  type: string;
  setType: (type: string) => void;
  onSubmit: (values: CompanyFormData) => Promise<void>;
  onCancel: () => void;
  assayOnly?: boolean;
};

const TermsForm = ({ onSubmit, type, setType, onCancel, assayOnly }: Props): React.ReactElement => {
  const intlLocal = useTypedIntlLocal();
  const intlCompany = useTypedIntlCompany();

  const validationSchema = useMemo(() => companyFormSchema(intlCompany), [intlCompany]);

  const formikContext = useFormik<CompanyFormData>({
    initialValues: {
      terms: {},
    },
    validationSchema: type === '0' ? validationSchema : null,
    onSubmit: rawValues =>
      onSubmit?.(validationSchema.cast(rawValues, { stripUnknown: true })).catch(() =>
        formikContext.setSubmitting(false),
      ),
  });

  const { handleSubmit, touched, isValidating, isSubmitting, isValid } = formikContext;

  const isFormInvalid = !(isValid && Object.keys(touched).length) || isValidating || isSubmitting;

  return (
    <TermsContainer>
      <FormWrapper
        header={intlLocal.formatMessage({
          id: 'SetYourApp.TermsForm.Header',
        })}
        submitLabel={intlLocal.formatMessage({
          id: 'SetYourApp.TermsForm.Save',
        })}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        submitDisabled={type === '0' ? isFormInvalid : false}
        context={formikContext}
      >
        {assayOnly ? (
          <InfoBox>
            <Icon name="Info" />
            {intlLocal.formatMessage({ id: 'SetYourApp.TermsForm.FillOut' })}
          </InfoBox>
        ) : (
          <TypeSection
            type={type}
            setType={setType}
            label={intlLocal.formatMessage({ id: 'SetYourApp.TermsSelectedStep.Header' })}
            extraInformation
          />
        )}
        {(type === '0' || assayOnly) && [
          <RatesAndChargesSection
            key="ratesAndCharges"
            formikContext={formikContext}
            label={intlCompany.formatMessage({ id: 'CompanyForm.Section.RatesAndCharges' })}
          />,
          <OtherChargesSection
            key="otherCharges"
            formikContext={formikContext}
            label={intlCompany.formatMessage({ id: 'CompanyForm.Section.OtherCharges' })}
          />,
          <MiscellaneousFeesSection
            key="miscellaneousFeesSection"
            formikContext={formikContext}
            label={intlCompany.formatMessage({
              id: 'CompanyForm.Section.MiscellaneousFees',
            })}
          />,
        ]}
      </FormWrapper>
    </TermsContainer>
  );
};

export { TermsForm };
