import React, { useCallback } from 'react';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { LoadableContent } from 'components/shared/Loader';
import { ModalFormType } from 'shared/constants';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useCreateHedge } from 'shared/mutations';
import { isAlphamartHttpError } from 'shared/types';
import { HedgeFormSchema } from 'shared/types/hedgeFormSchema ';
import { showModalForm } from 'store/modalFormSlice';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { HedgeForm } from '../HedgeForm/HedgeForm';
import { messages, useTypedIntl } from '../locale/messages';
import { MarketPriceRangeModal } from '../MarketPriceRangeModal/MarketPriceRangeModal';

const CreateHedgeComponent = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();
  const { mutateAsync: createHedge, isLoading: isCreateHedgeLoading } = useCreateHedge();

  const onSubmit = useCallback(
    async (values: HedgeFormSchema) => {
      try {
        await createHedge(values);
        dispatch(
          snackBarPushSuccess(
            intl.formatMessage({
              id: `HedgeForm.Create.Success${values.assignedUsers?.length ? 'WithUsers' : ''}`,
            }),
          ),
        );
        navigate('/hedges');
      } catch (e) {
        if (
          isAlphamartHttpError(e) &&
          e.response?.data?.errorData?.constraints.includes('IsPriceInMarketRangeConstraint')
        ) {
          dispatch(showModalForm({ modalType: ModalFormType.MarketPriceRange, params: null }));
        } else {
          dispatch(
            snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
          );
        }
      }
    },
    [dispatch],
  );

  return (
    <>
      <LoadableContent drawContent mode={LoadableContent.MODE.FULL} loading={isCreateHedgeLoading}>
        <FormContainer>
          <HedgeForm onSubmit={onSubmit} />
        </FormContainer>
      </LoadableContent>
      <MarketPriceRangeModal />
    </>
  );
};

export const CreateHedge = withAlphamartIntlProvider(CreateHedgeComponent, messages);
