import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from '@emotion/styled';

import { useWindowWidth } from 'shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';
import { GroupItem } from './GroupItem';
import { GroupColumn, Item } from '../DroppableGroup';

interface GroupItemProps {
  mainColor?: string;
  className?: string;
  columns: GroupColumn[];
  isOpen: boolean;
  itemType: string;
  items: Item[];
}
const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  margin-left: 64px;
  margin-bottom: 8px;
  font-size: 12px;
  color: ${theme.fontColor};

  @media ${MEDIA_QUERY.MAX_MD} {
    grid-template-columns: repeat(3, 1fr);
  }

  p {
    padding: 0 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const GroupItemsLayout = ({
  className,
  items,
  columns,
  mainColor,
  isOpen,
  itemType,
}: GroupItemProps): React.ReactElement => {
  const windowWidth = useWindowWidth();
  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition in={isOpen} timeout={300} classNames="menu" nodeRef={nodeRef}>
      <div className={className} ref={nodeRef}>
        <Header>
          {columns.map(c => c.mediaQuery < windowWidth && <p key={c.id}>{c.name}</p>)}
        </Header>
        {items.map((item, index) => (
          <GroupItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            mainColor={mainColor}
            item={item}
            columns={columns}
            itemType={itemType}
          />
        ))}
      </div>
    </CSSTransition>
  );
};

const GroupItems = styled(GroupItemsLayout)`
  position: relative;
  margin-bottom: 32px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  transform-origin: top;

  ::before {
    content: '';
    position: absolute;
    left: 32px;
    top: -16px;
    width: 1px;
    height: 16px;
    border-left: 1px dashed ${({ mainColor }) => mainColor || theme.colors.mineShaftLighter};
  }

  &.menu-enter {
    transform: scale(1, 0);
  }
  &.menu-enter-active {
    transform: scale(1, 1);
    transition: transform 300ms;
  }
  &.menu-exit {
    transform: scale(1, 1);
  }
  &.menu-exit-active {
    transform: scale(1, 0);
    transition: transform 300ms;
  }
`;

export { GroupItems };
