export enum UserTypes {
  SUPER_ADMIN = 'SUPER_ADMIN',
  INTERNAL_COMPANY_MANAGER = 'INTERNAL_COMPANY_MANAGER',
  MANAGER = 'MANAGER',
  TECH = 'TECH',
  PRIMARY_CONTACT = 'PRIMARY_CONTACT',
  BUYER = 'BUYER',
  REGULAR_USER = 'REGULAR_USER',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
}
