import React from 'react';
import { DataOutput, OutputCell } from '@alpha-recycling/component-library';

import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { UserLoginLog } from 'shared/types';
import { useTypedIntl } from '../locale/messages';

interface Props {
  userLoginLog: UserLoginLog;
}

export function UserDetailsLastLogin({ userLoginLog }: Props): React.ReactElement {
  const intl = useTypedIntl();

  return (
    userLoginLog && (
      <DataOutput
        headerText={intl.formatMessage({
          id: 'UserDetails.LastLogin',
        })}
      >
        {[
          {
            name: 'date',
            label: intl.formatMessage({
              id: 'UserDetails.LastLogin.Date',
            }),
            value: formatDateWithTime(userLoginLog.createdAt),
          },
        ].map(field => (
          <OutputCell key={field.name} data-cy={field.name} mergeCells={4} labelValue={field.label}>
            {field.value}
          </OutputCell>
        ))}
      </DataOutput>
    )
  );
}
