import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  isVisible: boolean;
  scrollToId?: string;
}
const userProfileSlice = createSlice<
  State,
  {
    openProfile: CaseReducer<State, PayloadAction<{ scrollToId?: string } | undefined>>;
    closeProfile: CaseReducer<State>;
  }
>({
  name: 'userProfile',
  initialState: {
    isVisible: false,
    scrollToId: undefined,
  },
  reducers: {
    openProfile: (state, action: PayloadAction<{ scrollToId?: string } | undefined>) => ({
      isVisible: true,
      scrollToId: action.payload?.scrollToId,
    }),
    closeProfile: () => ({
      isVisible: false,
      scrollToId: undefined,
    }),
  },
});

export const { openProfile, closeProfile } = userProfileSlice.actions;

export default userProfileSlice.reducer;
