import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import { Terms } from 'shared/types/terms';
import { getHttpClient } from 'shared/utils/httpClient';

export enum CompaniesKeys {
  SetGradingAndTerms = 'SetGradingAndTerms',
}

export const useSetGradingAndTerms = (
  options?: UseMutationOptions<void, AlphamartHttpError, { grading: boolean; terms?: Terms }>,
) =>
  useMutation(
    async params => {
      await getHttpClient().put<void>(`${apiHostname}/api/companies/grading`, params);
    },
    {
      mutationKey: [CompaniesKeys.SetGradingAndTerms],
      ...options,
    },
  );
