import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { LoadableContent } from 'components/shared/Loader';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useGetCompanyDetails } from 'shared/queries';
import { CompanyFormData } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateCompany } from 'store/updateCompanySlice';
import { CompanyForm } from '../CompanyForm/CompanyForm';
import { messages, useTypedIntl } from '../locale/messages';

function UpdateCompanyComponent(): React.ReactElement {
  const companyId = Number(useParams<{ id: string }>().id);
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();
  const { data: company, isFetching } = useGetCompanyDetails(companyId);
  const initialValues: CompanyFormData | undefined = company && {
    city: company.city,
    country: company.country.id,
    name: company.name,
    note: company.note || '',
    state: company.state || '',
    street: company.street,
    terms: {
      treatmentChargePerPound: company.terms.treatmentChargePerPound,
      metalsReturnTermInDays: company.terms.metalsReturnTermInDays,
      platinumReturnRate: company.terms.platinumReturnRate,
      palladiumReturnRate: company.terms.palladiumReturnRate,
      rhodiumReturnRate: company.terms.rhodiumReturnRate,
      platinumReturnFinanceRate: company.terms.platinumReturnFinanceRate,
      palladiumReturnFinanceRate: company.terms.palladiumReturnFinanceRate,
      rhodiumReturnFinanceRate: company.terms.rhodiumReturnFinanceRate,
      platinumRefiningCharge: company.terms.platinumRefiningCharge,
      palladiumRefiningCharge: company.terms.palladiumRefiningCharge,
      rhodiumRefiningCharge: company.terms.rhodiumRefiningCharge,
      miscellaneousFees: company.terms.miscellaneousFees,
    },
    type: company.type,
    zipCode: company.zipCode || '',
  };

  const handleSuccess = (): void => {
    dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'CompanyForm.Message.UpdateSuccess' })));
    navigate(`/companies/${companyId}`);
  };

  const handleSubmit = useCallback(
    async values => {
      try {
        await dispatch(
          updateCompany(values, companyId, {
            grading: !!company?.grading,
          }),
        );
        handleSuccess();
      } catch (e) {
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
        );
      }
    },
    [dispatch, updateCompany, company],
  );

  return (
    <FormContainer>
      <LoadableContent loading={isFetching} mode={LoadableContent.MODE.MODAL}>
        {company && (
          <CompanyForm
            onSubmit={handleSubmit}
            initialValues={initialValues}
            grading={!!company?.grading}
          />
        )}
      </LoadableContent>
    </FormContainer>
  );
}

export const UpdateCompany = withAlphamartIntlProvider(UpdateCompanyComponent, messages);
