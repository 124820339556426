import React, { useCallback, useRef } from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { Details } from 'components/shared/Details';
import { LoadableContent } from 'components/shared/Loader';
import { NotificationsBell } from 'components/shared/NotificationsBell/NotificationsBell';
import { useClickAway } from 'shared/hooks';
import { clearNotificationDetails } from 'store/notificationDetails';
import { closeNotifications } from 'store/notificationsList';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { layers } from 'theme';
import { messages, TypedFormattedMessage } from './locale/messages';
import { NotificationDetails } from './NotificationDetails';
import { NotificationsFilters } from './NotificationsFilters';
import { NotificationsList } from './NotificationsList';

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  word-break: keep-all;
`;

export function Notifications(): React.ReactElement {
  const dispatch = useAppDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const isOpen = useAppSelector(state => state.notificationsList.isOpen);
  const isLoadingDetails = useAppSelector(state => state.notificationDetails.isPending);

  const close = useCallback(() => {
    dispatch(closeNotifications());
    return true;
  }, [dispatch]);

  function handleClickOutside() {
    if (isOpen) {
      dispatch(closeNotifications());
      dispatch(clearNotificationDetails());
    }
  }

  useClickAway(wrapperRef, handleClickOutside, ['click']);

  const notificationModalStyle = {
    overlay: {
      backgroundColor: 'transparent',
      zIndex: layers.notificationModal,
    },
    content: {
      backgroundColor: 'transparent',
      border: 'none',
    },
  };

  return (
    <AlphamartIntlProvider messages={messages}>
      <ReactModal
        isOpen={isOpen}
        style={notificationModalStyle}
        parentSelector={() => document.querySelector('#root') as HTMLElement}
      >
        <Details
          ref={wrapperRef}
          title={
            <Header>
              <NotificationsBell />
              <TypedFormattedMessage id="Notifications.Header" />
            </Header>
          }
          open
          onClose={close}
          options={{
            direction: 'right',
            width: '300px',
            header: { padding: 'M' },
            removePadding: true,
          }}
        >
          <NotificationsFilters />
          <NotificationsList />
          <LoadableContent loading={isLoadingDetails}>
            <NotificationDetails />
          </LoadableContent>
        </Details>
      </ReactModal>
    </AlphamartIntlProvider>
  );
}
