import styled from '@emotion/styled';

import { theme } from 'theme';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ListItem = styled.div`
  span {
    cursor: inherit;
    margin-left: auto;
    opacity: 0.5;
    transition: opacity 0.2s ease;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }

  &:focus,
  &:active {
    cursor: grabbing;
  }
`;

const ListItemContent = styled.div`
  word-break: break-word;
  padding-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const VirtualizedRowWrapper = styled.div<{
  isDragging?: boolean;
}>`
  opacity: ${({ isDragging }) => (isDragging ? 0.6 : 1)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 24px 32px;
  border-radius: 4px;

  font-size: 12px;
  font-weight: 700;
  user-select: none;

  background-color: ${theme.colors.mineShaft};
  color: ${theme.fontColor};
  cursor: grab;
`;

export { ListContainer, ListItem, ListItemContent, VirtualizedRowWrapper };
