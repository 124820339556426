import React, { useCallback, useEffect } from 'react';
import { Icon, OptionsProps, Select } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { isTruthy } from 'helpers/isTruthy/isTruthy';
import { LIST_MODE } from 'shared/constants';
import { useMediaQuery } from 'shared/hooks';
import { changeColumnsQuantityAction, changeListModeAction } from 'store/listComponentSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, Theme } from 'theme';
import { FieldSwitcherRaw } from '../Fields/FieldSwitcher/FieldSwitcher';

interface Props {
  className?: string;
  onChange?: () => void;
}

const ListModeButtonLayout = ({ className, onChange }: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { columnsQuantity, listMode } = useAppSelector(state => state.listComponent);

  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const isTablet = useMediaQuery(MEDIA_QUERY.MAX_MD);

  useEffect(() => {
    if (isPhone && columnsQuantity > 2) dispatch(changeColumnsQuantityAction(2));
    else if (isTablet && columnsQuantity > 3) dispatch(changeColumnsQuantityAction(3));
  }, [isPhone, isTablet]);

  const listModes = [
    {
      label: <Icon name="ThreeLines" size="small" />,
      value: LIST_MODE.TABLE,
    },
    {
      label: <Icon name="GridThreeColumns" size="small" />,
      value: LIST_MODE.GRID,
    },
  ];

  const changeListMode = useCallback(
    value => {
      dispatch(changeListModeAction(value));
      if (onChange) onChange();
    },
    [dispatch],
  );

  const gridColumnOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    !isPhone && { label: '3', value: 3 },
    !isTablet && { label: '4', value: 4 },
  ].filter(isTruthy);

  const selected = gridColumnOptions.find(p => p && p.value === columnsQuantity);

  const onSelectChange = (newValue: OptionsProps) => {
    newValue?.value && dispatch(changeColumnsQuantityAction(+newValue.value));
  };

  return (
    <div className={className}>
      <FieldSwitcherRaw
        value={listMode}
        name="currentListMode"
        onChange={changeListMode}
        options={listModes}
        dataCy="current-list-mode"
      />
      {listMode === LIST_MODE.GRID && selected && (
        <Select
          options={gridColumnOptions}
          value={selected}
          onFocus={e => {
            e.stopPropagation();
          }}
          required
          onChange={onSelectChange}
        />
      )}
    </div>
  );
};

export const ListModeButton = styled(ListModeButtonLayout)<{ theme?: Theme }>`
  display: flex;
  gap: 10px;
  height: 48px;
  width: 200px;

  & > div:nth-child(2) {
    width: 90px;
  }
`;
