import * as yup from 'yup';

import { SettingMessages, TypedIntlShape } from '../locale/messages';

interface LimitTypes {
  fileSizeLimit: number;
  imageExtensions: string[];
}

interface UploadLimitParams {
  converterPhoto: LimitTypes;
  vehiclePhoto: LimitTypes;
  watermark: LimitTypes;
}

export const designFormSchema = (
  uploadLimits: UploadLimitParams,
  intl: TypedIntlShape,
): yup.AnyObjectSchema => {
  const singleFileSchema = (fieldName: string, limits: LimitTypes) =>
    yup
      .array()
      .max(
        1,
        intl.formatMessage({
          id: `Settings.Display.Errors.${fieldName}.TooLong` as keyof SettingMessages,
        }),
      )
      .test(
        'fileExtension',
        f =>
          intl.formatMessage(
            {
              id: `Settings.Display.Errors.${fieldName}.InvalidExtension` as keyof SettingMessages,
            },
            {
              fileName: f.value.find(
                ({ name }) => !new RegExp(`${limits?.imageExtensions?.join('|')}$`, 'i').test(name),
              )?.name,
            },
          ),
        files =>
          !!files &&
          (files.length === 0 ||
            files.every(file =>
              new RegExp(`${limits?.imageExtensions?.join('|')}$`, 'i').test(file.name),
            )),
      )
      .test(
        'fileSize',
        f =>
          intl.formatMessage(
            { id: `Settings.Display.Errors.${fieldName}.FileSize` as keyof SettingMessages },
            {
              fileName: f.value.find(({ size }) => size > limits?.fileSizeLimit)?.name,
            },
          ),
        files =>
          !!files &&
          (files.length === 0 || files.every(file => file.size <= limits?.fileSizeLimit)),
      );

  return yup.object().shape({
    watermarkLogo: singleFileSchema('WatermarkLogo', uploadLimits?.watermark),
  });
};
