import React from 'react';
import styled from '@emotion/styled';

import { layers, MEDIA_QUERY, Theme } from 'theme';

const ErrorMessageWrapper = styled.div<{
  standalone?: boolean;
  show?: boolean;
  minHeight?: number;
}>`
  display: flex;
  min-height: ${({ minHeight }) => minHeight ?? 26}px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  z-index: ${layers.fieldInputError};
  ${({ standalone }) =>
    standalone
      ? `
      margin: 0 0 8px 0;
    `
      : `
      @media ${MEDIA_QUERY.MAX_XL} {
        justify-content: flex-end;
      }
    `}

  @media ${MEDIA_QUERY.MAX_MD} {
    justify-self: end;
  }
`;

const ErrorMessageContent = styled.div<{ theme?: Theme }>`
  display: inline-block;
  justify-self: left;
  align-self: center;
  min-width: 30px;
  padding: 2px 15px 3px;
  font-size: 10px;
  background-color: ${({ theme }) => theme.colors.fuzzyWuzzyBrown};
  color: white;
  border-radius: 4px;
  transition: opacity 50ms ease-out;
  bottom: 4px;

  @media ${MEDIA_QUERY.XL} {
    position: relative;

    ::before {
      content: '';
      display: inline-block;
      box-sizing: border-box;
      position: absolute;
      bottom: -4px;
      height: 6px;
      width: 20px;
      left: 32px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.fuzzyWuzzyBrown} transparent transparent
        transparent;
      border-width: 7px 10px 0 10px;
    }
  }
`;

interface Props {
  children: React.ReactNode;
  show?: boolean;
  label?: React.ReactNode;
  standalone?: boolean;
  minHeight?: number;
}

export const ErrorMessage = React.memo<Props>(
  ({ label, children, show, standalone = false, minHeight }): React.ReactElement => (
    <ErrorMessageWrapper standalone={standalone} show={!!children && show} minHeight={minHeight}>
      <ErrorMessageContent>
        {Array.isArray(children)
          ? children.map((error, index) => <div>{`${label} (${index + 1}) ${error}`}</div>)
          : children}
      </ErrorMessageContent>
    </ErrorMessageWrapper>
  ),
);
