import React, { useState } from 'react';

import { DetailsSection } from 'components/shared/Details';
import { FieldSwitcherRaw } from 'components/shared/Fields/FieldSwitcher/FieldSwitcher';
import { LoadableContent } from 'components/shared/Loader';
import { SUPPORTED_PORTAL_CURRENCIES } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';
import { fetchUser } from 'store/auth';
import { changeUserCurrency } from 'store/changeUserCurrencySlice';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { useTypedIntl } from './locale/messages';

export function UserProfileChangeCurrency(): React.ReactElement {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const [isPending, setIsPending] = useState(false);
  const handleCurrencyChange = async value => {
    const successMessage = intl.formatMessage({ id: 'UserProfile.ChangeCurrency.Success' });
    const failureMessage = intl.formatMessage({ id: 'UserProfile.ChangeCurrency.Error' });
    try {
      setIsPending(true);
      await dispatch(changeUserCurrency(value));
      await dispatch(fetchUser(true));
      dispatch(snackBarPushSuccess(successMessage));
    } catch {
      dispatch(snackBarPushFailure(failureMessage));
    } finally {
      setIsPending(false);
    }
  };
  const currencyOptions = Object.values(SUPPORTED_PORTAL_CURRENCIES).map(value => ({
    label: value,
    value,
  }));

  return (
    <>
      <LoadableContent loading={isPending} mode={LoadableContent.MODE.FULL} />
      <DetailsSection
        title={intl.formatMessage({ id: 'UserProfile.Currency' })}
        template={['changeCurrency changeCurrency changeCurrency']}
        fields={[
          {
            name: 'changeCurrency',
            label: intl.formatMessage({ id: 'UserProfile.ChangeCurrency' }),
            value: (
              <FieldSwitcherRaw
                value={currentUser.currency!}
                name="currency"
                onChange={handleCurrencyChange}
                dataCy="user-profile-change-currency-radio"
                disabled={isPending}
                options={currencyOptions}
              />
            ),
          },
        ]}
      />
    </>
  );
}
