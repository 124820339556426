import React from 'react';
import { DataOutput, OutputCell, Status } from '@alpha-recycling/component-library';

import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import {
  METAL_ABBR,
  PERMISSIONS,
  PriceSources,
  SUPPORTED_PORTAL_CURRENCIES,
} from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import { User } from 'shared/types';
import { useTypedIntl } from './locale/messages';
import { UserProfilePriceSourceActions } from './UserProfilePriceSourceActions';

const FormatMetalSource = ({
  source,
  price,
  displayPrice,
  currentUser,
}: {
  source: PriceSources;
  price?: number;
  displayPrice: boolean;
  currentUser: User;
}): React.ReactElement => {
  const intl = useTypedIntl();
  const sourceText = intl.formatMessage({ id: `Global.PriceSource.${source ?? 'MARKET'}` });
  const priceText = formatCurrency(price, SUPPORTED_PORTAL_CURRENCIES.USD, 2, currentUser.language);

  return displayPrice && source === 'HEDGE' ? (
    <>
      <p>{sourceText}</p>
      <Status type="inactive" value={priceText} size="small" discrete />
    </>
  ) : (
    <span>{sourceText}</span>
  );
};

export function UserProfilePriceSources(): React.ReactElement | null {
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();
  const authorize = useAuthorization();
  const canSeePrices = authorize(PERMISSIONS.USER_PROFILE.SHOW_PRICE_SOURCE);
  const { prices } = currentUser;
  const displayPrices = authorize(PERMISSIONS.HEDGES.SHOW_HEDGE_PRICES);

  if (!canSeePrices) return null;
  return (
    <DataOutput
      headerText={intl.formatMessage({
        id: 'UserProfile.MetalPriceSource',
      })}
    >
      {[
        {
          name: 'platinum',
          label: intl.formatMessage({ id: 'UserProfile.PlatinumPriceSource' }),
          value: (
            <FormatMetalSource
              source={prices?.ptPriceSource}
              price={prices?.ptHedgeUsed?.ptPriceCust}
              displayPrice={displayPrices}
              currentUser={currentUser}
            />
          ),
        },
        {
          name: 'platinumChangeSource',
          label: '',
          value: <UserProfilePriceSourceActions user={currentUser} metalAbbr={METAL_ABBR.PT} />,
        },
        {
          name: 'palladium',
          label: intl.formatMessage({ id: 'UserProfile.PalladiumPriceSource' }),
          value: (
            <FormatMetalSource
              source={prices?.pdPriceSource}
              price={prices?.pdHedgeUsed?.pdPriceCust}
              displayPrice={displayPrices}
              currentUser={currentUser}
            />
          ),
        },
        {
          name: 'palladiumChangeSource',
          label: '',
          value: <UserProfilePriceSourceActions user={currentUser} metalAbbr={METAL_ABBR.PD} />,
        },
        {
          name: 'rhodium',
          label: intl.formatMessage({ id: 'UserProfile.RhodiumPriceSource' }),
          value: (
            <FormatMetalSource
              source={prices?.rhPriceSource}
              price={prices?.rhHedgeUsed?.rhPriceCust}
              displayPrice={displayPrices}
              currentUser={currentUser}
            />
          ),
        },
        {
          name: 'rhodiumChangeSource',
          label: '',
          value: <UserProfilePriceSourceActions user={currentUser} metalAbbr={METAL_ABBR.RH} />,
        },
      ].map(field => (
        <OutputCell key={field.name} data-cy={field.name} mergeCells={2} labelValue={field.label}>
          {field.value}
        </OutputCell>
      ))}
    </DataOutput>
  );
}
