import { useEffect } from 'react';

import { useAuth } from 'shared/hooks';
import { useAppSelector } from 'store/shared/hooks';

export function useAutoLogin() {
  const isConfigLoading = useAppSelector(state => state.config.isPending);
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth();

  useEffect(() => {
    if (isConfigLoading || isLoading || isAuthenticated) return;
    loginWithRedirect();
  }, [isConfigLoading, isLoading, isAuthenticated, loginWithRedirect]);
}
