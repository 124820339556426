import React, { CSSProperties } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { Icon } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { DndTypes, lambdaHostname } from 'shared/constants';
import { useMediaQuery } from 'shared/hooks';
import { Converter } from 'shared/types';
import { MEDIA_QUERY } from 'theme';
import { DraggableRowContainer } from '../DraggableRow/DraggableRow';
import { ListImage } from '../List';
import { PhotoPlaceholder } from '../PhotoPlaceholder';

const StyledIcon = styled.div`
  min-width: 32px;
  height: 32px;
`;

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
};

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
): { display: 'none' } | { transform: string } {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
  };
}

export const CustomDragLayer = (): React.ReactElement | null => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem<Converter>(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  const isDesktop = useMediaQuery(MEDIA_QUERY.LG);

  function renderItem(): React.ReactElement | null {
    switch (itemType) {
      case DndTypes.CONVERTERS_GROUPS: {
        if (isDesktop || !item) return null;
        return (
          <DraggableRowContainer>
            {item.files?.length ? (
              <ListImage
                alt="photo"
                src={`${lambdaHostname}${item.files[0].presignedUrls?.SMALL}`}
              />
            ) : (
              <PhotoPlaceholder />
            )}
            <p>{item.identifier}</p>
            <StyledIcon>
              <Icon name="ThreeLines" size="big" />
            </StyledIcon>
          </DraggableRowContainer>
        );
      }
      default:
        return null;
    }
  }

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem()}</div>
    </div>
  );
};
