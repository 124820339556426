import React from 'react';
import { ButtonProps, Form, FormRow } from '@alpha-recycling/component-library';
import { FormikContextType } from 'formik';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldTextArea } from 'components/shared/Fields/FieldTextArea/FieldTextArea';
import { CompanyFormData } from 'shared/types';
import { messages, useTypedIntl } from '../locale/messages';

interface Props {
  formikContext: FormikContextType<CompanyFormData>;
  label: string;
  grading?: boolean;
}

export const MiscellaneousFeesSectionComponent = ({
  formikContext,
  label,
  grading,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const { values, handleChange, handleBlur } = formikContext;

  return (
    <Form
      data-cy="miscellaneous-fees-section"
      headerText={label}
      formDescriptionMessage={intl.formatMessage({ id: 'TermsForm.MiscellaneousFees.Info' })}
      headerButton={false as unknown as ButtonProps}
    >
      <FormRow>
        <FieldTextArea
          id="terms-miscellaneous-fees"
          label={intl.formatMessage({ id: 'TermsForm.MiscellaneousFees' })}
          name="terms.miscellaneousFees"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.terms.miscellaneousFees}
          data-cy="terms-miscellaneousFees"
          maxLength={350}
          disabled={grading}
        />
      </FormRow>
    </Form>
  );
};

export const MiscellaneousFeesSection = withAlphamartIntlProvider(
  MiscellaneousFeesSectionComponent,
  messages,
);
