import { useCallback } from 'react';

import { useTypedIntl } from 'components/views/Users/locale/messages';
import { ModalFormType } from 'shared/constants';
import { User, UserListItem } from 'shared/types';
import { DownloadUserLogsForm, showModalForm, ThresholdsModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { unblockUser } from 'store/updateUserSlice';
import { useAlphamartNavigate } from './useAlphamartRouter';

type useUserActionsReturnType = {
  handleUnblock: (user: Pick<User, 'id'>, onUserUnblockSuccess?: () => void) => Promise<void>;
  handleUpdate: (user: Pick<User, 'id'>) => void;
  handleLocationsModalOpen: (params: DownloadUserLogsForm) => unknown;
  handleHedgeLogsModalOpen: (params: DownloadUserLogsForm) => unknown;
  handleConverterLogsModalOpen: (params: DownloadUserLogsForm) => unknown;
  handleThresholdsModalOpen: (params: ThresholdsModalForm) => unknown;
  handleAccessListModalOpen: (params: UserListItem | User) => unknown;
  handleShoppingCartActivityLogsModalOpen: (params: DownloadUserLogsForm) => unknown;
  isPending: boolean;
};

export function useUserActions(): useUserActionsReturnType {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const userDetailsState = useAppSelector(state => state.userDetails);
  const fileDownloadingState = useAppSelector(state => state.downloadFile);
  const userUpdateState = useAppSelector(state => state.updateUser);

  const isPending = !!(
    userDetailsState?.isPending ||
    fileDownloadingState.isPending ||
    userUpdateState.isPending
  );

  const handleUnblock = useCallback(
    async (user: Pick<User, 'id'>, onUserUnblockSuccess = () => {}) => {
      try {
        await dispatch(unblockUser(user.id));
        dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'Global.User.Unblock.Success' })));
        await onUserUnblockSuccess();
      } catch {
        dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.User.Unblock.Error' })));
      }
    },
    [dispatch],
  );

  const handleUpdate = (user: Pick<User, 'id'>) => navigate(`/users/${user.id}/edit`);

  const handleLocationsModalOpen = (params: DownloadUserLogsForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.UserLocations, params }));

  const handleShoppingCartActivityLogsModalOpen = (params: DownloadUserLogsForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.DownloadShoppingCartActivityLogs, params }));

  const handleHedgeLogsModalOpen = (params: DownloadUserLogsForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.UserHedgeLogs, params }));

  const handleConverterLogsModalOpen = (params: DownloadUserLogsForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.UserConverterLogs, params }));

  const handleThresholdsModalOpen = (params: ThresholdsModalForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.UsageLimit, params }));

  const handleAccessListModalOpen = (params: UserListItem | User) =>
    dispatch(showModalForm({ modalType: ModalFormType.AccessList, params }));

  return {
    handleUnblock,
    handleUpdate,
    handleLocationsModalOpen,
    handleHedgeLogsModalOpen,
    handleConverterLogsModalOpen,
    handleThresholdsModalOpen,
    handleAccessListModalOpen,
    handleShoppingCartActivityLogsModalOpen,
    isPending,
  };
}
