import React from 'react';
import { Button } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import dinoPhoto from 'assets/images/dino.png';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from 'locale/messages';
import { MEDIA_QUERY, theme } from 'theme';

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${theme.colors.mineShaftDarker};

  img {
    z-index: 1;
    margin-top: 7%;
    max-width: 30vw;
    max-height: 22vw;
  }

  h1,
  h2 {
    text-transform: uppercase;
    margin-bottom: 0;
    text-align: center;
  }

  h1 {
    color: ${theme.mainColor};
    font-size: 64px;
  }

  h2,
  h4 {
    color: ${theme.colors.mineShaft};
  }

  button {
    margin-bottom: 16px;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    img {
      margin-top: 24vw;
      max-width: 100vw;
      max-height: 75vw;
    }
  }

  @media ${MEDIA_QUERY.MOBILE_LANDSCAPE} {
    img {
      max-height: 75vh;
    }
  }
`;

const Moon = styled.div`
  position: absolute;
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  transform: translate3d(75px, 25px, 0);
  background-color: #d0d5d2;
  box-shadow: 5px 0 10px 3px rgba(229, 229, 229, 0.3), 0 0 10px 2px rgba(229, 229, 229, 0.3),
    5px 0 10px 3px rgba(229, 229, 229, 0.3), 0px 0 3px 2px rgba(229, 229, 229, 0.3);

  &::after {
    content: '';
    background-color: rgba(162, 162, 162, 0.81);
    display: block;
    position: absolute;
    left: 80px;
    top: 50px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 3px 0 0 1px rgba(98, 98, 98, 0.51), 7px 45px 0 -5px rgba(162, 162, 162, 0.9),
      9px 45px 0 -5px rgba(100, 100, 100, 0.51), 35px 20px 0 -10px rgba(162, 162, 162, 0.9),
      37px 20px 0 -10px rgba(100, 100, 100, 0.51);
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    width: 60vw;
    height: 60vw;
    transform: translate3d(40px, 25px, 0);

    &::after {
      left: 10%;
      top: 25%;
    }
  }
`;

function NoConnection(): React.ReactElement {
  const intl = useTypedIntl();
  return (
    <Container>
      <Moon />
      <img src={dinoPhoto} alt="No connection" />
      <h1>
        <FormattedMessage id="Global.Whoops" />
      </h1>
      <h2>
        <FormattedMessage id="Global.SlowOrNoInternet" />
      </h2>
      <h4>
        <FormattedMessage id="Global.CheckInternetSettings" />
      </h4>
      <Button
        variant="filled"
        onClick={() => window.location.reload()}
        content="text"
        label={intl.formatMessage({
          id: 'Global.TryAgain',
        })}
      />
    </Container>
  );
}

export { NoConnection };
