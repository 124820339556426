import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export function getExternalLink(url: string) {
  return (children: ReactElement) => <a href={url}>{children}</a>;
}

export function getInternalLink(to: string) {
  return (children: ReactElement) => <Link to={to}>{children}</Link>;
}
