import { css } from '@emotion/css';

import { MEDIA_QUERY, Theme } from 'theme';

const FieldPhotosGridStyle = (single = false): string => css`
  ${single ? 'display: block' : 'display: grid'};
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 32px;

  @media ${MEDIA_QUERY.MAX_MD} {
    grid-template-columns: 1fr 1fr;
  }

  ${MEDIA_QUERY.MAX_SM} {
    grid-template-columns: 1fr;
  }
`;

const FieldPhotosPhotoContainerStyle = (
  theme?: Theme,
  large?: boolean,
  dragging?: boolean,
): string => css`
  height: ${large ? '210px' : '100px'};
  background: ${theme?.colors.mineShaftLighter};
  border-radius: 4px;
  display: flex;

  &:hover {
    cursor: ${dragging ? 'grabbing' : 'grab'};
  }
`;

const FieldPhotosPhotoStyle = (large?: boolean): string => css`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${large ? '10px' : '20px'};

  img {
    max-height: ${large ? '180px' : '60px'};
    width: auto;
    max-width: ${large ? '220px' : '86px'};
    height: auto;
  }
`;

const FieldPhotosPhotoRemoveButtonStyle = (theme?: Theme): string => css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: ${theme?.fontColor};
  width: 32px;
  border-left: 1px solid ${theme?.colors.mineShaft};
  cursor: pointer;
  outline: none;

  span {
    pointer-events: none;
    align-self: center;
  }

  &:hover {
    span {
      background-color: ${theme?.colors.white};
    }
  }
`;

const FieldPhotosAddButtonStyle = (theme?: Theme, available?: boolean): string => css`
  height: 100px;
  border: 1px dashed ${theme?.colors.mineShaftLighter};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 15px;
  color: ${theme?.fontColor};
  font-weight: 700;
  background: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  position: relative;
  width: 100%;

  ${available &&
  `
    border-color: ${theme?.colors.statuses.active};
    color: ${theme?.colors.statuses.active};
  `}
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }
`;

export {
  FieldPhotosAddButtonStyle,
  FieldPhotosGridStyle,
  FieldPhotosPhotoContainerStyle,
  FieldPhotosPhotoRemoveButtonStyle,
  FieldPhotosPhotoStyle,
};
