import React, { useState } from 'react';
import { useFormik } from 'formik';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { PhotoShape } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { Controls } from 'components/shared/forms/Wizard/Controls/Controls';
import { FormWrapper } from 'components/shared/forms/Wizard/FormWrapper/FormWrapper';
import { Wizard } from 'components/shared/forms/Wizard/Wizard';
import { ErrorCode } from 'shared/constants';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { BooleanString } from 'shared/types';
import { createAssay } from 'store/createAssaySlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateAssay } from 'store/updateAssaySlice';
import { assayFormSchema } from './assayFormSchema';
import { AssayForm } from '../AssayForm/AssayForm';
import { ConverterForm } from '../ConverterForm/ConverterForm';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

export interface AssayFormShape {
  converter: {
    identifier: string;
    identifierConfirmation: string;
    partName: string;
    nicknames: string;
    otherNumbers: { value: string; id?: number }[];
    notes: string;
    photos: PhotoShape[];
    vehicle: {
      make: string;
      model: string;
      year: number | null;
    };
    type: number | null;
    folder: number | null;
    isPartial: BooleanString;
    counterfeit: boolean;
  };
  sampleName: string;
  sampleDate: string | Date;
  wetWeight: string;
  confirmWetWeight: string;
  moisture: string;
  confirmMoisture: string;
  carbon: string;
  platinum: string;
  confirmPlatinum: string;
  palladium: string;
  confirmPalladium: string;
  rhodium: string;
  confirmRhodium: string;
  notes: string;
}

interface Props {
  assayId?: number;
  converterId?: number;
  editMode?: boolean;
  initialValues?: AssayFormShape | null;
}

const CreateAssayComponent = ({
  initialValues,
  editMode,
  assayId,
  converterId,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const { models } = useAppSelector(state => state.models);
  const { makes } = useAppSelector(state => state.makes);
  const [activeInput, setActiveInput] = useState<string | null>(null);
  const { position } = useAppSelector(state => state.position);
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const { fileSizeLimit, imageExtensions } = useAppSelector(
    state => state.config.upload!.converterPhoto,
  );

  const validationSchema = assayFormSchema(fileSizeLimit, imageExtensions, models, makes, intl);
  const formikContext = useFormik<AssayFormShape>({
    initialValues: initialValues || {
      converter: {
        identifier: '',
        identifierConfirmation: '',
        partName: '',
        nicknames: '',
        otherNumbers: [],
        notes: '',
        photos: [],
        vehicle: {
          make: '',
          model: '',
          year: null,
        },
        type: null,
        folder: null,
        isPartial: 'false',
        counterfeit: false,
      },
      sampleName: 'CC-',
      sampleDate: new Date(),
      wetWeight: '',
      confirmWetWeight: '',
      moisture: '',
      confirmMoisture: '',
      carbon: '',
      platinum: '',
      confirmPlatinum: '',
      palladium: '',
      confirmPalladium: '',
      rhodium: '',
      confirmRhodium: '',
      notes: '',
    },
    validationSchema,
    onSubmit: async values => {
      formikContext.setSubmitting(true);

      const handleSuccess = (path, messageId) => {
        dispatch(snackBarPushSuccess(intl.formatMessage({ id: messageId })));
        navigate(path);
      };
      const handleError = e => {
        formikContext.setSubmitting(false);
        dispatch(
          snackBarPushFailure(
            intl.formatMessage({
              id:
                e?.response?.data?.errorCode === ErrorCode.ASSAY_NAME_ALREADY_EXIST
                  ? 'AssaysForm.Message.SampleNameExists'
                  : 'Global.Error.SomethingWentWrong',
            }),
          ),
        );

        console.error(e);
      };

      if (editMode && assayId && converterId) {
        try {
          await dispatch(
            updateAssay(validationSchema.cast(values), assayId, converterId, position),
          );
          return handleSuccess(`/assays/${assayId}`, 'AssaysForm.Message.UpdateSuccess');
        } catch (e) {
          handleError(e);
        }
      } else {
        try {
          await dispatch(createAssay(validationSchema.cast(values), position));
          return handleSuccess('/assays', 'AssaysForm.Message.Success');
        } catch (e) {
          handleError(e);
        }
      }
    },
  });

  const {
    values,
    handleChange,
    touched,
    isSubmitting,
    getFieldMeta,
    handleBlur,
    isValid,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    isValidating,
  } = formikContext;

  const getErrors = name => {
    const { touched: fieldTouched, error } = getFieldMeta(name);

    return fieldTouched && error;
  };

  const getArrayErrors = name => {
    if (
      values.converter.otherNumbers
        ?.reduce<string[]>((acc, item) => (item?.value ? [...acc, item.value] : acc), [])
        .filter((e, i, a) => a.indexOf(e) !== i).length
    ) {
      return <FormattedMessage id="ConverterForm.Errors.OtherNumbers.Unique" />;
    }
    const indexesNotToValidate =
      getFieldMeta(name)
        .value?.map((val, i) => val.value === '' && i)
        .filter(Boolean) ?? [];

    const getFieldMetaArray = ([] as ({ value: string } | undefined)[])
      .concat(getFieldMeta(name)?.error as unknown as { value: string })
      .filter((_, i) => !indexesNotToValidate.includes(i))
      .filter(Boolean)
      .shift()?.value;

    return getFieldMetaArray;
  };

  const getRawErrors = name => {
    const { error } = getFieldMeta(name);
    return error;
  };

  const onBlur = e => {
    setActiveInput(null);
    handleBlur(e);
  };

  const controlButtons = (
    <Controls
      stepsErrors={formikContext.errors.converter ? [formikContext.errors.converter] : []}
      isValid={isValid}
      touched={touched}
      isValidating={isValidating}
      isSubmitting={isSubmitting}
      editMode={editMode}
      lastPageButton={intl.formatMessage({
        id: editMode ? 'AssaysForm.UpdateAssay' : 'AssaysForm.AddAssay.Submit',
      })}
    />
  );

  return (
    <FormContainer>
      <Wizard>
        <FormWrapper pageIndex={1}>
          <ConverterForm
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            values={values}
            getErrors={getErrors}
            getArrayErrors={getArrayErrors}
            context={formikContext}
            onBlur={onBlur}
            setActiveInput={setActiveInput}
            activeInput={activeInput}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
            touched={touched}
            editMode={editMode}
            controlButtons={controlButtons}
          />
        </FormWrapper>
        <FormWrapper pageIndex={2}>
          <AssayForm
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            values={values}
            getErrors={getErrors}
            getRawErrors={getRawErrors}
            context={formikContext}
            setFieldValue={setFieldValue}
            onBlur={onBlur}
            setActiveInput={setActiveInput}
            activeInput={activeInput}
            editMode={editMode}
            controlButtons={controlButtons}
          />
        </FormWrapper>
      </Wizard>
    </FormContainer>
  );
};

export const CreateAssay = withAlphamartIntlProvider<Props>(CreateAssayComponent, messages);
