import React, { ReactElement, useEffect } from 'react';
import { ButtonProps, Form, FormRow, Label, Slider } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { FieldMultiText } from 'components/shared/Fields/FieldMultiText/FieldMultiText';
import { FieldSwitcher } from 'components/shared/Fields/FieldSwitcher/FieldSwitcher';
import { MarginVisibility, PERMISSIONS, SHARED } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import { useGetCompanies } from 'shared/queries';
import { Status, UserFormData } from 'shared/types';
import { useAppSelector } from 'store/shared/hooks';
import { Theme } from 'theme';
import { useTypedIntl } from '../locale/messages';

type Props = {
  handleChange: (e?: React.ChangeEvent) => void;
  getArrayErrors: (fieldName: string) => string | undefined;
  setFieldTouched: (fieldName: string) => void;
  setFieldValue: (field: string, value?: unknown, shouldValidate?: boolean) => void;
  handleBlur: (e?: React.FocusEvent) => void;
  formValues: UserFormData;
  isSubscribingCompanyType: boolean;
  label?: string;
};

type MetalSymbol = 'Pt' | 'Pd' | 'Rh';

export const getTotalProfitMargin = (
  metal: MetalSymbol,
  formValues: UserFormData,
  limit: number,
): number | SHARED.LONG_DASH => {
  const profitMargin = [...(formValues?.prices?.userProfitMargin ?? [])].sort(
    (a, b) => Number(b.value) - Number(a.value),
  )[0];

  const baseValue = Math.trunc(
    Number(formValues.prices[`currentProfitMargin${metal}`]) + Number(profitMargin?.value ?? 0),
  );

  if (Number.isNaN(baseValue)) {
    return SHARED.LONG_DASH;
  }

  return Math.min(baseValue, limit, 140);
};

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  flex-direction: row;
`;

const SliderContainer = styled.div`
  width: 100%;
`;

const SliderSummary = styled.div<{ theme?: Theme }>`
  min-width: 100px;
  font-size: 12px;
  color: ${({ theme }) => theme.fontColor};
  font-weight: 700;
  text-align: right;
  margin-bottom: 14px;
}
`;

export function ProfitMarginSection({
  handleChange,
  getArrayErrors,
  setFieldTouched,
  handleBlur,
  setFieldValue,
  formValues,
  isSubscribingCompanyType,
  label = '',
}: Props): React.ReactElement {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const allowedToFetchCompanies = authorize(PERMISSIONS.COMPANIES.LIST);
  const currentUser = useCurrentUser();
  const { single, slider } = useAppSelector(state => state.config.profitMarginLimit!);
  const { data: fetchedCompanies } = useGetCompanies(
    { status: Status.ACTIVE },
    { enabled: allowedToFetchCompanies },
  );
  const companies = allowedToFetchCompanies ? fetchedCompanies!.data : [currentUser.company];
  const selectedCompany = [...companies].find(c => c.id === formValues?.info?.company);
  const canUseMarginSliderPerMetal = !!(
    selectedCompany?.canUseProfitMarginPerMetal ?? currentUser.company?.canUseProfitMarginPerMetal
  );

  const getHandleCurrentProfitMarginChange =
    (metal: MetalSymbol) => (newMinValue: number, newMaxValue: number) =>
      setFieldValue(`prices.currentProfitMargin${metal}`, newMaxValue, false);

  const displaySingleProfitMargin =
    isSubscribingCompanyType ||
    formValues.prices.marginVisibility === MarginVisibility.SLIDER_MARGIN_ONLY ||
    formValues.prices.marginVisibility === MarginVisibility.SLIDER_AND_TOTAL_MARGIN;

  useEffect(() => {
    if (
      formValues?.prices?.marginVisibility !== MarginVisibility.NO_VISIBILITY &&
      formValues?.prices?.userProfitMargin?.length > 1
    ) {
      setFieldValue('prices.userProfitMargin', [formValues?.prices?.userProfitMargin?.[0] ?? 0]);
    }
  }, [formValues?.prices?.marginVisibility]);

  return (
    <Form headerText={label} headerButton={false as unknown as ButtonProps}>
      <FormRow>
        <FieldSwitcher
          id="prices-margin-visibility"
          label={intl.formatMessage({ id: 'UserForm.MarginVisibility' })}
          value={formValues.prices.marginVisibility!}
          name="prices.marginVisibility"
          dataCy="margin-visibility"
          options={Object.values(MarginVisibility).map(value => ({
            label: intl.formatMessage({ id: `UserForm.MarginVisibility.${value}` }),
            description: intl.formatMessage({
              id: `UserForm.MarginVisibility.${value}.Description`,
            }),
            value: value as string,
          }))}
        />
      </FormRow>
      <FormRow>
        <FieldMultiText
          minItems={1}
          maxItems={displaySingleProfitMargin ? 1 : 10}
          label={intl.formatMessage({ id: 'UserForm.UserProfitMargin' })}
          addLabel={intl.formatMessage({ id: 'UserForm.AddUserProfitMargin' })}
          name="prices.userProfitMargin"
          onChange={handleChange}
          onTouch={setFieldTouched}
          onBlur={handleBlur}
          value={(formValues.prices?.userProfitMargin as { value: string }[]) ?? []}
          error={getArrayErrors('prices.userProfitMargin')}
          prefix="%"
          data-cy="user-profit-margin"
          required
        />
      </FormRow>
      <FormRow data-cy="platinum-slider-row">
        <SliderWrapper>
          <SliderContainer>
            <Label
              value={
                canUseMarginSliderPerMetal
                  ? intl.formatMessage({ id: 'Global.Metals.Platinum' })
                  : intl.formatMessage({ id: 'UserForm.UserProfitMarginSlider' })
              }
              // TODO remove after fix lack of id in slider in NEWA-3031
              htmlFor="platinum-slider"
            >
              <Slider
                dataTestId="platinum-slider"
                min={0}
                max={slider}
                maxRangeValue={formValues.prices.currentProfitMarginPt}
                onChange={getHandleCurrentProfitMarginChange('Pt')}
              />
            </Label>
          </SliderContainer>
          <SliderSummary>
            {intl.formatMessage(
              {
                id: 'UserForm.TotalProfitMargin',
              },
              {
                total: getTotalProfitMargin('Pt', formValues, single + slider),
              },
            )}
          </SliderSummary>
        </SliderWrapper>
      </FormRow>
      {canUseMarginSliderPerMetal ? (
        <FormRow data-cy="palladium-slider-row">
          <SliderWrapper>
            <SliderContainer>
              <Label
                value={intl.formatMessage({ id: 'Global.Metals.Palladium' })}
                // TODO remove after fix lack of id in slider in NEWA-3031
                htmlFor="palladium-slider"
              >
                <Slider
                  dataTestId="palladium-slider"
                  min={0}
                  max={slider}
                  maxRangeValue={formValues.prices.currentProfitMarginPd}
                  onChange={getHandleCurrentProfitMarginChange('Pd')}
                />
              </Label>
            </SliderContainer>
            <SliderSummary>
              {intl.formatMessage(
                {
                  id: 'UserForm.TotalProfitMargin',
                },
                {
                  total: getTotalProfitMargin('Pd', formValues, single + slider),
                },
              )}
            </SliderSummary>
          </SliderWrapper>
        </FormRow>
      ) : (
        (null as unknown as ReactElement)
      )}
      {canUseMarginSliderPerMetal ? (
        <FormRow data-cy="rhodium-slider-row">
          <SliderWrapper>
            <SliderContainer>
              <Label
                value={intl.formatMessage({ id: 'Global.Metals.Rhodium' })}
                // TODO remove after fix lack of id in slider in NEWA-3031
                htmlFor="rhodium-slider"
              >
                <Slider
                  dataTestId="rhodium-slider"
                  min={0}
                  max={slider}
                  maxRangeValue={formValues.prices.currentProfitMarginRh}
                  onChange={getHandleCurrentProfitMarginChange('Rh')}
                />
              </Label>
            </SliderContainer>
            <SliderSummary>
              {intl.formatMessage(
                {
                  id: 'UserForm.TotalProfitMargin',
                },
                {
                  total: getTotalProfitMargin('Rh', formValues, single + slider),
                },
              )}
            </SliderSummary>
          </SliderWrapper>
        </FormRow>
      ) : (
        (null as unknown as ReactElement)
      )}
    </Form>
  );
}
