import React, { useContext } from 'react';
import { ProgressIndicator } from '@alpha-recycling/component-library';
import { FormikContextType } from 'formik';

import { WizardContext } from 'components/shared/forms/Wizard/Wizard';
import { useTypedIntl } from 'locale/messages';
import { FormContainer } from './FormContainer';

interface Props {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: FormikContextType<any>;
  controlButtons?: React.ReactNode;
  header: string;
}

export const FormInWizard = ({
  children: sections,
  controlButtons,
  context,
  header,
}: Props): React.ReactElement => {
  const { currentPage, pageIndexes } = useContext(WizardContext);
  const intl = useTypedIntl();

  return (
    <FormContainer
      context={context}
      header={header}
      controlSteps={
        <ProgressIndicator totalPages={pageIndexes.length} currentPage={currentPage}>
          {intl.formatMessage(
            { id: 'Global.Step' },
            { curr: currentPage, total: pageIndexes.length },
          )}
        </ProgressIndicator>
      }
      controlButtons={controlButtons}
    >
      {sections}
    </FormContainer>
  );
};
