import React from 'react';
import { Button, InfoBar, ModalPortal } from '@alpha-recycling/component-library';

import { useAlphamartNavigate } from 'shared/hooks';
import { fetchUser } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { TypedFormattedMessage, useTypedIntl } from '../../locale/messages';

export const GradingSelectedStep = () => {
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const handleCloseClick = async () => {
    await dispatch(fetchUser(true));
    navigate('/converters');
  };
  const config = useAppSelector(state => state.config.termsTypeDefaultMargin);

  return (
    <ModalPortal
      isOpen
      header={intl.formatMessage({ id: 'SetYourApp.GradingSelectedStep.Header' })}
      data-cy="set-app-grading-selected"
      rightButtons={[
        <Button
          onClick={handleCloseClick}
          content="text"
          variant="outline"
          data-cy="set-app-grading-selected-button"
          label={intl.formatMessage({ id: 'SetYourApp.GradingSelectedStep.Button' })}
        />,
      ]}
    >
      <InfoBar
        icon
        text={
          <>
            <TypedFormattedMessage
              id="SetYourApp.GradingSelectedStep.FirstPart"
              values={{ defaultMarginPercentage: config?.grading.selectedProfitMargin }}
            />
            <br />
            <br />
            <TypedFormattedMessage id="SetYourApp.GradingSelectedStep.SecondPart" />
          </>
        }
      />
    </ModalPortal>
  );
};
