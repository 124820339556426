import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { capitalize } from 'lodash';

import { FieldMultiSelect } from 'components/shared/Fields/FieldSelect/FieldMultiSelect';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FiltersForm } from 'components/shared/forms/FiltersForm/FiltersForm';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { getUserTypeLang } from 'helpers/userTypes/userTypes';
import {
  AccessFlag,
  DEFAULT_FILTERS,
  FilterableModules,
  METAL_ABBR,
  PERMISSIONS,
  PRICE_SOURCES,
  PriceSources,
  SHARED,
  UserTypes,
} from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useEffectAfterMount, useMediaQuery } from 'shared/hooks';
import { useGetCompanies } from 'shared/queries';
import { Status } from 'shared/types';
import { useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';

const getStatusOption = (status, intl) => ({
  value: status,
  label: capitalize(intl.formatMessage({ id: `Global.Status.${status}` })),
});

export interface UsersListFiltersShape {
  status?: Status | null;
  userTypes?: UserTypes[];
  company?: number | null;
  ptPriceSource?: PriceSources | null;
  pdPriceSource?: PriceSources | null;
  rhPriceSource?: PriceSources | null;
  accessFlags?: AccessFlag[];
  query?: string;
}

type Props = {
  onFiltersChanged: (filters: UsersListFiltersShape) => void;
  onFiltersApplied: () => void;
};

export const UsersFilters = ({ onFiltersChanged, onFiltersApplied }: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const auth = useAppSelector(state => state.auth);
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.USERS]);
  const formikContext = useFormik<UsersListFiltersShape>({
    initialValues: savedFilters.data,
    onSubmit: () => {},
  });
  const { setValues, values } = formikContext;
  const isSuperAdmin = auth.user?.data.userTypes.some(r => r.name === UserTypes.SUPER_ADMIN);
  const canFilterByCompany = authorize(PERMISSIONS.USERS.FILTER_BY_COMPANY);
  const companiesForFilters = useGetCompanies(
    { pageSize: Number(SHARED.SELECT_INPUT_PAGE_LIMIT), page: 1, query: searchPhrase },
    { enabled: canFilterByCompany },
  );
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  useEffectAfterMount(() => {
    !isMobile && onFiltersChanged(values);
  }, [values]);

  const onFiltersClear = () => {
    setValues(DEFAULT_FILTERS.users);
    onFiltersChanged(DEFAULT_FILTERS.users);
  };

  const onFiltersApply = () => {
    onFiltersChanged(values);
    onFiltersApplied();
  };

  const userTypeOptions = (
    authorize(PERMISSIONS.USERS.FILTER_ALL_USER_TYPES)
      ? Object.values(UserTypes).filter(
          userType => isSuperAdmin || userType !== UserTypes.SUPER_ADMIN,
        )
      : [UserTypes.MANAGER, UserTypes.REGULAR_USER, UserTypes.PRIMARY_CONTACT]
  ).map(userType => ({ value: userType, label: getUserTypeLang(userType, intl) }));

  const accessFlagsOptions = [
    AccessFlag.CONVERTER_VIEWER,
    AccessFlag.HEDGE_PRICE_VIEWER,
    AccessFlag.NON_STANDARD_CONVERTER_VIEWER,
    AccessFlag.NON_STANDARD_CONVERTER_EDITOR,
    AccessFlag.VEHICLE_BASIC_VIEWER,
    AccessFlag.VEHICLE_ADVANCED_VIEWER,
    AccessFlag.VEHICLE_CREATOR,
    AccessFlag.VEHICLE_EDITOR,
    AccessFlag.REPORT_BASIC_GENERATOR,
    AccessFlag.SHOPPING_CART_VIEWER,
  ].map(value => ({
    value,
    label: intl.formatMessage({ id: `UserForm.Access.Flag.${value}` }),
  }));

  const companyOptions = useMemo(
    () =>
      companiesForFilters.data?.data.map(company => ({ value: company.id, label: company.name })) ??
      [],
    [companiesForFilters.data?.data],
  );

  const statusOptions = useMemo(
    () => Object.values(Status).map(status => getStatusOption(status, intl)),
    [intl],
  );

  const priceSourceOptions = Object.values(PRICE_SOURCES).map(i => ({
    value: i,
    label: intl.formatMessage({ id: `UsersList.Filters.Use${i}` }),
  }));

  return (
    <FiltersForm
      context={formikContext}
      onFiltersClear={onFiltersClear}
      onFiltersApply={onFiltersApply}
      savedFilters={savedFilters}
    >
      {canFilterByCompany && (
        <FiltersSection label={intl.formatMessage({ id: 'UsersList.Filters.Section.Companies' })}>
          <FieldSelect
            name="company"
            searchable
            customSearchFunction={(options, text) => {
              setSearchPhrase(text);
              return options;
            }}
            options={companyOptions}
            label={intl.formatMessage({ id: 'UsersList.Filters.Company' })}
            value={values.company ?? ''}
            data-cy="filter-company"
          />
        </FiltersSection>
      )}
      <FiltersSection
        label={intl.formatMessage({ id: 'UsersList.Filters.Section.UserTypeStatus' })}
      >
        <FieldMultiSelect
          name="userTypes"
          options={userTypeOptions}
          label={intl.formatMessage({ id: 'UsersList.Filters.UserType' })}
          value={values.userTypes ?? []}
          data-cy="filter-user-type"
        />
        <FieldSelect
          name="status"
          options={statusOptions}
          label={intl.formatMessage({ id: 'UsersList.Filters.Status' })}
          value={values.status ?? ''}
          data-cy="filter-status"
        />
      </FiltersSection>
      <FiltersSection label={intl.formatMessage({ id: 'UsersList.Filters.Section.PriceSources' })}>
        {Object.values(METAL_ABBR).map(abbr => (
          <FieldSelect
            key={abbr}
            name={`${abbr}PriceSource`}
            options={priceSourceOptions}
            label={intl.formatMessage({ id: `UsersList.Filters.${abbr}PriceSource` })}
            value={values[`${abbr}PriceSource`] ?? ''}
            data-cy={`filter-${abbr}-price-source`}
          />
        ))}
      </FiltersSection>
      {authorize(PERMISSIONS.USERS.FILTER_BY_ACCESS_FLAGS) && (
        <FiltersSection label={intl.formatMessage({ id: 'UsersList.Filters.Section.AccessList' })}>
          <FieldMultiSelect
            name="accessFlags"
            options={accessFlagsOptions}
            label={intl.formatMessage({ id: 'UsersList.Filters.Section.AccessList.Select' })}
            value={values.accessFlags ?? []}
            data-cy="filter-access-flags"
          />
        </FiltersSection>
      )}
    </FiltersForm>
  );
};
