import React from 'react';
import { Button } from '@alpha-recycling/component-library';

import { ErrorCode } from 'shared/constants';
import { isAlphamartHttpError } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { withAlphamartIntlProvider } from '../../AlphamartIntlProvider';
import { Container, Content, Footer, Header, wrapperStyles } from '../Auth.styles';
import { messages, useTypedIntl } from '../locale/messages';

interface Props {
  error: ErrorCode;
  onAddDevice: () => Promise<void>;
}

export function AuthErrorComponent({ error, onAddDevice }: Props) {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const canAddDevice = error === ErrorCode.UNRECOGNIZED_DEVICE;

  const handleAddDevice = async () => {
    try {
      await onAddDevice();
    } catch (e) {
      if (!isAlphamartHttpError(e)) throw e;
      const limitReached = e.response?.data.errorCode === ErrorCode.MAXIMAL_REQUESTS_NUMBER_REACHED;
      dispatch(
        snackBarPushFailure(
          intl.formatMessage({
            id: limitReached
              ? 'Login.Error.MaximumNumberOfRequests'
              : 'Global.Error.SomethingWentWrong',
          }),
        ),
      );
    }
  };

  return (
    <Container data-cy="auth-error">
      <div className={wrapperStyles}>
        <Header>{intl.formatMessage({ id: 'Global.Error' })}</Header>
        <Content>
          {intl.formatMessage({
            id: `Login.Error.${canAddDevice ? 'UnrecognizedDevice' : 'Unauthorized'}`,
          })}
        </Content>
        {canAddDevice && (
          <Footer>
            <Button
              data-cy="button-add-device"
              onClick={handleAddDevice}
              type="button"
              label={intl.formatMessage({ id: 'Login.Button.AddDevice' })}
            />
          </Footer>
        )}
      </div>
    </Container>
  );
}

export const AuthError = withAlphamartIntlProvider(AuthErrorComponent, messages);
