import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { MEDIA_QUERY, Theme } from 'theme';

export const listPaginationItemStyles = (isActive?: boolean, theme?: Theme): string => css`
  border: none;
  background: none;
  width: 64px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  color: #b1b1b1;
  cursor: pointer;
  outline: none;

  ${isActive
    ? `
    color: ${theme?.mainColor};
  `
    : ``}
  &:hover {
    color: #ffffff;
    ${isActive
      ? `
      color: ${theme?.mainColor};
    `
      : ``}
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    display: none;
  }
`;

export const ListPaginationEllipsisStyled = styled.div<{ theme?: Theme }>`
  width: 64px;
  height: 48px;
  color: #b1b1b1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${MEDIA_QUERY.MAX_XL} {
    display: none;
  }
`;

export const listPaginationStyles = css`
  display: flex;
  grid-column: 1 / -1;
  margin: 32px 0 0 0;

  & > button:first-of-type {
    margin-left: auto;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    flex-wrap: wrap;

    #sort-list-select {
      order: -1;
      flex-basis: 100%;
      margin-bottom: 15px;
    }
  }
`;

export const listPaginationMobileStyles = css`
  font-size: 12px;
  align-items: flex-start;
  font-weight: 700;
  display: none;

  @media ${MEDIA_QUERY.MAX_XL} {
    display: flex;
  }
`;

export const ListPaginationMobileButtonsStyled = styled.button<{
  theme?: Theme;
  visible?: boolean;
}>`
  border: none;
  background: none;
  width: 42px;
  height: 48px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  color: #b1b1b1;
  cursor: pointer;
  outline: none;
  position: relative;

  &:hover {
    color: #ffffff;
  }

  ${({ visible }) => (visible ? '' : 'z-index: -1')}
`;

export const listPaginationMobileStateStyles = css`
  height: 48px;
  color: #b1b1b1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListPaginationWrapper = styled.div`
  display: flex;
  flex-grow: 2;
  justify-content: center;
  align-items: center;
`;
