import React, { useEffect, useRef, useState } from 'react';
import { Button, ModalPortal } from '@alpha-recycling/component-library';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldPhotos, PhotoEvent } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { ModalFormContent } from 'components/shared/forms/Form/ModalForm.styles';
import { LoadableContent, LoadableContentModes } from 'components/shared/Loader';
import { FilterableModules, ModalFormType } from 'shared/constants';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useVinImageRecognition } from 'shared/mutations/vinImageRecognition';
import { useGetByVin } from 'shared/queries';
import { hideModalForm } from 'store/modalFormSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { useTypedIntl } from '../locale/messages';

export const VehicleSearchModal = (): React.ReactElement => {
  const navigate = useAlphamartNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const { isOpen, modalType } = useAppSelector(state => state.modalForm);
  const vinSearchModalType = modalType === ModalFormType.VinSearch;
  const [handleOpenModal, setHandleOpenModal] = useState(false);

  const { imageExtensions, fileSizeLimit } = useAppSelector(
    state => state.config.upload!.imageRecognition,
  );

  const vinImageRecognition = useVinImageRecognition();

  const validationSchema = Yup.object().shape({
    vin: Yup.string()
      .uppercase()
      .matches(/^[A-Z0-9]+$/, {
        message: intl.formatMessage({ id: 'VehicleForm.Errors.Vin.AcceptedCharacters' }),
      })
      .matches(/^[^oiq]+$/i, {
        message: intl.formatMessage({ id: 'VehicleForm.Errors.Vin.ExcludedCharacters' }),
      })
      .min(17, intl.formatMessage({ id: 'VehicleForm.Errors.Vin.TooShort' })),
  });

  const formikContext = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      vin: '',
    },
    validationSchema,
    onSubmit: async x => x,
  });

  const { values, handleChange, errors, handleBlur, isValidating } = formikContext;

  const handleCancelClick = () => {
    formikContext.resetForm();
    dispatch(hideModalForm());
  };

  const { refetch, isFetching } = useGetByVin(values?.vin, { enabled: false });

  const handleConfirmClick = async () => {
    try {
      const { data: vehicle, isSuccess } = await refetch();

      if (isSuccess) {
        const vehicleFilters = {
          make: vehicle?.make,
          model: vehicle?.model,
          engineType: vehicle?.engineType,
          year: vehicle?.year,
        };

        dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'Global.Results' })));
        dispatch(
          saveFilters({
            filterKey: FilterableModules.VEHICLES,
            values: { ...vehicleFilters },
          }),
        );
        navigate('/vehicles/list');
        formikContext.resetForm();
        dispatch(hideModalForm());
      } else {
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'VehicleForm.Errors.Vin.NotFound' })),
        );
      }
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  const handleAfterOpen = () => {
    setHandleOpenModal(true);
  };

  const handlePhotoChange = async (event: PhotoEvent) => {
    if (!event.target.value?.[0]?.file) {
      return;
    }

    try {
      const decodedVin = await vinImageRecognition.mutateAsync(event.target.value[0].file);
      await formikContext.setFieldValue('vin', decodedVin);
      dispatch(
        snackBarPushSuccess(
          intl.formatMessage({ id: 'VehicleSearch.Modal.Vin.ImageSearch.Success' }),
        ),
      );
    } catch (error) {
      dispatch(
        snackBarPushFailure(
          intl.formatMessage({ id: 'VehicleSearch.Modal.Vin.ImageSearch.Error' }),
        ),
      );
    }
  };
  const submitDisabled =
    !formikContext.isValid || isValidating || isFetching || !formikContext.dirty;

  useEffect(() => {
    if (!handleOpenModal) return;

    const currentToFocus = inputRef?.current;
    currentToFocus?.focus();
    setHandleOpenModal(false);
  }, [handleAfterOpen]);

  return (
    <LoadableContent
      mode={LoadableContentModes.FULL}
      loading={isFetching || vinImageRecognition.isLoading}
      drawContent
    >
      <ModalPortal
        isOpen={isOpen && vinSearchModalType}
        innerPadding={false}
        header={intl.formatMessage({ id: 'VehicleSearch.Modal.Title' })}
        rightButtons={[
          <Button
            onClick={handleCancelClick}
            variant="transparent"
            content="text"
            label={intl.formatMessage({ id: 'Global.Cancel' })}
          />,
          <Button
            onClick={handleConfirmClick}
            disabled={submitDisabled}
            content="text"
            label={intl.formatMessage({ id: 'Global.Search' })}
          />,
        ]}
      >
        <ModalFormContent>
          <FormikProvider value={formikContext}>
            <div>
              <FieldInput
                label={intl.formatMessage({ id: 'VehicleSearch.Modal.Vin' })}
                name="vin"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.vin}
                error={errors.vin}
                data-cy="vin"
                autoComplete="off"
                ref={inputRef}
                capitalize
                maxLength={17}
              />
            </div>
            <FieldPhotos
              onChange={handlePhotoChange}
              name="vinImage"
              single
              addPhotoMessage={
                <div>
                  {intl.formatMessage({ id: 'VehicleSearch.Modal.Vin.ImageSearch.Label' })}
                  <br />
                  {intl.formatMessage({ id: 'VehicleSearch.Modal.Vin.ImageSearch.Tip' })}
                </div>
              }
              allowedExtensions={imageExtensions}
              largePreview
              fileSizeLimit={fileSizeLimit}
            />
          </FormikProvider>
        </ModalFormContent>
      </ModalPortal>
    </LoadableContent>
  );
};
