import React, { useContext } from 'react';
import { Button } from '@alpha-recycling/component-library';
import { FormikErrors, FormikTouched } from 'formik';

import { useTypedIntl } from 'locale/messages';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import {
  StyledBackButton,
  StyledButtonsWrapper,
  StyledControls,
  StyledCreateButtonWrapper,
} from './Controls.styles';
import { WizardContext } from '../Wizard';

interface Props {
  stepsErrors?: (FormikErrors<unknown> | undefined)[];
  touched: FormikTouched<unknown>;
  isSubmitting: boolean;
  isValid: boolean;
  isValidating: boolean;
  editMode?: boolean;
  onGoBack?: () => void;
  lastPageButton?: string;
}

const Controls = ({
  touched,
  stepsErrors = [],
  isValid,
  isSubmitting,
  isValidating,
  editMode = false,
  onGoBack,
  lastPageButton,
}: Props): React.ReactElement => {
  const { changePage, currentPage, pageIndexes } = useContext(WizardContext);
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();
  const onCancel = () =>
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'Modal.Confirm' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: () => {
          if (onGoBack) {
            onGoBack();
          } else {
            navigate(-1);
          }
          dispatch(hideModal());
        },
      }),
    );

  const errorsCountForCurrentStep =
    currentPage && stepsErrors?.length && stepsErrors?.[currentPage - 1]
      ? Object.keys(stepsErrors[currentPage - 1]!)?.length
      : 0;

  const currentPageValid = errorsCountForCurrentStep === 0;
  const isLastPage = pageIndexes?.at(-1) === currentPage || pageIndexes.length === 0;

  const submitDisabled =
    !isValid ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    !Object.keys(editMode ? { ...touched, ...(touched as any).converter } : touched).length ||
    isValidating ||
    isSubmitting;

  const lastPageButtonText = lastPageButton ?? intl.formatMessage({ id: 'Global.Submit' });

  return (
    <StyledControls>
      <StyledBackButton>
        <Button
          onClick={() => onCancel()}
          variant="transparent"
          content="text"
          label={intl.formatMessage({ id: 'Global.Cancel' })}
        />
      </StyledBackButton>
      <StyledButtonsWrapper>
        {currentPage > 1 && (
          <Button
            onClick={() => changePage(currentPage - 1)}
            variant="tonal"
            iconName="ShevronLeft"
            label={intl.formatMessage({ id: 'Global.Back' })}
            data-cy="cancel-button"
          />
        )}
        <StyledCreateButtonWrapper>
          {isLastPage ? (
            <Button
              type="submit"
              content="text"
              label={lastPageButtonText}
              data-cy="submit"
              disabled={submitDisabled}
            />
          ) : (
            <Button
              onClick={e => {
                e.preventDefault();
                changePage(currentPage + 1);
              }}
              content="text_icon"
              iconName="ShevronRight"
              label={intl.formatMessage({ id: 'Global.Next' })}
              data-cy="form-wizard-next"
              disabled={!currentPageValid}
            />
          )}
        </StyledCreateButtonWrapper>
      </StyledButtonsWrapper>
    </StyledControls>
  );
};

export { Controls };
