import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';
import { Button } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { ItemAction } from 'shared/types';
import { MEDIA_QUERY, Theme } from 'theme';
import { GroupItems } from './GroupItems/GroupItems';
import { ContextMenu } from '../ContextMenu';

// eslint-disable-next-line
type Group = Record<string, any>;
// eslint-disable-next-line
export type Item = Record<string, any>;
export type GroupColumn = {
  id: number;
  name?: string | React.ReactNode;
  render: (group: Group) => React.ReactElement;
  mediaQuery: number;
};

interface DroppableGroupProps {
  className?: string;
  generateName: (group: Group) => string;
  generatePhoto?: (group: Group) => React.ReactElement;
  group: Group;
  groupColumns: GroupColumn[];
  groupAccentColor?: string;
  groupItems: string;
  groupItemsSortKey: string;
  infoFields?: {
    render: (group: Group) => React.ReactElement;
    access: (group: Group) => boolean;
  }[];
  itemType: string;
  onDrop?: (item: Item, group: Group) => void;
  groupActions: ItemAction[];
}

const GroupMenu = styled.div<{ theme?: Theme; isOpen: boolean }>`
  min-width: 64px;
  width: 64px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.mineShaftLightest};
  border-radius: 4px;
  margin-right: 20px;

  & > button {
    width: 100%;
    height: 100%;
    outline: none;

    span {
      transition: transform 0.2s ease;
      ${({ isOpen }) => isOpen && 'transform: rotate(90deg);'}
    }

    :focus {
      box-shadow: none;
    }
  }
`;
const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  flex-direction: row;

  @media ${MEDIA_QUERY.MAX_MD} {
    flex-direction: column;
  }
`;
const GroupRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const GroupName = styled.p`
  margin: 0 12px 0 12px;
  max-width: 44vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${MEDIA_QUERY.MAX_MD} {
    width: 100px;
  }
`;
const IconsWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  flex-grow: 100;

  & > * {
    margin-left: 12px;
  }
`;
const ActionButtonWrapper = styled.div`
  padding: 10px;
`;
const ImageWrapper = styled.div`
  @media ${MEDIA_QUERY.MAX_MD} {
    min-width: 72px;
  }
`;

const DroppableGroupLayout = ({
  className,
  group,
  onDrop,
  generateName,
  groupItems,
  groupColumns,
  groupAccentColor,
  generatePhoto,
  itemType,
  infoFields,
  groupItemsSortKey,
  groupActions = [],
}: DroppableGroupProps): React.ReactElement => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (!group[groupItems]?.length) setOpen(false);
  }, [group]);

  const toggleGroup = useCallback(
    () => group[groupItems]?.length && setOpen(!isOpen),
    [isOpen, group],
  );

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: itemType,
    drop: (item: Item) => onDrop?.(item, group),
    hover: (_, monitor) => {
      if (group[groupItems]?.length && monitor.isOver()) {
        setOpen(true);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = isOver && canDrop;

  const preparedItems = useMemo(
    () =>
      group[groupItems]
        ?.map(item => ({ ...item, group }))
        .sort((item1, item2) => item1[groupItemsSortKey].localeCompare(item2[groupItemsSortKey])),
    [group],
  );

  return (
    <div ref={drop}>
      <div className={className}>
        <GroupMenu isOpen={isOpen}>
          <Button
            variant="transparent"
            content="icon"
            size="big"
            iconName="ShevronRight"
            onClick={toggleGroup}
            disabled={!preparedItems.length}
            data-cy="next-button"
          />
        </GroupMenu>
        <GroupContainer>
          <GroupRow>
            {generatePhoto && <ImageWrapper> {generatePhoto?.(group)}</ImageWrapper>}
            {!!groupActions.length && (
              <ActionButtonWrapper>
                <ContextMenu
                  offsetX={-60}
                  trigger={
                    <Button
                      data-cy="actionButton"
                      iconName="ThreeDots"
                      variant="plain"
                      content="icon"
                    />
                  }
                >
                  {groupActions?.map(({ label, onClick, dataCy }, index) => (
                    <button
                      type="button"
                      /* eslint-disable react/no-array-index-key */
                      key={index}
                      onClick={onClick}
                      data-cy={dataCy?.(group)}
                    >
                      {label}
                    </button>
                  ))}
                </ContextMenu>
              </ActionButtonWrapper>
            )}
          </GroupRow>
          <GroupRow>
            <GroupName>
              {isActive ? <FormattedMessage id="Global.DropToAssign" /> : generateName(group)}
            </GroupName>
            {infoFields && (
              <IconsWrapper>
                {infoFields.map(({ access, render }) => access(group) && render(group))}
              </IconsWrapper>
            )}
          </GroupRow>
        </GroupContainer>
      </div>
      <GroupItems
        items={preparedItems}
        columns={groupColumns}
        mainColor={groupAccentColor}
        isOpen={isOpen}
        itemType={itemType}
      />
    </div>
  );
};

const DroppableGroup = styled(DroppableGroupLayout)<{ theme?: Theme }>`
  display: flex;
  align-items: center;

  height: 64px;
  min-width: 300px;
  max-width: fit-content;
  background-color: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.colors.dataInput.border};
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 12px;
  padding-right: 16px;

  @media ${MEDIA_QUERY.MAX_MD} {
    height: 100px;
  }
`;

export { DroppableGroup };
