import React from 'react';
import { DataOutput, OutputCell, Status } from '@alpha-recycling/component-library';

import { TextButton } from 'components/shared/Buttons';
import { Details } from 'components/shared/Details';
import { DetailsField } from 'components/shared/Details/Details';
import { LoadableContent } from 'components/shared/Loader';
import { MiscellaneousFees } from 'components/views/Terms/Sections/MiscellaneousFees/MiscellaneousFees';
import { OtherCharges } from 'components/views/Terms/Sections/OtherCharges/OtherCharges';
import { RatesAndCharges } from 'components/views/Terms/Sections/RatesAndCharges/RatesAndCharges';
import { formatDate } from 'helpers/dateTime/dateTime';
import { PERMISSIONS, SHARED } from 'shared/constants';
import { resolveStatusColor, useAuthorization } from 'shared/helpers';
import { useAlphamartNavigate, useCompanyActions, useCurrentUser } from 'shared/hooks';
import { useLanguage } from 'shared/hooks/useLanguage';
import { Company, UsageThresholds } from 'shared/types';
import { CompanyDetailsThresholds } from './CompanyDetailsThresholds';
import { useCompanyItemActions } from '../hooks/useCompanyItemActions';
import { CompanyMessages, useTypedIntl } from '../locale/messages';

type Props = {
  company: Company;
};

const CompanyDetails = ({ company }: Props): React.ReactElement | null => {
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const authorize = useAuthorization();
  const language = useLanguage();
  const { companiesThresholds } = useCurrentUser();
  const filterCompany: UsageThresholds | undefined = companiesThresholds?.find(
    (item: UsageThresholds) => item.assignedTo === company?.id,
  );
  const canSeeUsageThresholds = authorize(PERMISSIONS.COMPANIES.EDIT_USAGES_THRESHOLDS);
  const { actionsPending } = useCompanyActions();
  const actions = useCompanyItemActions(company);

  if (!company) return null;

  let detailsFields = [
    {
      name: 'name',
      label: intl.formatMessage({ id: 'CompanyDetails.Name' }),
      value: company.name,
    },
    {
      name: 'status',
      label: intl.formatMessage({ id: 'CompanyDetails.Status' }),
      value: (
        <Status
          type={resolveStatusColor(company.status.toUpperCase())}
          discrete
          value={intl.formatMessage({
            id: `Global.Status.${company.status.toUpperCase()}` as keyof CompanyMessages,
          })}
          size="small"
        />
      ),
    },
  ];

  if (authorize(PERMISSIONS.COMPANIES.DETAILS.TYPE)) {
    detailsFields = [
      ...detailsFields,
      {
        name: 'type',
        label: intl.formatMessage({ id: 'CompanyDetails.Type' }),
        value: intl.formatMessage({ id: `CompanyDetails.Type.${company.type}` }),
      },
    ];
  }

  const primaryContacts = company.users.length
    ? company.users
    : [{ id: 1, firstName: SHARED.LONG_DASH, lastName: '', email: SHARED.LONG_DASH }];

  return (
    <LoadableContent loading={actionsPending} mode={LoadableContent.MODE.FULL} drawContent>
      <Details title={company.name} backUrl="/companies" actions={actions}>
        <DataOutput
          headerText={intl.formatMessage({
            id: 'CompanyDetails.Details',
          })}
        >
          {detailsFields.map((field, index, fields) => (
            <OutputCell
              key={field.name}
              data-cy={field.name}
              mergeCells={fields.length - 1 === index ? 4 : 2}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>

        <DataOutput
          headerText={intl.formatMessage({
            id: 'CompanyDetails.Address',
          })}
        >
          {[
            {
              name: 'country',
              label: intl.formatMessage({ id: 'CompanyDetails.Country' }),
              value: company.country.name[language],
            },
            {
              name: 'state',
              label: intl.formatMessage({ id: 'CompanyDetails.State' }),
              value: intl.messages[`Global.Provinces.${company.state}`]
                ? intl.formatMessage({
                    id: `Global.Provinces.${company.state}` as keyof CompanyMessages,
                  })
                : company.state,
            },
            {
              name: 'city',
              label: intl.formatMessage({ id: 'CompanyDetails.City' }),
              value: company.city,
            },
            {
              name: 'street',
              label: intl.formatMessage({ id: 'CompanyDetails.Street' }),
              value: company.street,
            },
            {
              name: 'zipCode',
              label: intl.formatMessage({ id: 'CompanyDetails.ZipCode' }),
              value: company.zipCode,
            },
          ].map((field, index, fields) => (
            <OutputCell
              key={field.name}
              data-cy={field.name}
              mergeCells={fields.length - 1 === index ? 4 : 2}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>

        <RatesAndCharges terms={company.terms} />
        <OtherCharges terms={company.terms} />
        <MiscellaneousFees terms={company.terms} />

        {primaryContacts.map((contact, index) => (
          <DataOutput
            key={contact.id}
            headerText={intl.formatMessage({ id: 'CompanyDetails.PrimaryContacts' })}
          >
            {[
              {
                name: `contactName${index}`,
                label: intl.formatMessage({ id: 'CompanyDetails.ContactName' }),
                value: contact.firstName ? (
                  <TextButton onClick={() => navigate(`/users/${contact.id}`)}>
                    {`${contact.firstName} ${contact.lastName}`}
                  </TextButton>
                ) : (
                  SHARED.LONG_DASH
                ),
              },
              {
                name: `contactEmail${index}`,
                label: intl.formatMessage({ id: 'CompanyDetails.ContactEmail' }),
                value: contact.email,
              },
            ]
              .flat()
              .map(field => (
                <OutputCell
                  key={field.name}
                  data-cy={field.name}
                  mergeCells={2}
                  labelValue={field.label}
                >
                  {field.value}
                </OutputCell>
              ))}
          </DataOutput>
        ))}

        <DataOutput
          headerText={intl.formatMessage({
            id: 'CompanyDetails.Notes',
          })}
        >
          {[
            {
              name: 'notes',
              label: intl.formatMessage({
                id: 'CompanyDetails.Notes',
              }),
              value: company.note ?? SHARED.LONG_DASH,
            },
          ].map(field => (
            <OutputCell
              key={field.name}
              data-cy={field.name}
              mergeCells={4}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>

        {canSeeUsageThresholds ? <CompanyDetailsThresholds company={filterCompany} /> : null}

        <DataOutput
          headerText={intl.formatMessage({
            id: 'Global.MoreDetails',
          })}
        >
          {(
            [
              {
                name: 'termsCreatedBy',
                label: intl.formatMessage({ id: 'CompanyDetails.AddedBy' }),
                value: company.terms.createdBy
                  ? `${company.terms.createdBy.firstName} ${company.terms.createdBy.lastName}`
                  : SHARED.LONG_DASH,
              },
              {
                name: 'termsCreatedAt',
                label: intl.formatMessage({ id: 'CompanyDetails.AddedOn' }),
                value: company.terms.createdAt
                  ? formatDate(company.terms.createdAt)
                  : SHARED.LONG_DASH,
              },
              !company.grading && {
                name: 'termsUpdatedBy',
                label: intl.formatMessage({ id: 'CompanyDetails.UpdatedBy' }),
                value: company.terms?.updatedBy
                  ? `${company.terms.updatedBy.firstName} ${company.terms.updatedBy.lastName}`
                  : SHARED.LONG_DASH,
              },
              !company.grading && {
                name: 'termsUpdatedAt',
                label: intl.formatMessage({ id: 'CompanyDetails.UpdatedOn' }),
                value: company.terms?.updatedAt
                  ? formatDate(company.terms.updatedAt)
                  : SHARED.LONG_DASH,
              },
            ].filter(Boolean) as DetailsField[]
          ).map(field => (
            <OutputCell key={field.name} data-cy={field.name} labelValue={field.label}>
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>
      </Details>
    </LoadableContent>
  );
};

export { CompanyDetails };
