import React, { useEffect, useMemo, useState } from 'react';
import { ButtonProps, Form, FormRow } from '@alpha-recycling/component-library';
import { addMinutes, addMonths, isSameDay, isToday, startOfHour } from 'date-fns';
import { useFormik } from 'formik';

import { FieldDatePicker } from 'components/shared/Fields/FieldDatePicker/FieldDatePicker';
import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldTextArea } from 'components/shared/Fields/FieldTextArea/FieldTextArea';
import { FormWrapper } from 'components/shared/forms/Form/FormWrapper';
import { getDateTimeFormat } from 'helpers/dateTime/dateTime';
import { PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser, useLanguage } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { HedgeFormSchema } from 'shared/types/hedgeFormSchema ';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { HedgeFormAssign } from './HedgeFormAssign';
import { hedgeFormSchema } from './hedgeFormSchema';
import { useTypedIntl } from '../locale/messages';

interface Props {
  onSubmit: (values: HedgeFormSchema) => void;
  initialValues?: HedgeFormSchema;
  editMode?: boolean;
  hedgeId?: number;
}

export const HedgeForm = ({
  onSubmit,
  hedgeId,
  initialValues,
  editMode = false,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const validationSchema = useMemo(() => hedgeFormSchema(intl), [intl]);
  const authorize = useAuthorization();
  const canChangeCompany = authorize(PERMISSIONS.HEDGES.CHANGE_COMPANY);
  const currentUser = useCurrentUser();

  const formikContext = useFormik<HedgeFormSchema>({
    initialValues: initialValues ?? {
      name: '',
      relatedLot: '',
      note: '',
      company: canChangeCompany ? null : currentUser.company.id,
      hedgeCompany: '',
      expiresAt: null,
      ptPriceCust: '',
      ptPriceCustConfirm: '',
      pdPriceCust: '',
      pdPriceCustConfirm: '',
      rhPriceCust: '',
      rhPriceCustConfirm: '',
      placedAt: null,
      assignedUsers: null,
    },
    initialTouched: editMode
      ? {
          ptPriceCust: true,
          pdPriceCust: true,
          rhPriceCust: true,
          relatedLot: true,
          name: true,
          note: true,
          placedAt: false,
          expiresAt: true,
        }
      : {},
    validationSchema,
    onSubmit: async hedge => {
      dispatch(
        showModal({
          message: intl.formatMessage({ id: 'HedgeForm.Modal.Message' }),
          onClose: () => {
            dispatch(hideModal());
          },
          onConfirm: async () => {
            dispatch(hideModal());
            await onSubmit(hedge);
          },
        }),
      );
    },
  });
  const language = useLanguage();
  const [activeInput, setActiveInput] = useState<string | null>();
  const dateFormat = getDateTimeFormat(language);
  const { values, handleChange, getFieldMeta, handleBlur, setFieldValue, setFieldTouched } =
    formikContext;

  useEffect(() => {
    if (!values.ptPriceCust) setFieldValue('ptPriceCustConfirm', '');
    if (!values.pdPriceCust) setFieldValue('pdPriceCustConfirm', '');
    if (!values.rhPriceCust) setFieldValue('rhPriceCustConfirm', '');
  }, [values.ptPriceCust, values.pdPriceCust, values.rhPriceCust]);
  const getErrors = (name: string): string | undefined => {
    const { touched: fieldTouched, error } = getFieldMeta(name);
    return (fieldTouched && error) || undefined;
  };

  const handleBlurOnConfirmableFields = e => {
    setFieldTouched(`${e.target.name}Confirm`);
    setActiveInput(null);
  };
  const handleFocusOnConfirmableFields = e => {
    setFieldTouched(e.target.name);
    setActiveInput(e.target.name);
  };
  const handleCancel = () => {
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'HedgeForm.Modal.Message' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: () => {
          navigate('/hedges');
          dispatch(hideModal());
        },
      }),
    );
  };

  useEffect(() => {
    if (editMode) return;

    const placedAt = startOfHour(new Date());
    const expiresAt = startOfHour(addMonths(placedAt, 3));
    formikContext.setFieldValue('placedAt', placedAt);
    formikContext.setFieldValue('expiresAt', expiresAt);
  }, [editMode]);

  const submitDisabled =
    !(formikContext.isValid && Object.keys(formikContext.touched).length) ||
    formikContext.isValidating ||
    formikContext.isSubmitting;

  return (
    <FormWrapper
      header={intl.formatMessage({
        id: editMode ? 'HedgeForm.UpdateHeader' : 'HedgeForm.Header',
      })}
      submitLabel={intl.formatMessage({
        id: editMode ? 'HedgeForm.Update' : 'HedgeForm.Submit',
      })}
      onSubmit={formikContext.handleSubmit}
      onCancel={handleCancel}
      submitDisabled={submitDisabled}
      context={formikContext}
      showSubmitIcon={!editMode}
    >
      {canChangeCompany && (
        <HedgeFormAssign
          formikContext={formikContext}
          hedgeId={hedgeId}
          label={intl.formatMessage({ id: 'HedgeForm.Section.Assign' })}
          editMode={editMode}
        />
      )}
      <Form
        headerText={intl.formatMessage({ id: 'HedgeForm.Section.HedgeInfo' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldInput
            label={intl.formatMessage({ id: 'HedgeForm.HedgeName' })}
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={getErrors('name')}
            data-cy="name"
            required
            autoComplete="off"
            maxLength={50}
          />
          <FieldInput
            label={intl.formatMessage({ id: 'HedgeForm.RelatedLot' })}
            name="relatedLot"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.relatedLot}
            error={getErrors('relatedLot')}
            data-cy="related-lot"
            maxLength={50}
          />
          <FieldInput
            label={intl.formatMessage({ id: 'HedgeForm.HedgeCompany' })}
            name="hedgeCompany"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.hedgeCompany}
            error={getErrors('hedgeCompany')}
            data-cy="hedge-company"
            maxLength={100}
          />
        </FormRow>
      </Form>
      {!canChangeCompany && (
        <HedgeFormAssign
          formikContext={formikContext}
          hedgeId={hedgeId}
          label={intl.formatMessage({ id: 'HedgeForm.Section.Assign' })}
          editMode={editMode}
        />
      )}
      <Form
        headerText={intl.formatMessage({ id: 'HedgeForm.Section.Prices' })}
        headerButton={false as unknown as ButtonProps}
        formDescriptionMessage={intl.formatMessage({ id: 'HedgeForm.OnePriceRequired' })}
      >
        <FormRow>
          <FieldInput
            label={intl.formatMessage({ id: 'HedgeForm.PtPriceCust' })}
            name="ptPriceCust"
            onChange={handleChange}
            onBlur={handleBlurOnConfirmableFields}
            onFocus={handleFocusOnConfirmableFields}
            protection={{
              hide: activeInput === 'ptPriceCustConfirm',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            value={values.ptPriceCust!}
            error={getErrors('ptPriceCust')}
            prefix="$"
            data-cy="pt-price-cust"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'HedgeForm.PtPriceCustConfirm' })}
            name="ptPriceCustConfirm"
            onChange={handleChange}
            onBlur={handleBlurOnConfirmableFields}
            onFocus={handleFocusOnConfirmableFields}
            protection={{
              hide: activeInput === 'ptPriceCust',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            value={values.ptPriceCustConfirm!}
            error={getErrors('ptPriceCustConfirm')}
            disabled={!values.ptPriceCust}
            prefix="$"
            data-cy="pt-price-cust-confirm"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'HedgeForm.PdPriceCust' })}
            name="pdPriceCust"
            onChange={handleChange}
            onBlur={handleBlurOnConfirmableFields}
            onFocus={handleFocusOnConfirmableFields}
            protection={{
              hide: activeInput === 'pdPriceCustConfirm',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            value={values.pdPriceCust!}
            error={getErrors('pdPriceCust')}
            prefix="$"
            data-cy="pd-price-cust"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'HedgeForm.PdPriceCustConfirm' })}
            name="pdPriceCustConfirm"
            onChange={handleChange}
            onBlur={handleBlurOnConfirmableFields}
            onFocus={handleFocusOnConfirmableFields}
            protection={{
              hide: activeInput === 'pdPriceCust',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            value={values.pdPriceCustConfirm!}
            error={getErrors('pdPriceCustConfirm')}
            disabled={!values.pdPriceCust}
            prefix="$"
            data-cy="pd-price-cust-confirm"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'HedgeForm.RhPriceCust' })}
            name="rhPriceCust"
            onChange={handleChange}
            onBlur={handleBlurOnConfirmableFields}
            onFocus={handleFocusOnConfirmableFields}
            protection={{
              hide: activeInput === 'rhPriceCustConfirm',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            value={values.rhPriceCust!}
            error={getErrors('rhPriceCust')}
            prefix="$"
            data-cy="rh-price-cust"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'HedgeForm.RhPriceCustConfirm' })}
            name="rhPriceCustConfirm"
            onChange={handleChange}
            onBlur={handleBlurOnConfirmableFields}
            onFocus={handleFocusOnConfirmableFields}
            protection={{
              hide: activeInput === 'rhPriceCust',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            value={values.rhPriceCustConfirm!}
            error={getErrors('rhPriceCustConfirm')}
            disabled={!values.rhPriceCust}
            prefix="$"
            data-cy="rh-price-cust-confirm"
          />
        </FormRow>
      </Form>
      <Form
        headerText={intl.formatMessage({ id: 'HedgeForm.Section.Dates' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldDatePicker
            id="placed-at"
            name="placedAt"
            value={values.placedAt!}
            onChange={setFieldValue}
            onTouch={setFieldTouched}
            error={getErrors('placedAt')}
            label={intl.formatMessage({ id: 'HedgeForm.PlacedOn' })}
            dateFormat={dateFormat}
            max={new Date()}
            maxTime={isToday(values.placedAt ?? new Date()) ? addMinutes(new Date(), 1) : undefined}
            required
            showTimeSelect
          />
          <FieldDatePicker
            id="name"
            name="expiresAt"
            value={values.expiresAt as Date}
            onChange={setFieldValue}
            onTouch={setFieldTouched}
            error={getErrors('expiresAt')}
            label={intl.formatMessage({ id: 'HedgeForm.Expiration' })}
            dateFormat={dateFormat}
            min={values.placedAt!}
            minTime={
              isSameDay(values.placedAt!, values.expiresAt!)
                ? addMinutes(values.placedAt!, 1)
                : undefined
            }
            required
            showTimeSelect
          />
        </FormRow>
      </Form>
      <Form
        headerText={intl.formatMessage({ id: 'HedgeForm.Section.Note' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldTextArea
            id="note"
            label={intl.formatMessage({ id: 'HedgeForm.Note' })}
            name="note"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.note}
            data-cy="note"
            maxLength={250}
          />
        </FormRow>
      </Form>
    </FormWrapper>
  );
};
