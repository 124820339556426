import React, { useEffect } from 'react';
import { Button, Label } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';
import { FieldArray, useField } from 'formik';

import { layers } from 'theme';
import { FieldInputProps, FieldInputRaw } from '../FieldInput/FieldInput';

interface Props extends Omit<FieldInputProps, 'value'> {
  name: string;
  value: {
    value: string;
  }[];
  onChange?: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLButtonElement>) => void;
  onTouch?: (name: string) => void;
  addLabel?: string;
  maxItems?: number;
  minItems?: number;
  label?: string;
}

const InputRemoveButtonStyled = styled.div`
  position: absolute;
  top: 9px;
  right: 12px;
  z-index: ${layers.fieldInputRemove};
  order: 1;
`;

const InputWrapper = styled.div`
  position: relative;
`;

// @TODO: Remove after Form migration
const MultiTextWrapper = styled.div`
  margin-top: 24px;
`;

const FieldMultiText = ({
  maxLength,
  value,
  onChange,
  onFocus,
  onBlur,
  onTouch,
  name,
  addLabel,
  error,
  prefix,
  maxItems = 10,
  minItems = 0,
  capitalize = false,
  label = '',
  required,
}: Props): React.ReactElement => {
  const [, , { setTouched }] = useField(name);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const onItemChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
    setTouched(true);
  };
  const filteredValues = value.slice(0, maxItems);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [filteredValues.length]);

  return (
    <FieldArray name={name}>
      {arrayHelpers => (
        <MultiTextWrapper>
          <Label value={label} htmlFor={`${name}.value`}>
            {filteredValues.map((_, index) => (
              <InputWrapper>
                {filteredValues.length > minItems && (
                  <InputRemoveButtonStyled>
                    <Button
                      variant="plain"
                      size="small"
                      iconName="X"
                      onClick={() => {
                        onTouch?.(name);
                        setTouched(true);
                        return arrayHelpers.remove(index);
                      }}
                    />
                  </InputRemoveButtonStyled>
                )}

                <FieldInputRaw
                  required={required}
                  onChange={onItemChange}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  name={`${name}.${index}.value`}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  value={filteredValues[index].value}
                  error={error}
                  maxLength={maxLength}
                  prefix={prefix}
                  capitalize={capitalize}
                  ref={inputRef}
                />
              </InputWrapper>
            ))}
            {filteredValues.length < maxItems && (
              <Button
                label={addLabel}
                content="text"
                variant="content"
                onClick={() => {
                  arrayHelpers.push({ value: '' });
                }}
                onFocus={onFocus}
              />
            )}
          </Label>
        </MultiTextWrapper>
      )}
    </FieldArray>
  );
};

export { FieldMultiText };
