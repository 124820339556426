import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { parseTheme } from 'shared/parsers/parseTheme';
import { AlphamartHttpError, CompanyTheme } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface ThemeState extends GenericStoreSlice {
  theme: CompanyTheme | undefined;
}

interface FileValueParam {
  id?: number;
  file: Partial<File>;
  image: string;
  name: string;
  size: number;
  data?: string;
}

export type UpdateThemeParam = {
  watermarkLogo: FileValueParam[];
  watermarkOpacity: number;
};
const updateThemeSlice = createSlice<ThemeState, GenericStoreReducer<ThemeState>>({
  name: 'updateTheme',
  initialState: {
    isPending: false,
    theme: undefined,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      theme: payload,
    })),
  },
});
export const {
  pending: updateThemeAction,
  success: updateThemeSuccessAction,
  failure: updateThemeFailureAction,
} = updateThemeSlice.actions;

export const updateTheme =
  (values: UpdateThemeParam, keepWatermark: boolean): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(updateThemeAction());

      const [watermark] = await Promise.all(
        values.watermarkLogo.map(async watermarkLogo => {
          if (watermarkLogo.id && keepWatermark) {
            return watermarkLogo;
          }
          if (watermarkLogo.id) {
            const response = await fetch(watermarkLogo.image, { mode: 'cors' });
            const blob = await response.blob();
            const file = new File([blob], `watermarkLogo-${new Date().valueOf()}.png`, {
              type: blob.type,
            });

            // eslint-disable-next-line no-param-reassign
            watermarkLogo.file = file;
          }

          const formData = new FormData();
          formData.append('watermark', watermarkLogo.file as File);

          return httpClient.post(
            `${apiHostname}/api/upload/watermark?opacity=${values.watermarkOpacity}`,
            formData,
            {
              headers: { 'content-type': 'multipart/form-data' },
            },
          );
        }),
      );
      const parsedTheme = parseTheme(values, watermark);
      const theme = await httpClient.put(`${apiHostname}/api/companies/theme`, parsedTheme);
      dispatch(updateThemeSuccessAction({ theme: theme.data }));
    } catch (error) {
      dispatch(updateThemeFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };

export default updateThemeSlice.reducer;
