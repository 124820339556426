import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { layers, MEDIA_QUERY, Theme } from 'theme';

export const averageSectionWrapper = css`
  position: sticky;
  bottom: 0;
  margin: 15px 0;
  z-index: ${layers.averageBox};

  @media ${MEDIA_QUERY.MAX_MD} {
    position: fixed;
    bottom: 60px;
    margin: 15px 0;
    width: calc(100% - 32px);
  }
`;
export const averageSelectionStyles = (enabled: boolean, theme: Theme): string => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 26px 32px;
  font-size: 12px;
  font-weight: 700;

  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: ${theme.colors.mineShaft};

  z-index: ${enabled ? layers.averageBox : `unset`};
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.9);

  button {
    margin-right: 8px;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    bottom: 60px;
    width: auto;
    right: 6px;
    border-radius: 17px;
    padding: 0;
    left: auto;
    border: none;
    background-color: ${theme.colors.mineShaft};
    box-shadow: 0 0px 30px rgba(0, 0, 0, 0.17);

    button {
      margin-right: 0;
    }

    ${enabled &&
    `
      padding: 20px;
      bottom: 0px;
      width: 100%;
      right: 0px;
      border-radius: 0;
      background-color: ${theme.colors.mineShaft};
    `}
  }
`;

export const highlightStyles = (theme: Theme): string => css`
  color: ${theme.colors.statuses.active};
`;

export const averagePriceInfo = (enabled: boolean): string => css`
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media ${MEDIA_QUERY.MAX_MD} {
    display: none;

    ${enabled &&
    `
      display: flex;
    `}
  }
`;

export const AveragePriceTitle = styled.div`
  align-items: center;
  display: flex;
  gap: 15px;
`;

export const ButtonWrapper = styled.div<{ isPhone: boolean }>`
  width: ${({ isPhone }) => (isPhone ? '100%' : 'fit-content')};
  button {
    width: 100%;
  }
`;
