import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MultiSelect, MultiSelectProps, OptionsProps } from '@alpha-recycling/component-library';
import { useField } from 'formik';
import { kebabCase } from 'lodash';
import { matchSorter } from 'match-sorter';

import { useTypedIntl } from 'locale/messages';
import { useDropdownPlacement } from 'shared/hooks';
import { usePrevious } from 'shared/hooks/usePrevious';
import { CustomSearchFunction, SelectWrapper } from './FieldSelect';
import { withFieldLabel } from '../FieldWrapper/FieldLabel';

const SELECT_ALL_VALUE = 'SelectAll';

export interface FieldMultiSelectProps extends Omit<MultiSelectProps, 'value' | 'error'> {
  customSearchFunction?: CustomSearchFunction;
  value?: string[] | number[] | null;
  error?: string | boolean | React.ReactNode;
  clearable?: boolean;
  selectAll?: boolean;
}

export const FieldMultiSelectLayout = React.memo<FieldMultiSelectProps>(
  ({
    disabled,
    name = '',
    onBlur,
    onChange,
    onFocus,
    options,
    value = [],
    placeholder,
    error,
    required,
    clearable = false,
    selectAll = false,
    searchable,
    customSearchFunction,
    onInputChange,
  }) => {
    const [, { touched }, { setValue, setTouched }] = useField(name);
    const [filteredOptions, setFilteredOptions] = useState<OptionsProps[]>([]);
    const previousOptions = usePrevious(options, []);
    const { dropdownRef, refreshDropdownPlacement } = useDropdownPlacement('bottom');
    const intl = useTypedIntl();
    const selectAllOption = {
      label: intl.formatMessage({ id: 'Global.Fields.Select.SelectAll' }),
      value: SELECT_ALL_VALUE,
    };

    useEffect(() => {
      setFilteredOptions(selectAll ? [selectAllOption, ...options] : options);
      const newOptionsAreEmpty = previousOptions?.length !== 0 && options?.length === 0;
      const hasValue = Array.isArray(value) ? value.length !== 0 : value !== null;
      if (newOptionsAreEmpty && hasValue) {
        // TODO: unexpected change event on initial empty user types list
        handleChange([]);
      }
    }, [options]);

    useEffect(() => {
      value && !touched && setTouched(true);
    }, [value, touched]);

    const selectAllClicked = val => val.some(item => item.value === SELECT_ALL_VALUE);

    const handleInputChange = useCallback(
      val => {
        if (searchable && val) {
          setFilteredOptions(
            customSearchFunction?.(options, val) || matchSorter(options, val, { keys: ['label'] }),
          );
        } else if (!val) {
          setFilteredOptions(options);
        }

        return val;
      },
      [options],
    );

    const handleChange = val => {
      const isSelectAllValue = selectAllClicked(val);
      onChange?.(val);

      if (isSelectAllValue) {
        setValue(filteredOptions.filter(v => v.value !== SELECT_ALL_VALUE).map(v => v.value));
      } else {
        setValue(val?.map(v => v.value ?? null));
      }
    };

    const handleFocus = useCallback(e => onFocus?.(e), [onFocus]);

    const handleBlur = useCallback(
      e => {
        setTouched(true);
        setFilteredOptions(options);
        onBlur?.(e);
      },
      [setTouched, onBlur, options],
    );

    const selected = useMemo(() => {
      if (!value) return undefined;

      return [...options]
        .filter(option => (value as (string | number)[])?.some(val => val === option?.value))
        .sort(
          ({ value: v1 }, { value: v2 }) =>
            (value as (string | number | undefined)[]).indexOf(v1) -
            (value as (string | number | undefined)[]).indexOf(v2),
        );
    }, [value, options]);

    return (
      <SelectWrapper ref={dropdownRef} data-cy={kebabCase(name)}>
        <MultiSelect
          placeholder={
            placeholder === ''
              ? ''
              : placeholder || intl.formatMessage({ id: 'Global.Fields.Select.Placeholder' })
          }
          disabled={disabled}
          name={name}
          onChange={disabled ? undefined : handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          options={(filteredOptions ?? []).filter(Boolean)}
          value={selected}
          noOptionsMessage={intl.formatMessage({ id: 'Global.Fields.Select.NoOptions' })}
          error={!!error}
          required={required || !clearable}
          isMenuOpen={disabled ? false : undefined}
          onInputChange={onInputChange ?? handleInputChange}
          onMenuOpen={refreshDropdownPlacement}
          {...(!!error && { errorMessage: String(error) })}
        />
      </SelectWrapper>
    );
  },
);

const FieldMultiSelect = withFieldLabel(FieldMultiSelectLayout);

export { FieldMultiSelect, FieldMultiSelectLayout as FieldMultiSelectRaw };
