import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Icon } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { shouldRender } from 'shared/helpers';
import { useClickAway, useDropdownPlacement } from 'shared/hooks';
import { ItemAction } from 'shared/types';
import { layers, MEDIA_QUERY, Theme } from 'theme';

const buttonWidth = 44;
const smallButtonWidth = 38;

interface Props {
  actions: ItemAction[];
  // eslint-disable-next-line
  item?: any;
  renderCustomOpenButton?: (params: {
    toggleMenu: (e?: React.MouseEvent) => void;
    isMenuOpen: boolean;
    dropdownRef: (node?: Element | null) => void;
  }) => React.ReactElement;
}

const ListActionsLayout = styled.div<{ isRenderCustomOpenButton?: boolean }>`
  align-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ isRenderCustomOpenButton }) =>
    !isRenderCustomOpenButton
      ? `
    width: ${buttonWidth}px;
    @media ${MEDIA_QUERY.MAX_MD} {
      width: ${smallButtonWidth}px;
      img {
        width: 26px;
      }
    }
  `
      : ``}

  @media ${MEDIA_QUERY.MAX_MD} {
    img {
      width: 26px;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    ${({ isRenderCustomOpenButton }) =>
      isRenderCustomOpenButton
        ? ''
        : `
        &:hover {
          box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
          border-radius: 4px;
        }
        `};
  }

  .button {
    padding: 0px;
    width: ${buttonWidth}px;
    align-self: center;

    @media ${MEDIA_QUERY.MAX_MD} {
      width: ${smallButtonWidth}px;
    }
  }

  .menu-enter {
    opacity: 0;
    visibility: visible;
  }

  .menu-enter-active {
    opacity: 1;
    transition: opacity 100ms;
  }

  .menu-enter-done {
    visibility: visible;
  }

  .menu-exit {
    opacity: 1;
    visibility: visible;
  }

  .menu-exit-active {
    opacity: 0;
    transition: opacity 100ms;
    visibility: visible;
  }

  .menu-exit-done {
    visibility: hidden;
  }
`;

const ActionsMenu = styled.div<{
  menuTop?: boolean;
  theme?: Theme;
  isRenderCustomOpenButton?: boolean;
  menuLeft?: boolean;
}>`
  position: absolute;
  ${({ menuTop }) =>
    menuTop ? 'bottom: 130%; margin-bottom: 4px;' : 'top: 130%; margin-top: 4px;'}
  display: flex;
  visibility: hidden;
  flex-direction: column;
  border: solid 2px ${({ theme }) => theme.colors.stroke};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.background};
  width: max-content;
  max-width: 140px;
  ${({ menuLeft }) => (menuLeft ? `right: -10px` : `left: 50%`)};
  transform: translateX(${({ menuLeft }) => (menuLeft ? '0' : '-50%')});
  z-index: ${layers.selectMenu};
  box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.3);

  button {
    min-height: 32px;
    padding: 4px 16px;
    color: ${({ theme }) => theme.fontColor};

    &: ${({ menuTop }) => (menuTop ? 'last-of-type' : 'first-of-type')}::after {
      content: '';
      position: absolute;
      ${({ menuTop }) => (menuTop ? 'bottom' : 'top')}: -9px;
      ${({ menuLeft }) =>
        menuLeft
          ? `
        right: ${buttonWidth / 2 - 7}px;
        @media ${MEDIA_QUERY.MAX_MD} {
          right: ${smallButtonWidth / 2 - 7}px;
        }
      `
          : `left: 50%;`}
      width: 12px;
      height: 12px;
      transform: translateX(-50%) rotate(${({ menuTop }) => (menuTop ? '-135' : '45')}deg);
      border-top: solid 2px ${({ theme }) => theme.colors.stroke};
      border-left: solid 2px ${({ theme }) => theme.colors.stroke};
      background-color: ${({ theme }) => theme.background};
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.mineShaftLightest};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.mineShaft};
      border-radius: 0px;
      height: 100%;
      width: 100%;

      &: ${({ menuTop }) => (menuTop ? 'last-of-type' : 'first-of-type')} {
        &::after {
          background-color: ${({ theme }) => theme.colors.mineShaft};

          ${({ isRenderCustomOpenButton }) =>
            isRenderCustomOpenButton
              ? ''
              : `box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);`}
        }
      }
    }
  }
`;
const DROPDOWN_OFFSET_PER_ITEM = 12;

export const ListActions = ({
  actions,
  item,
  renderCustomOpenButton,
}: Props): React.ReactElement => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const nodeRef = useRef<HTMLDivElement>(null);
  const filteredActions = actions?.filter(action => shouldRender(action, item));
  const { dropdownPlacement, dropdownSide, dropdownRef, refreshDropdownPlacement } =
    useDropdownPlacement('top', 'center', {
      topOffset: DROPDOWN_OFFSET_PER_ITEM * filteredActions.length,
    });
  useClickAway(menuRef, () => setMenuOpen(false));

  const toggleMenu = e => {
    refreshDropdownPlacement();
    e.stopPropagation();
    setMenuOpen(isOpen => !isOpen);
  };

  const onActionCall = (e, actionMethod) => {
    e.stopPropagation();
    actionMethod(item);
    return setMenuOpen(false);
  };

  const isRenderCustomOpenButton = typeof renderCustomOpenButton === 'function';

  return (
    <ListActionsLayout ref={menuRef} isRenderCustomOpenButton={isRenderCustomOpenButton}>
      {isRenderCustomOpenButton ? (
        renderCustomOpenButton({
          toggleMenu,
          isMenuOpen,
          dropdownRef,
        })
      ) : (
        <button
          type="button"
          className="button"
          onClick={toggleMenu}
          data-cy={`list-actions-${item?.metal || item?.id}`}
          ref={dropdownRef}
        >
          <Icon name="ThreeDots" size="big" />
        </button>
      )}

      <CSSTransition in={!isMenuOpen} timeout={100} classNames="menu" nodeRef={nodeRef}>
        <ActionsMenu
          isRenderCustomOpenButton={isRenderCustomOpenButton}
          menuTop={dropdownPlacement === 'top'}
          menuLeft={dropdownSide === 'left'}
          ref={nodeRef}
        >
          {filteredActions?.map(({ label, onClick, dataCy = () => '' }, index) => (
            <button
              type="button"
              /* eslint-disable react/no-array-index-key */
              key={index}
              onClick={e => onActionCall(e, onClick)}
              data-cy={dataCy(item)}
            >
              {label}
            </button>
          ))}
        </ActionsMenu>
      </CSSTransition>
    </ListActionsLayout>
  );
};
