import React from 'react';
import { Icon } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';
import { capitalize, isNil } from 'lodash';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { Card } from 'components/shared/Card';
import { IndicatorDot } from 'components/shared/forms/Section/components/IndicatorDot/IndicatorDot';
import { LinearChart } from 'components/shared/LinearChart';
import { LoadableContent } from 'components/shared/Loader';
import { formatDate } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { checkNumber } from 'shared/checkers';
import {
  axisDateFormats,
  DashboardFiltersKeys,
  FilterableModules,
  METAL_ABBR,
  METALS,
  SHARED,
  tooltipDateFormats,
} from 'shared/constants';
import { useLanguage, useMediaQuery } from 'shared/hooks';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';
import { useMarketPricesChartData } from './hooks/useMarketPricesChartData';
import {
  CardLabel,
  CardsSection,
  MetalName,
  MetalPrice,
  MetalPriceDiff,
} from '../Dashboard.styles';
import { DashboardHeader } from '../DashboardHeader/DashboardHeader';
import {
  DashboardMessages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

export interface DashboardFiltersShape {
  from: DashboardFiltersKeys;
}

const StyledIcon = styled.div<{ isUp?: boolean }>`
  transform: rotate(${({ isUp }) => (isUp ? 90 : -90)}deg);
`;
const DashboardChartsComponent = (): React.ReactElement => {
  const language = useLanguage();
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const { metalPrices, isPending } = useAppSelector(state => state.metalPrices);
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.DASHBOARD]);

  const { data, maxPrice, xAxisTicks } = useMarketPricesChartData(metalPrices?.list ?? []);

  const percentageDiffs: Record<METALS, number | null> = {
    [METALS.PLATINUM]: metalPrices.today.ptChangePercentage
      ? checkNumber(metalPrices.today.ptChangePercentage)
      : 0,
    [METALS.PALLADIUM]: metalPrices.today.pdChangePercentage
      ? checkNumber(metalPrices.today.pdChangePercentage)
      : 0,
    [METALS.RHODIUM]: metalPrices.today.rhChangePercentage
      ? checkNumber(metalPrices.today.rhChangePercentage)
      : 0,
  };

  const isDesktop = useMediaQuery(MEDIA_QUERY.XL);
  const lineColors = ['#7279D1', '#F54952', '#71CF93'];

  const handleDateChange = val =>
    dispatch(saveFilters({ filterKey: FilterableModules.DASHBOARD, values: { from: val } }));

  const generateLabel = (val: string | number, isTooltip?: boolean): string =>
    (typeof val === 'number' &&
      Number.isFinite(val) &&
      formatDate(
        new Date(val),
        isTooltip
          ? tooltipDateFormats[`${savedFilters.data.from}-${language}`]
          : axisDateFormats[`${savedFilters.data.from}-${language}`],
      )) ||
    '';

  const chartValuesFormatter = (value: string | number, name: string): [string, string] => [
    formatCurrency(Number(value), 'USD', 2, language),
    intl.formatMessage({ id: `Global.Metals.${capitalize(name)}` as keyof DashboardMessages }),
  ];

  return (
    <>
      <DashboardHeader
        onFilterClick={handleDateChange}
        currentFilter={savedFilters.data.from}
        colors={lineColors}
      />
      <LoadableContent loading={isPending} mode={LoadableContent.MODE.OVERLAY} drawContent>
        <LinearChart
          data={data}
          xAxisTicks={xAxisTicks}
          xAxisDataKey="createdAt"
          xAxisDomain={[data?.[0]?.createdAt, data?.[data.length - 1]?.createdAt]}
          yAxisDataKeys={Object.values(METALS)}
          yAxisDomain={[0, maxPrice || 'auto']}
          lineColors={lineColors}
          generateXLabel={generateLabel}
          valuesFormatter={chartValuesFormatter}
          labelFormatter={generateLabel}
        />
      </LoadableContent>
      <CardsSection>
        {Object.values(METALS).map((metal: METALS, i) => (
          <Card
            key={metal}
            height={isDesktop ? 30 : undefined}
            startOpacity={1 / (i + 2)}
            endOpacity={1 / (i + 2) - 0.2}
            className="single-card"
          >
            <CardLabel>
              <div>
                <IndicatorDot className="indicator-dot" bgColor={lineColors[i]} display="block">
                  <span>{capitalize(Object.values(METAL_ABBR)[i])}</span>
                </IndicatorDot>
              </div>
              <MetalName className="name">
                <FormattedMessage
                  id={`Global.Metals.${capitalize(metal)}` as keyof DashboardMessages}
                />
              </MetalName>
            </CardLabel>
            <MetalPrice>
              {isNil(metalPrices.today?.[metal])
                ? SHARED.LONG_DASH
                : formatCurrency(metalPrices.today?.[metal], 'USD', 2, language)}
            </MetalPrice>
            <MetalPriceDiff className="diff">
              {!percentageDiffs[metal] || percentageDiffs[metal] === 0 ? (
                `${SHARED.LONG_DASH} `
              ) : (
                <StyledIcon isUp={(percentageDiffs[metal] as number) > 0}>
                  <Icon
                    name="ArrowLeft"
                    size="medium"
                    fillColor={
                      (percentageDiffs[metal] as number) > 0
                        ? theme.colors.statuses.active
                        : theme.colors.statuses.blocked
                    }
                  />
                </StyledIcon>
              )}
              {`${percentageDiffs[metal]?.toFixed(3)}%`}
            </MetalPriceDiff>
          </Card>
        ))}
      </CardsSection>
    </>
  );
};

export const DashboardCharts = withAlphamartIntlProvider(DashboardChartsComponent);
