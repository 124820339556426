import React from 'react';
import {
  ButtonProps,
  Form,
  FormRow,
  RadioButton,
  RadioButtonGroup,
} from '@alpha-recycling/component-library';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { messages, useTypedIntl } from '../locale/messages';

interface Props {
  label: string;
  type: string;
  setType: (type: string) => void;
  extraInformation?: boolean;
}

export const TypeSectionComponent = ({
  label,
  type,
  setType,
  extraInformation,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const typeOptions = [
    {
      label: intl.formatMessage({ id: 'TermsForm.GradingSelectStep.Type.0' }),
      value: '0',
    },
    {
      label: intl.formatMessage({ id: 'TermsForm.GradingSelectStep.Type.1' }),
      value: '1',
    },
  ];

  return (
    <Form
      data-cy="type-section"
      headerButton={false as unknown as ButtonProps}
      headerText={label}
      {...(extraInformation && {
        formDescriptionMessage: intl.formatMessage({ id: 'TermsForm.Grading.Info' }),
      })}
    >
      <FormRow>
        <RadioButtonGroup name="grading" valueSelected={type} orientation="horizontal">
          {typeOptions.map(({ label: labelText, value }) => (
            <RadioButton
              id={`grading_${value}`}
              labelText={labelText}
              value={value}
              onChange={e => setType(e.target.value)}
              data-cy={`type-section-${value}`}
            />
          ))}
        </RadioButtonGroup>
      </FormRow>
    </Form>
  );
};

export const TypeSection = withAlphamartIntlProvider(TypeSectionComponent, messages);
