import { Language } from './languages';

export const dashboardFilters: Record<DashboardFiltersKeys, DashboardFiltersValues> = {
  'Days-1': '1d',
  'Weeks-1': '1w',
  'Months-1': '1m',
  'Years-1': '1y',
  'Years-5': '5y',
};

export const dashboardFiltersLabels: Record<`${DashboardFiltersValues}-${Language}`, string> = {
  '1d-EN': '1d',
  '1w-EN': '1w',
  '1m-EN': '1m',
  '1y-EN': '1y',
  '5y-EN': '5y',
  '1d-ES': '1d',
  '1w-ES': '1s',
  '1m-ES': '1m',
  '1y-ES': '1a',
  '5y-ES': '5a',
  '1d-FR': '1j',
  '1w-FR': '1s',
  '1m-FR': '1m',
  '1y-FR': '1a',
  '5y-FR': '5a',
  '1d-PL': '1d',
  '1w-PL': '1t',
  '1m-PL': '1m',
  '1y-PL': '1r',
  '5y-PL': '5l',
};

const dayInSeconds = 24 * 60 * 60;

export const granulationLevels: Record<DashboardFiltersKeys, number> = {
  'Days-1': 10 * 60,
  'Weeks-1': 45 * 60,
  'Months-1': 6 * 60 * 60,
  'Years-1': dayInSeconds,
  'Years-5': 7 * dayInSeconds,
};

export const axisDateFormats: Record<`${DashboardFiltersKeys}-${Language}`, string> = {
  'Days-1-EN': 'hh:mm a',
  'Weeks-1-EN': 'EEE',
  'Months-1-EN': 'MMM d',
  'Years-1-EN': 'MMM',
  'Years-5-EN': 'yyyy',
  'Days-1-ES': 'HH:mm',
  'Weeks-1-ES': 'EEE',
  'Months-1-ES': 'MMM d',
  'Years-1-ES': 'MMM',
  'Years-5-ES': 'yyyy',
  'Days-1-FR': 'HH:mm',
  'Weeks-1-FR': 'EEE',
  'Months-1-FR': 'MMM d',
  'Years-1-FR': 'MMM',
  'Years-5-FR': 'yyyy',
  'Days-1-PL': 'HH:mm',
  'Weeks-1-PL': 'EEE',
  'Months-1-PL': 'MMM d',
  'Years-1-PL': 'MMM',
  'Years-5-PL': 'yyyy',
};

export const tooltipDateFormats: Record<`${DashboardFiltersKeys}-${Language}`, string> = {
  'Days-1-EN': 'hh:mm a',
  'Weeks-1-EN': 'EEE hh:mm a',
  'Months-1-EN': 'MMM d, hh a',
  'Years-1-EN': 'MMM d',
  'Years-5-EN': 'MMM yyyy',
  'Days-1-ES': 'HH:mm',
  'Weeks-1-ES': 'EEE HH:mm',
  'Months-1-ES': "MMM d, 'a las' HH",
  'Years-1-ES': 'MMM d',
  'Years-5-ES': 'MMM yyyy',
  'Days-1-FR': 'HH:mm',
  'Weeks-1-FR': 'EEE HH:mm',
  'Months-1-FR': "MMM d, HH'h'",
  'Years-1-FR': 'MMM d',
  'Years-5-FR': 'MMM yyyy',
  'Days-1-PL': 'HH:mm',
  'Weeks-1-PL': 'EEE HH:mm',
  'Months-1-PL': "MMM d, 'godz.' HH",
  'Years-1-PL': 'MMM d',
  'Years-5-PL': 'MMM yyyy',
};

export type DashboardFiltersValues = '1d' | '1w' | '1m' | '1y' | '5y';
export type DashboardFiltersKeys = 'Days-1' | 'Weeks-1' | 'Months-1' | 'Years-1' | 'Years-5';
