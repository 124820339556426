import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import { capitalize, debounce } from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { DetailsSection } from 'components/shared/Details';
import { DetailsSectionField } from 'components/shared/Details/DetailsSection';
import { FieldCheckboxRaw } from 'components/shared/Fields/FieldCheckbox';
import { User, UserNotificationPreferences } from 'shared/types';
import { fetchUser } from 'store/auth';
import { changeUserNotifications } from 'store/changeUserNotificationsSlice';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import {
  SettingMessages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from './locale/messages';

const NotificationsRadioGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;
`;

type UserProfileNotificationsProps = { user: Pick<User, 'notificationPreferences'> };

export const UserProfileNotifications = ({
  user,
}: UserProfileNotificationsProps): React.ReactElement => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();

  const handleNotificationsChange = useCallback(
    debounce(async (preferences: UserNotificationPreferences[]) => {
      try {
        await dispatch(changeUserNotifications(preferences));
        await dispatch(fetchUser(true));
      } catch {
        dispatch(snackBarPushFailure(intl.formatMessage({ id: 'UserProfile.NotificationsError' })));
      }
    }, 1000),
    [],
  );

  const { values, handleChange, handleBlur, touched, dirty } = useFormik({
    initialValues: [...(user.notificationPreferences ?? [])].sort((a, b) =>
      a.type.localeCompare(b.type),
    ),
    onSubmit: handleNotificationsChange,
  });

  const isTouched = !!Object.values(touched).length || dirty;

  useDeepCompareEffect(() => {
    if (isTouched) handleNotificationsChange(values);
  }, [values, isTouched]);

  return (
    <DetailsSection
      title={<FormattedMessage id="UserProfile.Notifications" />}
      template={values.map(({ type }) => `type-${type} options-${type} options-${type}`)}
      fields={values.reduce<DetailsSectionField[]>(
        (prev, { type, useEmail, useSms, usePortal }, index) => [
          ...prev,
          {
            name: `type-${type}`,
            value: (
              <FormattedMessage
                id={`UserProfile.Notification.${capitalize(type)}` as keyof SettingMessages}
              />
            ),
          },
          {
            name: `options-${type}`,
            value: (
              <NotificationsRadioGroup>
                <FieldCheckboxRaw
                  name={`${index}.useEmail`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={useEmail}
                >
                  <FormattedMessage id="UserProfile.EmailNotifications" />
                </FieldCheckboxRaw>
                <FieldCheckboxRaw
                  name={`${index}.useSms`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={useSms}
                >
                  <FormattedMessage id="UserProfile.SmsNotifications" />
                </FieldCheckboxRaw>
                <FieldCheckboxRaw
                  name={`${index}.usePortal`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={usePortal}
                >
                  <FormattedMessage id="UserProfile.PortalNotifications" />
                </FieldCheckboxRaw>
              </NotificationsRadioGroup>
            ),
          },
        ],
        [],
      )}
    />
  );
};
