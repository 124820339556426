import React from 'react';
import styled from '@emotion/styled';

import { FieldSwitcherRaw } from 'components/shared/Fields/FieldSwitcher/FieldSwitcher';
import { NotificationStatus } from 'shared/types';
import { NotificationFilter, setStatusFilter } from 'store/notificationsList';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { theme } from 'theme';
import { useTypedIntl } from './locale/messages';

const Container = styled.div`
  border-bottom: 1px solid ${theme.colors.mineShaftLightest};
`;

export function NotificationsFilters(): React.ReactElement {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const notificationsListData = useAppSelector(state => state.notificationsList);
  const handleRadioChange = value => {
    dispatch(setStatusFilter(value as NotificationFilter));
  };
  const radioOptions = [
    {
      label: intl.formatMessage({
        id: 'Notifications.Filters.Status.OPEN',
      }),
      value: NotificationStatus.OPEN,
    },
    {
      label: intl.formatMessage({
        id: 'Notifications.Filters.Status.RESOLVED',
      }),
      value: NotificationStatus.RESOLVED,
    },
    {
      label: intl.formatMessage({
        id: 'Notifications.Filters.Status.ALL',
      }),
      value: 'ALL',
    },
  ];

  return (
    <Container>
      <FieldSwitcherRaw
        onChange={handleRadioChange}
        name="notifications-filters"
        options={radioOptions}
        value={notificationsListData.statusFilter}
        dataCy="notifications-header-filters"
      />
    </Container>
  );
}
