import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, CompanyFormData } from 'shared/types';
import { GenericThunk } from './shared/createGenericStoreSlice';

export const updateCompany =
  (values: CompanyFormData, id: number, options: { grading: boolean }): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      const { type, ...updateValues } = values;
      const body = { ...updateValues, terms: options.grading ? undefined : updateValues.terms };

      await httpClient.put(`${apiHostname}/api/companies/${id}`, body);
    } catch (error) {
      return Promise.reject((error as AlphamartHttpError)?.response?.data);
    }
  };
