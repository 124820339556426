import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { StatisticFiltersFormShape } from 'components/views/Statistics/ConvertersStatistics/StatisticsPageFilters';
import { apiHostname } from 'shared/constants';
import { parseGetStatisticsParams } from 'shared/parsers/parseStatistics';
import { Statistics } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum StatisticsKeys {
  GetViewsStatistics = 'GetViewsStatistics',
  GetSearchStatistics = 'GetSearchStatistics',
}

export interface GetStatisticsParams {
  users: number[];
  from: string | Date;
  to: string | Date;
  granulationLevel: number;
  timezone?: string;
  minOveruseRatio: number;
}

export const useGetViewsStatistics = (
  params: StatisticFiltersFormShape,
  options: UseQueryOptions<Statistics> = {},
): UseQueryResult<Statistics> =>
  useQuery<Statistics>(
    [StatisticsKeys.GetViewsStatistics, params],
    async () => {
      const response = await getHttpClient().post<Statistics>(
        `${apiHostname}/api/statistics/converters/views`,
        parseGetStatisticsParams(params),
      );
      return response.data;
    },
    options,
  );

export const useGetSearchStatistics = (
  params: StatisticFiltersFormShape,
  options: UseQueryOptions<Statistics> = {},
): UseQueryResult<Statistics> =>
  useQuery<Statistics>(
    [StatisticsKeys.GetSearchStatistics, params],
    async () => {
      const response = await getHttpClient().post<Statistics>(
        `${apiHostname}/api/statistics/converters/search`,
        parseGetStatisticsParams(params),
      );
      return response.data;
    },
    options,
  );
