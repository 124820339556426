import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Button, Icon, InfoBar, ModalPortal } from '@alpha-recycling/component-library';
import { isNil } from 'lodash';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldTextArea } from 'components/shared/Fields/FieldTextArea/FieldTextArea';
import { withFieldWrapper } from 'components/shared/Fields/FieldWrapper/FieldWrapper';
import { ModalFormContent } from 'components/shared/forms/Form/ModalForm.styles';
import { Rate } from 'components/shared/Rating/Rate';
import { QuestionnaireStatus } from 'shared/constants';
import { compareCurrentHour } from 'shared/helpers';
import {
  useCancelQuestionnaire,
  useFillUpQuestionnaire,
  useSkipQuestionnaire,
} from 'shared/mutations';
import { useGetQuestionnaireStatus } from 'shared/queries';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import {
  hideModal as hideConfirmationModal,
  showModal as showConfirmationModal,
} from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import {
  MessageInfo,
  QuestionnaireContentWrapper,
  QuestionnaireTextAreaWrapper,
  RateContainer,
  RateInfo,
  RateInfoContainer,
} from './QuestionnaireModal.styles';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

const FieldRate = withFieldWrapper(Rate);

function QuestionnaireModalComponent(): React.ReactElement {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const authData = useAppSelector(store => store.auth);
  const config = useAppSelector(store => store.config);
  const [rating, setRating] = useState<number | null>(null);
  const [message, setMessage] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const cancelQuestionnaire = useCancelQuestionnaire();
  const skipQuestionnaire = useSkipQuestionnaire();
  const fillUpQuestionnaire = useFillUpQuestionnaire();
  const questionnaireStatus = useGetQuestionnaireStatus({ enabled: false, retry: false });
  const skipTimeoutRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (!config?.questionnaire || !authData.user) {
      return;
    }

    if (compareCurrentHour(config.questionnaire.checkHour)) {
      questionnaireStatus.refetch();
    }
    const interval = setInterval(() => {
      if (compareCurrentHour(config.questionnaire!.checkHour)) {
        questionnaireStatus.refetch();
      }
    }, config.questionnaire.checkIntervalInMinutes * 60000);

    return () => {
      clearInterval(interval);
      skipTimeoutRef.current && clearTimeout(skipTimeoutRef.current);
    };
  }, [
    config.questionnaire?.checkIntervalInMinutes,
    config.questionnaire?.checkHour,
    authData.user,
  ]);

  useEffect(() => {
    setIsModalOpen(questionnaireStatus.data !== null);
  }, [questionnaireStatus.data]);

  const closeModal = () => {
    questionnaireStatus.remove();
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    try {
      if (isNil(rating)) return;
      await fillUpQuestionnaire.mutateAsync({
        rating,
        message,
      });
      dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'Feedback.Questionnaire.Success' })));
      closeModal();
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };
  const handleSkip = async () => {
    try {
      await skipQuestionnaire.mutateAsync();
      skipTimeoutRef.current = setTimeout(() => {
        questionnaireStatus.refetch();
      }, config.questionnaire!.skipTimeInMinutes * 60000);
      closeModal();
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };
  const handleCancel = async () => {
    dispatch(
      showConfirmationModal({
        message: intl.formatMessage({ id: 'Feedback.Questionnaire.ConfirmCancel' }),
        closeMessage: intl.formatMessage({ id: 'Feedback.Questionnaire.ConfirmCancel.No' }),
        confirmMessage: intl.formatMessage({ id: 'Feedback.Questionnaire.ConfirmCancel.Yes' }),
        onClose: () => dispatch(hideConfirmationModal()),
        onConfirm: async () => {
          try {
            await cancelQuestionnaire.mutateAsync();
            closeModal();
          } catch (error) {
            dispatch(
              snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
            );
          }
          dispatch(hideConfirmationModal());
        },
      }),
    );
  };
  const submitDisabled = rating === null;
  const isSkipAvailable = questionnaireStatus.data === QuestionnaireStatus.REQUESTED;

  return (
    <ModalPortal
      isOpen={Boolean(authData.user) && isModalOpen}
      innerPadding={false}
      header={intl.formatMessage({ id: 'Feedback.Questionnaire.Header' })}
      leftButton={
        <Button
          variant="transparent"
          onClick={handleCancel}
          content="text"
          label={intl.formatMessage({
            id: 'Global.Cancel',
          })}
          data-cy="questionnaire-cancel"
        />
      }
      rightButtons={[
        isSkipAvailable ? (
          <Button
            variant="tonal"
            onClick={handleSkip}
            content="text"
            label={intl.formatMessage(
              {
                id: 'Feedback.Questionnaire.Skip',
              },
              { minutes: config.questionnaire?.skipTimeInMinutes },
            )}
            data-cy="questionnaire-skip"
          />
        ) : (
          (null as unknown as ReactElement)
        ),
        <Button
          variant="filled"
          onClick={handleSubmit}
          content="text"
          label={intl.formatMessage({
            id: 'Global.Submit',
          })}
          disabled={submitDisabled}
          data-cy="questionnaire-submit"
        />,
      ]}
    >
      <ModalFormContent data-cy="questionnaire-content" data-guide-blocker noMargin>
        <InfoBar
          text={<FormattedMessage id="Feedback.Questionnaire.InfoBar" />}
          type="info"
          icon
          variant="basic"
        />
        <QuestionnaireContentWrapper>
          <RateContainer>
            <FieldRate
              id="rating"
              onChange={setRating}
              value={rating}
              label={intl.formatMessage({ id: 'Feedback.Questionnaire.Rate' })}
              required
            />
            <RateInfoContainer data-cy="questionnaire-star-info">
              <RateInfo>
                <FormattedMessage id="Feedback.Questionnaire.OneStar" />
              </RateInfo>
              <RateInfo>
                <FormattedMessage id="Feedback.Questionnaire.FiveStars" />
              </RateInfo>
            </RateInfoContainer>
          </RateContainer>
          {rating !== null && (
            <MessageInfo data-cy="questionnaire-message-info">
              <Icon name="Info" />
              {rating >= 3 ? (
                <FormattedMessage id="Feedback.Questionnaire.InfoGood" />
              ) : (
                <FormattedMessage id="Feedback.Questionnaire.InfoBad" />
              )}
            </MessageInfo>
          )}
          <QuestionnaireTextAreaWrapper>
            <FieldTextArea
              id="message"
              label={intl.formatMessage({ id: 'Feedback.Questionnaire.Comment' })}
              name="message"
              onChange={event => setMessage(event.target.value)}
              value={message}
              data-cy="questionnaire-message"
              maxLength={5000}
            />
          </QuestionnaireTextAreaWrapper>
        </QuestionnaireContentWrapper>
      </ModalFormContent>
    </ModalPortal>
  );
}

export const QuestionnaireModal = withAlphamartIntlProvider(QuestionnaireModalComponent, messages);
