import React from 'react';
import { Tag, Tooltip } from '@alpha-recycling/component-library';

import { layers } from 'theme';

interface TagGroupProps {
  size?: 'small' | 'medium' | 'big';
  items: string[];
  countLabelFormatter: (count: number) => string;
}

export const TagGroup = ({ items, countLabelFormatter, size }: TagGroupProps) => {
  if (!items.length) {
    return null;
  }

  if (items.length === 1) {
    return (
      <div>
        <Tag size={size ?? 'medium'} title={items[0]} />
      </div>
    );
  }
  return (
    <Tooltip
      position="top"
      data-cy="tag-group-tooltip"
      label={items.join(', ')}
      zIndex={layers.tooltip}
    >
      <div>
        <Tag size={size ?? 'medium'} title={countLabelFormatter(items.length)} />
      </div>
    </Tooltip>
  );
};
