import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, SuccessResponse } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum DevicesKeys {
  VerifyDevice = 'VerifyDevice',
  AddPendingDevice = 'AddPendingDevice',
  VerifyChallenge = 'VerifyChallenge',
  ResendDeviceChallenge = 'ResendDeviceChallenge',
}

interface DeviceDetailsOptions {
  deviceDetails: string;
}

export interface VerifyChallengeOptions {
  challenge: string;
}

export const useVerifyDevice = (
  options?: UseMutationOptions<void, AlphamartHttpError, DeviceDetailsOptions>,
) =>
  useMutation(
    async params => {
      await getHttpClient().post<SuccessResponse>(`${apiHostname}/api/devices/verify`, params);
    },
    {
      mutationKey: [DevicesKeys.VerifyDevice],
      ...options,
    },
  );

export const useAddPendingDevice = (
  options?: UseMutationOptions<void, AlphamartHttpError, DeviceDetailsOptions>,
) =>
  useMutation(
    async params => {
      await getHttpClient().post<SuccessResponse>(`${apiHostname}/api/devices`, params);
    },
    {
      mutationKey: [DevicesKeys.AddPendingDevice],
      ...options,
    },
  );

export const useVerifyChallenge = (
  options?: UseMutationOptions<void, AlphamartHttpError, VerifyChallengeOptions>,
) =>
  useMutation(
    async params => {
      await getHttpClient().put<SuccessResponse>(`${apiHostname}/api/devices/challenge`, params);
    },
    {
      mutationKey: [DevicesKeys.VerifyChallenge],
      ...options,
    },
  );

export const useResendDeviceChallenge = (options?: UseMutationOptions<void, AlphamartHttpError>) =>
  useMutation(
    async () => {
      await getHttpClient().post<SuccessResponse>(`${apiHostname}/api/devices/challenge/resend`);
    },
    {
      mutationKey: [DevicesKeys.ResendDeviceChallenge],
      ...options,
    },
  );
