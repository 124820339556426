import { PRICE_SOURCES, UserTypes } from 'shared/constants';
import { ManageUserAccesses, UserFormData } from 'shared/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseUser = (
  user: UserFormData,
  { allowedToChangeTermsAdjustment }: ManageUserAccesses,
) => {
  const parsedUserIsBuyer = user.userTypes.chosenUserTypes.some(
    userType => userType.name === UserTypes.BUYER,
  );

  return {
    marginVisibility: user.prices.marginVisibility,
    privileges: Object.fromEntries(
      Object.entries(user.userTypes?.privileges).map(([key, value]) => [
        key,
        [true, 'true'].includes(value),
      ]),
    ),
    ...{
      profitMargins: user.prices.userProfitMargin.map(pM => ({ ...pM, value: +pM.value })),
    },
    ...{
      assignedHedges: user.prices.assignedHedges,
    },
    ...(user.prices.prices && {
      prices: {
        ...user.prices.prices,
        ptHedgeUsed:
          user.prices.prices.ptPriceSource === PRICE_SOURCES.HEDGE
            ? +user.prices.prices.ptHedgeUsed!
            : null,
        pdHedgeUsed:
          user.prices.prices.pdPriceSource === PRICE_SOURCES.HEDGE
            ? +user.prices.prices.pdHedgeUsed!
            : null,
        rhHedgeUsed:
          user.prices.prices.rhPriceSource === PRICE_SOURCES.HEDGE
            ? +user.prices.prices.rhHedgeUsed!
            : null,
      },
    }),
    ...(allowedToChangeTermsAdjustment &&
      parsedUserIsBuyer && {
        ptTermsAdjustment: user.prices.ptTermsAdjustment
          ? +user.prices.ptTermsAdjustment
          : undefined,
        pdTermsAdjustment: user.prices.pdTermsAdjustment
          ? +user.prices.pdTermsAdjustment
          : undefined,
        rhTermsAdjustment: user.prices.rhTermsAdjustment
          ? +user.prices.rhTermsAdjustment
          : undefined,
      }),
    currentProfitMarginPt: user.prices.currentProfitMarginPt,
    currentProfitMarginPd: user.prices.canUseMarginSliderPerMetal
      ? user.prices.currentProfitMarginPd
      : user.prices.currentProfitMarginPt,
    currentProfitMarginRh: user.prices.canUseMarginSliderPerMetal
      ? user.prices.currentProfitMarginRh
      : user.prices.currentProfitMarginPt,
  };
};
