import { IntlShape } from 'react-intl';

import { UserTypes } from 'shared/constants';

export const getUserTypeLang = (name: UserTypes, intl: IntlShape): string => {
  const pascalCased = name
    .toLowerCase()
    .replace(/(^|_)[a-z]/g, match => match.replace('_', '').toUpperCase());
  return intl.formatMessage({ id: `Global.UserType.${pascalCased}` });
};
