import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { BasicUserInfo } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum AssignableUsersKeys {
  GetAssignableUsers = 'GetAssignableUsers',
  GetAssignableUsersForUpdate = 'GetAssignableUsersForUpdate',
}

interface GetAssignableUsersParams {
  hedgeId: number | string;
}
interface GetAssignableUsersForUpdateParams {
  hedgeId: number | string;
  companyId: number | string;
}

export const useGetAssignableUsers = (
  params: GetAssignableUsersParams,
  options: UseQueryOptions<BasicUserInfo[]> = {},
): UseQueryResult<BasicUserInfo[]> =>
  useQuery(
    [AssignableUsersKeys.GetAssignableUsers, params] as QueryKey,
    async () => {
      const response = await getHttpClient().get<BasicUserInfo[]>(
        `${apiHostname}/api/hedges/${params.hedgeId}/assign/list`,
      );
      return response.data;
    },
    {
      initialData: [],
      ...options,
    },
  );

export const useGetAssignableUsersForUpdate = (
  params: GetAssignableUsersForUpdateParams,
  options: UseQueryOptions<BasicUserInfo[]> = {},
): UseQueryResult<BasicUserInfo[]> =>
  useQuery(
    [AssignableUsersKeys.GetAssignableUsersForUpdate, params] as QueryKey,
    async () => {
      const response = await getHttpClient().get<BasicUserInfo[]>(
        `${apiHostname}/api/hedges/${params.hedgeId}/assign/list-for-update`,
        {
          params: {
            companyId: params.companyId,
          },
        },
      );
      return response.data;
    },
    {
      initialData: [],
      ...options,
    },
  );
