import React, { useCallback, useEffect, useState } from 'react';
import { Button, Slider } from '@alpha-recycling/component-library';
import { FormikProvider, useFormik } from 'formik';
import { isNil } from 'lodash';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldPhotos } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { LoadableContent } from 'components/shared/Loader';
import { useAlphamartLocation, useAlphamartNavigate } from 'shared/hooks';
import { fetchConfig } from 'store/config';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateTheme } from 'store/updateThemeSlice';
import { GridItemMockup } from './GridItemMockup/GridItemMockup';
import { designFormSchema } from './designFormSchema';
import {
  DisplaySettingsFieldButtons,
  DisplaySettingsFieldSection,
  DisplaySettingsFieldSectionTitle,
  DisplaySettingsMain,
  DisplaySettingsMockupContainer,
  DisplaySettingsSidebar,
  DisplaySettingsTitle,
} from './DisplaySettings.styles';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

const DisplaySettingsComponent = (): React.ReactElement => {
  const [watermarkPreview, setWatermarkPreview] = useState<string | null>(null);
  const [isGeneratingWatermark, setGeneratingWatermark] = useState(false);
  const upload = useAppSelector(state => state.config?.upload);
  const { theme: currentTheme } = useAppSelector(state => state.config || {});
  const { isPending: isUpdatingTheme } = useAppSelector(state => state.theme);

  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const location = useAlphamartLocation();

  const formikContext = useFormik({
    initialValues: {
      watermarkLogo: currentTheme?.watermarkLogo
        ? [
            {
              file: {
                name: currentTheme?.watermarkLogo.fileName,
                type: currentTheme?.watermarkLogo.mimetype,
              } as File,
              data: currentTheme?.watermarkLogo.externalId,
              name: currentTheme?.watermarkLogo.fileName,
              size: 1,
              image: currentTheme?.watermarkLogo.presignedUrls!.ORIGINAL as string,
              id: currentTheme?.watermarkLogo.id,
            },
          ]
        : [],
      watermarkOpacity: currentTheme?.watermarkOpacity ?? 0,
    },
    enableReinitialize: true,
    validationSchema: designFormSchema(upload!, intl),
    onSubmit: async newTheme => {
      try {
        await dispatch(
          updateTheme(newTheme, newTheme.watermarkOpacity === currentTheme?.watermarkOpacity),
        );
        await dispatch(
          snackBarPushSuccess(intl.formatMessage({ id: 'Settings.Display.UpdateSuccess' })),
        );
        await dispatch(fetchConfig());
        navigate('/company/settings');
      } catch {
        dispatch(snackBarPushFailure(<FormattedMessage id="Global.Error.SomethingWentWrong" />));
      }
    },
  });

  const onCancel = useCallback(() => {
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'Settings.Display.Modal.Message' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: () => {
          navigate('/company/settings');
          dispatch(hideModal());
        },
      }),
    );
  }, [location, dispatch]);

  const {
    handleChange,
    values,
    handleSubmit,
    initialValues,
    touched,
    getFieldMeta,
    setFieldTouched,
    setFieldValue,
    isValid,
  } = formikContext;

  const prepareInitialWatermark = async () => {
    setGeneratingWatermark(true);
    const image = await fetch(initialValues.watermarkLogo[0]?.image);
    const initialWatermark = await import('helpers/watermarks/prepareWatermark').then(
      ({ prepareWatermark }) => prepareWatermark(image),
    );
    const reader = image.body!.getReader();
    let originalLogo: Uint8Array[] = [];
    await reader.read().then(function prepareImage({ value, done }) {
      if (done) return;
      originalLogo = originalLogo.concat(value);

      return reader.read().then(prepareImage);
    });
    const file = new File(originalLogo, initialValues.watermarkLogo[0].file.name, {
      type: initialValues.watermarkLogo[0].file.type,
    });

    setFieldValue('watermarkLogo', [
      {
        ...initialValues.watermarkLogo[0],
        file,
      },
    ]);

    setGeneratingWatermark(false);
    return setWatermarkPreview(initialWatermark);
  };

  useEffect(() => {
    if (initialValues.watermarkLogo.length) prepareInitialWatermark();
  }, [initialValues.watermarkLogo.length]);

  useEffect(() => {
    if (!values.watermarkLogo.length) {
      setWatermarkPreview(null);
      setFieldValue('watermarkOpacity', 0);
    }
  }, [values.watermarkLogo.length]);

  const getErrors = name => Object.keys(touched).includes(name) && getFieldMeta(name).error;

  const handleWatermarkLogoChange = useCallback(
    async e => {
      handleChange(e);
      const isExtensionValid = e.target.value.every(file =>
        new RegExp(`${upload?.watermark?.imageExtensions?.join('|')}$`, 'i').test(file.name),
      );
      const isAllowedSize =
        !isNil(upload?.watermark?.fileSizeLimit) &&
        e.target.value.every(file => file.size <= upload!.watermark.fileSizeLimit);

      if (e.target.value.length && isExtensionValid && isAllowedSize) {
        setGeneratingWatermark(true);
        setFieldValue('watermarkOpacity', 20);

        const image = await import('helpers/watermarks/prepareWatermark').then(
          ({ prepareWatermark }) => prepareWatermark(e.target.value[0].image),
        );

        setGeneratingWatermark(false);
        return setWatermarkPreview(image);
      }
    },
    [setWatermarkPreview, setGeneratingWatermark, values.watermarkOpacity, upload],
  );

  const handleTransparencyChange = (newMinValue: number, newMaxValue: number) => {
    setFieldValue('watermarkOpacity', newMaxValue);
  };

  const handleReset = async () => {
    formikContext.resetForm();
    await prepareInitialWatermark();
    setFieldValue('watermarkLogo', initialValues.watermarkLogo, false);
  };

  const isResetDisabled =
    values.watermarkLogo[0]?.image === initialValues.watermarkLogo[0]?.image &&
    values.watermarkOpacity === initialValues.watermarkOpacity;

  const opacityIsVisible = !!values.watermarkLogo?.length;

  return (
    <LoadableContent mode={LoadableContent.MODE.FULL} loading={isUpdatingTheme}>
      <DisplaySettingsMain>
        <FormikProvider value={formikContext}>
          <DisplaySettingsSidebar>
            <form onSubmit={handleSubmit}>
              <DisplaySettingsTitle>
                <FormattedMessage id="Settings.Display.ApplicationDesign" />
              </DisplaySettingsTitle>
              <DisplaySettingsFieldSection>
                <DisplaySettingsFieldSectionTitle>
                  <FormattedMessage id="Settings.Display.WatermarkLogo" />
                </DisplaySettingsFieldSectionTitle>
                <FieldPhotos
                  name="watermarkLogo"
                  onChange={handleWatermarkLogoChange}
                  onBlur={() => setFieldTouched('watermarkLogo')}
                  onTouch={setFieldTouched}
                  allowedExtensions={upload?.watermark?.imageExtensions as string[]}
                  initialPhotos={values.watermarkLogo}
                  data-cy="watermark-logo"
                  sortable={false}
                  error={getErrors('watermarkLogo')}
                  single
                  fileSizeLimit={upload?.watermark?.fileSizeLimit as number}
                />
                {opacityIsVisible && (
                  <>
                    <DisplaySettingsFieldSectionTitle>
                      <FormattedMessage id="Settings.Display.WatermarkOpacity" />
                    </DisplaySettingsFieldSectionTitle>

                    <Slider
                      min={0}
                      max={100}
                      maxRangeValue={values.watermarkOpacity}
                      onChange={handleTransparencyChange}
                      dataTestId="watermark-opacity-slider"
                    />
                  </>
                )}
              </DisplaySettingsFieldSection>
              <DisplaySettingsFieldSection>
                <DisplaySettingsFieldSectionTitle>
                  <FormattedMessage id="Global.Restore" />
                </DisplaySettingsFieldSectionTitle>
                <Button
                  disabled={isResetDisabled}
                  onClick={handleReset}
                  variant="outline"
                  content="text"
                  label={intl.formatMessage({ id: 'Global.ClearChanges' })}
                />
              </DisplaySettingsFieldSection>
              <DisplaySettingsFieldSection>
                <DisplaySettingsFieldButtons>
                  <Button
                    onClick={onCancel}
                    variant="transparent"
                    content="text"
                    label={intl.formatMessage({ id: 'Global.Cancel' })}
                    data-cy="cancel-button"
                  />
                  <Button
                    type="submit"
                    iconName="Check"
                    disabled={!isValid}
                    label={intl.formatMessage({ id: 'Global.Save' })}
                  />
                </DisplaySettingsFieldButtons>
              </DisplaySettingsFieldSection>
            </form>
          </DisplaySettingsSidebar>
        </FormikProvider>
        <DisplaySettingsMockupContainer>
          <GridItemMockup
            isGeneratingWatermark={isGeneratingWatermark}
            watermark={watermarkPreview}
            watermarkLogo={values.watermarkLogo?.[0]}
            watermarkOpacity={values.watermarkOpacity}
          />
        </DisplaySettingsMockupContainer>
      </DisplaySettingsMain>
    </LoadableContent>
  );
};

export const DisplaySettings = withAlphamartIntlProvider(DisplaySettingsComponent, messages);
