import React, { useEffect, useState } from 'react';
import { ButtonProps, Form, FormRow } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';
import { useFormik } from 'formik';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { MaterialUnit } from 'shared/types';
import { MEDIA_QUERY } from 'theme';
import { singleNonstandardConverterFormSchema } from './singleNonstandardConverterFormSchema';
import { useTypedIntl } from '../../locale/messages';
import { NonstandardConverterFormShape } from '../NonstandardConvertersForm';

export const MainContainer = styled.div`
  @media ${MEDIA_QUERY.XL} {
    margin-top: 25px;
  }
`;

interface Props {
  formId: string;
  onRemove: (formId: string) => void;
  onValidValuesChange: (formId: string, values: NonstandardConverterFormShape) => void;
  onIsValidChange: (formId: string, isValid: boolean) => void;
  placeholderLabel: string;
  canBeRemoved?: boolean;
  initialValues?: NonstandardConverterFormShape;
  materialError?: string | null;
}

export const SingleNonstandardConverterForm = ({
  formId,
  onRemove,
  onValidValuesChange,
  onIsValidChange,
  canBeRemoved,
  initialValues,
  placeholderLabel,
  materialError,
}: Props) => {
  const intl = useTypedIntl();
  const [activeInput, setActiveInput] = useState<string | null>(null);
  const formikContext = useFormik({
    initialValues: initialValues ?? {
      material: '',
      pricePer: MaterialUnit.LB,
      buyingPercentAdjustment: '100',
      dollarPriceAdjustment: '0',
      weightDryLbs: '',
      confirmWeightDryLbs: '',
      platinumAssay: '',
      confirmPlatinumAssay: '',
      palladiumAssay: '',
      confirmPalladiumAssay: '',
      rhodiumAssay: '',
      confirmRhodiumAssay: '',
    },
    onSubmit: () => {},
    validationSchema: singleNonstandardConverterFormSchema(intl),
    isInitialValid: false,
  });
  const handleConfirmFieldFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setActiveInput(e.target.name);
  };
  const handleRemove = () => onRemove(formId);
  useEffect(() => {
    if (formikContext.isValid) {
      onValidValuesChange(formId, formikContext.values);
    }
  }, [formikContext.values, formikContext.isValid]);
  useEffect(() => {
    onIsValidChange(formId, formikContext.isValid);
  }, [formikContext.isValid]);

  const pricePerOptions = [
    {
      label: MaterialUnit.LB,
      value: MaterialUnit.LB,
    },
    {
      label: intl.formatMessage({ id: 'NonstandardConvertersForm.Units.Unit' }),
      value: MaterialUnit.UNIT,
    },
  ];
  const handlePricePerChange = data => {
    formikContext.setFieldValue('pricePer', data);
  };

  useEffect(() => {
    if (!formikContext.values.weightDryLbs) formikContext.setFieldValue('confirmWeightDryLbs', '');
    if (!formikContext.values.platinumAssay)
      formikContext.setFieldValue('confirmPlatinumAssay', '');
    if (!formikContext.values.palladiumAssay)
      formikContext.setFieldValue('confirmPalladiumAssay', '');
    if (!formikContext.values.rhodiumAssay) formikContext.setFieldValue('confirmRhodiumAssay', '');
  }, [formikContext.values]);

  return (
    <MainContainer data-cy="nonstandard-converter-single-form">
      <Form
        {...(materialError && { formDescriptionMessage: materialError })}
        formDescriptionType="danger"
        headerText={formikContext.values.material || placeholderLabel}
        headerButton={
          canBeRemoved
            ? {
                iconName: 'X',
                size: 'small',
                variant: 'plain',
                onClick: handleRemove,
              }
            : (false as unknown as ButtonProps)
        }
      >
        <FormRow>
          <FieldInput
            onChange={formikContext.handleChange}
            value={formikContext.values.material}
            name="material"
            label={intl.formatMessage({ id: 'NonstandardConvertersForm.Material' })}
            error={formikContext.errors.material}
            maxLength={50}
            required
            data-cy="nonstandard-converter-form-material"
          />
          <FieldSelect
            onChange={handlePricePerChange}
            value={formikContext.values.pricePer}
            options={pricePerOptions}
            name="pricePer"
            label={intl.formatMessage({ id: 'NonstandardConvertersForm.PricePer' })}
            required
            data-cy="nonstandard-converter-form-price-per"
          />
          <FieldInput
            onChange={formikContext.handleChange}
            value={formikContext.values.buyingPercentAdjustment}
            name="buyingPercentAdjustment"
            label={intl.formatMessage({ id: 'NonstandardConvertersForm.BuyingPercentAdjustment' })}
            error={formikContext.errors.buyingPercentAdjustment}
            suffix="%"
            maxLength={6}
            required
            data-cy="nonstandard-converter-form-buying-percent-adjustment"
          />
          <FieldInput
            onChange={formikContext.handleChange}
            value={formikContext.values.dollarPriceAdjustment}
            name="dollarPriceAdjustment"
            label={intl.formatMessage({ id: 'NonstandardConvertersForm.DollarPriceAdjustment' })}
            prefix="$"
            error={formikContext.errors.dollarPriceAdjustment}
            maxLength={10}
            required
            data-cy="nonstandard-converter-form-dollar-price-adjustment"
          />
          <FieldInput
            onChange={formikContext.handleChange}
            onFocus={handleConfirmFieldFocus}
            name="weightDryLbs"
            value={formikContext.values.weightDryLbs}
            suffix="lbs"
            protection={{
              hide: activeInput === 'confirmWeightDryLbs',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            label={intl.formatMessage({ id: 'NonstandardConvertersForm.WeightDryLbs' })}
            error={formikContext.errors.weightDryLbs}
            maxLength={10}
            data-cy="nonstandard-converter-form-dry-weight"
          />
          <FieldInput
            onChange={formikContext.handleChange}
            onFocus={handleConfirmFieldFocus}
            name="confirmWeightDryLbs"
            value={formikContext.values.confirmWeightDryLbs}
            disabled={!formikContext.values.weightDryLbs}
            suffix="lbs"
            protection={{
              hide: activeInput === 'weightDryLbs',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            label={intl.formatMessage({ id: 'NonstandardConvertersForm.ConfirmWeightDryLbs' })}
            error={formikContext.errors.confirmWeightDryLbs}
            maxLength={10}
            data-cy="nonstandard-converter-form-confirm-dry-weight"
          />
          <FieldInput
            onChange={formikContext.handleChange}
            onFocus={handleConfirmFieldFocus}
            name="platinumAssay"
            value={formikContext.values.platinumAssay}
            suffix="ppm"
            protection={{
              hide: activeInput === 'confirmPlatinumAssay',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            label={intl.formatMessage({ id: 'NonstandardConvertersForm.PlatinumAssay' })}
            error={formikContext.errors.platinumAssay}
            maxLength={10}
            data-cy="nonstandard-converter-form-platinum-assay"
          />
          <FieldInput
            onChange={formikContext.handleChange}
            onFocus={handleConfirmFieldFocus}
            name="confirmPlatinumAssay"
            value={formikContext.values.confirmPlatinumAssay}
            disabled={!formikContext.values.platinumAssay}
            suffix="ppm"
            protection={{
              hide: activeInput === 'platinumAssay',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            label={intl.formatMessage({ id: 'NonstandardConvertersForm.ConfirmPlatinumAssay' })}
            error={formikContext.errors.confirmPlatinumAssay}
            maxLength={10}
            data-cy="nonstandard-converter-form-confirm-platinum-assay"
          />
          <FieldInput
            onChange={formikContext.handleChange}
            onFocus={handleConfirmFieldFocus}
            name="palladiumAssay"
            value={formikContext.values.palladiumAssay}
            suffix="ppm"
            protection={{
              hide: activeInput === 'confirmPalladiumAssay',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            label={intl.formatMessage({ id: 'NonstandardConvertersForm.PalladiumAssay' })}
            error={formikContext.errors.palladiumAssay}
            maxLength={10}
            data-cy="nonstandard-converter-form-palladium-assay"
          />
          <FieldInput
            onChange={formikContext.handleChange}
            onFocus={handleConfirmFieldFocus}
            name="confirmPalladiumAssay"
            value={formikContext.values.confirmPalladiumAssay}
            disabled={!formikContext.values.palladiumAssay}
            suffix="ppm"
            protection={{
              hide: activeInput === 'palladiumAssay',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            label={intl.formatMessage({ id: 'NonstandardConvertersForm.ConfirmPalladiumAssay' })}
            error={formikContext.errors.confirmPalladiumAssay}
            maxLength={10}
            data-cy="nonstandard-converter-form-confirm-palladium-assay"
          />
          <FieldInput
            onChange={formikContext.handleChange}
            onFocus={handleConfirmFieldFocus}
            name="rhodiumAssay"
            value={formikContext.values.rhodiumAssay}
            suffix="ppm"
            protection={{
              hide: activeInput === 'confirmRhodiumAssay',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            label={intl.formatMessage({ id: 'NonstandardConvertersForm.RhodiumAssay' })}
            error={formikContext.errors.rhodiumAssay}
            maxLength={10}
            data-cy="nonstandard-converter-form-rhodium-assay"
          />
          <FieldInput
            onChange={formikContext.handleChange}
            onFocus={handleConfirmFieldFocus}
            name="confirmRhodiumAssay"
            value={formikContext.values.confirmRhodiumAssay}
            disabled={!formikContext.values.rhodiumAssay}
            suffix="ppm"
            protection={{
              hide: activeInput === 'rhodiumAssay',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            label={intl.formatMessage({ id: 'NonstandardConvertersForm.ConfirmRhodiumAssay' })}
            error={formikContext.errors.confirmRhodiumAssay}
            maxLength={10}
            data-cy="nonstandard-converter-form-confirm-rhodium-assay"
          />
        </FormRow>
      </Form>
    </MainContainer>
  );
};
