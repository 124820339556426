import { createSlice } from '@reduxjs/toolkit';

interface State {
  termsTypeChanged: boolean;
}

const termsSlice = createSlice<
  State,
  {
    setTermsTypeChanged: (state: State, action: { payload: boolean }) => void;
  }
>({
  name: 'terms',
  initialState: { termsTypeChanged: false },
  reducers: {
    setTermsTypeChanged: (state, action) => ({
      termsTypeChanged: action.payload,
    }),
  },
});

export const { setTermsTypeChanged } = termsSlice.actions;

export default termsSlice.reducer;
