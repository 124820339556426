import React from 'react';
import { DataOutput, OutputCell } from '@alpha-recycling/component-library';

import { SHARED } from 'shared/constants';
import { UsageThresholds } from 'shared/types';
import { useTypedIntl } from '../locale/messages';

interface Props {
  company?: UsageThresholds;
}

export function CompanyDetailsThresholds({ company }: Props): React.ReactElement {
  const intl = useTypedIntl();
  return (
    <DataOutput
      headerText={intl.formatMessage({
        id: 'CompanyDetails.Thresholds.Label',
      })}
    >
      {[
        {
          name: 'maxConverterViewsPerDay',
          label: intl.formatMessage({ id: 'CompanyDetails.Thresholds.maxConverterViews' }),
          value: company?.maxConverterViewsPerDay ?? SHARED.LONG_DASH,
        },
        {
          name: 'maxConverterSearchesPerDay',
          label: intl.formatMessage({ id: 'CompanyDetails.Thresholds.maxConverterSearches' }),
          value: company?.maxConverterSearchesPerDay ?? SHARED.LONG_DASH,
        },
      ].map(field => (
        <OutputCell key={field.name} data-cy={field.name} mergeCells={2} labelValue={field.label}>
          {field.value}
        </OutputCell>
      ))}
    </DataOutput>
  );
}
