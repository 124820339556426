import React, { useEffect } from 'react';
import { Button, DataOutput, OutputCell } from '@alpha-recycling/component-library';
import { css } from '@emotion/css';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelLayout } from 'components/shared/PanelLayout/PanelLayout';
import { formatDate } from 'helpers/dateTime/dateTime';
import { ModalFormType, SHARED } from 'shared/constants';
import { useAlphamartNavigate, useCurrentUser } from 'shared/hooks';
import { fetchUser } from 'store/auth';
import { showModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { GoToPreferenceModal } from './GoToPreferenceModal/GoToPreferenceModal';
import { messages, useTypedIntl } from './locale/messages';
import { MiscellaneousFees } from './Sections/MiscellaneousFees/MiscellaneousFees';
import { OtherCharges } from './Sections/OtherCharges/OtherCharges';
import { RatesAndCharges } from './Sections/RatesAndCharges/RatesAndCharges';

export const panelContent = css`
  overflow: auto;
`;

export const panelContentMain = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;
`;

const TermsPageComponent = () => {
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const { termsTypeChanged } = useAppSelector(state => state.terms);

  const updateTerms = () => {
    navigate('/terms/edit');
  };

  useEffect(() => {
    dispatch(fetchUser(true));
  }, []);

  useEffect(() => {
    if (termsTypeChanged) {
      setTimeout(
        () => dispatch(showModalForm({ modalType: ModalFormType.GoToPreference, params: null })),
        600,
      );
    }
  }, [termsTypeChanged]);

  const { terms, grading: isGrading } = currentUser.company;

  return (
    <>
      <PanelLayout>
        <PanelHeader title={intl.formatMessage({ id: 'Terms.Header' })}>
          <Button
            onClick={updateTerms}
            content="text"
            variant="filled"
            label={intl.formatMessage({ id: 'Global.Update' })}
          />
        </PanelHeader>
        <PanelContent className={panelContent}>
          <PanelContentMain className={panelContentMain}>
            <DataOutput
              headerText={intl.formatMessage({
                id: 'Terms.TermsType',
              })}
            >
              <OutputCell
                dataTestId="termsType"
                mergeCells={4}
                labelValue={intl.formatMessage({ id: 'Terms.Type' })}
              >
                {intl.formatMessage({ id: isGrading ? 'Terms.Grading' : 'Terms.Assay' })}
              </OutputCell>
            </DataOutput>
            {!isGrading ? (
              <>
                <RatesAndCharges terms={terms} />
                <OtherCharges terms={terms} />
                <MiscellaneousFees terms={terms} />
              </>
            ) : null}
            <DataOutput
              headerText={intl.formatMessage({
                id: 'Global.MoreDetails',
              })}
            >
              {[
                {
                  name: 'createdBy',
                  label: intl.formatMessage({
                    id: 'Global.CreatedBy',
                  }),
                  value: terms.createdBy
                    ? `${terms.createdBy.firstName} ${terms.createdBy.lastName}`
                    : SHARED.LONG_DASH,
                },
                {
                  name: 'createdAt',
                  label: intl.formatMessage({
                    id: 'Global.CreatedOn',
                  }),
                  value: formatDate(terms.createdAt),
                },
                {
                  name: 'updatedBy',
                  label: intl.formatMessage({
                    id: 'Global.UpdatedBy',
                  }),
                  value: terms.updatedBy
                    ? `${terms.updatedBy.firstName} ${terms.updatedBy.lastName}`
                    : SHARED.LONG_DASH,
                },
                {
                  name: 'updatedAt',
                  label: intl.formatMessage({
                    id: 'Global.UpdatedOn',
                  }),
                  value: terms.updatedAt ? formatDate(terms.updatedAt) : SHARED.LONG_DASH,
                },
              ].map((field, index, fields) => (
                <OutputCell
                  key={field.name}
                  dataTestId={field.name}
                  mergeCells={fields.length % 2! !== 0 && fields.length - 1 === index ? 4 : 1}
                  labelValue={field.label}
                >
                  {field.value}
                </OutputCell>
              ))}
            </DataOutput>
          </PanelContentMain>
        </PanelContent>
      </PanelLayout>
      <GoToPreferenceModal />
    </>
  );
};

export const TermsPage = withAlphamartIntlProvider(TermsPageComponent, messages);
