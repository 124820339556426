import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { capitalize } from 'lodash';

import { FieldMultiSelectRaw } from 'components/shared/Fields/FieldSelect/FieldMultiSelect';
import { FieldSelectRaw } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FiltersForm } from 'components/shared/forms/FiltersForm/FiltersForm';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { DEFAULT_FILTERS, FilterableModules, PERMISSIONS, SHARED } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useEffectAfterMount, useMediaQuery } from 'shared/hooks';
import { useGetCompanies, useGetUsers } from 'shared/queries';
import { Status } from 'shared/types';
import { useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';

const getStatusOption = (status, intl) => ({
  value: status,
  label: capitalize(intl.formatMessage({ id: `Global.Status.${status}` })),
});

export type HedgePlacedOn = 3 | 6 | 12 | null;
export interface HedgesFiltersShape {
  company?: number | null;
  status?: Status | null;
  users?: number[];
  placedOn: HedgePlacedOn;
}

interface Props {
  onFiltersChanged: (filters?: HedgesFiltersShape) => void;
  onFiltersApplied: () => void;
}

export const HedgesFilters = ({
  onFiltersChanged,
  onFiltersApplied,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.HEDGES]);
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  const formikContext = useFormik<HedgesFiltersShape>({
    initialValues: { ...savedFilters.data },
    onSubmit: () => {},
  });
  const { setValues, values } = formikContext;
  useEffectAfterMount(() => {
    !isMobile && onFiltersChanged(values);
  }, [values]);
  const statusOptions = [Status.ACTIVE, Status.INACTIVE].map(status =>
    getStatusOption(status, intl),
  );
  const placedOnOptions = [3, 6, 12].map(el => ({
    value: el,
    label: `${intl.formatMessage({
      id: 'Global.Fields.InputRange.From',
    })} ${el} ${intl.formatMessage({ id: 'Global.Fields.Select.Date.Months.Long' })}`,
  }));

  const [userSearchPhrase, setUserSearchPhrase] = useState<string>('');
  const { data: users } = useGetUsers(
    {
      status: [Status.ACTIVE],
      company: values.company,
      page: 1,
      pageSize: Number(SHARED.SELECT_INPUT_PAGE_LIMIT),
      query: userSearchPhrase,
    },
    { initialData: [] },
  );
  const usersOptions = users!.map(user => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));
  const [companySearchPhrase, setCompanySearchPhrase] = useState<string>('');
  const canFilterByCompany = authorize(PERMISSIONS.HEDGES.FILTER_BY_COMPANY);
  const companiesForFilters = useGetCompanies(
    { pageSize: Number(SHARED.SELECT_INPUT_PAGE_LIMIT), page: 1, query: companySearchPhrase },
    { enabled: canFilterByCompany },
  );
  const companyOptions = useMemo(
    () =>
      companiesForFilters.data?.data.map(company => ({ value: company.id, label: company.name })) ??
      [],
    [companiesForFilters.data?.data],
  );

  const onFiltersClear = () => {
    setValues(DEFAULT_FILTERS.hedges);
    onFiltersChanged(DEFAULT_FILTERS.hedges);
  };
  const onFiltersApply = () => {
    onFiltersChanged(values);
    onFiltersApplied();
  };
  const onCompaniesChange = () => {
    setValues({ ...values, users: [] });
  };

  return (
    <FiltersForm
      context={formikContext}
      onFiltersClear={onFiltersClear}
      onFiltersApply={onFiltersApply}
      savedFilters={savedFilters}
    >
      {canFilterByCompany && (
        <FiltersSection label={intl.formatMessage({ id: 'HedgesList.Filters.Company' })}>
          <FieldSelectRaw
            name="company"
            options={companyOptions}
            value={values.company ?? ''}
            onChange={onCompaniesChange}
            data-cy="filter-company"
            searchable
            customSearchFunction={(options, text) => {
              setCompanySearchPhrase(text);
              return options;
            }}
          />
        </FiltersSection>
      )}
      <FiltersSection
        sectionName="assignedUsers"
        label={intl.formatMessage({ id: 'HedgesList.Filters.Section.Users' })}
      >
        <FieldMultiSelectRaw
          name="users"
          options={usersOptions}
          value={values.users}
          searchable
          customSearchFunction={(options, text) => {
            setUserSearchPhrase(text);
            return options;
          }}
          data-cy="filter-users"
        />
      </FiltersSection>
      <FiltersSection
        sectionName="placedOn"
        label={intl.formatMessage({ id: 'HedgesList.Filters.Section.PlacedOn' })}
      >
        <FieldSelectRaw
          name="placedOn"
          options={placedOnOptions}
          value={values.placedOn}
          data-cy="filter-placed-on"
        />
      </FiltersSection>
      <FiltersSection
        sectionName="status"
        label={intl.formatMessage({ id: 'HedgesList.Filters.Section.Status' })}
      >
        <FieldSelectRaw
          name="status"
          options={statusOptions}
          value={values.status}
          data-cy="filter-status"
        />
      </FiltersSection>
    </FiltersForm>
  );
};
