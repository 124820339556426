import { isNil } from 'lodash';
import * as yup from 'yup';

import { CompanyMessages, TypedIntlShape } from '../locale/messages';

export const companyFormSchema = (intl: TypedIntlShape): yup.AnyObjectSchema => {
  const prefix = 'CompanyForm.Errors.';

  const decimalSchema = (name: string, min: number, max: number): yup.AnySchema =>
    yup
      .mixed()
      .required(intl.formatMessage({ id: `${prefix}${name}.Required` as keyof CompanyMessages }))
      .test({
        message: intl.formatMessage({
          id: 'Global.Validation.TwoDigits',
        }),
        test: value => /^-?\d+(\.\d{1,2})?$/.test(value),
      })
      .test({
        message: intl.formatMessage({ id: 'Global.Validation.GreaterOrEqual' }, { number: min }),
        test: value => Number(value) >= min,
      })
      .test({
        message: intl.formatMessage({ id: 'Global.Validation.TooBig' }),
        test: value => Number(value) <= max,
      })
      .transform(value => (value?.toString()?.trim().length ? +value : null));

  const decimalOptionalSchema = (
    min: number,
    max: number,
  ): yup.NumberSchema<number | null | undefined> =>
    yup
      .mixed()
      .nullable()
      .optional()
      .test({
        message: intl.formatMessage({
          id: 'Global.Validation.TwoDigits',
        }),
        test: value => (!value ? true : /^-?\d+(\.\d{1,2})?$/.test(value)),
      })
      .test({
        message: intl.formatMessage({ id: 'Global.Validation.GreaterOrEqual' }, { number: min }),
        test: value => (!value ? true : Number(value) >= min),
      })
      .test({
        message: intl.formatMessage({ id: 'Global.Validation.TooBig' }),
        test: value => (!value ? true : Number(value) <= max),
      })
      .transform(value => {
        if (value === '' || isNil(value)) {
          return null;
        }

        return Number.isNaN(Number(value)) ? value : Number(value);
      });

  const integerOptionalSchema = (
    name: string,
    min: number,
    max: number,
  ): yup.NumberSchema<number | undefined | null> =>
    yup
      .number()
      .typeError(intl.formatMessage({ id: `${prefix}${name}.Integer` as keyof CompanyMessages }))
      .integer(intl.formatMessage({ id: `${prefix}${name}.Integer` as keyof CompanyMessages }))
      .min(min, intl.formatMessage({ id: `${prefix}${name}.TooSmall` as keyof CompanyMessages }))
      .max(max, intl.formatMessage({ id: `${prefix}${name}.TooBig` as keyof CompanyMessages }))
      .nullable()
      .optional()
      .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

  const dependencyRequired = (existingSchema: yup.AnySchema, fieldName: string, keys: string[]) =>
    existingSchema.when(keys, {
      is: (...values: unknown[]) => values.some(p => !isNil(p)),
      then: schema =>
        schema.required(
          intl.formatMessage({ id: `${prefix}${fieldName}.Required` as keyof CompanyMessages }),
        ),
      otherwise: schema => schema,
    });

  const financeRateFields = ['platinum', 'palladium', 'rhodium'].map(p => `${p}ReturnFinanceRate`);

  return yup.object().shape({
    terms: yup.object().shape(
      {
        treatmentChargePerPound: decimalOptionalSchema(0, 100000),
        metalsReturnTermInDays: dependencyRequired(
          integerOptionalSchema('MetalsReturnTermInDays', 0, 150),
          'MetalsReturnTermInDays',
          financeRateFields,
        ),
        platinumReturnRate: decimalSchema('PlatinumReturnRate', 70, 98.5),
        palladiumReturnRate: decimalSchema('PalladiumReturnRate', 70, 98.5),
        rhodiumReturnRate: decimalSchema('RhodiumReturnRate', 70, 96),
        platinumReturnFinanceRate: dependencyRequired(
          decimalOptionalSchema(0, 100),
          'PlatinumReturnFinanceRate',
          ['metalsReturnTermInDays'],
        ),
        palladiumReturnFinanceRate: dependencyRequired(
          decimalOptionalSchema(0, 100),
          'PalladiumReturnFinanceRate',
          ['metalsReturnTermInDays'],
        ),
        rhodiumReturnFinanceRate: dependencyRequired(
          decimalOptionalSchema(0, 100),
          'RhodiumReturnFinanceRate',
          ['metalsReturnTermInDays'],
        ),
        platinumRefiningCharge: decimalOptionalSchema(0, 999),
        palladiumRefiningCharge: decimalOptionalSchema(0, 999),
        rhodiumRefiningCharge: decimalOptionalSchema(0, 999),
        miscellaneousFees: yup.string().nullable().optional(),
      },
      financeRateFields.map(p => [p, 'metalsReturnTermInDays'] as [string, string]),
    ),
  });
};
