import React from 'react';
import { useParams } from 'react-router-dom';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { LoadableContent } from 'components/shared/Loader';
import { ModalFormType } from 'shared/constants';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useUpdateHedge } from 'shared/mutations';
import { useGetHedgeDetails } from 'shared/queries';
import { isAlphamartHttpError } from 'shared/types';
import { HedgeFormSchema } from 'shared/types/hedgeFormSchema ';
import { showModalForm } from 'store/modalFormSlice';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { HedgeForm } from '../HedgeForm/HedgeForm';
import { messages, useTypedIntl } from '../locale/messages';
import { MarketPriceRangeModal } from '../MarketPriceRangeModal/MarketPriceRangeModal';

const UpdateHedgeComponent = (): React.ReactElement => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: hedge, isFetching: isHedgeDetailsFetching } = useGetHedgeDetails(+id!);
  const { mutateAsync: updateHedge, isLoading: isUpdateHedgeLoading } = useUpdateHedge();
  const navigate = useAlphamartNavigate();

  const initialValues: HedgeFormSchema | undefined = hedge && {
    company: hedge.company.id,
    hedgeCompany: hedge.hedgeCompany,
    ptPriceCust: hedge.ptPriceCust === 0 ? null : String(hedge.ptPriceCust ?? ''),
    pdPriceCust: hedge.pdPriceCust === 0 ? null : String(hedge.pdPriceCust ?? ''),
    rhPriceCust: hedge.rhPriceCust === 0 ? null : String(hedge.rhPriceCust ?? ''),
    ptPriceCustConfirm: hedge.ptPriceCust === 0 ? null : String(hedge.ptPriceCust ?? ''),
    pdPriceCustConfirm: hedge.pdPriceCust === 0 ? null : String(hedge.pdPriceCust ?? ''),
    rhPriceCustConfirm: hedge.rhPriceCust === 0 ? null : String(hedge.rhPriceCust ?? ''),
    relatedLot: hedge.relatedLot,
    name: hedge.name,
    note: hedge.note ?? '',
    placedAt: new Date(hedge.placedAt),
    expiresAt: new Date(hedge.expiresAt),
    assignedUsers: null,
  };

  const submitFormData = async (values: HedgeFormSchema) => {
    try {
      await updateHedge({ values, id: id! });
      dispatch(
        snackBarPushSuccess(
          intl.formatMessage({
            id: `HedgeForm.Update.Success${values.assignedUsers?.length ? 'WithUsers' : ''}`,
          }),
        ),
      );
      navigate(`/hedges/${id}`);
    } catch (e) {
      if (
        isAlphamartHttpError(e) &&
        e.response?.data?.errorData?.constraints.includes('IsPriceInMarketRangeConstraint')
      ) {
        dispatch(showModalForm({ modalType: ModalFormType.MarketPriceRange, params: null }));
      } else {
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
        );
      }
    }
  };

  const handleSubmit = async (values: HedgeFormSchema) => {
    const companyChanged = initialValues?.company !== values.company;
    if (companyChanged) {
      dispatch(
        showModal({
          message: intl.formatMessage({ id: 'HedgeForm.Update.ChangeCompanyWarning' }),
          onClose: () => {
            dispatch(hideModal());
          },
          onConfirm: async () => {
            dispatch(hideModal());
            await submitFormData(values);
          },
        }),
      );
    } else {
      await submitFormData(values);
    }
  };

  return (
    <>
      <FormContainer>
        <LoadableContent
          loading={isUpdateHedgeLoading || isHedgeDetailsFetching}
          mode={LoadableContent.MODE.FULL}
          drawContent
        >
          {hedge && (
            <HedgeForm
              onSubmit={handleSubmit}
              editMode
              initialValues={initialValues}
              hedgeId={hedge.id}
            />
          )}
        </LoadableContent>
      </FormContainer>
      <MarketPriceRangeModal />
    </>
  );
};

export const UpdateHedge = withAlphamartIntlProvider(UpdateHedgeComponent, messages);
