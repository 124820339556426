import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { MEDIA_QUERY } from 'theme';

export const searchBarStyles = (searchInputVisible: boolean) => css`
  ${searchInputVisible && `display: grid;`}

  grid-template-columns: 340px auto;
  gap: 16px;
  margin-bottom: 16px;
  position: relative;
  align-items: flex-start;

  @media ${MEDIA_QUERY.MAX_MD} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const SidebarButton = styled.div`
  position: relative;
  margin-left: 10px;

  [class*='counter'] {
    position: absolute;
    top: -5px;
    right: -3px;
  }

  @media ${MEDIA_QUERY.XL} {
    display: none;
  }
`;

export const sidebarStyles = chooseListType => css`
  justify-self: end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media ${MEDIA_QUERY.MAX_MD} {
    justify-content: ${chooseListType ? 'space-between' : 'flex-end'};
  }
`;
