import styled from '@emotion/styled';

import { MEDIA_QUERY } from 'theme';

export const ModalFormContent = styled.div<{ noMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: ${props => (props?.noMargin ? '0' : '20px 30px 40px 30px')}
  gap: 20px;

  fieldset {
    border-bottom: none;
  }
`;

export const ModalFormInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  & > div:nth-child(1) {
    padding: 5px 10px;
    flex-basis: 100%;

    @media ${MEDIA_QUERY.MAX_MD} {
      padding: 10px 0 0 0;
      flex-basis: 82%;
    }
  }

  & > div:nth-child(2) {
    width: 50px;
    text-align: center;
    align-self: self-end;
    margin-bottom: 5px;

    @media ${MEDIA_QUERY.MAX_MD} {
      margin-bottom: 0;
    }
  }

  label {
    flex-grow: 1;
  }
`;
