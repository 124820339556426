import React from 'react';
import { InputGroup } from '@alpha-recycling/component-library';
import { isNil } from 'lodash';

import { useTypedIntl } from 'locale/messages';
import { FieldInputRaw } from '../FieldInput/FieldInput';
import { withFilterWrapper } from '../FieldWrapper/FilterWrapper';

export type RangeValue = {
  from?: number | null;
  to?: number | null;
};
interface Props {
  value: RangeValue;
  max?: number;
  min?: number;
  onChange(val: RangeValue): void;
}

export const FieldInputRange = ({
  value,
  onChange,
  min = 0,
  max = 100,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      onChange({
        ...value,
        [event.target.name]: null,
      });
      return;
    }

    const numericValue = Number(event.target.value);
    if (Number.isInteger(numericValue) && numericValue >= min && numericValue <= max) {
      onChange({
        ...value,
        [event.target.name]: numericValue,
      });
    }
  };

  const maskedFrom = !isNil(value?.from) ? value!.from : '';
  const maskedTo = !isNil(value?.to) ? value!.to : '';

  return (
    <InputGroup size="fifty-fifty">
      <FieldInputRaw
        value={String(maskedFrom)}
        name="from"
        onChange={handleChange}
        placeholder={intl.formatMessage({ id: 'Global.Fields.InputRange.From' })}
        data-cy="field-input-range-from"
      />
      <FieldInputRaw
        value={String(maskedTo)}
        name="to"
        onChange={handleChange}
        placeholder={intl.formatMessage({ id: 'Global.Fields.InputRange.To' })}
        data-cy="field-input-range-to"
      />
    </InputGroup>
  );
};

export const FilterFieldInputRange = withFilterWrapper<Props>(props => (
  <FieldInputRange {...props} />
));
