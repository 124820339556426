import React from 'react';
import { Button, ModalPortal } from '@alpha-recycling/component-library';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { ModalFormContent } from 'components/shared/forms/Form/ModalForm.styles';
import { ModalFormType } from 'shared/constants';
import { hideModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

export const MarketPriceRangeModal = () => {
  const intl = useTypedIntl();
  const { isOpen, modalType } = useAppSelector(state => state.modalForm);
  const isModalOpen = modalType === ModalFormType.MarketPriceRange && isOpen;
  const dispatch = useAppDispatch();

  const hideModal = () => {
    dispatch(hideModalForm());
  };

  return (
    <AlphamartIntlProvider messages={messages}>
      <ModalPortal
        isOpen={isModalOpen}
        innerPadding={false}
        data-cy="market-price-range-modal"
        header={intl.formatMessage({ id: 'Hedge.MarketPriceRangeModal.Header' })}
        rightButtons={[
          <Button
            onClick={hideModal}
            data-cy="cancel"
            variant="transparent"
            content="text"
            label={intl.formatMessage({ id: 'Global.Close' })}
          />,
        ]}
      >
        <ModalFormContent>
          <FormattedMessage id="Hedge.MarketPriceRangeModal.Content" />
          <li>
            <FormattedMessage id="Hedge.MarketPriceRangeModal.Phone" />
          </li>
          <li>
            <FormattedMessage id="Hedge.MarketPriceRangeModal.Email" />
          </li>
        </ModalFormContent>
      </ModalPortal>
    </AlphamartIntlProvider>
  );
};
