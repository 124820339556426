import React from 'react';
import { Button, ModalPortal } from '@alpha-recycling/component-library';
import { add, addDays } from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldDatePickerRange } from 'components/shared/Fields/FieldDatePickerRange/FieldDatePickerRange';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { ModalFormContent } from 'components/shared/forms/Form/ModalForm.styles';
import { LoadableContent } from 'components/shared/Loader';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { ModalFormType } from 'shared/constants';
import { getDateString } from 'shared/utils/getDateString';
import { downloadFile } from 'store/downloadFileSlice';
import { hideModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { messages, useTypedIntl } from '../locale/messages';

const validationSchema = Yup.object().shape({
  addedAt: Yup.object()
    .shape({
      from: Yup.string().required(),
      to: Yup.string().required(),
    })
    .required(),
});

const DownloadUserLocationsModalComponent = (): React.ReactElement => {
  const intl = useTypedIntl();
  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);
  const isFileDownloading = useAppSelector(state => state.downloadFile.isPending);
  const dispatch = useAppDispatch();

  const formikContext = useFormik({
    initialValues: {
      addedAt: { from: null, to: null },
    },
    validationSchema,
    onSubmit: async x => x,
  });
  const submitDisabled = !formikContext.isValid || Object.keys(formikContext.touched).length === 0;
  const handleCancelClick = () => {
    formikContext.resetForm();
    dispatch(hideModalForm());
  };
  const companyLocations = modalType === ModalFormType.CompanyLocations;
  const userLocations = modalType === ModalFormType.UserLocations;

  const handleConfirmClick = async () => {
    try {
      const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
      await dispatch(
        downloadFile(
          `/api/locations/export${companyLocations ? '/company' : ''}`,
          `locations_${formatDateWithTime(new Date())}.xlsx`,
          {
            ...params,
            addedFrom: getDateString(formikContext.values.addedAt.from!),
            addedTo: getDateString(addDays(formikContext.values.addedAt.to!, 1)),
            timezone,
          },
        ),
      );
      dispatch(hideModalForm());
      formikContext.resetForm();
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  const maxDate =
    formikContext.values?.addedAt?.from &&
    add(new Date(formikContext.values.addedAt.from), { days: 30 });

  return (
    <ModalPortal
      isOpen={isOpen && (companyLocations || userLocations)}
      innerPadding={false}
      rightButtons={[
        <Button
          onClick={handleCancelClick}
          variant="transparent"
          content="text"
          label={intl.formatMessage({ id: 'Global.Cancel' })}
        />,
        <Button
          onClick={handleConfirmClick}
          content="text"
          disabled={submitDisabled}
          label={intl.formatMessage({ id: 'Global.DownloadLogs.Confirm' })}
        />,
      ]}
    >
      <LoadableContent mode={LoadableContent.MODE.FULL} loading={isFileDownloading} />
      <ModalFormContent>
        <FormikProvider value={formikContext}>
          <FiltersSection
            noPadding
            label={intl.formatMessage({ id: 'Global.DownloadLogs.SelectDateRange' })}
          >
            <FieldDatePickerRange
              name="addedAt"
              value={formikContext.values.addedAt}
              max={maxDate}
              isClearable
              dataTestId="addedAt"
            />
          </FiltersSection>
        </FormikProvider>
      </ModalFormContent>
    </ModalPortal>
  );
};

export const DownloadUserLocationsModal = withAlphamartIntlProvider(
  DownloadUserLocationsModalComponent,
  messages,
);
