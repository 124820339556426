import React from 'react';
import styled from '@emotion/styled';

import { TextButton } from 'components/shared/Buttons';
import { DashboardFiltersKeys, FilterableModules } from 'shared/constants';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';
import { useTypedIntl } from '../../locale/messages';

type DatesForFilter = {
  value: DashboardFiltersKeys;
  label: string;
};
const Container = styled.span`
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media ${MEDIA_QUERY.MAX_MD} {
    gap: 5px;
    margin-right: 5px;
  }
`;

export const SelectPeriod = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const savedFilters = useAppSelector(state => state.filters.statistics);
  const intl = useTypedIntl();
  const datesForFilter: DatesForFilter[] = [
    {
      label: `1${intl.formatMessage({ id: 'Global.Fields.Select.Date.Day.Short' })}`,
      value: 'Days-1',
    },
    {
      label: `1${intl.formatMessage({ id: 'Global.Fields.Select.Date.Week.Short' })}`,
      value: 'Weeks-1',
    },
    {
      label: `1${intl.formatMessage({ id: 'Global.Fields.Select.Date.Month.Short' })}`,
      value: 'Months-1',
    },
    {
      label: `1${intl.formatMessage({ id: 'Global.Fields.Select.Date.Year.Short' })}`,
      value: 'Years-1',
    },
    {
      label: `5${intl.formatMessage({ id: 'Global.Fields.Select.Date.Years.Short' })}`,
      value: 'Years-5',
    },
  ];
  const handleSelect = (value: DashboardFiltersKeys) => {
    dispatch(
      saveFilters({
        filterKey: FilterableModules.STATISTICS,
        values: { ...savedFilters.data, date: value },
      }),
    );
  };

  return (
    <Container>
      {datesForFilter.map(el => (
        <TextButton
          key={el.value}
          color={savedFilters.data.date !== el.value ? theme.fontColor : undefined}
          isActive={savedFilters.data.date === el.value}
          onClick={() => handleSelect(el.value)}
        >
          {el.label}
        </TextButton>
      ))}
    </Container>
  );
};
