import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { fillGaps, formatTemplate, templateGenerator } from 'shared/helpers';
import { MEDIA_QUERY, Theme, theme as appTheme } from 'theme';
import { DetailsField } from './DetailsField';

export interface DetailsSectionField {
  name: string;
  value: ReactNode;
  label?: ReactNode;
  padding?: {
    v?: number;
    h?: number;
  };
  doNotRender?: boolean;
}
interface DetailsSectionProps {
  background?: string;
  id?: string;
  asLink?: boolean;
  children?: ReactNode;
  fields?: DetailsSectionField[];
  template?: string[];
  mobileTemplate?: string[];
  title?: ReactNode;
  rowGap?: boolean;
  hasRoundedBorders?: boolean;
  cols?: number;
  dataCy?: string;
  className?: string;
}

const SectionHeader = styled.header<{ theme?: Theme }>`
  padding: 30px 0 24px;
  font-weight: 700;
  font-size: 13px;
  color: ${({ theme }) => theme.fontColor};

  @media ${MEDIA_QUERY.MAX_MD} {
    padding: 24px 16px 16px;
  }
`;

const DetailsContent = styled.div<{
  theme?: Theme;
  rowGap: boolean;
  asLink?: boolean;
  templateAreas: string;
  mobileTemplateAreas: string;
  background?: string;
  roundedBorders: boolean;
}>`
  background: ${({ theme }) => theme.colors.mineShaftLighter};
  display: grid;
  gap: 1px;
  grid-template-areas: ${({ templateAreas }) => templateAreas};
  grid-template-columns: repeat(3, 1fr);
  position: relative;

  ${({ roundedBorders, background }) =>
    roundedBorders &&
    css`
      &:before,
      &:after {
        background-color: ${background || appTheme.colors.mineShaft};
        content: '';
        display: block;
        height: 8px;
        position: absolute;
        width: 100%;
      }

      &:before {
        border-radius: 4px 4px 0 0;
        top: -8px;
      }

      &:after {
        border-radius: 0 0 4px 4px;
        bottom: -8px;
      }
    `}

  ${({ rowGap }) =>
    rowGap &&
    css`
      background: none;
      column-gap: 0;
      row-gap: 15px;

      > div {
        &:nth-of-type(3n + 1) {
          border-bottom-left-radius: 8px;
          border-right: 1px solid ${appTheme.colors.mineShaftLighter};
          border-top-left-radius: 8px;
        }
        &:nth-of-type(3n + 3) {
          border-bottom-right-radius: 8px;
          border-left: 1px solid ${appTheme.colors.mineShaftLighter};
          border-top-right-radius: 8px;
        }
      }
    `}

  ${({ asLink }) =>
    asLink &&
    css`
      background-color: transparent;

      div {
        border-radius: 4px;
      }
    `}

    @media ${MEDIA_QUERY.MAX_XL} {
    ${({ mobileTemplateAreas, rowGap }) =>
      css`
        ${mobileTemplateAreas.length > 0
          ? css`
              grid-template-areas: ${mobileTemplateAreas};
            `
          : css`
              display: block;

              ${rowGap &&
              css`
                > div {
                  margin-bottom: 0;

                  &:nth-of-type(3n + 1) {
                    border-bottom-left-radius: 0;
                    border-bottom: 1px solid ${appTheme.colors.mineShaftLighter};
                    border-right: none;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                  }
                  &:nth-of-type(3n + 3) {
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    border-left: none;
                    border-top-right-radius: 0;
                    border-top: 1px solid ${appTheme.colors.mineShaftLighter};
                    margin-bottom: 15px;
                  }
                }
              `}
            `}
      `}
  }
`;

const PlaceholderField = styled.div<{ theme?: Theme; name: string }>`
  grid-area: ${({ name }) => name};
  background: ${({ theme }) => theme.colors.mineShaft};
`;

export const DetailsSection = ({
  id,
  background,
  title,
  children,
  template = [],
  mobileTemplate = [],
  fields,
  asLink,
  rowGap = false,
  hasRoundedBorders = true,
  cols = 3,
  dataCy = 'details-section',
  className,
}: DetailsSectionProps): React.ReactElement => {
  const defaultTemplate = template?.length > 0 ? template : templateGenerator(fields, cols);
  const templateAreas = formatTemplate(fillGaps(defaultTemplate));
  const mobileTemplateAreas = mobileTemplate?.length
    ? formatTemplate(fillGaps(mobileTemplate))
    : '';
  const placeholders = templateAreas?.match(/placeholder\d+/g) || [];

  const roundedBorders = hasRoundedBorders && fields && fields.length > 0;

  return (
    <div id={id} data-cy={dataCy} className={className}>
      {title && <SectionHeader>{title}</SectionHeader>}
      {children}
      {fields && (
        <DetailsContent
          asLink={asLink}
          templateAreas={templateAreas}
          mobileTemplateAreas={mobileTemplateAreas}
          rowGap={rowGap}
          roundedBorders={!!roundedBorders}
          background={background}
        >
          {fields.map(field =>
            field.doNotRender ? null : (
              <DetailsField background={background} key={field.name} {...field} />
            ),
          )}
          {placeholders.map((p, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <PlaceholderField key={p + index} name={p} />
          ))}
        </DetailsContent>
      )}
    </div>
  );
};
