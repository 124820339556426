import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { layers } from 'theme';

export const Backdrop = styled.div<{ show: boolean }>`
  z-index: ${layers.navbar};
  display: ${props => (props.show ? 'block' : 'none')};
  position: fixed;
  inset: 0;
  height: 100vh;
  background-color: rgba(15, 15, 15, 0.8);
`;

export const StyledNav = styled.nav<{ hidden?: boolean }>`
  position: sticky;
  inset: 0;
  z-index: ${layers.navbar};
  display: flex;
  align-items: center;
  background-color: var(--color-navigation-surface);
  transition: transform 0.24s ease-in-out;
  transform: translateY(${props => (props.hidden ? '-100%' : '0')});

  --navbar-height: 56px;

  & > [class^='_extra-navigation-panel-mobile'] {
    z-index: ${layers.navbar};
  }

  [class^='_extra-navigation-panel-mobile__menu_'] {
    height: calc(100dvh - 2 * var(--navbar-height));

    & > [class^='_extra-navigation-panel_'] {
      overflow-y: auto;
    }
  }
`;

export const headerStyles = (isLink: boolean) => css`
  display: flex;
  align-items: center;
  width: 100%;
  ${isLink
    ? `
      gap: 20px;
      padding: 0 12px;
    `
    : `
      padding: 12px;
    `}

  svg {
    width: 40px;
    height: 40px;
  }
`;

export const NavbarButtonWrapper = styled.div`
  position: relative;

  [class^='_counter'] {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
`;

export const NavbarButtons = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 12px;
  border-left: 1px solid var(--color-navigation-divider);

  svg {
    width: 24px;
    height: 24px;
  }
`;
