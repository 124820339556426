import styled from '@emotion/styled';

import { layers, Theme } from 'theme';

export const MainContainer = styled.div<{
  theme?: Theme;
  error?: boolean;
  isMenuOpen?: boolean;
  isDisabled?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 48px;
  border: ${({ theme, error }) =>
    `1px solid ${error ? theme.colors.fuzzyWuzzyBrown : theme.colors.mineShaftLightest}`};
  border-radius: 4px;
  padding: 5px 16px 5px 20px;
  background-color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colors.mineShaftLightest : 'transparent'};
  box-shadow: none;
  outline: none;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  ${({ isMenuOpen, theme }) =>
    isMenuOpen
      ? `
border-bottom: 1px solid transparent;
background-color: ${theme.colors.mineShaft};
border-radius: 4px 4px 0px 0px;
`
      : ''}
`;
export const ListContainer = styled.div<{ theme?: Theme }>`
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  max-height: 500px;
  overflow: scroll;
  border-radius: 0px 0px 4px 4px;
  background-color: ${({ theme }) => theme.colors.mineShaft};
  border: 1px solid ${({ theme }) => theme.colors.mineShaftLightest};
  border-top: none;
  box-shadow: none;
  cursor: pointer;
  z-index: ${layers.modal};
`;
export const Input = styled.input<{ theme?: Theme }>`
  outline: none;
  color: ${({ theme }) => theme.colors.dataInput.text};
  background-color: transparent;
  border: none;
  width: 80%;
  height: 40px;
  input:not(focus) {
    width: 0;
    height: 0;
  }
`;
export const SelectedCounter = styled.span<{ theme?: Theme }>`
  background: ${({ theme }) => theme.mainColor};
  color: ${({ theme }) => theme.colors.dataInput.text};
  width: 25px;
  height: 25px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`;
