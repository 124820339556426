import React, { useEffect } from 'react';
import {
  Button,
  ModalPortal,
  RadioButton,
  RadioButtonGroup,
  Skeleton,
} from '@alpha-recycling/component-library';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldNumber } from 'components/shared/Fields/FieldNumber/FieldNumber';
import { FieldSwitcher } from 'components/shared/Fields/FieldSwitcher/FieldSwitcher';
import { ModalFormContent } from 'components/shared/forms/Form/ModalForm.styles';
import { LoadableContent, LoadableContentModes } from 'components/shared/Loader';
import { ModalFormType, SHARED } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';
import { useAddItemsToShoppingCart, useCreateShoppingCart } from 'shared/mutations/shoppingCart';
import { useGetShoppingCarts } from 'shared/queries';
import { hideModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { messages, TypedIntlShape, useTypedIntl } from '../../locale/messages';
import {
  ALLOWED_PARTS_OF_CONVERTER,
  formatPartOfConverter,
  isUsingHedgePricing,
} from '../ShoppingCart.helpers';
import { ShoppingCartsListSkeletonContainer } from '../ShoppingCart.styles';

const NEW_CART_ID = 'new-cart';

interface FormValues {
  unitsCount: number;
  partOfConverter: string;
  shoppingCart?: string;
  newCartName: string;
}

const validationSchema = (intl: TypedIntlShape) =>
  Yup.object().shape({
    unitsCount: Yup.number()
      .required(intl.formatMessage({ id: 'ShoppingCart.Modal.UnitsCount.RequiredError' }))
      .min(1, intl.formatMessage({ id: 'ShoppingCart.Modal.UnitsCount.MinError' }))
      .max(
        SHARED.MAX_DB_INTEGER,
        intl.formatMessage(
          { id: 'ShoppingCart.Modal.UnitsCount.MaxError' },
          { max: SHARED.MAX_DB_INTEGER },
        ),
      ),
    partOfConverter: Yup.number().required().oneOf(ALLOWED_PARTS_OF_CONVERTER),
    shoppingCart: Yup.string().required(),
    newCartName: Yup.string()
      .min(1, intl.formatMessage({ id: 'ShoppingCart.Modal.ShoppingCartName' }))
      .max(50)
      .when('shoppingCart', {
        is: NEW_CART_ID,
        then: Yup.string().required(
          intl.formatMessage({ id: 'ShoppingCart.Modal.ShoppingCartName.RequiredError' }),
        ),
      }),
  });

function ShoppingCartsListSkeleton() {
  return (
    <ShoppingCartsListSkeletonContainer>
      <Skeleton type="line-slim" />
      <Skeleton type="line-slim" />
    </ShoppingCartsListSkeletonContainer>
  );
}

const AddShoppingCartItemsModalComponent = () => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);

  const enabled = isOpen && modalType === ModalFormType.AddShoppingCartItems;
  const config = useAppSelector(state => state.config.shoppingCart);
  const carts = useGetShoppingCarts({ enabled });
  const createCart = useCreateShoppingCart();
  const addItemsToCart = useAddItemsToShoppingCart();

  const currentUser = useCurrentUser();
  const hedgePriceInUse = isUsingHedgePricing(currentUser);

  const partOfConverterOptions = ALLOWED_PARTS_OF_CONVERTER.map(value => ({
    value: String(value),
    label: formatPartOfConverter(value, intl, true),
  }));

  const shoppingCartOptions = [
    ...(carts.data?.map(cart => ({
      value: String(cart.id),
      label: cart.name,
      disabled: hedgePriceInUse !== cart.hedgePricing,
    })) ?? []),
    {
      value: NEW_CART_ID,
      label: intl.formatMessage({ id: 'ShoppingCart.Modal.NewCart' }),
      disabled: false,
    },
  ].slice(0, config?.maxCartsPerUser ?? 0);

  const onClose = () => dispatch(hideModalForm());

  const formikContext = useFormik<FormValues>({
    initialValues: {
      unitsCount: 1,
      partOfConverter: '1',
      newCartName: '',
      shoppingCart: undefined,
    },
    validationSchema: validationSchema(intl),
    onSubmit: async values => {
      if (!params || !('converters' in params)) return;

      try {
        let shoppingCartId: number;
        if (values.shoppingCart === NEW_CART_ID) {
          shoppingCartId = await createCart.mutateAsync(values.newCartName);
        } else {
          shoppingCartId = Number(values.shoppingCart);
        }

        await addItemsToCart.mutateAsync({
          convertersIds: params.converters.map(c => c.id),
          unitsCount: values.unitsCount,
          partOfConverter: Number(values.partOfConverter),
          shoppingCartId,
        });
        onClose();

        dispatch(
          snackBarPushSuccess(
            intl.formatMessage(
              { id: 'ShoppingCart.Modal.Add.Success' },
              { identifier: params.converters[0].converterIdentifier },
            ),
          ),
        );
      } catch (e) {
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
        );
      }
    },
  });
  const { values, handleChange, handleBlur, touched, errors, resetForm, handleSubmit } =
    formikContext;

  useEffect(() => {
    formikContext.setFieldValue('newCartName', '');
    formikContext.setTouched({ newCartName: false });
  }, [values.shoppingCart]);

  const submitDisabled =
    !formikContext.dirty ||
    !formikContext.isValid ||
    formikContext.isSubmitting ||
    carts.isFetching ||
    createCart.isLoading ||
    addItemsToCart.isLoading;

  useEffect(() => {
    resetForm();
  }, [enabled]);

  useEffect(() => {
    if (carts.isFetching || !carts.data) return;
    if (carts.data.length === 0) {
      formikContext.setFieldValue('shoppingCart', NEW_CART_ID);
    } else if (carts.data.length === 1) {
      formikContext.setFieldValue('shoppingCart', String(carts.data[0].id));
    }
  }, [enabled, carts.isFetching, carts.data]);

  return (
    <ModalPortal
      isOpen={enabled}
      innerPadding={false}
      header={intl.formatMessage({ id: 'ShoppingCart.Modal.Add.Title' })}
      rightButtons={[
        <Button
          onClick={onClose}
          variant="transparent"
          content="text"
          label={intl.formatMessage({ id: 'Global.Cancel' })}
        />,
        <Button
          onClick={() => handleSubmit()}
          disabled={submitDisabled}
          content="text"
          data-cy="add-items-modal-submit-button"
          label={intl.formatMessage({ id: 'Global.Confirm' })}
        />,
      ]}
    >
      <LoadableContent
        loading={addItemsToCart.isLoading || !carts.isFetched}
        mode={LoadableContentModes.OVERLAY}
        drawContent
      >
        <ModalFormContent>
          <FormikProvider value={formikContext}>
            <FieldSwitcher
              id="part-of-converter"
              label={intl.formatMessage({ id: 'ShoppingCart.Modal.PartOfConverter' })}
              name="partOfConverter"
              value={values.partOfConverter}
              onChange={handleChange}
              onBlur={handleBlur}
              options={partOfConverterOptions}
              // TODO add error prop when https://alpharec.atlassian.net/browse/NEWA-2951 is ready
              required
            />
            <FieldNumber
              label={intl.formatMessage({ id: 'ShoppingCart.Modal.UnitsCount' })}
              name="unitsCount"
              value={values.unitsCount}
              onChange={handleChange}
              onBlur={handleBlur}
              min={1}
              max={SHARED.MAX_DB_INTEGER as number}
              error={touched.unitsCount && errors.unitsCount}
              required
            />
            {carts.isFetching ? (
              <ShoppingCartsListSkeleton />
            ) : (
              <>
                <RadioButtonGroup
                  legendText={intl.formatMessage({ id: 'ShoppingCart.Modal.SelectCart' })}
                  error={!!touched.shoppingCart && !!errors.shoppingCart}
                  required
                  name="shoppingCart"
                  data-cy="shopping_cart"
                >
                  {shoppingCartOptions.map(({ label, value, disabled }) => (
                    <RadioButton
                      id={`shoppingCart_${value}`}
                      data-cy={`shopping_cart_${value}`}
                      disabled={disabled}
                      labelText={label}
                      value={value}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      checked={values.shoppingCart === value}
                    />
                  ))}
                </RadioButtonGroup>
                {values.shoppingCart === NEW_CART_ID && (
                  <FieldInput
                    label={intl.formatMessage({ id: 'ShoppingCart.Modal.ShoppingCartName' })}
                    name="newCartName"
                    maxLength={20}
                    value={values.newCartName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.newCartName && errors.newCartName}
                    required
                  />
                )}
              </>
            )}
          </FormikProvider>
        </ModalFormContent>
      </LoadableContent>
    </ModalPortal>
  );
};

export const AddItemsModal = withAlphamartIntlProvider(
  AddShoppingCartItemsModalComponent,
  messages,
);
