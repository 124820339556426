import React from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';

import AppButton from 'components/shared/forms/AppButton/AppButton';
import { LoadableContent } from 'components/shared/Loader';
import { ReactModalStyle } from 'components/shared/Modals.styles';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { METAL_ABBR, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { HedgeListItem, Metals, User } from 'shared/types';
import { layers, theme } from 'theme';
import { useTypedIntl } from './locale/messages';
import { useChangeHedge } from './hooks';

const ModalContent = styled.div`
  background-color: ${theme.colors.mineShaft};
  padding: 24px 32px;
  border-radius: 12px;
  max-height: 100vh;
  overflow-y: scroll;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
`;

type UserProfileChooseHedgeModalProps = {
  user: Pick<User, 'assignedHedges' | 'userTypes' | 'accessFlags'>;
  metalAbbr: METAL_ABBR;
  isOpen: boolean;
  onClose: () => void;
};

export const UserProfileChooseHedgeModal = ({
  user,
  metalAbbr,
  isOpen,
  onClose,
}: UserProfileChooseHedgeModalProps): React.ReactElement => {
  const intl = useTypedIntl();
  const auth = useAuthorization();
  const { changeHedge, isPending } = useChangeHedge(metalAbbr);
  const applicableHedges = (user?.assignedHedges ?? []).filter(
    ({ applicableTo }) =>
      (applicableTo.includes(Metals.PLATINUM) && metalAbbr === 'pt') ||
      (applicableTo.includes(Metals.PALLADIUM) && metalAbbr === 'pd') ||
      (applicableTo.includes(Metals.RHODIUM) && metalAbbr === 'rh'),
  );

  const getPrice = (hedge: HedgeListItem) => {
    switch (metalAbbr) {
      case 'pt':
        return hedge.ptPriceCust;
      case 'pd':
        return hedge.pdPriceCust;
      case 'rh':
        return hedge.rhPriceCust;
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={ReactModalStyle(false, layers.modal)}
      parentSelector={() => document.querySelector('#root')!}
    >
      <ModalContent>
        <ButtonContainer>
          {applicableHedges.map(hedge => (
            <AppButton
              onClick={async () => {
                await changeHedge('HEDGE', hedge.id);
                onClose();
              }}
              data-cy="change-hedge"
              key={hedge.id}
            >
              {hedge.name} {formatDateWithTime(hedge.placedAt)}
              {auth(PERMISSIONS.HEDGES.SHOW_HEDGE_PRICES) && (
                <>
                  <br />
                  {formatCurrency(getPrice(hedge))}
                </>
              )}
            </AppButton>
          ))}
          <AppButton styleType="neutral-empty" onClick={onClose} data-cy="change-hedge-cancel">
            {intl.formatMessage({ id: 'Global.Cancel' })}
          </AppButton>
        </ButtonContainer>
      </ModalContent>
      <LoadableContent mode={LoadableContent.MODE.FULL} loading={isPending} />
    </ReactModal>
  );
};
