import React from 'react';
import { Button, ButtonProps, Counter } from '@alpha-recycling/component-library';

import { useTypedIntl } from 'locale/messages';
import { FilterData } from 'store/shared/filtersSlice';
import { searchBarStyles, SidebarButton, sidebarStyles } from './SearchBar.styles';
import { SearchChangEvent, SearchInput } from './SearchInput';
import { ListModeButton } from '../ListModeButton/ListModeButton';

interface FilterButtonProps extends ButtonProps {
  count?: number;
}

export const FilterButton = ({ count = 0, ...props }: FilterButtonProps): React.ReactElement => {
  const intl = useTypedIntl();
  return (
    <SidebarButton>
      <Button
        {...props}
        iconName="Filter"
        label={intl.formatMessage({ id: 'Global.Filters' })}
        content="icon_text"
        variant="plain"
      />
      {count > 0 && <Counter active value={count} />}
    </SidebarButton>
  );
};

interface SearchBarProps {
  onFiltersToggle?: () => void;
  savedFilters?: FilterData;
  onSearchChanged?: (changed: SearchChangEvent) => void;
  searchAfter?: number;
  chooseListType?: boolean;
  isSearchPending?: boolean;
  initialQuery?: string;
  searchInputVisible?: boolean;
  addSearchSession?: boolean;
}

export const SearchBar = ({
  onSearchChanged,
  onFiltersToggle,
  initialQuery,
  savedFilters,
  searchAfter = 3,
  chooseListType = false,
  isSearchPending,
  searchInputVisible = true,
  addSearchSession = false,
}: SearchBarProps): React.ReactElement => (
  <div className={searchBarStyles(searchInputVisible)} data-cy="search-bar">
    {searchInputVisible && (
      <SearchInput
        onSearchChanged={ev => onSearchChanged?.(ev)}
        loadingState={isSearchPending}
        searchAfter={searchAfter}
        initialQuery={initialQuery}
        addSearchSession={addSearchSession}
      />
    )}
    <div className={sidebarStyles(chooseListType)}>
      {chooseListType && <ListModeButton />}
      {onFiltersToggle && (
        <FilterButton count={savedFilters?.diff.count} onClick={onFiltersToggle} />
      )}
    </div>
  </div>
);
