import { STATUS_TYPE_VALUES } from '@alpha-recycling/component-library';
import { isNil } from 'lodash';

import { FeedbackReviewStatus } from 'shared/constants';
import { Status } from 'shared/types';

const placeholder = 'placeholder';
type Template = string[];
const fillGaps = (template: Template): string[] =>
  template.map((row, index) =>
    row
      .replace(/\s\./g, () => ` ${placeholder}${index}`)
      .replace(/\.\s/g, () => `${placeholder}${index} `),
  );

const sanitizeRow = (row: string): string => row?.replace(/\.|\[|\]/g, '');

const sanitizeTemplate = (template: Template): string[] => template.map(sanitizeRow);

const formatTemplate = (template: Template): string =>
  template.reduce((acc, row) => `${acc} "${row}"`, '').trim();

const resolveStatusColor = (status: Status | string, isBlocked?: boolean): STATUS_TYPE_VALUES => {
  switch (status) {
    case Status.ACTIVE:
      return isBlocked ? STATUS_TYPE_VALUES.blocked : STATUS_TYPE_VALUES.active;
    case Status.INACTIVE:
      return STATUS_TYPE_VALUES.inactive;
    case Status.PENDING:
      return STATUS_TYPE_VALUES.pending;
    default:
      return STATUS_TYPE_VALUES.unread;
  }
};

const resolveFeedbackStatusColor = (status: FeedbackReviewStatus | string) => {
  switch (status) {
    case FeedbackReviewStatus.READ:
      return STATUS_TYPE_VALUES.pending;
    case FeedbackReviewStatus.UNREAD:
      return STATUS_TYPE_VALUES.unread;
    case FeedbackReviewStatus.SAVED:
      return STATUS_TYPE_VALUES.active;
    default:
      return STATUS_TYPE_VALUES.inactive;
  }
};

const unsetOrPxVal = (val?: number) => (isNil(val) ? 'unset' : `${val}px`);

export {
  fillGaps,
  formatTemplate,
  resolveFeedbackStatusColor,
  resolveStatusColor,
  sanitizeRow,
  sanitizeTemplate,
  unsetOrPxVal,
};
