import { useCallback, useEffect, useState } from 'react';

import { getDeviceDetails } from 'helpers/devices/deviceDetails';
import { ErrorCode } from 'shared/constants';
import { useAuth } from 'shared/hooks';
import { useAddPendingDevice, useVerifyChallenge, useVerifyDevice } from 'shared/mutations';
import { isAlphamartHttpError } from 'shared/types';
import { fetchUser } from 'store/auth';
import { clearError } from 'store/errorsSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';

const MAX_RETRY_COUNT = 10;
const RETRY_DELAY = 2000;

export interface DeviceVerificationResult {
  loading: boolean;
  error: ErrorCode | null;
  verified: boolean;
  addingDevice: boolean;
  addPendingDevice: () => Promise<void>;
  submitChallenge: (challenge: string) => Promise<void>;
}

export async function getDeviceParams() {
  return {
    deviceDetails: JSON.stringify(await getDeviceDetails()),
  };
}

export function useDeviceVerification(enabled: boolean): DeviceVerificationResult {
  const { isAuthenticated } = useAuth();
  const dispatch = useAppDispatch();

  const isAuthPending = useAppSelector(state => state.auth.isPending);
  const stateErrorCode = useAppSelector(state => state.errors?.errorCode);

  const verifyDevice = useVerifyDevice({
    retry: (count, error) =>
      count < MAX_RETRY_COUNT && error?.response?.data.errorCode === ErrorCode.UNSYNC_USER,
    retryDelay: RETRY_DELAY,
  });
  const addPendingDevice = useAddPendingDevice();
  const verifyChallenge = useVerifyChallenge();

  const [isVerified, setIsVerified] = useState(false);
  const [addingDevice, setAddingDevice] = useState(false);

  const handleAddPendingDevice = async () => {
    setAddingDevice(true);
    setIsVerified(false);
    await addPendingDevice.mutateAsync(await getDeviceParams());
  };

  const submitChallenge = async (challenge: string) => {
    await verifyChallenge.mutateAsync({ challenge });
    await dispatch(fetchUser());
    setAddingDevice(false);
    setIsVerified(true);
    dispatch(clearError());
    dispatch(fetchUser());
  };

  const verify = useCallback(async () => {
    if (verifyDevice.isLoading) return;
    try {
      setIsVerified(false);
      await verifyDevice.mutateAsync(await getDeviceParams());
      setIsVerified(true);
      dispatch(clearError());
      dispatch(fetchUser());
    } catch (e) {
      if (!isAlphamartHttpError(e)) throw e;
    }
  }, [verifyDevice, dispatch]);

  const loading =
    isAuthPending ||
    verifyDevice.isLoading ||
    addPendingDevice.isLoading ||
    verifyChallenge.isLoading;

  useEffect(() => {
    if (enabled && isAuthenticated) {
      verify();
    }
  }, [enabled, isAuthenticated]);

  const isUnrecognizedDevice = stateErrorCode === ErrorCode.UNRECOGNIZED_DEVICE;

  useEffect(() => {
    if (isUnrecognizedDevice) {
      verify();
    }
  }, [isUnrecognizedDevice]);

  const error = verifyDevice.error?.response?.data.errorCode ?? stateErrorCode ?? null;

  return {
    verified: isVerified,
    loading,
    error,
    addingDevice,
    addPendingDevice: handleAddPendingDevice,
    submitChallenge,
  };
}
