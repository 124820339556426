import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { MEDIA_QUERY, Theme } from 'theme';

export const StyledHeader = styled(PanelHeader)`
  border-bottom: none;
  h3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const mainPanelStyles = css`
  @media ${MEDIA_QUERY.MAX_SM} {
    padding-top: 0 !important;
  }
`;

export const ChartContainer = styled.div<{ theme?: Theme }>`
  position: relative;
  overflow-x: auto;
  ::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 64px;
    background-color: ${({ theme }) => theme.colors.mineShaftLightest};
  }
`;

export const BarsContainer = styled.div<{ theme?: Theme }>`
  display: flex;
  height: calc(100vh - 300px);
  min-height: 360px;
  max-height: 580px;
  padding: 24px 0;
  gap: 0 70px;
  font-size: 15px;
`;

export const SingleBar = styled.div<{ theme?: Theme }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 23px;
  height: 100%;
  & > div:last-child {
    margin-top: 24px;
    order: 1;
  }
`;

export const SingleBarLabel = styled.div<{ theme?: Theme }>`
  background-color: ${({ theme }) => theme.colors.mineShaftLightest};
  padding: 4px 8px;
  font-size: 16px;
  border-radius: 10px;
`;

export const SingleBarColumn = styled.div<{ theme?: Theme; percentage: number }>`
  display: ${({ percentage }) => (percentage === 0 ? 'none' : 'block')};
  content: '';
  width: 40px;
  height: ${({ percentage }) => percentage}%;
  background-color: ${({ theme }) => theme.colors.mineShaftLightest};
  border-radius: 4px;
`;
