import React from 'react';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { LoadableContent } from 'components/shared/Loader';
import { messages as companyMessages } from 'components/views/Companies/locale/messages';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useSetGradingAndTerms } from 'shared/mutations';
import { CompanyFormData } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { setTermsTypeChanged } from 'store/termsSlice';
import { TermsForm } from '../Forms/TermsForm';
import { messages, useTypedIntl } from '../locale/messages';

const UpdateTermsComponent = (): React.ReactElement => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const { mutateAsync: updateTerms, isLoading } = useSetGradingAndTerms();

  const handleSubmit = async (
    company: CompanyFormData,
    termsType: boolean,
    termsTypeChanged: boolean,
  ) => {
    try {
      await updateTerms({
        grading: termsType,
        terms: termsType ? undefined : company.terms,
      });
      dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'TermsForm.Update.Success' })));
      dispatch(setTermsTypeChanged(termsTypeChanged));
      navigate('/terms');
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'TermsForm.Update.Failure' })));
    }
  };

  return (
    <FormContainer>
      <LoadableContent loading={isLoading} mode={LoadableContent.MODE.FULL} drawContent>
        <TermsForm
          onSubmit={handleSubmit}
          onCancel={() => {
            navigate('/terms');
          }}
        />
      </LoadableContent>
    </FormContainer>
  );
};

export const UpdateTerms = withAlphamartIntlProvider(UpdateTermsComponent, {
  EN: { ...messages.EN, ...companyMessages.EN },
  ES: { ...messages.ES, ...companyMessages.ES },
  FR: { ...messages.FR, ...companyMessages.FR },
  PL: { ...messages.PL, ...companyMessages.PL },
});
