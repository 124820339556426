import ReactModal from 'react-modal';
import { css } from '@emotion/css';

import { theme } from 'theme';

/**
 * File for global styles of modals
 */

const ModalMessageStyle = (): string => css`
  text-align: center;
  margin: 20px 30px;
  font-size: 14px;
`;

const ReactModalStyle = (
  isPhone?: boolean,
  zIndex?: number,
  transparentBackground?: boolean,
): ReactModal.Styles => ({
  overlay: {
    backgroundColor: transparentBackground ? 'rgba(0,0,0,0.3)' : `${theme.colors.black}`,
    zIndex,
    WebkitBackdropFilter: 'blur(2px)',
    backdropFilter: 'blur(2px)',
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(isPhone ? { padding: 0 } : {}),
  },
});

export { ModalMessageStyle, ReactModalStyle };
