import React, { useMemo } from 'react';
import { Button, Status } from '@alpha-recycling/component-library';

import { Price } from 'components/shared/Price/Price';
import { ModalFormType, SHARED } from 'shared/constants';
import { useCurrentUser, useExtendedTheme, useMediaQuery } from 'shared/hooks';
import { Converter } from 'shared/types';
import { showModalForm } from 'store/modalFormSlice';
import { useAppDispatch } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import {
  averagePriceInfo,
  AveragePriceTitle,
  averageSectionWrapper,
  averageSelectionStyles,
  ButtonWrapper,
  highlightStyles,
} from './AveragePrice.styles';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from '../locale/messages';

interface Props {
  enabled?: boolean;
  onAverageModeToggle?: (e?: React.MouseEvent<HTMLElement>) => void;
  onSelectAll?: (e?: React.MouseEvent<HTMLElement>) => void;
  onClear?: (e?: React.MouseEvent<HTMLElement>) => void;
  converters?: Partial<Converter>[];
}
export const AveragePrice = ({
  enabled,
  onAverageModeToggle,
  onSelectAll,
  onClear,
  converters = [],
}: Props): React.ReactElement => {
  const currentUser = useCurrentUser();
  const intl = useTypedIntl();
  const { pdHedgeUsed, ptHedgeUsed, rhHedgeUsed } = currentUser.prices;
  const hedgePriceInUse = [pdHedgeUsed, ptHedgeUsed, rhHedgeUsed].some(hedge => hedge);
  const count = converters?.filter(p => !p.converterGroup?.converters?.length).length;
  const dispatch = useAppDispatch();
  const allHaveSameIdentifier =
    converters.length >= 2 &&
    converters.every(cv => cv.converterIdentifier === converters[0].converterIdentifier);
  const theme = useExtendedTheme();

  const addItemsToCart = () => {
    dispatch(
      showModalForm({
        modalType: ModalFormType.AddShoppingCartItems,
        params: { converters: converters as Converter[] },
      }),
    );
  };

  const averagePrice = useMemo(() => {
    const groupPricesSums = converters
      ?.filter(({ converterGroup, price }) => !!converterGroup && price!.marketPrice !== null)
      ?.reduce((groups, { converterGroup, price }) => {
        groups.set(
          converterGroup,
          (groups.get(converterGroup) ?? 0) +
            Math.max(hedgePriceInUse ? price!.hedgePrice! : price!.marketPrice!, 0),
        );
        return groups;
      }, new Map());

    const groupConverterPrices = [...groupPricesSums.values()];
    const standaloneConverterPrices = converters
      ?.filter(({ converterGroup }) => !converterGroup)
      ?.map(({ price }) => (hedgePriceInUse ? price!.hedgePrice : price!.marketPrice))
      ?.filter(price => price !== null)
      ?.map(price => Math.max(price!, 0));

    if (!groupConverterPrices?.length && !standaloneConverterPrices?.length) return null;

    const prices = [...groupConverterPrices, ...standaloneConverterPrices];
    return (prices.reduce((sum, price) => sum + price, 0) / prices.length).toFixed(2);
  }, [converters]);

  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_MD);

  return (
    <div className={averageSectionWrapper}>
      <div className={averageSelectionStyles(!!enabled, theme)}>
        {enabled ? (
          <>
            <div className={averagePriceInfo(enabled)}>
              <AveragePriceTitle>
                <Status
                  type="inactive"
                  value={intl.formatMessage(
                    {
                      id: 'ConvertersList.Average.Badge',
                    },
                    { count },
                  )}
                  size="big"
                  discrete
                />
                <FormattedMessage id="ConvertersList.Average.Title" />
                <span className={highlightStyles(theme)}>
                  {count > 0 && averagePrice ? (
                    <Price
                      value={Number(averagePrice)}
                      language={currentUser.language}
                      currency={currentUser.currency}
                    />
                  ) : (
                    SHARED.LONG_DASH
                  )}
                </span>
              </AveragePriceTitle>
              {hedgePriceInUse && (
                <Status
                  type="inactive"
                  value={intl.formatMessage({
                    id: 'ConvertersList.Average.BasedOnHedge',
                  })}
                  size="big"
                  discrete
                />
              )}
            </div>
            <Button
              disabled={!allHaveSameIdentifier}
              iconName="BasketSecondary"
              variant="plain"
              size="medium"
              content="icon"
              onClick={addItemsToCart}
              data-cy="add-to-cart-multi"
            />
            <Button
              variant="tonal"
              content="text"
              onClick={onSelectAll}
              label={intl.formatMessage({
                id: `ConvertersList.Average.SelectAll`,
              })}
            />
            <Button
              variant="plain"
              onClick={onClear}
              content="text"
              label={intl.formatMessage({
                id: `ConvertersList.Average.Clear`,
              })}
            />
            <Button
              variant="plain"
              onClick={onAverageModeToggle}
              iconName="X"
              size="medium"
              content="icon"
            />
          </>
        ) : (
          <>
            <span className={averagePriceInfo(!!enabled)}>
              <FormattedMessage id="ConvertersList.Average.SelectPrompt" />
              <span className={highlightStyles(theme)}>
                <FormattedMessage id="ConvertersList.Average.Placeholder" />
              </span>
            </span>
            <ButtonWrapper isPhone={isPhone}>
              <Button
                data-cy="select-button"
                variant="plain"
                onClick={onAverageModeToggle}
                content={isPhone ? 'text' : 'icon_text'}
                iconName="Check"
                label={intl.formatMessage({
                  id: isPhone
                    ? `ConvertersList.Average.MobileButton`
                    : 'ConvertersList.Average.Select',
                })}
              />
            </ButtonWrapper>
          </>
        )}
      </div>
    </div>
  );
};
