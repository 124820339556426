import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  BUTTON_CONTENTS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
  Counter,
  ICON_NAMES_TYPE,
  LinkButton,
} from '@alpha-recycling/component-library';
import { isNumber } from 'lodash';

import { NavbarButtonWrapper } from '../Navigation.styles';

interface CounterWrapperProps {
  children: React.ReactNode;
  count?: number;
}

function CounterWrapper({ children, count }: CounterWrapperProps) {
  return (
    <NavbarButtonWrapper>
      {children}
      {isNumber(count) && <Counter value={count} active={count > 0} />}
    </NavbarButtonWrapper>
  );
}

export type NavbarButtonProps = {
  iconName: ICON_NAMES_TYPE;
  count?: number;
  'data-guide'?: string;
  'data-cy'?: string;
} & ({ onClick: MouseEventHandler<HTMLButtonElement> } | { to: string });

export function NavbarButton({
  iconName,
  count,
  'data-guide': dataGuide,
  'data-cy': dataCy,
  ...props
}: NavbarButtonProps) {
  const iconProps = {
    iconName,
    size: BUTTON_SIZES.small,
    content: BUTTON_CONTENTS.icon,
    variant: BUTTON_VARIANTS.transparent,
  };
  return (
    <CounterWrapper count={count}>
      {'to' in props ? (
        <Link to={props.to} data-guide={dataGuide} data-cy={dataCy}>
          <LinkButton {...iconProps} />
        </Link>
      ) : (
        <Button {...iconProps} onClick={props.onClick} data-guide={dataGuide} data-cy={dataCy} />
      )}
    </CounterWrapper>
  );
}
