import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { MEDIA_QUERY } from 'theme';
import { MapComponentProps } from './MapComponent';

const scaleOut = keyframes`
  0% {
    transform: translate3d(-50%, -50%, 0) scale(0);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) scale(1.0);
    opacity: 0;
  }
`;

export const MapComponentStyles = styled.div<MapComponentProps>`
  height: 100%;
  width: 100%;

  .gm-style {
    background: ${({ theme }) => theme.colors.mineShaftLightest};

    ::after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      background-color: ${({ theme }) => theme.mainColor};
      border-radius: 100%;
      animation: ${scaleOut} 0.7s infinite ease-in-out;
    }
  }

  .gm-style .gm-style-iw-c,
  .gm-style-iw-tc {
    filter: none;
  }

  .gm-style .gm-style-iw-c,
  .gm-style-iw-tc::after {
    background: ${({ theme }) => theme.colors.mineShaftLighter};
    box-shadow: none;
    border: 2px solid ${({ theme }) => theme.colors.mineShaftLighter};
  }

  .css-1m77a85 .gm-style .gm-style-iw-c {
    transform: translate(-52%, -101%);
  }

  .gm-style-iw-t::after {
    transform: translate(-70%, -70%) rotate(-45deg);
  }

  .gm-style .gm-style-iw-c {
    padding: 0;
  }

  .gm-ui-hover-effect {
    display: none !important;
  }

  .gm-style-iw-tc::after {
    border-right: none;
    border-top: none;
    transform: translateX(-20%);
  }

  .gm-style-iw-d {
    overflow: auto !important;

    &::-webkit-scrollbar-track-piece,
    &::-webkit-scrollbar-track {
      background: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.mineShaftLighter} !important;
      box-shadow: none !important;
    }
  }

  .cluster img {
    height: 53px;
  }

  .gm-control-active.gm-fullscreen-control {
    ${({ drawingMode }) => drawingMode && `display: none !important;`}

    border-radius: 50% !important;
    background: ${({ theme }) => theme.colors.mineShaftLighter} !important;
    transition: background 0.2s ease;
    top: 105px !important;

    @media ${MEDIA_QUERY.XL} {
      top: 50px !important;
    }

    :hover {
      background: ${({ theme }) => theme.colors.mineShaft} !important;
    }

    img {
      filter: contrast(0%) !important;
    }
  }

  .gmnoprint[role='menubar']:not(.gm-bundled-control):not(:root:root) {
    right: 0px !important;
    left: unset !important;
  }

  .gmnoprint:not(.gm-bundled-control) {
    right: 0 !important;
    border-radius: 50% !important;

    ul {
      background-color: transparent !important;
    }

    button {
      background: ${({ theme }) => theme.colors.mineShaftLighter} !important;
      transition: background 0.2s ease;
      color: ${({ theme }) => theme.fontColor} !important;
      font-weight: normal !important;

      &[aria-pressed='true'] {
        color: ${({ theme }) => theme.mainColor} !important;
      }

      :hover {
        background: ${({ theme }) => theme.colors.mineShaft} !important;
      }

      &[title='Show street map'] {
        ${({ drawingMode }) => drawingMode && `display: none !important;`}

        border-top-left-radius: 20px !important;
        border-bottom-left-radius: 20px !important;
      }

      &[title='Show satellite imagery'] {
        ${({ drawingMode }) => drawingMode && `display: none !important;`}

        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
      }
    }

    &[controlwidth='40'] {
      right: -36px !important;

      & > div:not(.gm-svpc) {
        background-color: transparent !important;

        & > div {
          background-color: transparent !important;
        }
      }

      button {
        :hover {
          background: ${({ theme }) => theme.colors.mineShaft} !important;
        }

        &[title='Zoom in'] {
          ${({ drawingMode }) => drawingMode && `display: none !important;`}

          transform: translateX(36px) !important;
          border-top-left-radius: 20px !important;
          border-top-right-radius: 20px !important;
        }

        &[title='Zoom out'] {
          ${({ drawingMode }) => drawingMode && `display: none !important;`}

          transform: translateX(36px) !important;
          border-bottom-left-radius: 20px !important;
          border-bottom-right-radius: 20px !important;
        }

        img {
          filter: contrast(0%) !important;
        }
      }
    }

    .gm-style-mtc {
      top: 0px !important;

      div {
        border-radius: 4px !important;
        background: ${({ theme }) => theme.colors.mineShaftLighter} !important;
        top: 44px !important;
        color: ${({ theme }) => theme.colors.mineShaft} !important;
        font-weight: normal !important;

        span {
          margin-right: 8px;

          :focus {
            outline: none;
          }

          img {
            filter: contrast(0);
          }
        }
      }
    }
  }

  .gm-svpc {
    ${({ drawingMode }) => drawingMode && `display: none !important;`}

    border-radius: 4px !important;
    background: ${({ theme }) => theme.colors.mineShaftLighter} !important;
    transition: background 0.2s ease;

    :hover {
      background: ${({ theme }) => theme.colors.mineShaft} !important;
    }
  }

  .ssQIHO-checkbox-menu-item {
    display: none;
  }
`;
