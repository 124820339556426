import React from 'react';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';
import { OptionsProps, Select } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';
import { isNull } from 'lodash';

import { changePageSizeAction } from 'store/listComponentSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';

const PageSizeSelectContainer = styled.div`
  min-width: 60px;
`;

const optionValues = [12, 24, 48];
const options = optionValues.map(value => ({
  label: String(value),
  value,
}));

export const PageSizeSelect = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { pageSize } = useAppSelector(state => state.listComponent);
  const value = options.find(option => option.value && +option.value === pageSize);
  const handleChange = (val: OnChangeValue<OptionsProps, false>) => {
    // page size is stored globally, because of business requirement to keep it save through session
    !isNull(val) && val.value && dispatch(changePageSizeAction(+val.value));
  };

  return (
    <PageSizeSelectContainer>
      <Select
        id="page-size-select"
        name="pageSize"
        onChange={handleChange}
        options={options}
        value={value}
        data-cy="page-size-select"
        required
      />
    </PageSizeSelectContainer>
  );
};
