import React from 'react';
import { Button, InfoBar, ModalPortal } from '@alpha-recycling/component-library';

import { useCurrentUser, useMarketToolUrls } from 'shared/hooks';
import { TypedFormattedMessage, useTypedIntl } from '../locale/messages';

export function RegularUserMessage() {
  const { companyManagers } = useCurrentUser();
  const marketNewsUrls = useMarketToolUrls();
  const intl = useTypedIntl();

  const goBack = () => window.location.replace(marketNewsUrls.home);

  return (
    <ModalPortal
      isOpen
      header={intl.formatMessage({ id: 'SetYourApp.RegularUserMessage.Header' })}
      rightButtons={[
        <Button
          onClick={goBack}
          content="text"
          variant="outline"
          label={intl.formatMessage({ id: 'SetYourApp.RegularUserMessage.Button' })}
        />,
      ]}
    >
      <InfoBar
        icon
        text={
          <>
            <TypedFormattedMessage id="SetYourApp.RegularUserMessage.Message" />
            {companyManagers && companyManagers.length > 0 && (
              <>
                <br />
                <TypedFormattedMessage
                  id="SetYourApp.RegularUserMessage.InformManager"
                  values={{
                    companyManagers: `(${companyManagers?.join(', ')})`,
                  }}
                />
              </>
            )}
          </>
        }
      />
    </ModalPortal>
  );
}
