import React from 'react';
import { Icon } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { useTypedIntl } from 'locale/messages';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { layers, MEDIA_QUERY, Theme } from 'theme';

const Actions = styled.div<{ theme?: Theme }>`
  height: 100%;
  position: absolute;
  width: 77px;
  z-index: ${layers.formContainer};
  background: var(--color-navigation-surface);

  @media ${MEDIA_QUERY.MAX_XL} {
    width: 100%;
    height: 70px;
    position: unset;
  }

  button {
    position: absolute;
    left: 14px;
    top: 10px;
    display: block;
    width: 50px;
    height: 50px;
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      background-color: ${({ theme }) => theme.colors.mineShaftLighter};
    }
  }
`;

const Content = styled.div`
  display: flex;
`;

interface Props {
  children: React.ReactNode;
  className?: string;
  onGoBack?: () => void;
}

const FormContainer = ({ children, className, onGoBack }: Props): React.ReactElement => {
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();

  const onBack = () => {
    if (onGoBack) onGoBack();
    else navigate(-1);
  };

  return (
    <div className={className}>
      <Actions>
        <button title={intl.formatMessage({ id: 'Global.GoBack' })} onClick={onBack} type="button">
          <Icon name="ArrowLeft" size="big" />
        </button>
      </Actions>
      <Content>{children}</Content>
    </div>
  );
};

const FormContainerStyled = styled(FormContainer)<{ theme?: Theme }>`
  min-height: 100vh;
  background-color: var(--color-page-surface);
  width: 100%;
  position: relative;

  @media ${MEDIA_QUERY.XL} {
    overflow-x: hidden;
  }
`;

export { FormContainerStyled as FormContainer };
