import { CompanyTheme } from 'shared/types';
import { UpdateThemeParam } from 'store/updateThemeSlice';

// eslint-disable-next-line
export function parseTheme(theme: UpdateThemeParam, watermark: any): CompanyTheme {
  return {
    watermark: watermark
      ? {
          externalId: watermark.data,
          fileName: theme.watermarkLogo[0].name,
          mimetype: theme.watermarkLogo[0].file.type!,
          ...(theme.watermarkLogo[0].id ? { id: theme.watermarkLogo[0].id } : {}),
        }
      : null,
    watermarkOpacity: theme.watermarkOpacity,
  };
}
