import React from 'react';
import { DataOutput, OutputCell } from '@alpha-recycling/component-library';

import { TextButton } from 'components/shared/Buttons';
import { DetailsField } from 'components/shared/Details/Details';
import { LoadableContent } from 'components/shared/Loader';
import { getUserTypeLang } from 'helpers/userTypes/userTypes';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useHedgeUsers } from './useHedgeUsers';
import { TagGroup } from '../../../shared/TagGroup';
import { useTypedIntl } from '../locale/messages';

export const HedgeDetailsAssignedUsers = ({
  hedgeId,
}: {
  hedgeId: number;
}): React.ReactElement | null => {
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const { isPending, list, count } = useHedgeUsers(hedgeId);

  if (count === 0) return null;

  return (
    <LoadableContent loading={isPending} mode={LoadableContent.MODE.INLINE}>
      <DataOutput
        headerText={intl.formatMessage({
          id: 'HedgeDetails.AssignedUsers',
        })}
      >
        {list
          .reduce<DetailsField[]>(
            (prev, user) => [
              ...prev,
              {
                name: `${user.id}Name`,
                label: intl.formatMessage({ id: 'HedgeDetails.AssignedUsers.Name' }),
                value: (
                  <TextButton onClick={() => navigate(`/users/${user.id}`)}>
                    {user.firstName} {user.lastName}
                  </TextButton>
                ),
              },
              {
                name: `${user.id}UserType`,
                label: intl.formatMessage({ id: 'HedgeDetails.AssignedUsers.UserTypes' }),
                value: (
                  <TagGroup
                    items={user?.userTypes?.map(userType => getUserTypeLang(userType.name, intl))}
                    countLabelFormatter={c =>
                      intl.formatMessage({ id: 'Global.Roles' }, { count: c })
                    }
                    size="small"
                  />
                ),
              },
            ],
            [],
          )
          .map((field, index, fields) => (
            <OutputCell
              key={field.name}
              dataTestId={field.name}
              mergeCells={fields.length % 2! !== 0 && fields.length - 1 === index ? 4 : 2}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
      </DataOutput>
    </LoadableContent>
  );
};
