import React from 'react';
import styled from '@emotion/styled';
import { CellContext, flexRender, HeaderContext } from '@tanstack/react-table';

import checkIcon from 'assets/images/icons/confirmBold.svg';
import { theme as mainTheme } from 'theme';
import { CellProps, RowProps } from './DataList.styles';

export const DataGridContainer = (columnsQuantity: number) => styled.div`
  display: grid;
  gap: 8px;
  grid-auto-flow: row;
  grid-template-columns: repeat(${columnsQuantity}, minmax(100px, 1fr));
  grid-auto-rows: 1fr;
`;

export const GridItemWrapper = styled.div<RowProps>`
  ${({ row }) =>
    row?.depth || row?.getIsExpanded()
      ? `
    box-shadow: 0 0 0 5px ${mainTheme.colors.mineShaftLightest};
    background: ${mainTheme.colors.mineShaftLightest};
  `
      : ``}

  ${({ row }) => (row?.getIsExpanded() ? 'border-radius: 4px 0 0 4px;' : '')}

  ${({ row, parentRow }) =>
    parentRow && row && parentRow?.getLeafRows()?.length - 1 === row.index
      ? 'border-radius: 0 8px 8px 0;'
      : ''}
`;

export const GridItemContent = styled.div<RowProps>`
  position: relative;
  background-color: ${mainTheme.colors.mineShaft};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
  height: 100%;

  ${({ row, theme }) =>
    row?.getCanSelect()
      ? `
    position: relative;

    ::before,::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: -4px;
      left: -4px;
      height: 20px;
      width: 20px;
    }

    ::before {
      background-color: ${theme.colors.mineShaft};
      border-radius: 3px;
      border: 2px solid ${theme.colors.mineShaftLighter};
    }

    ::after {
      content: ${row.getIsSelected() ? '""' : 'none'};
      background-color: ${theme.fontColor};
      mask-image: url(${checkIcon});
      mask-size: 21px;
      mask-position: 1px 1px;
    }
  `
      : ''}
`;

export const GridItem = (props: React.PropsWithChildren<RowProps>) => (
  <GridItemWrapper row={props.row} parentRow={props.parentRow}>
    <GridItemContent {...props} />
  </GridItemWrapper>
);

export const GridCell = styled.div<CellProps>`
  position: relative;
`;

export const GridItemLabel = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #696969;
  margin: 16px 16px 4px 16px;
`;

export const GridItemValue = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  margin: 0 16px 10px;
`;

export function GridCellContent<T>({ cell }: CellProps<T>) {
  return (
    <div>
      <GridItemLabel>
        {flexRender(
          cell?.column?.columnDef?.header,
          (cell?.getContext() ?? {}) as HeaderContext<T, unknown>,
        )}
      </GridItemLabel>
      <GridItemValue>
        {flexRender(
          cell?.column?.columnDef?.cell,
          cell?.getContext() ?? ({} as CellContext<T, unknown>),
        )}
      </GridItemValue>
    </div>
  );
}

export const GridImageContainer = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 1.59;
  border-radius: 10px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;

export const GridImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1.59;
  background: ${mainTheme.colors.codGrayDarker};
  color: ${mainTheme.fontColor};
  border-radius: 10px;
  font-weight: 700;
`;

export const GridActionsContainer = styled.div`
  align-items: center;
  border-top: 1px solid ${mainTheme.colors.mineShaftLighter};
  bottom: -24px;
  color: ${mainTheme.fontColor};
  display: flex;
  font-size: 12px;
  height: 44px;
  justify-content: center;
  left: -8px;
  margin-bottom: 18px;
  padding: 0 8px;
  position: relative;
  width: calc(100% + 16px);

  span {
    margin-right: 12px;
  }
`;

export const GridItemValues = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    min-width: 25px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const GridItemTooltipWrapper = styled.div`
  padding: 20px 15px 20px 0px;
`;

export const SearchGridContainer = styled.div`
  position: relative;
  left: -8px;
  width: calc(100% + 16px);
  margin-top: 5px;
`;
