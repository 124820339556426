import React from 'react';
import { css } from '@emotion/css';
import { SerializedStyles } from '@emotion/react';

import { MEDIA_QUERY } from 'theme';

interface Props {
  children: React.ReactNode;
  disableScroll?: boolean;
  className?: string | SerializedStyles;
}
const panelContentMainStyles = (
  disableScroll?: boolean,
  className: string | SerializedStyles = '',
) => css`
  flex-basis: 0;
  flex-grow: 1;
  padding: 32px;
  position: relative;
  z-index: 1;

  ${disableScroll
    ? `
    overflow: hidden;
    min-height: 100vh;

    & > div {
      overflow: hidden;
      min-height: 120px;
      max-height: 100%;
    }
    @media ${MEDIA_QUERY.MAX_MD} { height: calc(100vh - 130px); }
  `
    : `
    overflow-y: auto;
    overflow-x: scroll;
  `}

  @media ${MEDIA_QUERY.MAX_XL} {
    padding: 32px 16px;
  }

  ${className}
`;

export const PanelContentMain = React.forwardRef<HTMLDivElement, Props>(
  ({ children, disableScroll, className }, ref) => (
    <div className={panelContentMainStyles(disableScroll, className)} ref={ref}>
      {children}
    </div>
  ),
);
