import React from 'react';
import { DataOutput, OutputCell } from '@alpha-recycling/component-library';

import { User } from 'shared/types';
import { useTypedIntl } from '../locale/messages';

interface Props {
  user: User;
}

export function UserDetailsProfitMargins({ user }: Props): React.ReactElement {
  const intl = useTypedIntl();
  const canUseMarginSliderPerMetal = !!user?.company?.canUseProfitMarginPerMetal;
  return (
    <DataOutput
      headerText={intl.formatMessage({
        id: 'UserDetails.ProfitMargin',
      })}
    >
      {[
        {
          name: 'marginVisibility',
          label: intl.formatMessage({
            id: 'UserDetails.MarginVisibility',
          }),
          value: intl.formatMessage({
            id: `UserDetails.MarginVisibility.${user.marginVisibility!}`,
          }),
        },
        {
          name: 'userProfitMargin',
          label: intl.formatMessage(
            { id: 'UserDetails.UserProfitMargin' },
            { count: user.profitMargins.length },
          ),
          value: user.profitMargins
            ?.slice()
            .sort((pM1, pM2) => pM1.value - pM2.value)
            .map(({ value }) => `${value}%`)
            .join(', '),
        },
        {
          name: 'userProfitMarginPt',
          label: canUseMarginSliderPerMetal
            ? intl.formatMessage({
                id: `Global.Metals.Platinum`,
              })
            : intl.formatMessage({
                id: `UserDetails.SliderProfitMargin`,
              }),
          value: intl.formatMessage(
            { id: 'UserDetails.TotalProfitMargin' },
            {
              current: user.currentProfitMarginPt ?? 0,
              total: user.selectedProfitMargin! + user.currentProfitMarginPt!,
            },
          ),
        },
        ...(canUseMarginSliderPerMetal
          ? [
              {
                name: 'userProfitMarginPd',
                label: intl.formatMessage({
                  id: `Global.Metals.Palladium`,
                }),
                value: intl.formatMessage(
                  { id: 'UserDetails.TotalProfitMargin' },
                  {
                    current: user.currentProfitMarginPd ?? 0,
                    total: user.selectedProfitMargin! + user.currentProfitMarginPd!,
                  },
                ),
              },
              {
                name: 'userProfitMarginRh',
                label: intl.formatMessage({
                  id: `Global.Metals.Rhodium`,
                }),
                value: intl.formatMessage(
                  { id: 'UserDetails.TotalProfitMargin' },
                  {
                    current: user.currentProfitMarginRh ?? 0,
                    total: user.selectedProfitMargin! + user.currentProfitMarginRh!,
                  },
                ),
              },
            ]
          : []),
      ].map((field, index) => (
        <OutputCell
          key={field.name}
          data-cy={field.name}
          mergeCells={index > 1 ? 2 : 1}
          labelValue={field.label}
        >
          {field.value}
        </OutputCell>
      ))}
    </DataOutput>
  );
}
