import React from 'react';
import { DataOutput, OutputCell } from '@alpha-recycling/component-library';

import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { formatDeviceData } from 'helpers/devices/formatDeviceData';
import { SHARED } from 'shared/constants';
import { UserDetailsLocation } from 'shared/types';
import { useTypedIntl } from '../locale/messages';

interface Props {
  location?: UserDetailsLocation;
}

export function UserDetailsLocations({ location }: Props): React.ReactElement | null {
  const intl = useTypedIntl();

  return location ? (
    <DataOutput
      headerText={intl.formatMessage({
        id: 'UserDetails.LastLocation',
      })}
    >
      {[
        {
          name: 'date',
          label: intl.formatMessage({
            id: 'UserDetails.LastLocation.Date',
          }),
          value: formatDateWithTime(location.createdAt),
        },
        {
          name: 'device',
          label: intl.formatMessage({
            id: 'UserDetails.LastLocation.Device',
          }),
          value: formatDeviceData(
            location.device?.name,
            location.device?.os,
            location.device?.browserFamily,
          ),
        },
        {
          name: 'address',
          label: intl.formatMessage({
            id: 'UserDetails.LastLocation.Location',
          }),
          value: location.formattedAddress || SHARED.LONG_DASH,
        },
      ].map((field, index, fields) => (
        <OutputCell
          key={field.name}
          data-cy={field.name}
          mergeCells={fields.length - 1 === index ? 4 : 2}
          labelValue={field.label}
        >
          {field.value}
        </OutputCell>
      ))}
    </DataOutput>
  ) : null;
}
