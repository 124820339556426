import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@alpha-recycling/component-library';

import { closeNotifications, openNotifications } from 'store/notificationsList';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { NavbarButton } from './NavbarButton';
import { withAlphamartIntlProvider } from '../../AlphamartIntlProvider';
import { ExtraNavigation } from '../ExtraNavigation/ExtraNavigation';
import { messages, useTypedIntl } from '../locale/messages';
import { headerStyles, NavbarButtons, StyledNav } from '../Navigation.styles';

interface Props {
  children?: ReactNode;
  className?: string;
  hideInternalNavigation?: boolean;
}

export function NavbarComponent({ children, className, hideInternalNavigation }: Props) {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const count = useAppSelector(state => state.notificationCount?.count);
  const isNotificationsListOpen = useAppSelector(state => state.notificationsList?.isOpen);
  const [open, setOpen] = useState(false);

  const handleNotificationsToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(isNotificationsListOpen ? closeNotifications() : openNotifications());
  };

  return (
    <StyledNav className={className}>
      {hideInternalNavigation ? (
        <div className={headerStyles(false)}>
          <Icon name="MarketToolLogoSmall" />
          {intl.formatMessage({ id: 'Navigation.ConverterTool' })}
        </div>
      ) : (
        <>
          <Link className={headerStyles(true)} to="/">
            <Icon name="MarketToolLogoSmall" />
            {intl.formatMessage({ id: 'Navigation.ConverterTool' })}
          </Link>
          <NavbarButtons>
            <NavbarButton
              iconName="Bell"
              onClick={handleNotificationsToggle}
              count={count}
              data-cy="notifications-button"
            />
            {children}
          </NavbarButtons>
          <ExtraNavigation open={open} onChangeOpen={setOpen} mobile />
        </>
      )}
    </StyledNav>
  );
}

export const Navbar = withAlphamartIntlProvider(NavbarComponent, messages);
