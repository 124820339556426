import React, { MouseEventHandler, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import {
  ExtraNavigationPanel,
  ExtraNavigationPanelMobile,
  ICON_NAMES_TYPE,
  NavigationItemExtra,
  NavigationSubItem,
} from '@alpha-recycling/component-library';
import { ExtraNavigationPanelMobileProps } from '@alpha-recycling/component-library/dist/components/organisms/ExtraNavigationPanelMobile';
import { isUndefined, maxBy } from 'lodash';

import { PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useAlphamartLocation } from 'shared/hooks';
import { NonEmptyArray } from 'shared/types';
import { openNotifications } from 'store/notificationsList';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { openProfile } from 'store/shared/userProfileSlice';
import { withAlphamartIntlProvider } from '../../AlphamartIntlProvider';
import { messages, useTypedIntl } from '../locale/messages';
import { Backdrop } from '../Navigation.styles';

type NavItemActionHandler = { to: string } | { onClick: MouseEventHandler };

type NavItemLeaf = {
  id: string;
  label: string;
  show?: boolean;
} & NavItemActionHandler;

type NavItemWithLeafs = {
  id: string;
  label: string;
  icon: ICON_NAMES_TYPE;
  leafs: NonEmptyArray<NavItemLeaf>;
};

type NavItemWithoutLeafs = {
  id: string;
  label: string;
  icon: ICON_NAMES_TYPE;
  show?: boolean;
} & NavItemActionHandler;

type NavItem = NavItemWithLeafs | NavItemWithoutLeafs;

function shouldShow(item: NavItem | NavItemLeaf): boolean {
  if ('leafs' in item) return item.leafs.some(shouldShow);
  return isUndefined(item.show) || item.show;
}

function getItemProps(item: NavItem | NavItemLeaf) {
  return {
    id: item.id,
    ...('to' in item ? { getLinkItem: (c: ReactElement) => <Link to={item.to}>{c}</Link> } : {}),
    ...('onClick' in item ? { onClick: item.onClick } : {}),
  };
}

function getItemLeafs(item: NavItem) {
  if (!('leafs' in item)) return;
  return item.leafs
    .filter(shouldShow)
    .map(leaf => <NavigationSubItem {...getItemProps(leaf)}>{leaf.label}</NavigationSubItem>);
}

function calculateScore(item: NavItem | NavItemLeaf, pathname: string): number {
  if ('to' in item) return pathname.startsWith(item.to) ? item.to.length : 0;
  if ('leafs' in item) return Math.max(...item.leafs.map(leaf => calculateScore(leaf, pathname)));
  return 0;
}

function getBestMatch<T extends NavItem | NavItemLeaf>(
  items: T[],
  pathname: string,
): T | undefined {
  return maxBy(items, item => calculateScore(item, pathname));
}

interface Props {
  mobile?: boolean;
  open?: boolean;
  onChangeOpen?: (open: boolean) => void;
}

function Wrapper({
  mobile,
  open,
  onChangeOpen,
  children,
  ...props
}: Props & ExtraNavigationPanelMobileProps) {
  const intl = useTypedIntl();

  if (mobile) {
    return (
      <>
        <Backdrop show={!!open} onClick={() => onChangeOpen?.(false)} />
        <ExtraNavigationPanelMobile open={open} onChangeOpen={onChangeOpen} {...props}>
          {children}
        </ExtraNavigationPanelMobile>
      </>
    );
  }

  return (
    <ExtraNavigationPanel
      {...props}
      applicationName={intl.formatMessage({ id: 'Navigation.MarketTool' })}
      activeItemLabel={intl.formatMessage({ id: 'Navigation.ConverterTool' })}
    >
      {children}
    </ExtraNavigationPanel>
  );
}

export function ExtraNavigationComponent({ mobile, open, onChangeOpen }: Props) {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();

  const count = useAppSelector(state => state.notificationCount?.count);

  function handleNotificationsOpen(e: React.MouseEvent) {
    e.stopPropagation();
    dispatch(openNotifications());
  }

  function openProfileModal() {
    onChangeOpen?.(false);
    dispatch(openProfile());
  }

  const navItems: NavItem[] = [
    {
      id: 'dashboard',
      to: '/dashboard',
      icon: 'Columns',
      label: intl.formatMessage({ id: 'Navigation.Dashboard' }),
      show: authorize(PERMISSIONS.DASHBOARD.SHOW),
    },
    {
      id: 'assays',
      to: '/assays',
      icon: 'Folder',
      label: intl.formatMessage({ id: 'Navigation.Assays' }),
      show: authorize(PERMISSIONS.ASSAYS.LIST),
    },
    {
      id: 'converters',
      to: '/converters',
      icon: 'Converters',
      label: intl.formatMessage({ id: 'Navigation.Converters' }),
      show: authorize(PERMISSIONS.CONVERTERS.LIST),
    },
    {
      id: 'nonstandard-converters',
      to: '/nonstandard-converters',
      icon: 'Converter',
      label: intl.formatMessage({ id: 'Navigation.NonstandardConverters' }),
      show: authorize(PERMISSIONS.NONSTANDARD_CONVERTERS.MAIN),
    },
    {
      id: 'hedges',
      to: '/hedges',
      icon: 'Chart',
      label: intl.formatMessage({ id: 'Navigation.Hedges' }),
      show: authorize(PERMISSIONS.HEDGES.LIST, { gradingRequirement: 'assay' }),
    },
    {
      id: 'vehicles',
      to: '/vehicles',
      icon: 'JunkCars',
      label: intl.formatMessage({ id: 'Navigation.Vehicles' }),
      show: authorize(PERMISSIONS.VEHICLES.MAIN),
    },
    {
      id: 'users',
      to: '/users',
      icon: 'User',
      label: intl.formatMessage({ id: 'Navigation.Users' }),
      show: authorize(PERMISSIONS.USERS.LIST, { gradingRequirement: 'assay' }),
    },
    {
      id: 'companies',
      to: '/companies',
      icon: 'Company',
      label: intl.formatMessage({ id: 'Navigation.Companies' }),
      show: authorize(PERMISSIONS.COMPANIES.LIST),
    },
    {
      id: 'settings',
      to: '/company/settings',
      icon: 'Cog',
      label: intl.formatMessage({ id: 'Navigation.Settings' }),
      show: authorize(PERMISSIONS.COMPANY_SETTINGS.MAIN),
    },
    {
      id: 'geolocation',
      to: '/geolocation',
      icon: 'Globe',
      label: intl.formatMessage({ id: 'Navigation.Geolocation' }),
      show: authorize(PERMISSIONS.GEOLOCATION.DISPLAY),
    },
    {
      id: 'statistics',
      to: '/statistics',
      icon: 'Stats',
      label: intl.formatMessage({ id: 'Navigation.Statistics' }),
      show: authorize(PERMISSIONS.STATISTICS.LIST),
    },
    {
      id: 'feedback',
      to: '/feedback',
      icon: 'Mail',
      label: intl.formatMessage({ id: 'Navigation.Feedback' }),
      leafs: [
        {
          id: 'feedback-reviews',
          to: '/feedback',
          label: intl.formatMessage({ id: 'Navigation.Feedback.Reviews' }),
          show: authorize(PERMISSIONS.FEEDBACKS.MAIN),
        },
        {
          id: 'feedback-summary',
          to: '/feedback/summary',
          label: intl.formatMessage({ id: 'Navigation.Feedback.Summary' }),
          show: authorize(PERMISSIONS.FEEDBACKS.MAIN),
        },
      ],
    },
    {
      id: 'preferences',
      icon: 'List',
      label: intl.formatMessage({ id: 'Navigation.Preferences' }),
      onClick: openProfileModal,
    },
    {
      id: 'terms',
      to: '/terms',
      icon: 'TermsAndConditions',
      label: intl.formatMessage({ id: 'Navigation.Terms' }),
      show: authorize(PERMISSIONS.TERMS.MAIN),
    },
    {
      id: 'notifications',
      icon: 'Bell',
      label: intl.formatMessage({ id: 'Global.Notifications' }, { count }),
      show: !mobile,
      onClick: handleNotificationsOpen,
    },
  ];

  const { pathname } = useAlphamartLocation();
  const activeItem = getBestMatch(navItems, pathname) ?? navItems[0];
  const activeLeaf = 'leafs' in activeItem ? getBestMatch(activeItem.leafs, pathname) : undefined;

  return (
    <Wrapper
      mobile={mobile}
      open={open}
      onChangeOpen={onChangeOpen}
      activeItemId={activeItem.id}
      activeSubItemId={activeLeaf?.id}
    >
      {navItems.filter(shouldShow).map(item => (
        <NavigationItemExtra
          key={item.id}
          iconName={item.icon}
          subItems={getItemLeafs(item)}
          showList={'leafs' in item && activeItem.id === item.id}
          {...getItemProps(item)}
        >
          {item.label}
        </NavigationItemExtra>
      ))}
    </Wrapper>
  );
}

export const ExtraNavigation = withAlphamartIntlProvider(ExtraNavigationComponent, messages);
