import styled from '@emotion/styled';

import { Theme } from 'theme';

export const Subheader = styled.div<{ theme?: Theme }>`
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  padding-top: 5px;
`;
