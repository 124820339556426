import React from 'react';
import { useParams } from 'react-router-dom';
import { DataOutput, OutputCell, Status } from '@alpha-recycling/component-library';

import { Details } from 'components/shared/Details';
import { LoadableContent } from 'components/shared/Loader';
import { formatDate, formatDateWithTime } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { resolveHedgeStatus } from 'helpers/hedges/resolveHedgeStatus';
import { PERMISSIONS, SHARED } from 'shared/constants';
import { resolveStatusColor, useAuthorization } from 'shared/helpers';
import { useHedgeEditActions } from 'shared/hooks';
import { useGetHedgeDetails } from 'shared/queries';
import { ItemAction, Status as StatusTypes } from 'shared/types';
import { useAppSelector } from 'store/shared/hooks';
import { HedgeDetailsAssignedUsers } from './HedgeDetailsAssignedUsers';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from '../locale/messages';

export const HedgeDetails = (): React.ReactElement => {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const { id } = useParams<{ id: string }>();
  const { data: hedge, isFetching, refetch } = useGetHedgeDetails(+id!);
  const { handleUpdate, handleUnassign, handleRemove, handleAssign } = useHedgeEditActions();
  const usersCount = useAppSelector(state => state.users?.count);
  const showCompany = authorize(PERMISSIONS.HEDGES.DETAILS.SHOW_COMPANY);

  const actions: ItemAction[] = hedge
    ? [
        {
          id: 1,
          label: <FormattedMessage id="Global.Update" />,
          onClick: () => handleUpdate(hedge),
          visible: authorize(PERMISSIONS.HEDGES.UPDATE),
          dataCy: () => `update-${hedge.id}`,
        },
        {
          id: 2,
          label: <FormattedMessage id="Global.Unassign" />,
          onClick: () => handleUnassign(hedge),
          visible: usersCount > 0 && authorize(PERMISSIONS.HEDGES.UNASSIGN),
          dataCy: () => `unassign-${hedge.id}`,
        },
        {
          id: 3,
          label: <FormattedMessage id="Global.Remove" />,
          onClick: () => handleRemove(hedge),
          visible: authorize(PERMISSIONS.HEDGES.REMOVE),
          dataCy: () => `delete-${hedge.id}`,
        },
        {
          id: 4,
          label: <FormattedMessage id="HedgeAssign.Action.Name" />,
          onClick: () => handleAssign(hedge, refetch),
          visible: () =>
            authorize(PERMISSIONS.HEDGES.ASSIGN.ACTION) &&
            resolveHedgeStatus(hedge) === StatusTypes.ACTIVE,
          dataCy: () => `assign-${hedge.id}`,
        },
      ]
    : [];

  return (
    <LoadableContent loading={isFetching} mode={LoadableContent.MODE.FULL}>
      {hedge && (
        <Details title={hedge.name} backUrl="/hedges" actions={actions}>
          <DataOutput
            headerText={intl.formatMessage({
              id: 'HedgeDetails.Section.HedgeInfo',
            })}
          >
            {[
              {
                name: 'name',
                label: intl.formatMessage({
                  id: 'HedgeDetails.HedgeName',
                }),
                value: hedge.name,
              },
              {
                name: 'status',
                label: intl.formatMessage({
                  id: 'HedgeDetails.Status',
                }),
                value: (
                  <Status
                    type={resolveStatusColor(resolveHedgeStatus(hedge))}
                    discrete
                    value={intl.formatMessage({
                      id: `Global.Status.${resolveHedgeStatus(hedge)}`,
                    })}
                    size="small"
                  />
                ),
              },
              {
                name: 'relatedLot',
                label: intl.formatMessage({
                  id: 'HedgeDetails.RelatedLot',
                }),
                value: hedge.relatedLot || SHARED.LONG_DASH,
              },
              ...(showCompany
                ? [
                    {
                      name: 'company',
                      label: intl.formatMessage({
                        id: 'HedgeDetails.Company',
                      }),
                      value: hedge.company.name,
                    },
                  ]
                : []),
              {
                name: 'hedgeCompany',
                label: intl.formatMessage({
                  id: 'HedgeDetails.HedgeCompany',
                }),
                value: hedge.hedgeCompany || SHARED.LONG_DASH,
              },
            ].map((field, index, fields) => (
              <OutputCell
                key={field.name}
                dataTestId={field.name}
                mergeCells={fields.length % 2! !== 0 && fields.length - 1 === index ? 4 : 2}
                labelValue={field.label}
              >
                {field.value}
              </OutputCell>
            ))}
          </DataOutput>

          <DataOutput
            headerText={intl.formatMessage({
              id: 'HedgeDetails.Section.Prices',
            })}
          >
            {[
              {
                name: 'platinumPriceCust',
                label: intl.formatMessage({
                  id: 'HedgeDetails.PtPriceCust',
                }),
                value: formatCurrency(hedge.ptPriceCust) ?? SHARED.LONG_DASH,
              },
              {
                name: 'palladiumPriceCust',
                label: intl.formatMessage({
                  id: 'HedgeDetails.PdPriceCust',
                }),
                value: formatCurrency(hedge.pdPriceCust) ?? SHARED.LONG_DASH,
              },
              {
                name: 'rhodiumPriceCust',
                label: intl.formatMessage({
                  id: 'HedgeDetails.RhPriceCust',
                }),
                value: formatCurrency(hedge.rhPriceCust) ?? SHARED.LONG_DASH,
              },
            ].map((field, index, fields) => (
              <OutputCell
                key={field.name}
                dataTestId={field.name}
                mergeCells={fields.length - 1 === index ? 4 : 2}
                labelValue={field.label}
              >
                {field.value}
              </OutputCell>
            ))}
          </DataOutput>

          <DataOutput
            headerText={intl.formatMessage({
              id: 'HedgeDetails.Section.Dates',
            })}
          >
            {[
              {
                name: 'placedAt',
                label: intl.formatMessage({
                  id: 'HedgeDetails.PlacedOn',
                }),
                value: formatDateWithTime(hedge.placedAt),
              },
              {
                name: 'expiresAt',
                label: intl.formatMessage({
                  id: 'HedgeDetails.Expiration',
                }),
                value: formatDateWithTime(hedge.expiresAt),
              },
            ].map(field => (
              <OutputCell
                key={field.name}
                dataTestId={field.name}
                mergeCells={2}
                labelValue={field.label}
              >
                {field.value}
              </OutputCell>
            ))}
          </DataOutput>

          <DataOutput
            headerText={intl.formatMessage({
              id: 'HedgeDetails.Section.Note',
            })}
          >
            {[
              {
                name: 'note',
                label: intl.formatMessage({
                  id: 'HedgeDetails.Note',
                }),
                value: hedge.note || SHARED.LONG_DASH,
              },
            ].map(field => (
              <OutputCell
                key={field.name}
                dataTestId={field.name}
                mergeCells={4}
                labelValue={field.label}
              >
                {field.value}
              </OutputCell>
            ))}
          </DataOutput>

          <HedgeDetailsAssignedUsers hedgeId={hedge.id} />

          <DataOutput
            headerText={intl.formatMessage({
              id: 'Global.MoreDetails',
            })}
          >
            {[
              {
                name: 'createdBy',
                label: intl.formatMessage({
                  id: 'Global.CreatedBy',
                }),
                value:
                  hedge.createdBy && `${hedge.createdBy.firstName} ${hedge.createdBy.lastName}`,
              },
              {
                name: 'createdAt',
                label: intl.formatMessage({
                  id: 'Global.CreatedOn',
                }),
                value: formatDate(hedge.createdAt),
              },
              {
                name: 'updatedBy',
                label: intl.formatMessage({
                  id: 'Global.UpdatedBy',
                }),
                value: hedge.updatedBy
                  ? `${hedge.updatedBy.firstName} ${hedge.updatedBy.lastName}`
                  : SHARED.LONG_DASH,
              },
              {
                name: 'updatedAt',
                label: intl.formatMessage({
                  id: 'Global.UpdatedOn',
                }),
                value: hedge.updatedAt ? formatDate(hedge.updatedAt) : SHARED.LONG_DASH,
              },
            ].map(field => (
              <OutputCell key={field.name} dataTestId={field.name} labelValue={field.label}>
                {field.value}
              </OutputCell>
            ))}
          </DataOutput>
        </Details>
      )}
    </LoadableContent>
  );
};
