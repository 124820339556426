import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { MEDIA_QUERY } from 'theme';

export const StyledConverterGallery = styled.div`
  padding: 32px 0 8px;
`;

export const DisclaimerContent = styled.div`
  display: flex;
  flex-basis: 100%;
  background: #1e1e1e;
  height: 30px;
  font-weight: 700;
  align-items: center;
  font-size: 10px;
  color: #8e8d8d;
  position: sticky;
  top: 0;
  z-index: 1;

  @media ${MEDIA_QUERY.XL} {
    padding: 0 32px;
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    padding: 13px 15px;
  }

  @media ${MEDIA_QUERY.MAX_SM} {
    padding: 25px 15px;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    margin-bottom: 5px;
  }
`;

export const LinkToAssay = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledNotes = styled.div`
  align-items: center;
  display: flex;

  > div {
    margin-right: 10px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

    @media ${MEDIA_QUERY.MAX_MD} {
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-items: flex-start;
    }
  }
`;

export const StyledConvertedWrapper = styled.div`
  & > div > div {
    @media ${MEDIA_QUERY.XL} {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;
