import React, { useEffect, useRef } from 'react';
import { InputGoTo } from '@alpha-recycling/component-library';

type Props = {
  max: number;
  currentPage: number;
  onClick: (page: number) => void;
};

export const GoToPageButton = ({ currentPage, onClick, max }: Props): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (inputRef.current && currentPage !== +inputRef.current.value) {
      formRef.current?.reset();
    }
  }, [currentPage]);

  const handleClick = () => {
    if (!inputRef.current) return;
    const selectedPage = +inputRef.current.value;
    if (Number.isInteger(selectedPage)) {
      if (selectedPage > max) return;
      inputRef.current?.blur();
      onClick(selectedPage);
    }
  };

  return (
    <form ref={formRef}>
      <InputGoTo ref={inputRef} id="pageInput" onButtonClick={handleClick} />
    </form>
  );
};
