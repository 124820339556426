import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { layers, MEDIA_QUERY } from 'theme';

export const averagePriceSpacer = css`
  padding-bottom: 32px;

  @media ${MEDIA_QUERY.MAX_XL} {
    padding-bottom: 32px;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    padding-bottom: 105px;
  }
`;

export const DisclaimerContent = styled.div`
  display: flex;
  flex-basis: 100%;
  background: #1e1e1e;
  height: 30px;
  font-weight: 700;
  align-items: center;
  font-size: 10px;
  color: #8e8d8d;

  @media ${MEDIA_QUERY.XL} {
    padding: 0 32px;
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    padding: 15px 15px;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    display: none;
  }
`;

export const DisclaimerContentMobile = styled.div`
  display: none;
  background: #1e1e1e;
  font-weight: 700;
  font-size: 10px;
  color: #8e8d8d;
  position: sticky;
  top: 0;
  padding: 15px 15px;
  z-index: ${layers.disclaimer};
  @media ${MEDIA_QUERY.MAX_MD} {
    display: flex;
  }
`;

export const ConvertersHeaderWrapper = styled.div`
  header {
    @media ${MEDIA_QUERY.MAX_MD} {
      height: 100%;
      margin-top: 10px;
      padding-bottom: 10px;
    }

    & > div:first-of-type {
      flex-wrap: wrap;
      height: 100%;
      align-items: center;

      @media ${MEDIA_QUERY.MAX_SM} {
        justify-content: flex-end;
        gap: 0 8px;
      }

      > span {
        @media ${MEDIA_QUERY.MAX_SM} {
          flex-basis: 50%;
          text-align: right;

          & > span {
            margin-right: 15px;
          }
        }
      }

      > h3,
      span,
      button {
        order: 1;

        > span {
          margin-right: 0;
        }
      }

      > h3 {
        @media ${MEDIA_QUERY.MAX_SM} {
          flex-basis: 30%;
          padding: 5px 15px 5px;
        }
      }

      > button {
        @media ${MEDIA_QUERY.MAX_MD} {
          margin: 10px 0 0 0;
        }
      }

      > div {
        order: 0;
      }
    }
  }
`;

export const TableHeaderPriceInfo = styled.small`
  display: block;
  font-weight: normal;

  span {
    display: inline-block;

    &:first-letter {
      font-weight: 700;
    }

    &:last-child {
      margin-left: 5px;
    }
  }
`;
