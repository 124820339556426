import React from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { HIDDEN_ON_MEDIA_QUERY, layers, MEDIA_QUERY, Theme } from 'theme';
import { Navbar, NavigationPanel, Sidebar } from '../Navigation';
import { PaymentRequiredInfoBar } from '../PaymentRequiredInfoBar/PaymentRequiredInfoBar';

const layoutStyles = css`
  display: grid;
  grid-template:
    'infobar infobar' min-content
    'sidebar content' auto / auto 1fr;

  min-height: 100vh;
  min-height: 100dvh;

  &:not(:has(> [class*='infobar'])) {
    grid-template: 'sidebar content' auto / auto 1fr;
  }

  @media ${MEDIA_QUERY.MAX_LG} {
    grid-template:
      'infobar' min-content
      'navbar' auto
      'content' 1fr
      'panel' auto;

    &:not(:has(> [class*='infobar'])) {
      grid-template:
        'navbar' auto
        'content' 1fr
        'panel' auto;
    }
  }

  & > [class*='infobar'] {
    grid-area: infobar;
  }

  & > [class*='navigation-panel']:not(:last-child) {
    grid-area: sidebar;
    ${HIDDEN_ON_MEDIA_QUERY.MAX_LG}
    z-index: ${layers.navbar};
  }

  & > [class*='navigation-panel']:last-child {
    position: sticky;
    bottom: 0;
    grid-area: panel;
    ${HIDDEN_ON_MEDIA_QUERY.LG}
    z-index: ${layers.navbar};
  }
`;

const StyledContent = styled.section<{ theme?: Theme }>`
  max-height: 100%;
  overflow: hidden;
  grid-area: content;
  max-width: 100vw;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
`;

interface Props {
  children: React.ReactNode;
  navbarChildren?: React.ReactNode;
  hideInternalNavigation?: boolean;
}

export function PanelLayout({
  children,
  navbarChildren,
  hideInternalNavigation,
}: Props): React.ReactElement {
  return (
    <div className={layoutStyles}>
      <PaymentRequiredInfoBar />
      <Navbar className={HIDDEN_ON_MEDIA_QUERY.LG} hideInternalNavigation={hideInternalNavigation}>
        {navbarChildren}
      </Navbar>
      <Sidebar hideInternalNavigation={hideInternalNavigation} />
      <StyledContent>{children}</StyledContent>
      <NavigationPanel />
    </div>
  );
}
