import React from 'react';
import { Button, ModalPortal } from '@alpha-recycling/component-library';
import { ThemeProvider } from '@emotion/react';

import { ModalMessageStyle } from 'components/shared/Modals.styles';
import { useTypedIntl } from 'locale/messages';
import { useExtendedTheme } from 'shared/hooks';
import { useAppSelector } from 'store/shared/hooks';
import { AlphamartIntlProvider } from './AlphamartIntlProvider';

function AppModal(): React.ReactElement {
  const intl = useTypedIntl();
  const theme = useExtendedTheme();
  const { isOpen, message, closeMessage, confirmMessage, onClose, onConfirm } = useAppSelector(
    ({ modal }) => modal,
  );

  return (
    <ThemeProvider theme={theme}>
      <ModalPortal
        data-cy="app-modal"
        isOpen={isOpen}
        innerPadding={false}
        rightButtons={[
          <Button
            onClick={() => onClose?.()}
            variant="transparent"
            content="text"
            label={(closeMessage as string) || intl.formatMessage({ id: 'Global.Cancel' })}
            data-cy="cancel-button"
          />,
          <Button
            onClick={() => onConfirm?.()}
            content="text"
            label={(confirmMessage as string) || intl.formatMessage({ id: 'Global.Confirm' })}
            data-cy="confirm-modal"
          />,
        ]}
      >
        <AlphamartIntlProvider>
          <p className={ModalMessageStyle()}>{message}</p>
        </AlphamartIntlProvider>
      </ModalPortal>
    </ThemeProvider>
  );
}

export default AppModal;
