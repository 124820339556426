import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import isNil from 'lodash/isNil';
import { v4 as uuid4 } from 'uuid';

import { FormWrapper } from 'components/shared/forms/Form/FormWrapper';
import { isTruthy } from 'helpers/isTruthy/isTruthy';
import { SHARED } from 'shared/constants';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { MaterialUnit } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { SingleNonstandardConverterForm } from './SingleForm/SingleNonstandardConverterForm';
import { useTypedIntl } from '../locale/messages';

const ButtonWrapper = styled.div`
  height: 40px;
  width: 100%;

  button {
    width: 100%;
  }
`;

export interface NonstandardConverterFormShape {
  material: string;
  pricePer: MaterialUnit;
  buyingPercentAdjustment: string;
  dollarPriceAdjustment: string;
  weightDryLbs: string;
  confirmWeightDryLbs: string;
  platinumAssay: string;
  confirmPlatinumAssay: string;
  palladiumAssay: string;
  confirmPalladiumAssay: string;
  rhodiumAssay: string;
  confirmRhodiumAssay: string;
}

interface SingleFormShape {
  initialValues?: NonstandardConverterFormShape;
  backendMaterialError?: string | null;
}

export type SingleNonstandardConverterFormData = Map<string, SingleFormShape>;
type InternalFormValues = Map<string, NonstandardConverterFormShape | null | undefined>;

interface Props {
  onSubmit: (values: NonstandardConverterFormShape[]) => Promise<void>;
  backendMaterialErrors?: string[] | null;
  initialData?: SingleNonstandardConverterFormData;
  editMode?: boolean;
}

export const NonstandardConvertersForm = ({
  onSubmit,
  initialData,
  backendMaterialErrors,
  editMode,
}: Props) => {
  const maxForms = editMode ? 1 : SHARED.NONSTANDARD_CONVERTER_LIMIT;
  // `singleForms`, `internalFormValues`, `internalFormIsValid` are separated intentionally to optimize rendering up to 20 forms
  const [singleForms, setSingleForms] = useState<SingleNonstandardConverterFormData>(
    initialData ?? new Map().set(uuid4(), {}),
  );
  const internalFormValues = useRef<InternalFormValues>(
    new Map([...(initialData?.entries() ?? [])].map(entry => [entry[0], entry[1].initialValues])),
  );
  const [internalFormIsValid, setInternalFormIsValid] = useState<Map<string, boolean>>(new Map());
  const blankFormikContext = useFormik({
    initialValues: {},
    onSubmit: async () => {
      await onSubmit([...internalFormValues.current.values()].filter(isTruthy));
    },
  });
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();

  useEffect(() => {
    const data = mapBackendMaterialErrors(
      internalFormValues.current,
      singleForms,
      backendMaterialErrors,
      intl.formatMessage({
        id: 'NonstandardConvertersForm.Errors.MaterialNotUnique',
      }),
    );
    setSingleForms(data);
  }, [backendMaterialErrors]);

  const handleCancel = useCallback(() => {
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'Modal.Confirm' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: () => {
          navigate('/nonstandard-converters');
          dispatch(hideModal());
        },
      }),
    );
  }, [dispatch]);
  const handleFormRemove = (id: string) => {
    internalFormValues.current.delete(id);
    internalFormIsValid.delete(id);
    singleForms.delete(id);
    setSingleForms(new Map(singleForms));
    setInternalFormIsValid(new Map(internalFormIsValid));
  };
  const handleFormAdd = () => {
    const newId = uuid4();
    singleForms.set(newId, {});
    internalFormValues.current.set(newId, null);
    setSingleForms(new Map(singleForms));
    handleIsValidChange(newId, false);
  };
  const handleIsValidChange = (formId: string, isValid: boolean) => {
    internalFormIsValid.set(formId, isValid);
    setInternalFormIsValid(new Map(internalFormIsValid));
  };
  const handleValuesChange = (formId: string, values: NonstandardConverterFormShape) => {
    internalFormValues.current.set(formId, values);
  };
  const submitDisabled = [...internalFormIsValid.values()].some(isValid => !isValid);
  const limitReached = singleForms.size >= maxForms;

  return (
    <FormWrapper
      context={blankFormikContext}
      header={intl.formatMessage({
        id: editMode
          ? 'NonstandardConvertersForm.Update.Header'
          : 'NonstandardConvertersForm.Create.Header',
      })}
      submitLabel={intl.formatMessage({
        id: editMode ? 'NonstandardConvertersForm.Update' : 'NonstandardConvertersForm.Create',
      })}
      onCancel={handleCancel}
      onSubmit={blankFormikContext.handleSubmit}
      submitDisabled={submitDisabled}
    >
      {[...singleForms.entries()].map(([formId, data], i) => (
        <SingleNonstandardConverterForm
          onRemove={handleFormRemove}
          formId={formId}
          key={formId}
          canBeRemoved={singleForms.size > 1}
          initialValues={data?.initialValues}
          placeholderLabel={intl.formatMessage(
            { id: 'NonstandardConvertersForm.SectionHeader' },
            { number: i + 1 },
          )}
          onIsValidChange={handleIsValidChange}
          onValidValuesChange={handleValuesChange}
          materialError={data.backendMaterialError}
        />
      ))}
      {!editMode && (
        <ButtonWrapper>
          <Button
            variant="plain"
            content="text"
            onClick={handleFormAdd}
            disabled={limitReached}
            data-cy="nonstandard-converter-add"
            label={
              limitReached
                ? intl.formatMessage(
                    { id: 'NonstandardConvertersForm.AddNew.Limit' },
                    { limit: maxForms },
                  )
                : intl.formatMessage({ id: 'NonstandardConvertersForm.AddNew' })
            }
          />
        </ButtonWrapper>
      )}
    </FormWrapper>
  );
};

function mapBackendMaterialErrors(
  formValues: InternalFormValues,
  data: SingleNonstandardConverterFormData,
  errors: string[] | null | undefined,
  errorMsg: string,
): SingleNonstandardConverterFormData {
  if (isNil(errors)) {
    return data;
  }

  errors.forEach(materialName => {
    [...formValues.entries()].forEach(entry => {
      const hasError = entry[1]?.material === materialName;
      data.set(entry[0], {
        ...data.get(entry[0]),
        backendMaterialError: hasError ? errorMsg : null,
      });
    });
  });

  return new Map(data);
}
