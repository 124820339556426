import React, { ChangeEvent, useCallback, useState } from 'react';
import { InputActionMeta } from 'react-select';
import { Button, RadioButton, RadioButtonGroup } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { useTypedIntl } from 'locale/messages';
import { CustomSearchFunction, FieldSelect, FieldSelectProps } from './FieldSelect';
import { FieldInput } from '../FieldInput/FieldInput';

const CreateField = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;

  label {
    flex-grow: 1;
  }

  button {
    height: 40px;
    margin-top: 24px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 108px;
`;

interface Props extends FieldSelectProps {
  validateInput?: (value?: string) => void;
  maxLength: number;
  onCreateOption?: (newOption: string) => void;
  label?: string;
  onOptionChange?: () => void;
  customSearchFunction?: CustomSearchFunction;
}

export const FieldCreatableSelect = ({
  clearable = true,
  disabled,
  name = 'creatable-select',
  onBlur,
  onChange,
  onFocus,
  options,
  searchable = true,
  value,
  placeholder,
  onInputChange,
  noOptionsMessage,
  error,
  maxLength,
  validateInput,
  label,
  onCreateOption,
  onOptionChange,
  required = true,
  customSearchFunction,
}: Props): React.ReactElement => {
  const [type, setType] = useState('select');
  const [newOption, setNewOption] = useState('');
  const intl = useTypedIntl();

  const handleInputChange = useCallback(
    val => {
      const upperCasedVal = val.toUpperCase();
      if (upperCasedVal) {
        validateInput?.(upperCasedVal);
      }

      return val ? upperCasedVal : '';
    },
    [validateInput, onInputChange],
  );

  const handleSelectChange = useCallback(
    val => {
      const foundOption = options.find(option => option.value === val);
      val && onChange?.(foundOption ?? { value: val, label: val });
    },
    [onChange, options],
  );

  value &&
    !options.find(option => option.value === value) &&
    options.push({ value, label: String(value) });

  return (
    <InputWrapper data-cy={name}>
      <RadioButtonGroup orientation="horizontal" name={`radio-${name}`}>
        <RadioButton
          checked={type === 'select'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setType(e.target.value);
            onOptionChange?.();
          }}
          value="select"
          labelText={intl.formatMessage({ id: 'Global.Select' })}
          data-cy="select-radio"
        />
        <RadioButton
          checked={type === 'create'}
          onChange={e => {
            setType(e.target.value);
            onOptionChange?.();
          }}
          value="create"
          labelText={intl.formatMessage({ id: 'Global.Create' })}
          data-cy="add-radio"
        />
      </RadioButtonGroup>
      {type === 'create' ? (
        <CreateField>
          <FieldInput
            data-cy="create-input"
            value={newOption}
            label={label}
            error={error}
            name={name}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
            maxLength={maxLength}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setNewOption(handleInputChange(event.target.value))
            }
            required={required}
          />
          <Button
            data-cy="create-button"
            label={intl.formatMessage({ id: 'Global.Create' })}
            onClick={() => {
              if (newOption) {
                onCreateOption?.(newOption);
                onInputChange?.(newOption, { action: 'input-change' } as InputActionMeta);
                onChange?.({ value: newOption, label: newOption });
                setType('select');
              }
            }}
          />
        </CreateField>
      ) : (
        <FieldSelect
          label={label}
          placeholder={
            placeholder || intl.formatMessage({ id: 'Global.Fields.Select.Placeholder' })
          }
          clearable={!disabled || clearable}
          disabled={disabled}
          isMenuOpen={disabled ? false : undefined}
          searchable={searchable}
          name={name}
          onChange={disabled ? undefined : handleSelectChange}
          onBlur={onBlur}
          onFocus={onFocus}
          options={options}
          value={value}
          error={error}
          noOptionsMessage={noOptionsMessage}
          customSearchFunction={customSearchFunction}
          required={required}
        />
      )}
    </InputWrapper>
  );
};
