import React from 'react';
import { Label } from '@alpha-recycling/component-library';

interface BaseProps {
  label?: React.ReactNode;
  labelSuffix?: string | null;
  children?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
}

interface IdRequiredProps extends BaseProps {
  id: string;
  name?: string;
}

interface NameRequiredProps extends BaseProps {
  id?: string;
  name: string;
}

type Props = IdRequiredProps | NameRequiredProps;

const FieldLabel = ({
  label,
  labelSuffix = '',
  disabled,
  children,
  required,
  id,
  name,
}: Props): React.ReactElement => (
  <Label
    value={`${label ?? ''}${labelSuffix ?? ''}`}
    htmlFor={id ?? name ?? ''}
    required={required && !disabled}
  >
    {children}
  </Label>
);

function withFieldLabel<T>(
  C: React.FunctionComponent<T>,
  defaultProps?: Partial<Props>,
): (props: T & Props) => React.ReactElement {
  return (props: T & Props) => (
    <FieldLabel {...props} {...defaultProps}>
      <C {...props} />
    </FieldLabel>
  );
}

export { FieldLabel, withFieldLabel };
