export enum AccessFlag {
  ASSAY_VIEWER = 'ASSAY_VIEWER',
  ASSAY_EDITOR = 'ASSAY_EDITOR',
  COMPANY_MANAGER = 'COMPANY_MANAGER',
  CONVERTER_VIEWER = 'CONVERTER_VIEWER',
  PARTIAL_CONVERTER_MANAGER = 'PARTIAL_CONVERTER_MANAGER',
  DASHBOARD_VIEWER = 'DASHBOARD_VIEWER',
  GEOLOCATION_MANAGER = 'GEOLOCATION_MANAGER',
  HEDGE_BASIC_VIEWER = 'HEDGE_BASIC_VIEWER',
  HEDGE_EDITOR = 'HEDGE_EDITOR',
  HEDGE_PRICE_VIEWER = 'HEDGE_PRICE_VIEWER',
  NON_STANDARD_CONVERTER_VIEWER = 'NON_STANDARD_CONVERTER_VIEWER',
  NON_STANDARD_CONVERTER_EDITOR = 'NON_STANDARD_CONVERTER_EDITOR',
  SETTINGS_MANAGER = 'SETTINGS_MANAGER',
  STATISTICS_VIEWER = 'STATISTICS_VIEWER',
  USER_MANAGER = 'USER_MANAGER',
  VEHICLE_BASIC_VIEWER = 'VEHICLE_BASIC_VIEWER',
  VEHICLE_ADVANCED_VIEWER = 'VEHICLE_ADVANCED_VIEWER',
  VEHICLE_EDITOR = 'VEHICLE_EDITOR',
  VEHICLE_CREATOR = 'VEHICLE_CREATOR',
  REPORT_BASIC_GENERATOR = 'REPORT_BASIC_GENERATOR',
  REPORT_ADVANCED_GENERATOR = 'REPORT_ADVANCED_GENERATOR',
  FEEDBACK_MANAGER = 'FEEDBACK_MANAGER',
  FOIL_VIEWER = 'FOIL_VIEWER',
  SHOPPING_CART_VIEWER = 'SHOPPING_CART_VIEWER',
  TERMS_MANAGER = 'TERMS_MANAGER',
  MARKET_TOOL_MANAGER = 'MARKET_TOOL_MANAGER',
}
