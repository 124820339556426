import React from 'react';
import { Link } from 'react-router-dom';
import { DataOutput, OutputCell, Status } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';
import { UseQueryResult } from '@tanstack/react-query';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { Details } from 'components/shared/Details';
import { LoadableContent } from 'components/shared/Loader';
import { Rating } from 'components/shared/Rating/Rating';
import { formatDate } from 'helpers/dateTime/dateTime';
import { FeedbackReviewStatus, SHARED } from 'shared/constants';
import { resolveFeedbackStatusColor } from 'shared/helpers';
import { useAlphamartNavigate } from 'shared/hooks';
import { useRemoveFeedbacks, useSaveFeedback } from 'shared/mutations';
import { BasicUserInfo, Feedback, ItemAction } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { Theme } from 'theme';
import { messages, useTypedIntl } from '../locale/messages';

const StyledDetailsWrapper = styled.div<{ theme?: Theme }>`
  a:hover {
    text-decoration: underline;
  }
`;

const formatName = (user: BasicUserInfo): string => `${user.firstName} ${user.lastName}`;

interface Props {
  query: UseQueryResult<Feedback>;
  onRemove: () => void;
}

const FeedbackDetailsComponent = ({ query, onRemove }: Props): React.ReactElement | null => {
  const intl = useTypedIntl();
  const saveFeedback = useSaveFeedback();
  const removeFeedbacks = useRemoveFeedbacks();
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();

  const onFeedbackSave = async () => {
    if (!query.data) return;
    try {
      await saveFeedback.mutateAsync(query.data.id);
      await query.refetch();
    } catch (e) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  const onFeedbackRemove = async () => {
    if (!query.data) return;
    dispatch(
      showModal({
        message: intl.formatMessage({
          id: 'FeedbacksList.RemoveFeedbacks.RemoveSelected.Singular',
        }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: async () => {
          dispatch(hideModal());

          try {
            await removeFeedbacks.mutateAsync([query.data.id]);
            dispatch(
              snackBarPushSuccess(
                intl.formatMessage({ id: 'FeedbacksList.RemoveFeedbacks.SuccessMessage.Singular' }),
              ),
            );
            navigate('/feedback');
            onRemove();
          } catch (e) {
            dispatch(
              snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
            );
          }
        },
      }),
    );
  };

  const actions: ItemAction[] = [
    {
      id: 1,
      label: intl.formatMessage({ id: 'FeedbackDetails.Save' }),
      onClick: onFeedbackSave,
      visible: query.data?.reviewStatus !== FeedbackReviewStatus.SAVED,
    },
    {
      id: 2,
      label: intl.formatMessage({ id: 'Global.Remove' }),
      onClick: onFeedbackRemove,
    },
  ];

  if (!query.isFetching && !query.data) return null;

  return (
    <LoadableContent
      loading={query.isFetching || saveFeedback.isLoading}
      mode={LoadableContent.MODE.FULL}
      drawContent
    >
      <Details
        title={intl.formatMessage({ id: 'FeedbackDetails.Title' }, { id: query.data?.id })}
        backUrl="/feedback"
        actions={actions}
      >
        <StyledDetailsWrapper>
          <DataOutput
            headerText={intl.formatMessage({
              id: 'FeedbackDetails.Title.UserDetails',
            })}
          >
            {[
              {
                name: 'userName',
                label: intl.formatMessage({ id: 'FeedbackDetails.Label.Name' }),
                value: query.data?.user && (
                  <Link to={`/users/${query.data.user.id}`}>{formatName(query.data.user)}</Link>
                ),
              },
              {
                name: 'company',
                label: intl.formatMessage({ id: 'FeedbackDetails.Label.Company' }),
                value: query.data?.userCompany,
              },
            ].map(field => (
              <OutputCell
                key={field.name}
                data-cy={field.name}
                mergeCells={2}
                labelValue={field.label}
              >
                {field.value}
              </OutputCell>
            ))}
          </DataOutput>
        </StyledDetailsWrapper>
        <DataOutput
          headerText={intl.formatMessage({
            id: 'FeedbackDetails.Title.Message',
          })}
        >
          {[
            {
              name: 'status',
              label: intl.formatMessage({ id: 'FeedbackDetails.Label.Status' }),
              value: query.data?.reviewStatus && (
                <Status
                  type={resolveFeedbackStatusColor(query.data.reviewStatus)}
                  value={intl.formatMessage({
                    id: `Feedbacks.Filters.Status.${query.data.reviewStatus}`,
                  })}
                  size="small"
                  discrete
                />
              ),
            },
            {
              name: 'rating',
              label: intl.formatMessage({ id: 'FeedbackDetails.Label.Rating' }),
              value: query.data && <Rating rating={query.data.rating} />,
            },
            {
              name: 'comment',
              label: intl.formatMessage({ id: 'FeedbackDetails.Label.Comment' }),
              value: query.data?.message ?? SHARED.LONG_DASH,
            },
          ].map((field, index, fields) => (
            <OutputCell
              key={field.name}
              data-cy={field.name}
              mergeCells={fields.length - 1 === index ? 4 : 2}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>
        <DataOutput
          headerText={intl.formatMessage({
            id: 'Global.MoreDetails',
          })}
        >
          {[
            {
              name: 'createdAt',
              label: intl.formatMessage({ id: 'FeedbackDetails.Label.CreatedOn' }),
              value: query.data?.createdAt && formatDate(query.data.createdAt),
            },
            {
              name: 'filledAt',
              label: intl.formatMessage({ id: 'FeedbackDetails.Label.FilledOn' }),
              value: query.data?.filledAt && formatDate(query.data.filledAt),
            },
            {
              name: 'savedBy',
              label: intl.formatMessage({ id: 'Global.SavedBy' }),
              value: query.data?.savedBy && formatName(query.data.savedBy),
            },
            {
              name: 'savedAt',
              label: intl.formatMessage({ id: 'Global.SavedOn' }),
              value: query.data?.savedAt && formatDate(query.data.savedAt),
            },
          ].map(field => (
            <OutputCell
              key={field.name}
              data-cy={field.name}
              mergeCells={1}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>
      </Details>
    </LoadableContent>
  );
};

export const FeedbackDetails = withAlphamartIntlProvider(FeedbackDetailsComponent, messages);
