import { useAppSelector } from 'store/shared/hooks';
import { Theme, theme } from 'theme';

type ExtendedTheme = Theme & { watermarkOpacity: number };

export function useExtendedTheme(): ExtendedTheme {
  const config = useAppSelector(store => store.config);

  return {
    ...theme,
    ...(config?.theme && { watermarkOpacity: config.theme.watermarkOpacity }),
  } as ExtendedTheme;
}
