import * as yup from 'yup';

import { MarginVisibility, METAL_ABBR, PRICE_SOURCES, SHARED } from 'shared/constants';
import { TypedIntlShape, UsersMessages } from '../locale/messages';

export const userFormSchema = (intl: TypedIntlShape) => {
  const hedgeUsedSchema = (name, dependency) =>
    yup
      .string()
      .nullable()
      .when(dependency, {
        is: PRICE_SOURCES.HEDGE,
        then: yup
          .string()
          .nullable()
          .required(intl.formatMessage({ id: `${name}.Required` as keyof UsersMessages }))
          .min(1, intl.formatMessage({ id: `${name}.Required` as keyof UsersMessages })),
      });

  const profitMarginSchema = yup
    .string()
    .matches(/^-?\d+$/, {
      message: intl.formatMessage({
        id: 'UserForm.Errors.UserProfitMargin.NotMatchRegex',
      }),
    })
    .test({
      name: 'isRequired',
      message: intl.formatMessage({ id: 'UserForm.Errors.UserProfitMargin.Required' }),
      test(val) {
        return !!val;
      },
    })
    .test({
      name: 'lessThan40',
      message: intl.formatMessage({ id: 'UserForm.Errors.UserProfitMargin.LessThan' }),
      test(val) {
        return Number(val) <= 40;
      },
    })
    .test({
      name: 'moreOrEqual-0',
      message: intl.formatMessage({ id: 'UserForm.Errors.UserProfitMargin.MoreThan' }),
      test(val) {
        return Number(val) >= 0;
      },
    });

  const termsAdjustmentsSchema = metal =>
    yup
      .number()
      .typeError(
        intl.formatMessage({
          id: `UserForm.Errors.${metal}TermsAdjustment.InvalidType` as keyof UsersMessages,
        }),
      )
      .min(
        -100,
        intl.formatMessage({
          id: `UserForm.Errors.${metal}TermsAdjustment.Min` as keyof UsersMessages,
        }),
      )
      .max(
        SHARED.MAX_DB_INTEGER,
        intl.formatMessage({
          id: `UserForm.Errors.${metal}TermsAdjustment.Max` as keyof UsersMessages,
        }),
      )
      .integer(
        intl.formatMessage({
          id: `UserForm.Errors.${metal}TermsAdjustment.Integer` as keyof UsersMessages,
        }),
      )
      .nullable()
      .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

  return yup.object().shape({
    prices: yup.object().shape({
      userProfitMargin: yup.mixed().when('marginVisibility', {
        is: MarginVisibility.NO_VISIBILITY,
        then: yup
          .array()
          .ensure()
          .of(yup.object({ value: profitMarginSchema }).nullable())
          .max(10),
        otherwise: yup
          .array()
          .ensure()
          .of(yup.object({ value: profitMarginSchema }).nullable())
          .max(1),
      }),
      assignedHedges: yup.array().ensure(),
      prices: yup.object().shape({
        ptPriceSource: yup.string(),
        pdPriceSource: yup.string(),
        rhPriceSource: yup.string(),
        ptHedgeUsed: hedgeUsedSchema('UserForm.Errors.PtHedgeUsed', 'ptPriceSource'),
        pdHedgeUsed: hedgeUsedSchema('UserForm.Errors.PdHedgeUsed', 'pdPriceSource'),
        rhHedgeUsed: hedgeUsedSchema('UserForm.Errors.RhHedgeUsed', 'rhPriceSource'),
      }),
      ptTermsAdjustment: termsAdjustmentsSchema(METAL_ABBR.PT),
      pdTermsAdjustment: termsAdjustmentsSchema(METAL_ABBR.PD),
      rhTermsAdjustment: termsAdjustmentsSchema(METAL_ABBR.RH),
    }),
  });
};
