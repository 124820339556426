import React, { useContext, useEffect, useRef } from 'react';
import { ButtonProps, Form, FormRow, Icon } from '@alpha-recycling/component-library';
import { FormikContextType } from 'formik';
import { upperFirst } from 'lodash';

import { FieldDatePicker } from 'components/shared/Fields/FieldDatePicker/FieldDatePicker';
import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldPhotos, PhotoEvent } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { FieldTextArea } from 'components/shared/Fields/FieldTextArea/FieldTextArea';
import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { WizardContext } from 'components/shared/forms/Wizard/Wizard';
import { dateFormat } from 'helpers/dateTime/dateTime';
import { useAppSelector } from 'store/shared/hooks';
import { StyledEdit, StyledLabel, StyledSelectedConverter, StyledSpan } from './AssayForm.style';
import { AssayFormShape } from '../CreateAssay/CreateAssay';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from '../locale/messages';

interface Props {
  values: AssayFormShape;
  context: FormikContextType<AssayFormShape>;
  editMode?: boolean;
  activeInput: string | null;
  setActiveInput: (input: string) => void;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
  setFieldTouched: (field: string) => void;
  getErrors: (fieldName: string) => string | false | undefined;
  getRawErrors: (fieldName: string) => string | undefined;
  onBlur: (e: React.FocusEvent<unknown>) => void;
  handleBlur: (e: React.FocusEvent<unknown>) => void;
  handleChange: (e?: React.ChangeEvent<unknown>) => void;
  controlButtons?: React.ReactNode;
}

export const AssayForm = ({
  handleBlur,
  handleChange,
  values,
  getErrors,
  getRawErrors,
  context,
  setFieldValue,
  setFieldTouched,
  onBlur,
  activeInput,
  setActiveInput,
  editMode = false,
  controlButtons,
}: Props): React.ReactElement => {
  const { changePage, currentPage } = useContext(WizardContext);
  const { imageExtensions, fileSizeLimit } = useAppSelector(
    state => state.config.upload!.converterPhoto,
  );

  const gramsToPounds = 2.20462262;
  const intl = useTypedIntl();
  const dryWeight = useRef(0);

  const weightDryLbs = (wetWeight: number, moisture: number) => {
    const weightDryLbsRaw = (((wetWeight * gramsToPounds) / 1000) * (100 - moisture)) / 100;

    return Math.floor(weightDryLbsRaw * 1000) / 1000;
  };
  const newDryWeight = weightDryLbs(parseFloat(values.wetWeight), parseFloat(values.moisture));
  if (!Number.isNaN(newDryWeight) && !getRawErrors('wetWeight')) {
    dryWeight.current = newDryWeight;
  }

  const handleProtectedBlur = e => {
    setFieldTouched(`confirm${upperFirst(e.target.name)}`);
    return onBlur(e);
  };

  useEffect(() => {
    if (!values.wetWeight) setFieldValue('confirmWetWeight', '');
    if (!values.moisture) setFieldValue('confirmMoisture', '');
    if (!values.platinum) setFieldValue('confirmPlatinum', '');
    if (!values.palladium) setFieldValue('confirmPalladium', '');
    if (!values.rhodium) setFieldValue('confirmRhodium', '');
  }, [values.wetWeight, values.moisture, values.platinum, values.palladium, values.rhodium]);

  return (
    <FormInWizard
      context={context}
      header={
        editMode
          ? intl.formatMessage({ id: 'AssaysForm.UpdateAssay' })
          : intl.formatMessage({ id: 'AssaysList.CreateAssay' })
      }
      controlButtons={controlButtons}
    >
      <Form
        headerText={intl.formatMessage({ id: 'AssaysForm.Section.Converter' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow data-cy="catalytic-converter-form">
          <StyledSelectedConverter>
            <StyledLabel>
              <FormattedMessage id="AssaysForm.ConverterLabel" />
              <StyledSpan>{values.converter.identifier?.toUpperCase()}</StyledSpan>
            </StyledLabel>
            <StyledEdit onClick={() => changePage(currentPage - 1)}>
              <Icon name="Pen" size="big" />
            </StyledEdit>
          </StyledSelectedConverter>
        </FormRow>
      </Form>
      <Form
        formDescriptionMessage={`${intl.formatMessage({ id: 'AssaysForm.DryWeight' })}: ${
          dryWeight.current
        } lb`}
        headerText={intl.formatMessage({ id: 'AssaysForm.Section.PartDetails' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldInput
            label={intl.formatMessage({ id: 'AssaysForm.SampleName' })}
            name="sampleName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.sampleName}
            error={getErrors('sampleName')}
            required
            capitalize
            data-cy="sample-name"
            onFocus={e => setActiveInput(e.target.name)}
            maxLength={100}
          />
          <FieldDatePicker
            id="sample-date"
            label={intl.formatMessage({ id: 'AssaysForm.SampleDate' })}
            name="sampleDate"
            value={values.sampleDate as Date}
            onChange={setFieldValue}
            onTouch={setFieldTouched}
            dateFormat={dateFormat}
            dataTestId="sample-date"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'AssaysForm.WetWeight' })}
            name="wetWeight"
            onChange={handleChange}
            onBlur={handleProtectedBlur}
            value={values.wetWeight}
            error={getErrors('wetWeight')}
            data-cy="wet-weight"
            suffix="gm"
            onFocus={e => setActiveInput(e.target.name)}
            protection={{
              hide: activeInput === 'confirmWetWeight',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            maxLength={10}
          />
          <FieldInput
            label={intl.formatMessage({ id: 'AssaysForm.ConfirmWetWeight' })}
            name="confirmWetWeight"
            onChange={handleChange}
            onBlur={onBlur}
            value={values.confirmWetWeight}
            error={getErrors('confirmWetWeight')}
            data-cy="confirm-wet-weight"
            disabled={!values.wetWeight}
            suffix="gm"
            onFocus={e => setActiveInput(e.target.name)}
            protection={{
              hide: activeInput === 'wetWeight',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'AssaysForm.Moisture' })}
            name="moisture"
            onChange={handleChange}
            onBlur={handleProtectedBlur}
            value={values.moisture}
            error={getErrors('moisture')}
            data-cy="moisture"
            suffix="%"
            onFocus={e => setActiveInput(e.target.name)}
            protection={{
              hide: activeInput === 'confirmMoisture',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            maxLength={6}
          />
          <FieldInput
            label={intl.formatMessage({ id: 'AssaysForm.ConfirmMoisture' })}
            name="confirmMoisture"
            onChange={handleChange}
            onBlur={onBlur}
            value={values.confirmMoisture}
            error={getErrors('confirmMoisture')}
            data-cy="confirm-moisture"
            disabled={!values.moisture}
            suffix="%"
            onFocus={e => setActiveInput(e.target.name)}
            protection={{
              hide: activeInput === 'moisture',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'AssaysForm.Carbon' })}
            name="carbon"
            onChange={handleChange}
            onBlur={handleProtectedBlur}
            value={values.carbon}
            error={getErrors('carbon')}
            data-cy="carbon"
            suffix="%"
            onFocus={e => setActiveInput(e.target.name)}
            protection={{
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            maxLength={6}
          />
        </FormRow>
      </Form>
      <Form
        headerText={intl.formatMessage({ id: 'AssaysForm.Section.Photos' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldPhotos
            name="converter.photos"
            onChange={handleChange as unknown as (event?: PhotoEvent) => void}
            onBlur={() => setFieldTouched('converter.photos')}
            onTouch={setFieldTouched}
            error={getErrors('converter.photos')}
            allowedExtensions={imageExtensions}
            initialPhotos={values.converter.photos}
            data-cy="converter-photos"
            fileSizeLimit={fileSizeLimit}
          />
        </FormRow>
      </Form>
      <Form
        headerText={intl.formatMessage({ id: 'AssaysForm.Section.Analysis' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldInput
            label={intl.formatMessage({ id: 'AssaysForm.Platinum' })}
            name="platinum"
            onChange={handleChange}
            onBlur={handleProtectedBlur}
            value={values.platinum}
            error={getErrors('platinum')}
            data-cy="platinum"
            suffix="ppm"
            onFocus={e => setActiveInput(e.target.name)}
            protection={{
              hide: activeInput === 'confirmPlatinum',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            maxLength={10}
          />
          <FieldInput
            label={intl.formatMessage({ id: 'AssaysForm.ConfirmPlatinum' })}
            name="confirmPlatinum"
            onChange={handleChange}
            onBlur={onBlur}
            value={values.confirmPlatinum}
            error={getErrors('confirmPlatinum')}
            data-cy="confirm-platinum"
            disabled={!values.platinum}
            suffix="ppm"
            onFocus={e => setActiveInput(e.target.name)}
            protection={{
              hide: activeInput === 'platinum',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'AssaysForm.Palladium' })}
            name="palladium"
            onChange={handleChange}
            onBlur={handleProtectedBlur}
            value={values.palladium}
            error={getErrors('palladium')}
            data-cy="palladium"
            suffix="ppm"
            onFocus={e => setActiveInput(e.target.name)}
            protection={{
              hide: activeInput === 'confirmPalladium',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            maxLength={10}
          />
          <FieldInput
            label={intl.formatMessage({ id: 'AssaysForm.ConfirmPalladium' })}
            name="confirmPalladium"
            onChange={handleChange}
            onBlur={onBlur}
            value={values.confirmPalladium}
            error={getErrors('confirmPalladium')}
            data-cy="confirm-palladium"
            disabled={!values.palladium}
            suffix="ppm"
            onFocus={e => setActiveInput(e.target.name)}
            protection={{
              hide: activeInput === 'palladium',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'AssaysForm.Rhodium' })}
            name="rhodium"
            onChange={handleChange}
            onBlur={handleProtectedBlur}
            value={values.rhodium}
            error={getErrors('rhodium')}
            data-cy="rhodium"
            suffix="ppm"
            onFocus={e => setActiveInput(e.target.name)}
            protection={{
              hide: activeInput === 'confirmRhodium',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            maxLength={10}
          />
          <FieldInput
            label={intl.formatMessage({ id: 'AssaysForm.ConfirmRhodium' })}
            name="confirmRhodium"
            onChange={handleChange}
            onBlur={onBlur}
            value={values.confirmRhodium}
            error={getErrors('confirmRhodium')}
            data-cy="confirm-rhodium"
            disabled={!values.rhodium}
            suffix="ppm"
            onFocus={e => setActiveInput(e.target.name)}
            protection={{
              hide: activeInput === 'rhodium',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
          />
        </FormRow>
      </Form>
      <Form
        headerText={intl.formatMessage({ id: 'ConverterForm.Note' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldTextArea
            id="note"
            label={intl.formatMessage({ id: 'ConverterForm.Note' })}
            name="notes"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.notes}
            data-cy="notes"
            onFocus={e => setActiveInput(e.target.name)}
            maxLength={255}
          />
        </FormRow>
      </Form>
    </FormInWizard>
  );
};
