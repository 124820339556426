import React, { useEffect } from 'react';
import { Button, ModalPortal } from '@alpha-recycling/component-library';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldNumber } from 'components/shared/Fields/FieldNumber/FieldNumber';
import { FieldSwitcher } from 'components/shared/Fields/FieldSwitcher/FieldSwitcher';
import { ModalFormContent } from 'components/shared/forms/Form/ModalForm.styles';
import { LoadableContent, LoadableContentModes } from 'components/shared/Loader';
import { ModalFormType, SHARED } from 'shared/constants';
import {
  useEditItemInShoppingCart,
  useEditItemsGroupInShoppingCart,
} from 'shared/mutations/shoppingCart';
import { EditShoppingCartItemParams, hideModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { messages, TypedIntlShape, useTypedIntl } from '../../locale/messages';
import { ALLOWED_PARTS_OF_CONVERTER, formatPartOfConverter } from '../ShoppingCart.helpers';

interface FormValues {
  unitsCount: number;
  partOfConverter: string;
}

const validationSchema = (intl: TypedIntlShape) =>
  Yup.object().shape({
    unitsCount: Yup.number()
      .required(intl.formatMessage({ id: 'ShoppingCart.Modal.UnitsCount.RequiredError' }))
      .min(1, intl.formatMessage({ id: 'ShoppingCart.Modal.UnitsCount.MinError' }))
      .max(
        SHARED.MAX_DB_INTEGER,
        intl.formatMessage(
          { id: 'ShoppingCart.Modal.UnitsCount.MaxError' },
          { max: SHARED.MAX_DB_INTEGER },
        ),
      ),
    partOfConverter: Yup.number().required().oneOf(ALLOWED_PARTS_OF_CONVERTER),
  });

const EditShoppingCartItemModalComponent = ({
  onUpdate,
}: {
  onUpdate(): Promise<unknown>;
}): React.ReactElement => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);
  const typedParams = params as EditShoppingCartItemParams;

  const enabled = isOpen && modalType === ModalFormType.EditShoppingCartItem;
  const editItemInCart = useEditItemInShoppingCart();
  const editItemsGroupInCart = useEditItemsGroupInShoppingCart();

  const partOfConverterOptions = ALLOWED_PARTS_OF_CONVERTER.map(value => ({
    value: String(value),
    label: formatPartOfConverter(value, intl, true),
  }));

  const onClose = () => dispatch(hideModalForm());

  const formikContext = useFormik<FormValues>({
    initialValues: {
      unitsCount: typedParams?.shoppingCartElement?.unitsCount,
      partOfConverter: String(typedParams?.shoppingCartElement?.partOfConverter),
    },
    validationSchema: validationSchema(intl),
    onSubmit: async values => {
      if (!params || !('shoppingCartElement' in params)) return;

      const dataToSave = {
        id: typedParams.shoppingCartElement.id,
        unitsCount: values.unitsCount,
        partOfConverter: Number(values.partOfConverter),
      };

      try {
        if (typedParams.type === 'single') {
          await editItemInCart.mutateAsync(dataToSave);
        } else if (typedParams.type === 'multi') {
          await editItemsGroupInCart.mutateAsync(dataToSave);
        }
        onClose();
        await onUpdate();

        dispatch(
          snackBarPushSuccess(intl.formatMessage({ id: 'ShoppingCart.Modal.Edit.Success' })),
        );
      } catch (e) {
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
        );
      }
    },
    enableReinitialize: true,
  });
  const { values, handleChange, handleBlur, touched, errors, resetForm, handleSubmit } =
    formikContext;

  const isLoading = editItemInCart.isLoading || editItemsGroupInCart.isLoading;

  const submitDisabled =
    !formikContext.dirty || !formikContext.isValid || formikContext.isSubmitting || isLoading;

  useEffect(() => {
    resetForm();
  }, [enabled]);

  return (
    <LoadableContent loading={isLoading} mode={LoadableContentModes.OVERLAY} drawContent>
      <ModalPortal
        isOpen={enabled}
        innerPadding={false}
        header={intl.formatMessage({ id: 'ShoppingCart.Modal.Edit.Title' })}
        rightButtons={[
          <Button
            onClick={onClose}
            variant="transparent"
            content="text"
            label={intl.formatMessage({ id: 'Global.Cancel' })}
          />,
          <Button
            onClick={() => handleSubmit()}
            disabled={submitDisabled}
            content="text"
            label={intl.formatMessage({ id: 'Global.Confirm' })}
          />,
        ]}
      >
        <ModalFormContent>
          <FormikProvider value={formikContext}>
            <FieldSwitcher
              id="part-of-converter"
              label={intl.formatMessage({ id: 'ShoppingCart.Modal.PartOfConverter' })}
              name="partOfConverter"
              value={values.partOfConverter}
              onChange={handleChange}
              onBlur={handleBlur}
              options={partOfConverterOptions}
              required
              // TODO add error prop when https://alpharec.atlassian.net/browse/NEWA-2951 is ready
            />
            <FieldNumber
              label={intl.formatMessage({ id: 'ShoppingCart.Modal.UnitsCount' })}
              name="unitsCount"
              value={values.unitsCount}
              onChange={handleChange}
              onBlur={handleBlur}
              min={1}
              max={SHARED.MAX_DB_INTEGER as number}
              error={touched.unitsCount && errors.unitsCount}
              required
            />
          </FormikProvider>
        </ModalFormContent>
      </ModalPortal>
    </LoadableContent>
  );
};

export const EditItemModal = withAlphamartIntlProvider(
  EditShoppingCartItemModalComponent,
  messages,
);
