import React, { useMemo } from 'react';
import { InputGroup, OptionsProps } from '@alpha-recycling/component-library';

import { useLanguage } from 'shared/hooks/useLanguage';
import { useGetCountries } from 'shared/queries';
import { FieldInputProps, FieldInputRaw } from '../FieldInput/FieldInput';
import { FieldSelectRaw } from '../FieldSelect/FieldSelect';
import { withFieldLabel } from '../FieldWrapper/FieldLabel';

interface Props extends FieldInputProps {
  name: string;
  phoneError?: React.ReactNode;
  prefixError?: React.ReactNode;
  prefixName: string;
  prefixValue?: string;
  placeholder?: string;
}

export const FieldPhoneNumberLayout = ({
  phoneError,
  prefixError,
  prefixName,
  prefixValue = '',
  required,
  ...props
}: Props): React.ReactElement => {
  const { data: countries } = useGetCountries();
  const language = useLanguage();

  const countriesOptions = useMemo(() => {
    const createLabel = (phonePrefix, name) =>
      name?.includes('(') || name?.includes(')')
        ? `${phonePrefix} [${name}]`
        : `${phonePrefix} (${name})`;

    return countries!
      .map(country => ({ ...country, name: country.name[language] }))
      .reduce<{ phonePrefix; name; alphaCode2 }[]>((acc, curr) => {
        const samePrefix = acc.find(c => c.phonePrefix === curr.phonePrefix);
        if (samePrefix) {
          return [
            ...acc.filter(c => c.phonePrefix !== samePrefix.phonePrefix),
            { ...curr, name: `${samePrefix.name}, ${curr.name}` },
          ];
        }
        return [...acc, curr];
      }, [])
      .reduce<OptionsProps[]>((acc, { phonePrefix, name, alphaCode2 }) => {
        if (alphaCode2 === 'US' || alphaCode2 === 'CA' || alphaCode2 === 'UM') {
          return [{ label: createLabel(phonePrefix, name), value: phonePrefix }, ...acc];
        }

        return [...acc, { label: createLabel(phonePrefix, name), value: phonePrefix }];
      }, []);
  }, [countries]);

  return (
    <InputGroup size="left-l" disabled={props.disabled}>
      <FieldSelectRaw
        name={prefixName}
        value={prefixValue}
        options={countriesOptions}
        error={!!phoneError || !!prefixError}
        errorMessage={String(phoneError) || String(prefixError)}
        placeholder={props.placeholder}
      />
      <FieldInputRaw {...props} error={phoneError || prefixError} />
    </InputGroup>
  );
};

const FieldPhoneNumber = withFieldLabel(FieldPhoneNumberLayout);

export { FieldPhoneNumber, FieldPhoneNumberLayout as FieldPhoneNumberRaw };
