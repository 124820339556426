import React from 'react';
import { Button, ModalPortal } from '@alpha-recycling/component-library';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { LoadableContent } from 'components/shared/Loader';
import { useTypedIntl } from 'locale/messages';
import { ModalFormType, SHARED } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';
import { ThresholdsType, UsageThresholds } from 'shared/types';
import { fetchUser } from 'store/auth';
import { hideModalForm, ThresholdsModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { setCompanyUsage } from 'store/thresholdsUsageSlice';
import { theme } from 'theme';
import { FieldInput } from '../Fields/FieldInput/FieldInput';
import { ModalFormContent, ModalFormInputWrapper } from '../forms/Form/ModalForm.styles';
import { IconTooltip } from '../IconTooltip';

interface Props {
  modalEndpoint: ThresholdsType;
}

export const ThresholdsModal = ({ modalEndpoint }: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const { isPending } = useAppSelector(state => state.thresholdsUsage);
  const dispatch = useAppDispatch();
  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);
  const { companiesThresholds, usersThresholds } = useCurrentUser();

  const paramsTyped = params as ThresholdsModalForm;
  const usageLimitModal = modalType === ModalFormType.UsageLimit;

  const filterCompany: UsageThresholds = companiesThresholds!.find(
    (item: UsageThresholds) => item.assignedTo === paramsTyped?.assignedId,
  )!;

  const filterUser: UsageThresholds = usersThresholds!.find(
    (item: UsageThresholds) => item.assignedTo === paramsTyped?.assignedId,
  )!;

  const thresholdsValidationSchema = Yup.number()
    .min(1, intl.formatMessage({ id: `Global.Thresholds.Modal.Min` }))
    .max(SHARED.MAX_DB_INTEGER, intl.formatMessage({ id: `Global.Thresholds.Modal.Max` }))
    .typeError(intl.formatMessage({ id: `Global.Thresholds.Modal.shouldBeInt` }))
    .integer(intl.formatMessage({ id: `Global.Thresholds.Modal.shouldBeInt` }))
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

  const validationSchema = Yup.object().shape({
    maxConverterSearchesPerDay: thresholdsValidationSchema,
    maxConverterViewsPerDay: thresholdsValidationSchema,
  });

  const formikContext = useFormik({
    enableReinitialize: true,
    initialValues: {
      maxConverterSearchesPerDay:
        modalEndpoint === ThresholdsType.COMPANY
          ? filterCompany?.maxConverterSearchesPerDay
          : filterUser?.maxConverterSearchesPerDay,
      maxConverterViewsPerDay:
        modalEndpoint === ThresholdsType.COMPANY
          ? filterCompany?.maxConverterViewsPerDay
          : filterUser?.maxConverterViewsPerDay,
      companyName: '',
    },
    validationSchema,
    onSubmit: async x => x,
  });

  const { values, handleChange, getFieldMeta, handleBlur, isValidating, isSubmitting } =
    formikContext;

  const handleCancelClick = () => {
    formikContext.resetForm();
    dispatch(hideModalForm());
  };

  const handleConfirmClick = async () => {
    try {
      await dispatch(
        setCompanyUsage(modalEndpoint, {
          maxConverterSearchesPerDay: values.maxConverterSearchesPerDay
            ? +values.maxConverterSearchesPerDay
            : null,
          maxConverterViewsPerDay: values.maxConverterViewsPerDay
            ? +values.maxConverterViewsPerDay
            : null,
          assignedTo: paramsTyped.assignedId,
        }),
      );
      dispatch(fetchUser(true));
      formikContext.resetForm();
      dispatch(hideModalForm());
      dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'Global.UsageThresholds.Success' })));
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  const submitDisabled =
    !formikContext.isValid || isValidating || isSubmitting || !formikContext.dirty;

  const getErrors = name => {
    const { touched: fieldTouched, error } = getFieldMeta(name);
    return fieldTouched && error;
  };

  return (
    <ModalPortal
      isOpen={isOpen && usageLimitModal}
      innerPadding={false}
      header={
        paramsTyped?.assignedName &&
        intl.formatMessage(
          {
            id: 'Global.Thresholds.Title',
          },
          {
            companyName: paramsTyped.assignedName,
          },
        )
      }
      rightButtons={[
        <Button
          onClick={handleCancelClick}
          variant="transparent"
          content="text"
          label={intl.formatMessage({ id: 'Global.Cancel' })}
        />,
        <Button
          onClick={handleConfirmClick}
          content="text"
          disabled={submitDisabled}
          label={intl.formatMessage({ id: 'Global.Thresholds.Modal.Accept' })}
        />,
      ]}
    >
      <LoadableContent mode={LoadableContent.MODE.FULL} loading={isPending} drawContent />
      <ModalFormContent>
        <FormikProvider value={formikContext}>
          <ModalFormInputWrapper>
            <FieldInput
              label={intl.formatMessage({
                id: 'Global.Thresholds.maxConverterSearches',
              })}
              name="maxConverterSearchesPerDay"
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                values.maxConverterSearchesPerDay ? String(values.maxConverterSearchesPerDay) : ''
              }
              error={getErrors('maxConverterSearchesPerDay')}
              data-cy="max-converter-searches-per-day"
              autoComplete="off"
            />
            <IconTooltip
              key="warning"
              icon="Info"
              size="big"
              color={theme.colors.white}
              tooltip={intl.formatMessage({
                id: 'Global.Thresholds.maxConverterSearches.Tooltip',
              })}
            />
          </ModalFormInputWrapper>
          <ModalFormInputWrapper>
            <FieldInput
              label={intl.formatMessage({ id: 'Global.Thresholds.maxConverterViews' })}
              name="maxConverterViewsPerDay"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.maxConverterViewsPerDay ? String(values.maxConverterViewsPerDay) : ''}
              error={getErrors('maxConverterViewsPerDay')}
              data-cy="max-converter-views-per-day"
              autoComplete="off"
            />
            <IconTooltip
              key="warning"
              icon="Info"
              size="big"
              color={theme.colors.white}
              tooltip={intl.formatMessage({
                id: 'Global.Thresholds.maxConverterViews.Tooltip',
              })}
            />
          </ModalFormInputWrapper>
        </FormikProvider>
      </ModalFormContent>
    </ModalPortal>
  );
};
