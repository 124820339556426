import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InputActionMeta } from 'react-select';
import { OptionsProps, Select, SelectProps } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';
import { useField } from 'formik';
import { kebabCase } from 'lodash';
import { matchSorter } from 'match-sorter';

import { useTypedIntl } from 'locale/messages';
import { useDropdownPlacement } from 'shared/hooks';
import { usePrevious } from 'shared/hooks/usePrevious';
import { withFieldLabel } from '../FieldWrapper/FieldLabel';

export const SelectWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

export type FieldSelectOnChangeSingle = (val?: OptionsProps) => void;
export type CustomSearchFunction = (options: OptionsProps[], inputText: string) => OptionsProps[];
export interface FieldSelectProps extends Omit<SelectProps, 'value' | 'error'> {
  customSearchFunction?: CustomSearchFunction;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  value?: string | number | null;
  error?: string | boolean | React.ReactNode;
  clearable?: boolean;
}

export const FieldSelectLayout = React.memo<FieldSelectProps>(
  ({
    disabled,
    name = '',
    onBlur,
    onChange,
    onFocus,
    options,
    searchable = true,
    value = '',
    placeholder,
    error,
    required,
    clearable = true,
    customSearchFunction,
    onInputChange,
  }) => {
    const [, { touched }, { setValue, setTouched }] = useField(name);
    const [filteredOptions, setFilteredOptions] = useState<OptionsProps[]>([]);
    const previousOptions = usePrevious(options, []);
    const { dropdownRef, refreshDropdownPlacement } = useDropdownPlacement('bottom');
    const intl = useTypedIntl();

    useEffect(() => {
      setFilteredOptions(options);
      const newOptionsAreEmpty = previousOptions?.length !== 0 && options?.length === 0;
      const hasValue = Array.isArray(value) ? value.length !== 0 : value !== null;
      if (newOptionsAreEmpty && hasValue) {
        // TODO: unexpected change event on initial empty user types list
        handleChange(null);
      }
    }, [options]);

    useEffect(() => {
      value && !touched && setTouched(true);
    }, [value, touched]);

    const handleInputChange = useCallback(
      val => {
        if (searchable && val) {
          setFilteredOptions(
            customSearchFunction?.(options, val) || matchSorter(options, val, { keys: ['label'] }),
          );
        } else if (!val) {
          setFilteredOptions(options);
        }

        return val;
      },
      [options],
    );

    const handleChange = val => {
      onChange?.(val?.value);
      setValue(val?.value ?? null);
    };

    const handleFocus = useCallback(e => onFocus?.(e), [onFocus]);

    const handleBlur = useCallback(
      e => {
        setTouched(true);
        setFilteredOptions(options);
        onBlur?.(e);
      },
      [setTouched, onBlur, options],
    );

    const selected = useMemo(() => {
      if (!value) return null;

      return options?.find(option => value === option?.value);
    }, [value, options]);

    return (
      <SelectWrapper ref={dropdownRef} data-cy={kebabCase(name)}>
        <Select
          placeholder={
            placeholder === ''
              ? ''
              : placeholder || intl.formatMessage({ id: 'Global.Fields.Select.Placeholder' })
          }
          disabled={disabled}
          searchable={searchable}
          name={name}
          onChange={disabled ? undefined : handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          options={(filteredOptions ?? []).filter(Boolean)}
          value={selected}
          noOptionsMessage={intl.formatMessage({ id: 'Global.Fields.Select.NoOptions' })}
          error={!!error}
          required={required || !clearable}
          isMenuOpen={disabled ? false : undefined}
          onInputChange={onInputChange ?? handleInputChange}
          onMenuOpen={refreshDropdownPlacement}
          {...(!!error && { errorMessage: String(error) })}
        />
      </SelectWrapper>
    );
  },
);

const FieldSelect = withFieldLabel(FieldSelectLayout);

export { FieldSelect, FieldSelectLayout as FieldSelectRaw };
