import React from 'react';
import { Icon, Tooltip } from '@alpha-recycling/component-library';

import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { NotificationListItem as NotificationListItemType, NotificationStatus } from 'shared/types';
import { layers } from 'theme';
import { useTypedIntl } from './locale/messages';
import {
  DateText,
  ListItemContainer,
  ListItemContent,
  ListItemStatus,
  UnreadMarker,
} from './NotificationListItem.styles';

interface Props {
  notification: NotificationListItemType;
  onClick: (id: number) => unknown;
}

export function NotificationListItem({ notification, onClick }: Props): React.ReactElement {
  const intl = useTypedIntl();

  const handleItemClick = item => {
    const contentSelected = !!window.getSelection()?.toString().length;
    if (!contentSelected) onClick(item.id);
  };

  return (
    <ListItemContainer onClick={() => handleItemClick(notification)}>
      <ListItemStatus>
        {!notification.readAt && (
          <Tooltip
            position="bottom"
            label={intl.formatMessage({ id: 'Notifications.ListItem.Unread' })}
          >
            <UnreadMarker />
          </Tooltip>
        )}
        {notification.status === NotificationStatus.RESOLVED && (
          <Tooltip
            zIndex={layers.tooltip}
            position="left"
            label={intl.formatMessage({ id: 'Notifications.ListItem.Status.RESOLVED' })}
          >
            <Icon name="Check" size="medium" />
          </Tooltip>
        )}
      </ListItemStatus>
      <ListItemContent>
        {notification.subject} <br />
        <DateText>{formatDateWithTime(notification.createdAt)}</DateText>
      </ListItemContent>
    </ListItemContainer>
  );
}
