import React, { useEffect } from 'react';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelLayout } from 'components/shared/PanelLayout/PanelLayout';
import { SearchChangEvent, SearchInput } from 'components/shared/SearchBar/SearchInput';
import { FilterableModules, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useAlphamartLocation, useAlphamartNavigate, useMediaQuery } from 'shared/hooks';
import { sleep } from 'shared/utils/sleep';
import { fetchMetalPrices } from 'store/metalPricesSlice';
import { closeNotifications, openNotifications } from 'store/notificationsList';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { DashboardCharts } from './DashboardCharts/DashboardCharts';
import { messages, TypedFormattedMessage as FormattedMessage } from './locale/messages';
import { dashboardStyles } from './Dashboard.styles';
import { ManualEntryPriceNotification } from './ManualEntryPriceNotification';

const Dashboard = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const location = useAlphamartLocation();
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.DASHBOARD]);
  const isMaxMd = useMediaQuery(MEDIA_QUERY.MAX_MD);

  useEffect(() => {
    dispatch(closeNotifications());
    const handler = async () => {
      const shouldOpenNotifications = new URLSearchParams(location.search).get('notificationsOpen');
      if (shouldOpenNotifications) {
        // sleep is needed to make <SSTransition /> in <PanelContentSidebar /> work properly
        await sleep(100);
        dispatch(openNotifications());
      }
    };
    handler();
  }, []);

  useEffect(() => {
    dispatch(fetchMetalPrices(savedFilters.data.from));

    if (isMaxMd) {
      return window.scrollTo(0, 0);
    }
  }, [savedFilters.data.from]);

  const handleSearch = ({ query }: SearchChangEvent) => {
    dispatch(
      saveFilters({
        filterKey: FilterableModules.CONVERTERS,
        values: { query },
      }),
    );
    navigate('/converters');
  };

  const authorize = useAuthorization();
  const canSeeConvertersSearch = authorize(PERMISSIONS.CONVERTERS.LIST);

  return (
    <AlphamartIntlProvider messages={messages}>
      <PanelLayout>
        <PanelHeader title={<FormattedMessage id="Dashboard.Header.Title" />}>
          {canSeeConvertersSearch && <SearchInput searchAfter={3} onSearchChanged={handleSearch} />}
        </PanelHeader>
        <PanelContent>
          <PanelContentMain className={dashboardStyles}>
            <ManualEntryPriceNotification />
            <DashboardCharts />
          </PanelContentMain>
        </PanelContent>
      </PanelLayout>
    </AlphamartIntlProvider>
  );
};

export { Dashboard };
