import { useAppSelector } from 'store/shared/hooks';

export const useMarketToolUrls = () => {
  const config = useAppSelector(state => state.config);
  const root = config?.marketTool?.url ?? '';

  return {
    home: `${root}/`,
    manage: `${root}/manage`,
    profile: `${root}/profile`,
  };
};
